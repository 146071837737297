import {IWake_Abstract, IsDevFocused, ShowMessageBox} from '@denjpeters/intelliwakereact';
import {UserState} from "./Stores/user/types";
import {Session_Login, Session_Logout} from "./Stores/user/actions";
import deepEqual from "deep-equal";
import {Dispatch} from "redux";
import * as serviceWorker from './serviceWorker';
import {UpdateSystem} from "./Stores/system/actions";
import store from "./Stores/store";
import {AppSettingsAllUpdated} from "./Stores/appsettings/actions";

export class IWake extends IWake_Abstract {
    user: UserState | null;
    dispatch: Dispatch;

    constructor(user: UserState | null, dispatch: Dispatch) {
        super();

        this.user = user;
        this.dispatch = dispatch;
    }

    ReloadApp(): boolean {
        console.log('Do refresh...');

        serviceWorker.unregister();
        window.location.reload();
        return true;

        // return false;
    }

    IsDevFocused(): boolean {
        return IsDevFocused();
    }

    IsOldAPI(): boolean {
        return true;
    }

    ShowMessageBox(message: string, color?: string, messageBody?: string | null, autoDismiss?: boolean): void {
        ShowMessageBox(message, color, messageBody, autoDismiss)(this.dispatch);
    }

    APIAmendHeaderAuthorization(headersAuthorization: any): void {
        let settings = {...store.getState().appSettings};
        if (settings.shouldSave) {
            delete settings.shouldSave;

            // console.log('Saving Settings');

            headersAuthorization.Settings = settings;
        }
    }

    APIReceiveUser(receivedUser: any, resetSelections: boolean): void {
        if (!!receivedUser && receivedUser.length !== 0 && receivedUser.userID > 0) {
            const settings = {...store.getState().appSettings, ...receivedUser.Settings};

            delete receivedUser.Settings;

            const newUser: UserState = {
                loggedIn: true,
                userID: receivedUser.userID,
                firstname: receivedUser.firstname,
                lastname: receivedUser.lastname,
                email: receivedUser.email,
                caller: receivedUser.caller,
                smsphone: receivedUser.smsphone,
                features: (receivedUser.features ?? "").split(",")
            };

            if (newUser.userID) {
                if (!this.user) {
                    console.log('Adding User');

                    Session_Login(newUser)(this.dispatch);
                    setTimeout(() => {
                        window.sessionStorage.removeItem('referrer');
                    }, 2000);
                } else {
                    if (!deepEqual(this.user, newUser)) {
                        console.log('Updating User', this.user, newUser);

                        Session_Login(newUser)(this.dispatch);
                        setTimeout(() => {
                            window.sessionStorage.removeItem('referrer');
                        }, 2000);
                    }
                }

                settings.shouldSave = false;

                if (!deepEqual(settings, store.getState().appSettings)) {
                    console.log('Updating Settings', settings, store.getState().appSettings);
                    AppSettingsAllUpdated(settings)(this.dispatch);
                }
            }
        }
    }

    APIFailedAuthentication(): void {
        window.sessionStorage.setItem('referrer', window.location.pathname);
        console.log("Failed Authentication");
        Session_Logout()(this.dispatch);
    }

    APIUpdateSystem(systemData: any): void {
        UpdateSystem(systemData.app_version,
            systemData.html_version,
            systemData.db_version,
            systemData.environment,
            systemData.environment_name)(this.dispatch);
    }
}

import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import {Link, Redirect, useHistory} from "react-router-dom";
import {HasFeature, TTSFeatures} from "../../Data/TTSFeatures";
import {Button, Col, Container, ListGroup, ListGroupItem, Row} from "reactstrap";
import axios, {CancelTokenSource} from "axios";
import {APIProcess, ToDigitsBlank, InputSearch, GetPathThrough, MasterDetail, MDMaster, MDLink, MDDetail, GetPathComponentAfter} from '@denjpeters/intelliwakereact';
import ActiveDD from "../Generics/ActiveDD";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter} from "@fortawesome/pro-regular-svg-icons";
import ActivityCode from "./ActivityCode";
import {Itblactivitycode} from "../../Data/Tables/tblactivitycode";
import {IWake} from "../../IWake";

interface ItblactivitycodeList extends Itblactivitycode {
    projects_using_count: number
}

const ActivityCodes = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const isMounted = useRef(true);
    const {user} = useSelector((state: AppState) => state);
    const [activitycodes, setActivityCodes] = useState(null as ItblactivitycodeList[] | null);
    const [activeView, setActiveView] = useState(1 as 1 | -1 | 0);
    const [search, setSearch] = useState("");

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    const updateActivityCode = (updatedActivityCode: ItblactivitycodeList) => {
        if (!!activitycodes) {
            if (!!updatedActivityCode.activityID) {
                if (!window.location.pathname.endsWith('/ActivityCode' + updatedActivityCode.activityID)) {
                    history.push(GetPathThrough("/ActivityCodes") + "/ActivityCode/" + updatedActivityCode.activityID);
                }

                setActivityCodes([
                    ...activitycodes.filter(activitycode => activitycode.activityID !== updatedActivityCode.activityID),
                    updatedActivityCode
                ]);
            }
        }
    };

    useEffect(() => {
        let cancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();
        isMounted.current = true;

        APIProcess('activitycode', 'GetList', {ActiveView: activeView}, cancelTokenSource)(iWake)
            .then((results) => {
                if (isMounted.current && cancelTokenSource) {
                    for (let activitycodeItem of results.activitycodes) {
                        activitycodeItem.active = Number(activitycodeItem.active);
                    }
                    setActivityCodes((results.activitycodes as ItblactivitycodeList[]).sort(function (a, b) {
                        return (a.name).localeCompare((b.name), undefined, {sensitivity: 'accent'})
                    }));
                }
            })
            .catch(() => {
                if (isMounted.current && cancelTokenSource) {
                    setActivityCodes([]);
                }
            })
            .finally(() => {
                cancelTokenSource = null;
            });

        return () => {
            isMounted.current = false;
            if (cancelTokenSource) {
                cancelTokenSource.cancel();
                cancelTokenSource = null;
            }
        }
    }, [dispatch, activeView, iWake]);

    const searchTerms = useMemo(() => !search ? [] : search
        .split(' ')
        .map(term => term.trim().toLowerCase())
        .filter(term => !!term), [search]);

    const activitycodeList = useMemo(() => (activitycodes ?? [])
        .filter(userItem => searchTerms.length === 0 || (searchTerms.every(term => (userItem.name.toLowerCase().includes(term)))))
        .sort((a, b) => a.name.localeCompare(b.name, undefined, {sensitivity: 'base'})), [searchTerms, activitycodes]);

    if (!HasFeature(user, TTSFeatures.Feature_TimeSheet_Admin)) {
        return (
            <Redirect to="/TimeEntry"/>
        );
    }

    return (
        <Container fluid className="px-0">
            <MasterDetail breakAt="sm" mdPath="/ActivityCodes" backText="ActivityCodes">
                <MDMaster width="20em">
                    <Row className="p-2">
                        <Col>
                            <ActiveDD size="sm" handleSelectID={setActiveView} selectedID={activeView} caret/>
                        </Col>
                        <Col className="text-right">
                            <Button color="secondary" size="sm" tag={Link} to={GetPathThrough("/ActivityCodes") + "/ActivityCode/New"}>Add</Button>
                        </Col>
                    </Row>
                    <Row className="p-2">
                        <Col>
                            <div className="input-group input-group-filter">
                                <div className="input-group-prepend">
                                    <span className="input-group-text"><FontAwesomeIcon icon={faFilter} fixedWidth/></span>
                                </div>
                                <InputSearch className="noFocusRing" placeholder="Filter" aria-label="Filter" triggerSearchText={setSearch}/>
                            </div>
                        </Col>
                    </Row>
                    <ListGroup flush className="fill-height-scroll text-large-md-smaller">
                        {!activitycodes ?
                            <ListGroupItem className="text-secondary">Loading...</ListGroupItem>
                            : activitycodeList.length === 0 ?
                                <ListGroupItem>No activity codes</ListGroupItem>
                                :
                                activitycodeList.map((activitycode) =>
                                    <MDLink tag="li" panel="ActivityCode" className={"list-group-item list-group-item-action py-1" + (activitycode.active === 1 ? "" : " text-gray")} key={activitycode.activityID} id={activitycode.activityID}>
                                        <span>{activitycode.name}</span>
                                        <span className="float-right">{ToDigitsBlank(activitycode.projects_using_count, 0)}</span>
                                    </MDLink>
                                )
                        }
                    </ListGroup>
                </MDMaster>
                <MDDetail panel="ActivityCode" titleText="ActivityCode">
                    <ActivityCode activityID={GetPathComponentAfter('/ActivityCode')} updateActivityCode={updateActivityCode}/>
                </MDDetail>
            </MasterDetail>
        </Container>
    );
};

export default ActivityCodes;

import React from 'react';
import {Col, Container, Row} from "reactstrap";
import appIconBlueTransparent from '../../Assets/GCILogo.png';

export const UnsupportedBrowser = () => {
        return (
            <Container>
                <Row>
                    <Col className="text-center my-2">
                        <img className="img-xl" src={appIconBlueTransparent} alt="GCI TTS"/>
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col>
                        <h3 className="text-center">This browser is not supported.</h3>
                    </Col>
                </Row>
                <Row className="mt-5 narrow">
                    <Col>
                        <p>
                            <strong>The GCI recommended browser is Edge.</strong>
                        </p>
                        <p>
                            Older browsers like Internet Explorer are not as secure as more modern browsers.  Please use Edge or another modern browser as your default for browsing web sites.  Internet Explorer should only be used for older web-sites that may require it.
                        </p>
                    </Col>
                </Row>
            </Container>
        );
};

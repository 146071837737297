import React, {useEffect, useMemo, useRef, useState} from 'react';
import {
    Col,
    Row, Table
} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import axios, {CancelTokenSource} from "axios";
import {APIProcess, ShowActivityOverlay, HideActivityOverlay, ToDigitsBlank, ToDigits} from '@denjpeters/intelliwakereact';
import {Itblproject} from "../../Data/Tables/tblproject";
import ProjectDD from "../Generics/ProjectDD";
import EmployeeProjectDD from "../Generics/EmployeeProjectDD";
import {IUsers} from "../../Data/Views/Users";
import {Itblproject_budget} from "../../Data/Tables/tblproject_budget";
import {IView_TimeData} from "../../Data/Views/View_TimeData";
import {ASRSetViewDate} from "../../Stores/appsessionremembers/actions";
import { useHistory } from 'react-router-dom';
import {IWake} from "../../IWake";

interface IBudget extends Itblproject_budget {
    activityName: string
}

interface IReportStructure {
    project: Itblproject,
    user: IUsers,
    budgets: IBudget[],
    hours: IView_TimeData[]
}

const ProjectEmployee = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const isMounted = useRef(true);
    const {user, appSessionRemembersChange} = useSelector((state: AppState) => state);
    const [reportStructure, setReportStructure] = useState(null as IReportStructure | null);

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    const openTime = (date: string) => {
        ASRSetViewDate(date)(dispatch);
        history.push('/TimeSheet');
    }

    useEffect(() => {
        let cancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();
        isMounted.current = true;

        ShowActivityOverlay()(dispatch);
        APIProcess('project', 'TimeByProjectUser', {
            projectID: appSessionRemembersChange.viewProjectID ?? 0,
            userID: appSessionRemembersChange.viewUserID ?? 0
        }, cancelTokenSource)(iWake)
            .then((results) => {
                // console.log(results.reportStructure);
                if (isMounted.current && cancelTokenSource) {
                    setReportStructure(results);
                }
            })
            .catch(() => {
                if (isMounted.current && cancelTokenSource) {
                    setReportStructure(null);
                }
            })
            .finally(() => {
                HideActivityOverlay()(dispatch);
                cancelTokenSource = null;
            });

        return () => {
            isMounted.current = false;
            if (cancelTokenSource) {
                cancelTokenSource.cancel();
                cancelTokenSource = null;
            }
        }
    }, [appSessionRemembersChange.viewProjectID, appSessionRemembersChange.viewUserID, dispatch, iWake]);

    const assignedTotal: number = useMemo(() => {
        if (!reportStructure) return 0;
        return reportStructure.budgets
            .map(budget => budget.hours ?? 0)
            .reduce((prev, next) => +prev + +next, 0)
    }, [reportStructure]);

    const actualTotal: number = useMemo(() => {
        if (!reportStructure) return 0;
        return reportStructure.hours
            .map(hour => hour.total_hours ?? 0)
            .reduce((prev, next) => +prev + +next, 0)
    }, [reportStructure]);

    return (
        <>
            <Row className="mb-2">
                <Col>
                    <h3 className="text-center">Time-by-Project-by-Employee</h3>
                    <h4 className="text-center"><ProjectDD/></h4>
                    <h5 className="text-center">
                        <EmployeeProjectDD projectID={!!reportStructure ? reportStructure.project.projectID : 0}/></h5>
                </Col>
            </Row>
            {!!reportStructure ?
                <Row className="fill-height-scroll">
                    <Col>
                        <Row>
                            <Col xs={2} className="text-right strong">
                                Assigned
                            </Col>
                            <Col md={4}>
                                <Table bordered size="sm">
                                    <thead>
                                    <tr className="table-secondary">
                                        <th>Activity</th>
                                        <th className="text-right">Assigned</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {reportStructure.budgets.map(budget =>
                                        <tr key={(budget.activityID ?? 0).toString()}>
                                            <td>{budget.activityName}</td>
                                            <td className="text-right">{ToDigits(budget.hours, 2)}</td>
                                        </tr>
                                    )}
                                    </tbody>
                                    <tfoot>
                                    <tr>
                                        <th className="text-right">Total Assigned:</th>
                                        <th className="text-right">
                                            {ToDigits(assignedTotal, 2)}
                                        </th>
                                    </tr>
                                    <tr>
                                        <th className="text-right">Used Hours:</th>
                                        <th className="text-right">
                                            {ToDigits(actualTotal, 2)}
                                        </th>
                                    </tr>
                                    <tr>
                                        <th className="text-right">Available Hours:</th>
                                        <th className="text-right">
                                            {ToDigits(assignedTotal - actualTotal, 2)}
                                        </th>
                                    </tr>
                                    </tfoot>
                                </Table>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={2} className="text-right strong">
                                Time Entered
                            </Col>
                            <Col md={8}>
                                <Table hover bordered size="sm">
                                    <thead>
                                    <tr className="table-secondary">
                                        <th>Activity</th>
                                        <th className="text-right">Week Ending</th>
                                        <th className="text-right">Hours</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {reportStructure.hours.map(hour =>
                                        <tr key={hour.timeID.toString()} onClick={() => openTime(hour.date)}>
                                            <td>{hour.activityName}</td>
                                            <td className="text-right">{hour.date}</td>
                                            <td className="text-right">{ToDigitsBlank(hour.total_hours, 2)}</td>
                                        </tr>
                                    )}
                                    </tbody>
                                    <tfoot>
                                    <tr>
                                        <th colSpan={2} className="text-right">Used Hours:</th>
                                        <th className="text-right">
                                            {ToDigitsBlank(actualTotal, 2)}
                                        </th>
                                    </tr>
                                    </tfoot>
                                </Table>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                :
                null
            }
        </>
    );
};

export default ProjectEmployee;

/**
 * Automatically generated: 2020-06-15 15:30:50
 * © 2020, Solid Basis Ventures, LLC.
 * DO NOT MODIFY
 *
 * Table Manager for: tblaccrualbank
 */

export interface Itblaccrualbank {
	accrualbankID: number,
	employeeNo: string,
	startYear: number,
	type: string,
	limithours: number
}

export const initialtblaccrualbank: Itblaccrualbank = {
	accrualbankID: 0,
	employeeNo: '',
	startYear: 0,
	type: '',
	limithours: 0
};

import React, {useEffect, useMemo, useState} from 'react';
import {Itbluser} from "../../Data/Tables/tbluser";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import {Button, Col, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {APIProcess, ShowActivityOverlay, HideActivityOverlay, ToDigits, InputNumber, CleanNumber} from '@denjpeters/intelliwakereact';
import {IWake} from "../../IWake";

interface IProps {
    isOpen: boolean,
    doForceRefresh: (() => void),
    closeModal: (() => void),
    tbluser: Itbluser,
    illnessUsed: number,
    illnessAdjustment: number
}

const UserIllnessAdjustModal = (props: IProps) => {
    const dispatch = useDispatch();
    const user = useSelector((state: AppState) => state.user);

    const [editAdjust, setEditAdjust] = useState(0.0);

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    const saveAdjust = () => {
        ShowActivityOverlay()(dispatch);
        APIProcess('User', 'AccrualUsedAdjustment', {
            userID: props.tbluser.userID,
            type: 'Illness',
            amount: editAdjust
        })(iWake)
            .then(() => {
                props.doForceRefresh();
            })
            .catch(() => {
            })
            .finally(() => {
                HideActivityOverlay()(dispatch);
            });
    }

    useEffect(() => {
        setEditAdjust(props.illnessAdjustment);
    }, [props.isOpen, props.illnessAdjustment]);

    return (
        <Modal backdrop keyboard toggle={props.closeModal} isOpen={props.isOpen}>
            <ModalHeader className="alert-info">
                Illness Hours Used - {props.tbluser.lastname}, {props.tbluser.firstname}
            </ModalHeader>
            <ModalBody>
                <div className="form-half">
                    <FormGroup>
                        <Label>Recorded Hours Used</Label>
                        <Col className="text-right form-control-plaintext">{ToDigits(props.illnessUsed - props.illnessAdjustment, 2)}</Col>
                    </FormGroup>
                    <FormGroup>
                        <Label>Adjustment Amount</Label>
                        <Col>
                            <InputNumber name="startYear" value={editAdjust} onChange={(e) => {
                                setEditAdjust(!!e.target.value ? CleanNumber(e.target.value) : 0);
                            }} integerScale={5} decimalScale={2}/>
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Label>New Hours Used</Label>
                        <Col className="text-right form-control-plaintext">{ToDigits(props.illnessUsed - props.illnessAdjustment + editAdjust, 2)}</Col>
                    </FormGroup>
                    <FormGroup>
                        <Label/>
                        <Col>
                            <Button color="info" hidden={editAdjust === props.illnessAdjustment} onClick={saveAdjust}>Save
                                Adjustment</Button>
                        </Col>
                    </FormGroup>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={props.closeModal}>Close</Button>
            </ModalFooter>
        </Modal>
    );
};

export default UserIllnessAdjustModal;

import React, {useEffect, useState, useRef, useMemo} from 'react';
import {Col, Container, Row} from "reactstrap";
import axios, {CancelTokenSource} from "axios";
import {APIProcess, ShowActivityOverlay, HideActivityOverlay} from '@denjpeters/intelliwakereact';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import {IWake} from "../../IWake";

export default function InfoPHP() {
    const dispatch = useDispatch();
    const isMounted = useRef(true);
    const {user} = useSelector((state: AppState) => state);
    const [infoPHP, setInfoPHP] = useState("");

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    useEffect(() => {
        let cancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();
        isMounted.current = true;

        ShowActivityOverlay()(dispatch);
        APIProcess('System', 'InfoPHP', {}, cancelTokenSource)(iWake)
            .then((results) => {
                if (isMounted.current && cancelTokenSource) {
                    setInfoPHP(results.InfoPHP);
                }
            })
            .catch(() => {
                if (isMounted.current && cancelTokenSource) {
                    setInfoPHP("");
                }
            })
            .finally(() => {
                HideActivityOverlay()(dispatch);
                cancelTokenSource = null;
            });

        return () => {
            isMounted.current = false;
            if (cancelTokenSource) {
                cancelTokenSource.cancel();
                cancelTokenSource = null;
            }
        }
    }, [dispatch, iWake]);

    return (
        <Container>
            <Row className="fill-height">
                <Col className="fill-height-scroll">
                    {/*<object width="100%" type="text/html">*/}
                        <div dangerouslySetInnerHTML={{__html: (infoPHP ?? "")}}/>
                    {/*</object>*/}
                </Col>
            </Row>
        </Container>
    );
}

import {IInvoicingSettings} from "../../WebControls/Invoice/Invoicing";

export interface AppSessionRemembers {
    requiresPasswordReset: boolean,
    viewDate: string,
    viewUserID: number | null,
    viewProjectID: number | null,
    approvalUserID: number | null,
    projectSort: 'Name' | 'NoAsc' | 'NoDesc',
    projectActive: 1 | 0 | -1,
    payrollDate: string | null,
    reportEmpTimeDateStart: string | null,
    reportEmpTimeDateEnd: string | null,
    userTab: string | null,
    invoicingSettings: IInvoicingSettings
}

export const ASR_SET_PASSWORDRESET = 'ASR_SET_PASSWORDRESET';
export const ASR_SET_VIEWDATE = 'ASR_SET_VIEWDATE';
export const ASR_SET_VIEWUSERID = 'ASR_SET_VIEWUSERID';
export const ASR_SET_VIEWPROJECTID = 'ASR_SET_VIEWPROJECTID';
export const ASR_SET_APPROVALUSERID = 'ASR_SET_APPROVALUSERID';
export const ASR_SET_PROJECTSORT = 'ASR_SET_PROJECTSORT';
export const ASR_SET_PROJECTACTIVE = 'ASR_SET_PROJECTACTIVE';
export const ASR_SET_PAYROLLDATE = 'ASR_SET_PAYROLLDATE';
export const ASR_SET_REPORTEMPTTIMEDATES = 'ASR_SET_REPORTEMPTTIMEDATES';
export const ASR_SET_INVOICINGSETTINGS = 'ASR_SET_INVOICINGSETTINGS';
export const ASR_SET_USERTAB = 'ASR_SET_USERTAB';

interface ASRSetPasswordResetAction {
    type: typeof ASR_SET_PASSWORDRESET
    payload: boolean
}

interface ASRSetViewDateAction {
    type: typeof ASR_SET_VIEWDATE
    payload: string
}

interface ASRSetViewUserIDAction {
    type: typeof ASR_SET_VIEWUSERID
    payload: number | null
}

interface ASRSetViewProjectIDAction {
    type: typeof ASR_SET_VIEWPROJECTID
    payload: number | null
}

interface ASRSetApprovalUserIDAction {
    type: typeof ASR_SET_APPROVALUSERID
    payload: number | null
}

interface ASRSetProjectSortAction {
    type: typeof ASR_SET_PROJECTSORT
    payload: 'Name' | 'NoAsc' | 'NoDesc'
}

interface ASRSetProjectActiveAction {
    type: typeof ASR_SET_PROJECTACTIVE
    payload: 1 | 0 | -1
}

interface ASRSetPayrollDateAction {
    type: typeof ASR_SET_PAYROLLDATE
    payload: string
}

interface ASRSetReportEmpTimeDatesAction {
    type: typeof ASR_SET_REPORTEMPTTIMEDATES
    payload: [string | null, string | null]
}

interface ASRSetInvoicingSettingsAction {
    type: typeof ASR_SET_INVOICINGSETTINGS
    payload: IInvoicingSettings
}

interface ASRSetUserTabAction {
    type: typeof ASR_SET_USERTAB
    payload: string | null
}

export type AppSessionRemembersActionTypes =
    ASRSetPasswordResetAction
    | ASRSetViewDateAction
    | ASRSetViewUserIDAction
    | ASRSetViewProjectIDAction
    | ASRSetApprovalUserIDAction
    | ASRSetProjectSortAction
    | ASRSetProjectActiveAction
    | ASRSetPayrollDateAction
    | ASRSetReportEmpTimeDatesAction
    | ASRSetInvoicingSettingsAction
    | ASRSetUserTabAction;

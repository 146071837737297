import * as React from 'react';
import {Redirect, Route, RouteProps} from 'react-router';
import {useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";

interface ProtectedRouteProps extends RouteProps {
    requireAuthentication?: boolean | undefined;

    forceHide?: boolean;

    isAllowed?: boolean;
    restrictedPath?: string;

    path: string;
}

const ProtectedRoute = (props: ProtectedRouteProps) => {
    const user = useSelector((state: AppState) => state.user);

    if (props.forceHide) {
        return null;
    } else if (props.requireAuthentication) {
        if (user.loggedIn) {
            if (props.isAllowed === false) {
                const renderComponent = () => <Redirect to={{pathname: props.restrictedPath ?? "/"}}/>;
                return <Route {...props} component={renderComponent} render={undefined}/>;
            } else {
                return <Route {...props} />;
            }
        } else if (window.localStorage.getItem('device_token')) {
            return null;
        } else {
            const renderComponent = () => <Redirect to={{pathname: props.restrictedPath ?? "/Login"}}/>;
            window.sessionStorage.setItem('referrer', window.location.pathname);
            return <Route {...props} component={renderComponent} render={undefined}/>;
        }
    } else {
        if (props.isAllowed === false) {
            const renderComponent = () => <Redirect to={{pathname: props.restrictedPath ?? "/"}}/>;
            return <Route {...props} component={renderComponent} render={undefined}/>;
        } else {
            return <Route {...props} />;
        }
    }
};

export default ProtectedRoute;

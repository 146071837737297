import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Col, Row, Button, Table, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {APIProcess, ShowActivityOverlay, HideActivityOverlay, InputSearch, PagesForRange} from '@denjpeters/intelliwakereact';
import {useDispatch, useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBug, faFilter} from "@fortawesome/pro-regular-svg-icons";
import {AppState} from "../../Stores/rootReducer";
import axios, {CancelTokenSource} from "axios";
import {IWake} from "../../IWake";

interface IErrorLog {
    errno: string,
    errstr: string,
    errfile: string,
    errline: number,
    errtrace: string,
    request_uri: string,
    count: number,
    no_users: number,
    eventtime_min: string,
    eventtime_max: string
}

interface IRef {
    pages: number | undefined,
    searchDelay: NodeJS.Timeout
}

const initialRef: IRef = {
    pages: undefined,
    searchDelay: setTimeout(() => {
    }, 1)
};

const ErrorLog = () => {
    const dispatch = useDispatch();
    const ref = useRef(initialRef);
    const isMounted = useRef(true);
    const pageCount = 20;
    const user = useSelector((state: AppState) => state.user);
    const [forceRender, setForceRender] = useState(true);
    const [errorLogs, setErrorLogs] = useState(undefined as (IErrorLog[] | undefined));
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");
    const [modalErrorLog, setModalErrorLog] = useState(undefined as (IErrorLog | undefined));

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    const setModal = (errorLog: IErrorLog) => setModalErrorLog(errorLog);
    const closeModal = () => setModalErrorLog(undefined);

    const refreshLog = () => {
        setForceRender(!forceRender);
    };

    const clearLog = async () => {
        ShowActivityOverlay()(dispatch);
        APIProcess('System', 'ErrorLogClear')(iWake)
            .then(() => {
            })
            .catch(() => {
            })
            .finally(() => {
                if (isMounted.current) {
                    setForceRender(!forceRender);
                }
                HideActivityOverlay()(dispatch);
            });
    };

    const generateLog = async () => {
        ShowActivityOverlay()(dispatch);
        APIProcess('System', 'ErrorLogGenerate')(iWake)
            .then(() => {
            })
            .catch(() => {
            })
            .finally(() => {
                if (isMounted.current) {
                    setForceRender(!forceRender);
                }
                HideActivityOverlay()(dispatch);
            });
    };

    useEffect(() => {
        let cancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();
        isMounted.current = true;

        ShowActivityOverlay()(dispatch);
        APIProcess('System', 'ErrorLogGetSummary', {
            page: page,
            count_per_page: pageCount,
            search: search
        }, cancelTokenSource)(iWake)
            .then((results) => {
                if (isMounted.current && cancelTokenSource) {
                    ref.current.pages = Math.ceil(results.ErrorLogSummaryCount / pageCount);
                    setErrorLogs((results.ErrorLogSummary as IErrorLog[]) ?? []);
                }
            })
            .catch(() => {
                if (isMounted.current && cancelTokenSource) {
                    ref.current.pages = 0;
                    setErrorLogs([]);
                }
            })
            .finally(() => {
                HideActivityOverlay()(dispatch);
                cancelTokenSource = null;
            });

        return () => {
            isMounted.current = false;
            if (cancelTokenSource) {
                cancelTokenSource.cancel();
                cancelTokenSource = null;
            }
        }
    }, [forceRender, page, search, iWake, dispatch]);

    return (
        <div className="fill-height p-3">
            <Row className="mb-1">
                <Col sm={6}>
                    <div className="page-header">
                        <h2 className="text-center text-sm-left"><FontAwesomeIcon icon={faBug} fixedWidth/> Error Log
                        </h2>
                    </div>
                </Col>
                <Col sm={6} className="text-center text-sm-right">
                    <Button color="outline-danger" className="m-1" onClick={generateLog} size="sm">Generate</Button>
                    <Button color="warning" className="m-1" onClick={refreshLog} size="sm">Refresh</Button>
                    <Button color="danger" className="m-1" onClick={clearLog} size="sm">Clear</Button>
                </Col>
            </Row>
            <Row className="mb-1">
                <Col sm="6">
                    <div className="input-group input-group-filter">
                        <div className="input-group-prepend">
                            <span className="input-group-text"><FontAwesomeIcon icon={faFilter} fixedWidth/></span>
                        </div>
                        <InputSearch className="noFocusRing" placeholder="Filter" aria-label="Filter" triggerSearchText={setSearch} triggerDelayAmount={1000}/>
                    </div>
                </Col>
                <Col sm="6">
                    <ul className={"pagination pagination-sm justify-content-end" + (((ref.current.pages ?? 1) > 1) ? '' : ' invisible')}>
                        {PagesForRange(page, ref.current.pages ?? 1).map((pageDisplay, idx) =>
                            <li className={"page-item" + (pageDisplay ? '' : ' disabled')} key={idx}>
                                {pageDisplay ?
                                    <Button className={"menu_back-link" + (page === pageDisplay ? ' active' : '')} onClick={() => {
                                        setPage(pageDisplay ?? 1);
                                        setForceRender(!forceRender);
                                    }}>
                                        {pageDisplay ? pageDisplay : '...'}
                                    </Button>
                                    :
                                    <span className="page-link">...</span>
                                }
                            </li>
                        )}
                    </ul>
                </Col>
            </Row>
            <Row className="fill-height">
                <Col size={12} className="fill-height">
                    <Table size="sm" borderless className="table-scrollable">
                        <tbody>
                        {!errorLogs ?
                            <tr>
                                <td className="text-secondary font-italic">Loading...</td>
                            </tr>
                            : errorLogs.length === 0 ?
                                <tr>
                                    <td>No error logs</td>
                                </tr>
                                :
                                errorLogs.map((errorLog, idx) =>
                                    <tr key={idx}>
                                        <td>
                                            <strong>{errorLog.errstr}</strong> (<span>{errorLog.errno}</span>)
                                            <br/>
                                            Line: <span>{errorLog.errline}</span> of <span>{errorLog.errfile}</span>
                                            <br/>
                                            <small><strong>Occurred <span>{errorLog.count}</span> time(s)</strong>
                                                <span className="text-muted">affecting <span>{errorLog.no_users}</span> user(s) between <span>{errorLog.eventtime_min}</span> and <span>{errorLog.eventtime_max}</span> <Button color="link" className="btn-link-inline d-none"> see trace</Button></span>
                                                <Button color="link" className="btn-link-inline" onClick={() => setModal(errorLog)}>Trace</Button>
                                            </small>
                                        </td>
                                    </tr>
                                )
                        }
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Modal backdrop keyboard isOpen={modalErrorLog !== undefined} toggle={closeModal} size="lg">
                <ModalHeader>Error Trace</ModalHeader>
                <ModalBody>
                    <small>
                        {modalErrorLog?.errtrace}
                    </small>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={closeModal}>Close</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default ErrorLog;

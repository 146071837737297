import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import {Link, Redirect, useHistory} from "react-router-dom";
import {HasFeature, TTSFeatures} from "../../Data/TTSFeatures";
import {Button, Col, Container, ListGroup, ListGroupItem, Row} from "reactstrap";
import axios, {CancelTokenSource} from "axios";
import {APIProcess, GetPathThrough, MasterDetail, MDMaster, InputSearch, MDLink, MDDetail, GetPathComponentAfter} from '@denjpeters/intelliwakereact';
import ActiveDD from "../Generics/ActiveDD";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter} from "@fortawesome/pro-regular-svg-icons";
import Holiday from "./Holiday";
import {Itblholiday} from "../../Data/Tables/tblholiday";
import {IWake} from "../../IWake";

const Holidays = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const isMounted = useRef(true);
    const {user} = useSelector((state: AppState) => state);
    const [holidays, setHolidays] = useState(null as Itblholiday[] | null);
    const [activeView, setActiveView] = useState(1 as 0 | 1 | -1);
    const [search, setSearch] = useState("");

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    const updateHoliday = (updatedHoliday: Itblholiday) => {
        if (!!holidays) {
            if (!!updatedHoliday.holidayID) {
                if (!window.location.pathname.endsWith('/Holiday' + updatedHoliday.holidayID)) {
                    history.push(GetPathThrough("/Holidays") + "/Holiday/" + updatedHoliday.holidayID);
                }

                setHolidays([
                    ...holidays.filter(holiday => holiday.holidayID !== updatedHoliday.holidayID),
                    updatedHoliday
                ]);
            }
        }
    };

    useEffect(() => {
        let cancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();
        isMounted.current = true;

        APIProcess('holiday', 'GetList', {showActive: activeView}, cancelTokenSource)(iWake)
            .then((results) => {
                if (isMounted.current && cancelTokenSource) {
                    for (let holidayItem of results.holidays) {
                        holidayItem.active = Number(holidayItem.active);
                    }
                    setHolidays((results.holidays as Itblholiday[]).sort(function (a, b) {
                        return (a.name).localeCompare((b.name), undefined, {sensitivity: 'accent'})
                    }));
                }
            })
            .catch(() => {
                if (isMounted.current && cancelTokenSource) {
                    setHolidays([]);
                }
            })
            .finally(() => {
                cancelTokenSource = null;
            });

        return () => {
            isMounted.current = false;
            if (cancelTokenSource) {
                cancelTokenSource.cancel();
                cancelTokenSource = null;
            }
        }
    }, [iWake, activeView]);

    const searchTerms = useMemo(() => !search ? [] : search
        .split(' ')
        .map(term => term.trim().toLowerCase())
        .filter(term => !!term), [search]);

    const holidayList = useMemo(() => (holidays ?? [])
        .filter(userItem => searchTerms.length === 0 || (searchTerms.every(term => (userItem.name.toLowerCase().includes(term)))))
        .sort((a, b) => a.name.localeCompare(b.name, undefined, {sensitivity: 'base'})), [searchTerms, holidays]);

    if (!HasFeature(user, TTSFeatures.Feature_TimeSheet_Admin)) {
        return (
            <Redirect to="/TimeEntry"/>
        );
    }

    return (
        <Container fluid className="px-0">
            <MasterDetail breakAt="sm" mdPath="/Holidays" backText="Holidays">
                <MDMaster width="15em">
                    <Row className="p-2">
                        <Col>
                            <ActiveDD size="sm" handleSelectID={setActiveView} selectedID={activeView} caret/>
                        </Col>
                        <Col className="text-right">
                            <Button color="secondary" size="sm" tag={Link} to={GetPathThrough("/Holidays") + "/Holiday/New"}>Add</Button>
                        </Col>
                    </Row>
                    <Row className="p-2">
                        <Col>
                            <div className="input-group input-group-filter">
                                <div className="input-group-prepend">
                                    <span className="input-group-text"><FontAwesomeIcon icon={faFilter} fixedWidth/></span>
                                </div>
                                <InputSearch className="noFocusRing" placeholder="Filter" aria-label="Filter" triggerSearchText={setSearch}/>
                            </div>
                        </Col>
                    </Row>
                    <ListGroup flush className="fill-height-scroll text-large-md-smaller">
                        {!holidays ?
                            <ListGroupItem className="text-secondary">Loading...</ListGroupItem>
                            : holidayList.length === 0 ?
                                <ListGroupItem>No holidays</ListGroupItem>
                                :
                                holidayList.map((holiday) =>
                                    <MDLink tag="li" panel="Holiday" className={"list-group-item list-group-item-action py-1" + (holiday.active === 1 ? "" : " text-gray")} key={holiday.holidayID} id={holiday.holidayID}>
                                        <span>{holiday.name}</span>
                                    </MDLink>
                                )
                        }
                    </ListGroup>
                </MDMaster>
                <MDDetail panel="Holiday" titleText="Holiday">
                    <Holiday holidayID={GetPathComponentAfter('/Holiday')} updateHoliday={updateHoliday}/>
                </MDDetail>
            </MasterDetail>
        </Container>
    );
};

export default Holidays;

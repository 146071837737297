import React, {useEffect, useMemo, useRef, useState} from 'react';
import {
    Button,
    Col,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    Table,
    UncontrolledButtonDropdown
} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../Stores/rootReducer";
import {HasFeature, TTSFeatures} from "../Data/TTSFeatures";
import './Mileage.scss';
import WeekNav from "../WebControls/Generics/WeekNav";
import PMDD from "../WebControls/Generics/PMDD";
import moment from "moment";
import axios, {CancelTokenSource} from "axios";
import {APIProcess, ShowPromptOKCancel, ShowActivityOverlay, HideActivityOverlay, ToDigitsBlank} from '@denjpeters/intelliwakereact';
import {faCheck, faHandPaper, faHandPointRight, faTimes} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useHistory} from 'react-router-dom';
import {IApprovalData} from "../Data/Views/Approval";
import {faCheckSquare} from "@fortawesome/pro-regular-svg-icons";
import {TimeStatus} from "../Data/Enums/TimeStatus";
import {IWake} from "../IWake";

const Approval = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const isMounted = useRef(true);
    const {user, appSessionRemembersChange} = useSelector((state: AppState) => state);
    const [forceRefresh, setForceRefresh] = useState(false);
    const [approvalData, setApprovalData] = useState(null as IApprovalData | null);

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    const holdForUser = async (userID: number, fullName: string, hold_for_review: number) => {
        let cancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();
        isMounted.current = true;

        if (await ShowPromptOKCancel((hold_for_review === 1 ? 'Hold All' : 'Release Hold') + ' for ' + fullName + '?', 'Are you sure you want to ' + (hold_for_review === 1 ? 'hold' : 'release from hold') + ' all hours that are ready for ' + fullName + '?', (hold_for_review === 1 ? 'warning' : 'success'), (hold_for_review === 1 ? 'Hold All' : 'Release From Hold'))(dispatch)) {
            ShowActivityOverlay()(dispatch);
            APIProcess('timeapproval', 'SetHoldForReview', {
                userID: userID,
                hold_for_review: hold_for_review,
                date: moment(appSessionRemembersChange.viewDate).format('Y-MM-DD'),
                status: TimeStatus.SUBMITTED
            }, cancelTokenSource)(iWake)
                .then(() => {
                    if (isMounted.current && cancelTokenSource) {
                        setForceRefresh(!forceRefresh);
                    }
                })
                .catch(() => {
                    if (isMounted.current && cancelTokenSource) {
                        setForceRefresh(!forceRefresh);
                    }
                })
                .finally(() => {
                    HideActivityOverlay()(dispatch);
                    cancelTokenSource = null;
                });
        }
    };

    const statusForUser = async (userID: number, fullName: string, status: number) => {
        let cancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();
        isMounted.current = true;

        if (await ShowPromptOKCancel((status === TimeStatus.APPROVED ? 'Approve' : 'Reject') + ' All for ' + fullName + '?', 'Are you sure you want to ' + (status === TimeStatus.APPROVED ? 'approve' : 'reject') + ' all hours that are ready for ' + fullName + '?', (status === TimeStatus.APPROVED ? 'success' : 'warning'), (status === TimeStatus.APPROVED ? 'Approve' : 'Reject'))(dispatch)) {
            ShowActivityOverlay()(dispatch);
            APIProcess('timeapproval', 'SetStatus', {
                userID: userID,
                approveFor: appSessionRemembersChange.approvalUserID ?? user.userID,
                status: status,
                date: moment(appSessionRemembersChange.viewDate).format('Y-MM-DD')
            }, cancelTokenSource)(iWake)
                .then(() => {
                    if (isMounted.current && cancelTokenSource) {
                        setForceRefresh(!forceRefresh);
                    }
                })
                .catch(() => {
                    if (isMounted.current && cancelTokenSource) {
                        setForceRefresh(!forceRefresh);
                    }
                })
                .finally(() => {
                    HideActivityOverlay()(dispatch);
                    cancelTokenSource = null;
                });
        }
    };

    const approveAll = async () => {
        let cancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();
        isMounted.current = true;

        if (await ShowPromptOKCancel('Approve All?', 'Are you sure you want to approve all hours that are ready?', 'success', 'Approve All')(dispatch)) {
            ShowActivityOverlay()(dispatch);
            APIProcess('timeapproval', 'SetStatusAll', {
                approveFor: appSessionRemembersChange.approvalUserID ?? user.userID,
                status: TimeStatus.APPROVED,
                date: moment(appSessionRemembersChange.viewDate).format('Y-MM-DD')
            }, cancelTokenSource)(iWake)
                .then(() => {
                    if (isMounted.current && cancelTokenSource) {
                        setForceRefresh(!forceRefresh);
                    }
                })
                .catch(() => {
                    if (isMounted.current && cancelTokenSource) {
                        setForceRefresh(!forceRefresh);
                    }
                })
                .finally(() => {
                    HideActivityOverlay()(dispatch);
                    cancelTokenSource = null;
                });
        }
    };

    useEffect(() => {
        let cancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();
        isMounted.current = true;

        ShowActivityOverlay()(dispatch);
        APIProcess('timeapproval', 'GetData', {
            approveFor: appSessionRemembersChange.approvalUserID ?? user.userID,
            date: moment(appSessionRemembersChange.viewDate).format('Y-MM-DD')
        }, cancelTokenSource)(iWake)
            .then((results) => {
                if (isMounted.current && cancelTokenSource) {
                    const loadedApprovalData = {
                        ...results
                    } as IApprovalData;

                    setApprovalData(loadedApprovalData);
                }
            })
            .catch(() => {
                if (isMounted.current && cancelTokenSource) {
                    setApprovalData(null);
                }
            })
            .finally(() => {
                HideActivityOverlay()(dispatch);
                cancelTokenSource = null;
            });

        return () => {
            isMounted.current = false;
            if (cancelTokenSource) {
                cancelTokenSource.cancel();
                cancelTokenSource = null;
            }
        }
    }, [dispatch, iWake, user.userID, appSessionRemembersChange.approvalUserID, appSessionRemembersChange.viewDate, forceRefresh]);

    const projectIDs = useMemo(() => Array.from(new Set((!approvalData ? [] : approvalData.project_datum
        .sort((a, b) => a.projectNo.localeCompare(b.projectNo, undefined, {sensitivity: 'base'}))
        .map(projectData => projectData.projectID)))), [approvalData]);

    const hasUnapproved = useMemo(() => !approvalData ? false : !!approvalData.project_datum.find(project_data => project_data.status === TimeStatus.SUBMITTED), [approvalData]);

    // if (!!approvalData) {
    //     console.log(approvalData);
    // }

    return (
        <Container fluid className="fill-height-scroll">
            {approvalData ?
                <>
                    <Row className="p-2">
                        <Col>
                            <WeekNav/>
                        </Col>
                        <Col className="text-center strong">
                            <h4>
                                {HasFeature(user, TTSFeatures.Feature_User_Admin) ?
                                    <>
                                        Approvals for: <PMDD/>
                                    </>
                                    :
                                    null
                                }
                            </h4>
                        </Col>
                        <Col className="text-right">
                            {moment(appSessionRemembersChange.viewDate).startOf("isoWeek").format('Y-MM-DD')} to {moment(appSessionRemembersChange.viewDate).startOf("isoWeek").add(6, 'days').format('MM-DD')}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center">
                            <Button color="link" onClick={() => {
                                history.push('/TimeSheet/PM/' + (appSessionRemembersChange.approvalUserID ?? user.userID));
                            }
                            }>
                                Show All Time-Sheets
                            </Button>
                        </Col>
                    </Row>
                    {approvalData.hours_not_entered.length > 0 ?
                        <Row>
                            <Col xs={12}>
                                <h5>No Hours Entered</h5>
                                <ul>
                                    {approvalData.hours_not_entered.map(user =>
                                        <li key={user.userID}>
                                            {user.lastname}, {user.firstname}
                                        </li>
                                    )}
                                </ul>
                            </Col>
                        </Row>
                        :
                        null
                    }
                    <Row>
                        <Col md={8}>
                            <h5>Project Billable Hours</h5>
                            <Table bordered size="sm" className="small">
                                <tbody>
                                {projectIDs.map(projectID =>
                                    <React.Fragment key={projectID}>
                                        <tr>
                                            <th colSpan={8} className="alert-info text-dark">
                                                {approvalData.project_datum
                                                    .find(projectData => projectData.projectID === projectID)!
                                                    .projectNoAmend} - {approvalData.project_datum
                                                .find(projectData => projectData.projectID === projectID)!
                                                .project_name}
                                            </th>
                                        </tr>
                                        <tr className="bg-light-gray">
                                            <th>
                                                Person
                                            </th>
                                            <th>
                                                Activity
                                            </th>
                                            <th className="text-right">
                                                Regular Hours
                                            </th>
                                            <th className="text-right">
                                                OT Hours
                                            </th>
                                            <th>

                                            </th>
                                            <th className="text-right hidden-xs">
                                                Total Hours
                                            </th>
                                            <th className="text-right hidden-xs">
                                                Budgeted Hours
                                            </th>
                                            <th className="text-right hidden-xs">
                                                Remaining to Date
                                            </th>
                                        </tr>
                                        {approvalData.project_datum
                                            .filter(projectData => projectData.projectID === projectID)
                                            .map((projectData, idx) =>
                                                <tr key={idx}>
                                                    <td>
                                                        <Button color="link" className="btn-link-inline" onClick={() => {
                                                            history.push("/TimeSheet/Employee/" + projectData.employeeNo);
                                                        }}>
                                                            {projectData.fullname}
                                                        </Button>
                                                    </td>
                                                    <td>
                                                        {projectData.activityName}
                                                    </td>
                                                    <td className="text-right">
                                                        {ToDigitsBlank(projectData.regular_hours, 2)}
                                                    </td>
                                                    <td className="text-right">
                                                        {ToDigitsBlank(projectData.overtime_hours, 2)}
                                                    </td>
                                                    <td className="text-nowrap">
                                                        {projectData.status === 0 ?
                                                            <span className="text-danger">
                                                                Not Ready
                                                            </span>
                                                            :
                                                            null
                                                        }
                                                        {projectData.status === 2 ?
                                                            projectData.hold_for_review === 0 ?
                                                                <UncontrolledButtonDropdown>
                                                                    <DropdownToggle color="link" className="small btn-link-inline" caret>
                                                                        Action
                                                                    </DropdownToggle>
                                                                    <DropdownMenu>
                                                                        {HasFeature(user, [TTSFeatures.Feature_User_Admin, TTSFeatures.Feature_TimeSheet_Approval]) ?
                                                                            <DropdownItem onClick={() => {
                                                                                statusForUser(projectData.userID, projectData.fullname, TimeStatus.APPROVED)
                                                                            }} className="text-success">
                                                                                <FontAwesomeIcon icon={faCheck} fixedWidth/> Approve
                                                                                All {projectData.fullname}
                                                                            </DropdownItem>
                                                                            :
                                                                            null
                                                                        }
                                                                        <DropdownItem onClick={() => {
                                                                            statusForUser(projectData.userID, projectData.fullname, TimeStatus.DRAFT)
                                                                        }} className="text-danger">
                                                                            <FontAwesomeIcon icon={faTimes} fixedWidth/> Reject
                                                                            All {projectData.fullname}
                                                                        </DropdownItem>
                                                                        {HasFeature(user, [TTSFeatures.Feature_User_Admin, TTSFeatures.Feature_TimeSheet_Approval]) ?
                                                                            <DropdownItem onClick={() => {
                                                                                holdForUser(projectData.userID, projectData.fullname, 1)
                                                                            }} className="text-secondary">
                                                                                <FontAwesomeIcon icon={faHandPaper} fixedWidth/> Hold
                                                                                all for Review {projectData.fullname}
                                                                            </DropdownItem>
                                                                            :
                                                                            null
                                                                        }
                                                                    </DropdownMenu>
                                                                </UncontrolledButtonDropdown>
                                                                :
                                                                HasFeature(user, [TTSFeatures.Feature_User_Admin, TTSFeatures.Feature_TimeSheet_Approval]) ?
                                                                    <UncontrolledButtonDropdown>
                                                                        <DropdownToggle color="link" className="small btn-link-inline text-dark" caret>
                                                                            On Hold
                                                                        </DropdownToggle>
                                                                        <DropdownMenu>
                                                                            <DropdownItem onClick={() => {
                                                                                holdForUser(projectData.userID, projectData.fullname, 0)
                                                                            }} className="text-secondary">
                                                                                <FontAwesomeIcon icon={faHandPointRight} fixedWidth/> Release
                                                                                from Hold
                                                                                for {projectData.fullname}
                                                                            </DropdownItem>
                                                                        </DropdownMenu>
                                                                    </UncontrolledButtonDropdown>
                                                                    :
                                                                    "On Hold"
                                                            :
                                                            null
                                                        }
                                                        {projectData.status === 4 ?
                                                            <span className="text-success">
                                                                Approved
                                                            </span>
                                                            :
                                                            null
                                                        }
                                                    </td>
                                                    <td className="text-right hidden-xs">
                                                        {ToDigitsBlank(projectData.total_billed, 2)}
                                                    </td>
                                                    <td className="text-right hidden-xs">
                                                        {ToDigitsBlank(projectData.total_budgeted, 2)}
                                                    </td>
                                                    <td className={"text-right hidden-xs" + (projectData.total_remaining < 0 ? " alert-danger" : "")}>
                                                        {ToDigitsBlank(projectData.total_remaining, 2)}
                                                    </td>
                                                </tr>
                                            )}
                                        {approvalData.previous_summaries
                                            .filter(previousSummary => previousSummary.projectID === projectID && (previousSummary.other_budgeted !== 0 || previousSummary.other_previously_billed !== 0 || previousSummary.other_remaining !== 0))
                                            .map(previousSummary =>
                                                <tr key={previousSummary.projectID} className="text-right">
                                                    <td colSpan={5} className="strong">
                                                        Other Previous Hours:
                                                    </td>
                                                    <td>
                                                        {ToDigitsBlank(previousSummary.other_previously_billed, 2)}
                                                    </td>
                                                    <td>
                                                        {ToDigitsBlank(previousSummary.other_budgeted, 2)}
                                                    </td>
                                                    <td className={previousSummary.other_remaining < 0 ? "alert-danger" : ""}>
                                                        {ToDigitsBlank(previousSummary.other_remaining, 2)}
                                                    </td>
                                                </tr>
                                            )}
                                        <tr className="text-right strong bg-light-gray">
                                            <td colSpan={2}>
                                                Totals:
                                            </td>
                                            <td>
                                                {ToDigitsBlank(
                                                    (approvalData.project_datum.length > 0 ?
                                                        approvalData.project_datum
                                                            .filter(projectData => projectData.projectID === projectID)
                                                            .map(projectData => projectData.regular_hours)
                                                            .reduce((prev, next) => +prev + +next) : 0)
                                                    , 2)}
                                            </td>
                                            <td>
                                                {ToDigitsBlank(
                                                    (approvalData.project_datum.length > 0 ?
                                                        approvalData.project_datum
                                                            .filter(projectData => projectData.projectID === projectID)
                                                            .map(projectData => projectData.overtime_hours)
                                                            .reduce((prev, next) => +prev + +next) : 0)
                                                    , 2)}
                                            </td>
                                            <td/>
                                            <td>
                                                {ToDigitsBlank(
                                                    +(approvalData.project_datum.length > 0 ?
                                                        approvalData.project_datum
                                                            .filter(projectData => projectData.projectID === projectID)
                                                            .map(projectData => projectData.total_billed)
                                                            .reduce((prev, next) => +prev + +next) : 0)
                                                    +
                                                    +(approvalData.previous_summaries.length > 0 ?
                                                        approvalData.previous_summaries
                                                            .filter(previousSummary => previousSummary.projectID === projectID)
                                                            .map(previousSummary => previousSummary.other_previously_billed)
                                                            .reduce((prev, next) => +prev + +next) : 0)
                                                    , 2)}
                                            </td>
                                            <td>
                                                {ToDigitsBlank(
                                                    +(approvalData.project_datum.length > 0 ?
                                                        approvalData.project_datum
                                                            .filter(projectData => projectData.projectID === projectID)
                                                            .map(projectData => projectData.total_budgeted)
                                                            .reduce((prev, next) => +prev + +next) : 0)
                                                    +
                                                    +(approvalData.previous_summaries.length > 0 ?
                                                        approvalData.previous_summaries
                                                            .filter(previousSummary => previousSummary.projectID === projectID)
                                                            .map(previousSummary => previousSummary.other_budgeted)
                                                            .reduce((prev, next) => +prev + +next) : 0)
                                                    , 2)}
                                            </td>
                                            <td className={((approvalData.project_datum.length > 0 ?
                                                +approvalData.project_datum
                                                    .filter(projectData => projectData.projectID === projectID)
                                                    .map(projectData => projectData.total_remaining)
                                                    .reduce((prev, next) => +prev + +next) : 0)
                                                +
                                                +(approvalData.previous_summaries.length > 0 ?
                                                    approvalData.previous_summaries
                                                        .filter(previousSummary => previousSummary.projectID === projectID)
                                                        .map(previousSummary => previousSummary.other_remaining)
                                                        .reduce((prev, next) => +prev + +next) : 0)) < 0 ? "alert-danger" : ""}>
                                                {ToDigitsBlank(
                                                    +(approvalData.project_datum.length > 0 ?
                                                        approvalData.project_datum
                                                            .filter(projectData => projectData.projectID === projectID)
                                                            .map(projectData => projectData.total_remaining)
                                                            .reduce((prev, next) => +prev + +next) : 0)
                                                    +
                                                    +(approvalData.previous_summaries.length > 0 ?
                                                        approvalData.previous_summaries
                                                            .filter(previousSummary => previousSummary.projectID === projectID)
                                                            .map(previousSummary => previousSummary.other_remaining)
                                                            .reduce((prev, next) => +prev + +next) : 0)
                                                    , 2)}
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                )}
                                </tbody>
                            </Table>
                        </Col>
                        <Col md={4}>
                            <h5>Payroll Hours</h5>
                            <Table bordered size="sm" className="small">
                                <thead>
                                <tr className="bg-light-gray">
                                    <th>Person</th>
                                    <th className="text-right">Billable Regular</th>
                                    <th className="text-right">Billable OT</th>
                                    <th className="text-right">Payroll Regular</th>
                                    <th className="text-right">Payroll OT</th>
                                </tr>
                                </thead>
                                <tfoot>
                                <tr>
                                    <th className="text-right">Totals:</th>
                                    <th className="text-right">
                                        {ToDigitsBlank(approvalData.payroll_datum.length > 0 ? approvalData.payroll_datum.map(payrollData => payrollData.total_bill_reg).reduce((prev, next) => +prev + +next) : 0)}
                                    </th>
                                    <th className="text-right">
                                        {ToDigitsBlank(approvalData.payroll_datum.length > 0 ? approvalData.payroll_datum.map(payrollData => payrollData.total_bill_ot).reduce((prev, next) => +prev + +next) : 0)}
                                    </th>
                                    <th className="text-right">
                                        {ToDigitsBlank(approvalData.payroll_datum.length > 0 ? approvalData.payroll_datum.map(payrollData => payrollData.total_pay_reg).reduce((prev, next) => +prev + +next) : 0)}
                                    </th>
                                    <th className="text-right">
                                        {ToDigitsBlank(approvalData.payroll_datum.length > 0 ? approvalData.payroll_datum.map(payrollData => payrollData.total_pay_ot).reduce((prev, next) => +prev + +next) : 0)}
                                    </th>
                                </tr>
                                </tfoot>
                                <tbody>
                                {approvalData.payroll_datum
                                    .sort((a, b) => a.fullname.localeCompare(b.fullname, undefined, {sensitivity: 'base'}))
                                    .map(payrollData =>
                                        <tr key={payrollData.user}>
                                            <td>
                                                <Button color="link" className="btn-link-inline" onClick={() => {
                                                    history.push("/TimeSheet/Employee/" + payrollData.user);
                                                }}>
                                                    {payrollData.fullname}
                                                </Button>
                                            </td>
                                            <td className="text-right">
                                                {ToDigitsBlank(payrollData.total_bill_reg, 2)}
                                            </td>
                                            <td className="text-right">
                                                {ToDigitsBlank(payrollData.total_bill_ot, 2)}
                                            </td>
                                            <td className="text-right">
                                                {ToDigitsBlank(payrollData.total_pay_reg, 2)}
                                            </td>
                                            <td className="text-right">
                                                {ToDigitsBlank(payrollData.total_pay_ot, 2)}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    {(hasUnapproved && HasFeature(user, [TTSFeatures.Feature_User_Admin, TTSFeatures.Feature_TimeSheet_Approval])) ?
                        <Row className="mb-4">
                            <Col>
                                <Button color="success" onClick={approveAll}>
                                    <FontAwesomeIcon icon={faCheckSquare} fixedWidth/>
                                    Approve All
                                </Button>
                            </Col>
                        </Row>
                        :
                        null
                    }
                </>
                :
                null
            }
        </Container>
    );
};

export default Approval;

import React from 'react';
import {
    Container
} from "reactstrap";
import {useSelector} from "react-redux";
import {AppState} from "../Stores/rootReducer";
import moment from "moment";
import './TimeEntry.scss';
import TimeSheetsPayroll from "../WebControls/Time/TimeSheetsPayroll";
import TimeSheetsTimeApprover from "../WebControls/Time/TimeSheetsTimeApprover";
import TimeSheetsEmployeeDate from "../WebControls/Time/TimeSheetsEmployeeDate";
import TimeSheetsUser from "../WebControls/Time/TimeSheetsUser";
import TimeSheetsInvoice from "../WebControls/Time/TimeSheetsInvoice";
import { HasPathComponent, GetPathComponentAfter, CleanNumber } from '@denjpeters/intelliwakereact';

const TimeSheet = () => {
    const {user, appSessionRemembersChange} = useSelector((state: AppState) => state);

    return (
        <Container fluid className="fill-height-scroll">
            {HasPathComponent('PR') ?
                <TimeSheetsPayroll/>
                :
                !!GetPathComponentAfter('PM') ?
                    <TimeSheetsTimeApprover PM={GetPathComponentAfter('PM')} date={moment(GetPathComponentAfter('Date') ?? appSessionRemembersChange.viewDate ?? 'today').format('Y-MM-DD')}/>
                    :
                    !!GetPathComponentAfter('Employee') ?
                        <TimeSheetsEmployeeDate EmployeeNo={GetPathComponentAfter('Employee')} date={moment(GetPathComponentAfter('Date') ?? appSessionRemembersChange.viewDate ?? 'today').format('Y-MM-DD')}/>
                        :
                    !!GetPathComponentAfter('Invoice') ?
                        <TimeSheetsInvoice invoiceID={GetPathComponentAfter('Invoice')}/>
                        :
                        <TimeSheetsUser userID={CleanNumber(GetPathComponentAfter('User') ?? appSessionRemembersChange.viewUserID ?? user.userID)} date={moment(GetPathComponentAfter('Date') ?? appSessionRemembersChange.viewDate ?? 'today').format('Y-MM-DD')}/>
            }

        </Container>
    );
};

export default TimeSheet;

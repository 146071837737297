import React from 'react';
import {DaysOfWeek} from "../../Data/Enums/DaysOfWeek";
import {ITime, ITimeDataGeneric, ITimeDataUser} from "../../Data/Views/Time";
import moment from "moment";
import { ToDigitsBlank } from '@denjpeters/intelliwakereact';

interface IProps {
    timeDataGeneric: ITimeDataGeneric,
    timeDataUser: ITimeDataUser,
    timeRow: ITime,
    highlightDateRange?: [string, string],
    highlightProjectNo?: string
}

const LineReadOnly = (props: IProps) => {
        const project = props.timeDataGeneric.projects.find(project => project.projectID === props.timeRow.projectID);
        const activity = props.timeDataGeneric.activities.find(activity => activity.activityID === props.timeRow.activityID);

        const additionalClassName = (idx: number, timeRow: ITime): string => {
            let classes = "";

            if (!!props.highlightDateRange && !!props.highlightProjectNo) {
                if (!!props.timeDataGeneric.projects.find(project => project.projectNo === props.highlightProjectNo && project.projectID === timeRow.projectID)) {
                    const cellDate = moment(timeRow.date).subtract(6 - idx, 'days').format('Y-MM-DD');
                    if (cellDate >= props.highlightDateRange[0] && cellDate <= props.highlightDateRange[1]) {
                        classes += "tsCellIncluded";

                        if (idx === 0 || cellDate === props.highlightDateRange[0]) {
                            classes += " tsCellIncludedStart";
                        }
                        if (idx === 6 || cellDate === props.highlightDateRange[1]) {
                            classes += " tsCellIncludedEnd";
                        }
                    }
                }
            }

            return classes;
        }

        return (
            <tr>
                <td>
                    {!!project ? (project.projectNo + ' - ' + project.name) : (!!activity ? activity.name : null)}
                </td>
                <td>
                    {!!project ? (!!activity ? activity.name : null) : null}
                </td>
                {DaysOfWeek.map((dayOfWeek, idx) =>
                    <td className={"text-right " + additionalClassName(idx, props.timeRow)} key={dayOfWeek}>
                        {ToDigitsBlank((props.timeRow as any)[dayOfWeek])}
                    </td>
                )}
                <td className="text-right strong border-left-bold">
                    {ToDigitsBlank(DaysOfWeek.map(dayOfWeek => (props.timeRow as any)[dayOfWeek]).reduce((prev, next) => +prev + +next))}
                </td>
            </tr>
        );
};

export default LineReadOnly;

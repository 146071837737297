import React, {useEffect, useMemo, useRef, useState} from 'react';
import {
    Col,
    Row, Table
} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import axios, {CancelTokenSource} from "axios";
import {APIProcess, initialSortColumn, ISortColumn, ShowActivityOverlay, SearchSort, HideActivityOverlay, SortColumnUpdate, ToDigitsBlank, InputSearch} from '@denjpeters/intelliwakereact';
import {Itblproject} from "../../Data/Tables/tblproject";
import PMAllDD from "../Generics/PMAllDD";
import ActiveDD from "../Generics/ActiveDD";
import {ASRSetProjectActive, ASRSetViewProjectID} from "../../Stores/appsessionremembers/actions";
import {useHistory} from 'react-router-dom';
import {IWake} from "../../IWake";
import {HasFeature, TTSFeatures} from "../../Data/TTSFeatures";

interface IReportData extends Itblproject {
    customerName: string,
    used_hours: number,
    start_date: string,
    end_date: string,
    available_hours: string
}

const Projects = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const isMounted = useRef(true);
    const {user, appSessionRemembersChange} = useSelector((state: AppState) => state);
    const [projects, setProjects] = useState(null as IReportData[] | null);
    const [search, setSearch] = useState('');
    const [sortColumn, setSortColumn] = useState({
        ...initialSortColumn,
        primarySort: "projectNo"
    } as ISortColumn);

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    const openProject = (projectID: number) => {
        ASRSetViewProjectID(projectID)(dispatch);
        history.push('/Reports/Project');
    }

    useEffect(() => {
        let cancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();
        isMounted.current = true;

        ShowActivityOverlay()(dispatch);
        APIProcess('project', 'Projects', {
            userID: appSessionRemembersChange.approvalUserID ?? -1,
            active: appSessionRemembersChange.projectActive
        }, cancelTokenSource)(iWake)
            .then((results) => {
                // console.log(results.projects);
                if (isMounted.current && cancelTokenSource) {
                    setProjects(results.projects);
                }
            })
            .catch(() => {
                if (isMounted.current && cancelTokenSource) {
                    setProjects(null);
                }
            })
            .finally(() => {
                HideActivityOverlay()(dispatch);
                cancelTokenSource = null;
            });

        return () => {
            isMounted.current = false;
            if (cancelTokenSource) {
                cancelTokenSource.cancel();
                cancelTokenSource = null;
            }
        }
    }, [appSessionRemembersChange.approvalUserID, appSessionRemembersChange.projectActive, dispatch, iWake]);

    const projectsList: IReportData[] = useMemo(() => {
        return SearchSort(projects ?? [], search, sortColumn)
    }, [projects, search, sortColumn]);

    return (
        <>
            <Row>
                <Col>
                    <h3 className="text-center">Projects Report</h3>
                    <h4 className="text-center">Projects For:
                        {HasFeature(user, [TTSFeatures.Feature_Project_Admin]) ?
                            <PMAllDD/>
                            :
                            <>
                                {" " + user.lastname}, {user.firstname}
                            </>
                        }
                    </h4>
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    <InputSearch triggerSearchText={setSearch} placeholder="Search"/>
                </Col>
                <Col md={8} className="text-right">
                    <ActiveDD selectedID={appSessionRemembersChange.projectActive} handleSelectID={(value) => ASRSetProjectActive(value)(dispatch)} inline caret className="form-control-plaintext"/>
                </Col>
            </Row>
            {!!projects ?
                <Row className="mt-3 small fill-height">
                    <Col className="fill-height">
                        <Table bordered hover size="sm" className="table-scrollable table-sortable">
                            <thead>
                            <tr className="table-secondary">
                                <th className="td-lg" onClick={() => setSortColumn(SortColumnUpdate('customerName', sortColumn))}>Customer</th>
                                <th className="td-md" onClick={() => setSortColumn(SortColumnUpdate('projectNo', sortColumn))}>Project
                                    No
                                </th>
                                <th onClick={() => setSortColumn(SortColumnUpdate('name', sortColumn))}>Name</th>
                                <th className="td-sm" onClick={() => setSortColumn(SortColumnUpdate('addendumNo', sortColumn))}>Add.</th>
                                <th className="td-sm" onClick={() => setSortColumn(SortColumnUpdate('amendmentNo', sortColumn))}>Amnd.</th>
                                <th className="text-right td-md" onClick={() => setSortColumn(SortColumnUpdate('budgeted_hours', sortColumn))}>Budgeted
                                    Hours
                                </th>
                                <th className="text-right td-md" onClick={() => setSortColumn(SortColumnUpdate('used_hours', sortColumn))}>Used
                                    Hours
                                </th>
                                <th className="text-right td-md" onClick={() => setSortColumn(SortColumnUpdate('available_hours', sortColumn))}>Available
                                    Hours
                                </th>
                                <th className="td-lg" onClick={() => setSortColumn(SortColumnUpdate('start_date', sortColumn))}>First
                                    Hours Date
                                </th>
                                <th className="td-lg" onClick={() => setSortColumn(SortColumnUpdate('end_date', sortColumn))}>Last
                                    Hours Date
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {projectsList.map(project =>
                                <tr key={project.projectID} onClick={() => openProject(project.projectID)}>
                                    <td className="td-lg">{project.customerName}</td>
                                    <td className="td-md">{project.projectNo}</td>
                                    <td>{project.name}</td>
                                    <td className="td-sm">{project.addendumNo}</td>
                                    <td className="td-sm">{project.amendmentNo}</td>
                                    <td className="text-right td-md">{ToDigitsBlank(project.budgeted_hours, 2)}</td>
                                    <td className="text-right td-md">{ToDigitsBlank(project.used_hours, 2)}</td>
                                    <td className="text-right td-md">{ToDigitsBlank(project.available_hours, 2)}</td>
                                    <td className="td-lg">{project.start_date}</td>
                                    <td className="td-lg">{project.end_date}</td>
                                </tr>
                            )}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                :
                null
            }
        </>
    );
};

export default Projects;

import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Itbluser} from "../../Data/Tables/tbluser";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import {IWake} from "../../IWake";
import {Itbluser_file_type_group} from "../../Data/Tables/tbluser_file_type_group";
import {Itbluser_file_type} from "../../Data/Tables/tbluser_file_type";
import axios, {CancelTokenSource} from "axios";
import {APIProcess, GenerateUUID, InputSwitch, ActivityOverlayControl} from '@denjpeters/intelliwakereact';
import {Button, Col, Row, Table} from "reactstrap";
import {Itbluser_file} from "../../Data/Tables/tbluser_file";
import UserDocumentsTRItem from "./UserDocumentsTRItem";
import BlockNav from "../Generics/BlockNav";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCabinetFiling, faFile, faSpinner} from "@fortawesome/pro-regular-svg-icons";
import {GetFilesWebkitDataTransferItems, UploadFile} from "../../Data/Controllers/tbluser_file";

interface IProps {
    tbluser: Itbluser,
    setTbluser: ((tbluser: Itbluser) => void),
    updateUser?: Function | undefined
}

export interface IFileUploadStructure {
    file: File,
    uuid: string,
    isUploading: boolean,
    uploadFailed: boolean
}

interface IUserFilesStructure {
    tbluser_files: Itbluser_file[],
    tbluser_file_types: Itbluser_file_type[],
    tbluser_file_type_groups: Itbluser_file_type_group[]
}

export interface ITypeList {
    id: number,
    active: number,
    name: string,
    typeName: string
}

const UserDocuments = (props: IProps) => {
    const dispatch = useDispatch();
    const isMounted = useRef(true);
    const fileUploader = useRef(null as HTMLInputElement | null);
    const {user} = useSelector((state: AppState) => state);
    const [userFilesStructure, setUserFilesStructure] = useState(null as IUserFilesStructure | null);
    const [fileUploadStructure, setFileUploadStructure] = useState([] as IFileUploadStructure[]);
    const [forceUpdate, setForceUpdate] = useState(false);
    const [showArchived, setShowArchived] = useState(false);
    const simultaneousUploads = 2;

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    useEffect(() => {
        setUserFilesStructure(null);
        setFileUploadStructure([]);
    }, [props.tbluser]);

    const onDragOver = (e: DragEvent) => {
        e.preventDefault();
    }

    const onDrop = (e: DragEvent) => {
        e.preventDefault();

        if (!!e.dataTransfer) {
            GetFilesWebkitDataTransferItems(e.dataTransfer.items)
                .then((files) => {
                    let uploadStructures: IFileUploadStructure[] = [];

                    for (const file of files) {
                        uploadStructures.push({
                            file: file,
                            uuid: GenerateUUID(),
                            isUploading: false,
                            uploadFailed: false
                        } as IFileUploadStructure);
                    }

                    setFileUploadStructure(prevState => [
                        ...prevState,
                        ...uploadStructures
                    ]);
                });
        }
    }

    useEffect(() => {
        window.addEventListener('dragover', onDragOver);
        window.addEventListener('drop', onDrop);

        return () => {
            window.removeEventListener('dragover', onDragOver);
            window.removeEventListener('drop', onDrop);
        }
    });

    const showFileUpload = () => {
        if (fileUploader.current) {
            fileUploader.current.click();
        }
    };

    const executeFileUpload = (e: any) => {
        let uploadStructures: IFileUploadStructure[] = [];

        for (const file of e.target.files) {
            uploadStructures.push({
                file: file,
                uuid: GenerateUUID(),
                isUploading: false,
                uploadFailed: false
            } as IFileUploadStructure);
        }

        setFileUploadStructure(prevState => [
            ...prevState,
            ...uploadStructures
        ]);
    };

    const updateFile = () => {
        setForceUpdate(prevState => !prevState);
    }

    const retryUpload = (uuid: string) => {
        if (!!userFilesStructure) {
            let upload = fileUploadStructure.find(us => us.uuid === uuid && us.uploadFailed);

            if (!!upload) {
                setFileUploadStructure(prevState => [
                    ...prevState.filter(us => us.uuid !== uuid),
                    {
                        ...upload as IFileUploadStructure,
                        uploadFailed: false
                    }
                ]);
            }
        }
    }

    const deleteUpload = (uuid: string) => {
        if (!!userFilesStructure) {
            let upload = fileUploadStructure.find(us => us.uuid === uuid && us.uploadFailed);

            if (!!upload) {
                setFileUploadStructure(prevState => [
                    ...prevState.filter(us => us.uuid !== uuid)
                ]);
            }
        }
    }

    useEffect(() => {
        let cancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();
        isMounted.current = true;

        APIProcess('UserFile', 'GetList', {userID: props.tbluser.userID}, cancelTokenSource)(iWake)
            .then((results) => {
                // console.log(results);
                if (isMounted.current && cancelTokenSource) {
                    setUserFilesStructure({
                        ...results,
                        tbluser_file_types: [
                            ...results.tbluser_file_types.sort((a: Itbluser_file_type, b: Itbluser_file_type) => a.sort_order - b.sort_order)
                        ],
                        tbluser_file_type_groups: [
                            ...results.tbluser_file_type_groups.sort((a: Itbluser_file_type_group, b: Itbluser_file_type_group) => a.sort_order - b.sort_order)
                        ]
                    });
                }
            })
            .catch(() => {
                if (isMounted.current && cancelTokenSource) {
                    setUserFilesStructure(null);
                }
            })
            .finally(() => {
                cancelTokenSource = null;
            });

        return () => {
            isMounted.current = false;
            if (cancelTokenSource) {
                cancelTokenSource.cancel();
                cancelTokenSource = null;
            }
        }
    }, [iWake, forceUpdate, props.tbluser]);

    const hasTopLevelArchived = useMemo(() =>
        !!userFilesStructure && !!userFilesStructure.tbluser_files.find(us => us.active === 1 && us.archived === 1)
        , [userFilesStructure]);

    const typeList: ITypeList[] = useMemo(() =>
            !userFilesStructure ? [] : userFilesStructure.tbluser_file_types
                .map(type => {
                    return {
                        id: type.id,
                        active: type.active,
                        name: ((userFilesStructure.tbluser_file_type_groups.find(group => group.id === type.tbluser_file_type_group_id) ?? {})['name'] ?? '') + ' : ' + type.name,
                        typeName: type.name
                    } as ITypeList
                })
                .sort((a, b) => a.name.localeCompare(b.name, undefined, {sensitivity: 'base'}))
        , [userFilesStructure]);

    useEffect(() => {
        if (fileUploadStructure.length > 0) {
            const currentlyUploading = fileUploadStructure.filter(us => us.isUploading).length;
            let notYetUploading = fileUploadStructure.filter(us => !us.isUploading && !us.uploadFailed);

            if (currentlyUploading < simultaneousUploads && notYetUploading.length > 0) {
                let nextToUpload = notYetUploading.find(() => true);
                if (!!nextToUpload) {
                    nextToUpload.isUploading = true;

                    setFileUploadStructure(prevState => [
                        ...prevState.filter(us => us.uuid !== nextToUpload!.uuid),
                        nextToUpload!
                    ]);

                    UploadFile(nextToUpload.file!, {
                        uuid: nextToUpload.uuid,
                        userID: props.tbluser.userID
                    })
                        .then(() => {
                            if (!!nextToUpload) {
                                setFileUploadStructure(prevState => [
                                    ...prevState.filter(us => us.uuid !== nextToUpload!.uuid)
                                ]);
                                setForceUpdate(prevState => !prevState);
                            }
                        })
                        .catch(() => {
                            if (!!nextToUpload) {
                                nextToUpload.isUploading = false;
                                nextToUpload.uploadFailed = true;

                                setFileUploadStructure(prevState => [
                                    ...prevState.filter(us => us.uuid !== nextToUpload!.uuid),
                                    nextToUpload!
                                ]);
                            }
                        });
                }
            }
        }
    }, [fileUploadStructure, props.tbluser.userID]);

    const trItems = () => {
        let reactComponents: any[] = [];

        if (!!userFilesStructure) {
            userFilesStructure.tbluser_files.filter(userFile => userFile.tbluser_file_type_id === null).forEach(userFile => {
                reactComponents.push(
                    <UserDocumentsTRItem tbluser_file={userFile} typeList={typeList} updateFile={updateFile} hasUploading={fileUploadStructure.length > 0} key={userFile.uuid}/>
                )
            });

            userFilesStructure.tbluser_file_type_groups.forEach(group => {
                reactComponents.push(
                    <tr className="alert-primary" key={"GroupID" + group.id}>
                        <th colSpan={4}>
                            {group.name}
                        </th>
                    </tr>
                );

                userFilesStructure.tbluser_file_types.filter(type => type.tbluser_file_type_group_id === group.id).forEach(type => {
                    const userFiles = userFilesStructure.tbluser_files
                        .filter(userFile => userFile.tbluser_file_type_id === type.id && (showArchived || userFile.archived === 0))
                        .sort((a: Itbluser_file, b: Itbluser_file) => (a.archived !== b.archived) ? a.archived - b.archived : (a.archived === 0 ? a.name.localeCompare(b.name, undefined, {sensitivity: 'base'}) : b.create_ts.localeCompare(a.create_ts)));

                    if (userFiles.length === 0) {
                        reactComponents.push(
                            <tr className="alert-warning" key={"TypeID" + type.id}>
                                <td colSpan={4} className="pl-4">
                                    <strong>{type.name}</strong>
                                </td>
                            </tr>
                        );
                    } else {
                        userFiles.forEach(userFile => {
                            reactComponents.push(
                                <UserDocumentsTRItem tbluser_file={userFile} typeList={typeList} updateFile={updateFile} hasUploading={fileUploadStructure.length > 0} key={userFile.uuid}/>
                            )
                        });
                    }
                })
            });
        }

        return <>
            {[...reactComponents]}
        </>;
    }

    return (
        <Row className="fill-height">
            {!!userFilesStructure ?
                <>
                    <Col className="form-condensed fill-height">
                        <Row className="pb-2">
                            <Col>
                                {hasTopLevelArchived ?
                                    <InputSwitch value={showArchived ? 1 : 0} onChange={() => setShowArchived(prevState => !prevState)} label={<>
                                        <FontAwesomeIcon icon={faCabinetFiling} fixedWidth/>Show Archived</>}/>
                                    :
                                    null
                                }
                            </Col>
                            <Col className="text-right">
                                <Button size="sm" onClick={showFileUpload}><FontAwesomeIcon icon={faFile} fixedWidth/> Upload
                                    Files(s)</Button>
                                <input type="file" accept="*/*" multiple ref={fileUploader} onChange={executeFileUpload}/>
                            </Col>
                        </Row>
                        <Row className="fill-height">
                            <Col className="fill-height-scroll">
                                <Table size="sm" borderless>
                                    <tbody>
                                    {fileUploadStructure.length > 0 ?
                                        <>
                                            <tr className="alert-warning">
                                                <th colSpan={4}>
                                                    Uploads
                                                </th>
                                            </tr>
                                            {fileUploadStructure.map(us =>
                                                <tr key={us.uuid}>
                                                    <td colSpan={4}>
                                                        <FontAwesomeIcon icon={faSpinner} size="1x" fixedWidth spin={us.isUploading} className={us.isUploading ? "" : "invisible"}/>
                                                        {us.uploadFailed ?
                                                            "Failed... "
                                                            :
                                                            !us.isUploading ?
                                                                "Pending... "
                                                                :
                                                                "Uploading... "
                                                        }
                                                        "{us.file.name}"
                                                        {us.uploadFailed ?
                                                            <div className="float-right">
                                                                <Button type="button" size="sm" color="outline-success" onClick={() => retryUpload(us.uuid)}>Retry</Button>
                                                                <Button type="button" size="sm" color="outline-danger" className="ml-2" onClick={() => deleteUpload(us.uuid)}>Delete</Button>
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                    </td>
                                                </tr>
                                            )}
                                        </>
                                        :
                                        null
                                    }
                                    {trItems()}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Col>
                    <BlockNav when={fileUploadStructure.length > 0}/>
                </>
                :
                <ActivityOverlayControl show/>
            }
        </Row>
    );
};

export default UserDocuments;

import React from 'react';
import {useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import {Redirect} from "react-router-dom";
import {IsAdmin} from "../../Data/TTSFeatures";
import ErrorLog from "./ErrorLog";
import {Container} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBug} from "@fortawesome/pro-regular-svg-icons";
import { MasterDetail, MDMaster, MDLink, MDDetail } from '@denjpeters/intelliwakereact';

const System = () => {
    const user = useSelector((state: AppState) => state.user);

    if (!IsAdmin(user)) {
        return (
            <Redirect to="/TimeEntry"/>
        );
    }

    return (
        <Container fluid className="px-0">
            <MasterDetail breakAt="sm" mdPath="/System" backText="System">
                <MDMaster width="12em">
                    <ul className="list-group list-group-flush fill-height-scroll">
                        <li className="list-group-item">
                            <h3 className="text-center">System</h3>
                        </li>
                        <MDLink tag="li" panel="ErrorLog" className="list-group-item list-group-item-action">
                            <FontAwesomeIcon icon={faBug} fixedWidth/> Error Log
                        </MDLink>
                    </ul>
                </MDMaster>
                <MDDetail panel="ErrorLog" titleText="Error Log">
                    <ErrorLog/>
                </MDDetail>
            </MasterDetail>
        </Container>
    );
};

export default System;

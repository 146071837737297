import React, {useState} from 'react';
import {Itbluser} from "../../Data/Tables/tbluser";
import {IAccrualsIllness, IAccrualValues} from "./User";
import {Itblaccrualbank} from "../../Data/Tables/tblaccrualbank";
import {Itblaccrual} from "../../Data/Tables/tblaccrual";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {AppState} from "../../Stores/rootReducer";
import {ASRSetViewDate, ASRSetViewUserID} from "../../Stores/appsessionremembers/actions";
import moment from "moment";
import {Button, Row, Table} from "reactstrap";
import {HasFeature, TTSFeatures} from "../../Data/TTSFeatures";
import UserAccrualsModal from "./UserAccrualsModal";
import UserIllnessAdjustModal from "./UserIllnessAdjustModal";
import { ToDigits } from '@denjpeters/intelliwakereact';

interface IProps {
    tbluser: Itbluser,
    accrualsIllness: IAccrualsIllness[],
    tblAccrualBanks: Itblaccrualbank[],
    illnessAccruals: Itblaccrual[],
    accrualValues: IAccrualValues,
    illnessUsed: number,
    illnessAdjustment: number,
    doForceRefresh: (() => void)
}

const UserIllness = (props: IProps) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector((state: AppState) => state.user);
    const [showIllnessAdjustModal, setShowIllnessAdjustModal] = useState(false);
    const [showIllnessAccrualsModal, setShowIllnessAccrualsModal] = useState(false);

    const closeIllnessAdjustModal = () => {
        setShowIllnessAdjustModal(false);
    }

    const closeIllnessAccrualsModal = () => {
        setShowIllnessAccrualsModal(false);
    }

    const openTimeEntry = (userID: number, date: string) => {
        ASRSetViewDate(moment(date).format('YYYY-MM-DD'))(dispatch);
        ASRSetViewUserID(userID)(dispatch);
        history.push('/TimeEntry');
    }

    return (
        <>
            <div className="form-horizontal">
                {HasFeature(user, [TTSFeatures.Feature_User_Admin]) ?
                    <>
                        <Row>
                            <label className="col-sm-4 strong text-right">
                                Illness Hours Available
                            </label>
                            <div className="col-sm-1">
                                <p className="form-control-static text-right">
                                    {ToDigits((props.accrualValues.Illness ?? 0.0) - (props.illnessUsed ?? 0.0), 2)}
                                </p>
                            </div>
                        </Row>
                        <Row>
                            <label className="col-sm-4 strong text-right">Illness Hours Accrued</label>
                            <div className="col-sm-1">
                                <p className="form-control-static text-right">
                                    {ToDigits((props.accrualValues.Illness ?? 0.0), 2)}
                                </p>
                            </div>
                            <div className="col-sm-1">
                                <p className="form-control-static">
                                    <Button color="link" className="btn-link-inline" onClick={() => {
                                        setShowIllnessAccrualsModal(true);
                                    }}>(Edit)</Button>
                                </p>
                            </div>
                        </Row>
                    </>
                    :
                    null
                }
                <Row>
                    <label className="col-sm-4 strong text-right">Illness Hours Used</label>
                    <div className="col-sm-1">
                        <p className="form-control-static text-right">
                            {ToDigits((props.illnessUsed ?? 0.0), 2)}
                        </p>
                    </div>
                    {HasFeature(user, [TTSFeatures.Feature_User_Admin]) ?
                        <div className="col-sm-1">
                            <p className="form-control-static">
                                <Button color="link" className="btn-link-inline" onClick={() => {
                                    setShowIllnessAdjustModal(true);
                                }}>(Adjust)</Button>
                            </p>
                        </div>
                        :
                        null
                    }
                </Row>
                <Row>
                    <label className="col-sm-4 strong text-right">Illness History</label>
                    <div className="col-sm-4 small">
                        <Table bordered hover className="table-scrollable" style={{maxHeight: "15em"}} size="sm">
                            <thead>
                            <tr>
                                <th className="text-center">Date</th>
                                <th className="text-center">Hours Used</th>
                            </tr>
                            </thead>
                            <tbody>
                            {props.accrualsIllness.map((accrualIllness, idx) =>
                                <tr key={idx} onClick={() => {
                                    openTimeEntry(props.tbluser.userID, accrualIllness.date)
                                }}>
                                    <td>{accrualIllness.date}</td>
                                    <td className="text-right">{ToDigits(accrualIllness.amount ?? 0, 2)}</td>
                                </tr>
                            )}
                            </tbody>
                        </Table>
                    </div>
                </Row>
            </div>
            <UserIllnessAdjustModal isOpen={showIllnessAdjustModal} doForceRefresh={props.doForceRefresh} closeModal={closeIllnessAdjustModal} tbluser={props.tbluser} illnessUsed={props.illnessUsed} illnessAdjustment={props.illnessAdjustment}/>
            <UserAccrualsModal isOpen={showIllnessAccrualsModal} doForceRefresh={props.doForceRefresh} closeModal={closeIllnessAccrualsModal} tbluser={props.tbluser} accruals={props.illnessAccruals} accrualName={'Illness'}/>
        </>
    );
};

export default UserIllness;

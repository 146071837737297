import React, {useEffect} from "react";
import {GetFilesWebkitDataTransferItems, UploadFile} from "../../Data/Controllers/tbluser_file";
import { GenerateUUID } from "@denjpeters/intelliwakereact";

const TestPage = () => {
    // const {user} = useSelector((state: AppState) => state);

    const onDragOver = (e: DragEvent) => {
        e.preventDefault();
    }

    const onDrop = (e: DragEvent) => {
        e.preventDefault();

        if (!!e.dataTransfer) {
            GetFilesWebkitDataTransferItems(e.dataTransfer.items)
                .then((files) => {
                    for (const file of files) {
                        UploadFile(file, {
                            uuid: GenerateUUID(),
                            userID: 85
                        })
                            .then((result) => {
                                console.log(result);
                            })
                    }
                });
        }
    }

    useEffect(() => {
        window.addEventListener('dragover', onDragOver);
        window.addEventListener('drop', onDrop);

        return () => {
            window.removeEventListener('dragover', onDragOver);
            window.removeEventListener('drop', onDrop);
        }
    });

    return (
        <>
            Test Page </>
    )
}

export default TestPage;

import React, {useEffect, useState, useRef} from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {Prompt, Redirect, useHistory} from "react-router-dom";

interface IProps {
    when: boolean,
    message?: string,
}

interface IState {
    nextLocation: any | undefined,
    userAllowed: boolean
}

const initialState: IState = {
    nextLocation: undefined,
    userAllowed: false
};

export const BlockNav = (props: IProps) => {
    const history = useHistory();
    const clearState = useRef(setTimeout(() => {}, 1));
    const [state, setState] = useState(initialState);
    const isMounted = useRef(true);
    const handlePrompt = (nextLocation: any) => {
        setState({
            nextLocation: nextLocation,
            userAllowed: false
        });

        return false;
    };

    useEffect(() => {
        isMounted.current = true;
        
        if (!props.when && state.nextLocation) {
            clearTimeout(clearState.current);
            clearState.current = setTimeout(() => {
                if (isMounted.current) {
                    setState(initialState)
                }
            }, 100);

            history.push(state.nextLocation.pathname);
        }

        return () => {
            isMounted.current = false;
            clearTimeout(clearState.current);
        }
    }, [props.when, history, state.nextLocation]);

    if (state.userAllowed && state.nextLocation) {
        const pathname = state.nextLocation.pathname;

        clearTimeout(clearState.current);
        clearState.current = setTimeout(() => {
            if (isMounted.current) {
                setState(initialState)
            }
        }, 100);

        return (
            <Redirect to={pathname}/>
        )
    }

    return (
        <>
            <Modal isOpen={state.nextLocation !== undefined && !state.userAllowed} toggle={() => setState(initialState)} backdrop>
                <ModalHeader className="alert-danger">Leave without saving?</ModalHeader>
                <ModalBody>
                    {props.message ?? 'Data that has changed will not be saved.'}
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => setState({
                        nextLocation: state.nextLocation,
                        userAllowed: true
                    })} color="link" className="text-secondary">Leave</Button>{' '}
                    <Button onClick={() => setState(initialState)} color="success">Stay</Button>
                </ModalFooter>
            </Modal>
            <Prompt when={props.when && !state.userAllowed} message={handlePrompt}/>
        </>
    );
};

export default BlockNav;

export interface UserState {
    loggedIn: boolean,
    userID: number | null,
    firstname: string,
    lastname: string,
    email: string,
    caller: number,
    smsphone: string | null,
    features: string[]
}

export interface UserUpdateState {
    firstname: string,
    lastname: string,
    email: string,
    smsphone: string | null
}

export const UPDATE_SESSION_USER = 'UPDATE_SESSION_USER';
export const UPDATE_USER = 'UPDATE_USER';

interface UpdateSessionUserAction {
    type: typeof UPDATE_SESSION_USER
    payload: UserState
}

interface UpdateUserAction {
    type: typeof UPDATE_USER
    payload: UserUpdateState
}

export type UserActionTypes = UpdateSessionUserAction | UpdateUserAction;

import axios, {CancelTokenSource} from "axios";
import {APIProcess, ShowActivityOverlay, DownloadBase64Data, ShowMessageBox, HideActivityOverlay, IsDevFocused} from '@denjpeters/intelliwakereact';
import {IWake} from "../../IWake";


export const DownloadFile = (id: number, type: string, iWake: IWake) => {
    let cancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();

    ShowActivityOverlay()(iWake.dispatch);
    APIProcess('UserFile', 'Download', {
        id: id
    }, cancelTokenSource)(iWake)
        .then((results) => {
            if (cancelTokenSource) {
                DownloadBase64Data(results.image.name, results.stream, type);
                ShowMessageBox('File downloaded')(iWake.dispatch);
            }
        })
        .catch((e) => {
            console.log('Error', e);
            ShowMessageBox('Could not download file', 'warning')(iWake.dispatch);
        })
        .finally(() => {
            cancelTokenSource = null;
            HideActivityOverlay()(iWake.dispatch);
        });
}

export const UploadFile = (file: File, dataset: any) => {
    const device_token = window.localStorage.getItem('device_token') ?? "";
    const device_token_for = window.sessionStorage.getItem('device_token_for') ?? "";

    return new Promise<any | null>((resolve, reject) => {
        const processurl = process.env.REACT_APP_PROCESSES + 'user_fileuploader';

        let headers: any = {
            Authorization: JSON.stringify({
                device_token: device_token,
                device_token_for: device_token_for
            })
        };

        let config: any = {
            headers: headers
        };

        let formData = new FormData();

        formData.append(`file`, file);

        for (const key of Object.keys(dataset)) {
            formData.append(key, dataset[key]);
        }

        // console.log(file, dataset, formData);

        axios.defaults.withCredentials = true;
        axios.post(processurl, formData, config)
            .then((apiResponse: any) => {
                resolve(apiResponse.data ?? null);
            })
            .catch((error: any) => {
                console.log(error);
                if (IsDevFocused()) {
                    console.log(error);
                }
                reject();
            });
    });
}

/**
 * Gets all files, including those in folders and returns them through a promise as files
 * @param dataTransferItems
 * @constructor
 */
export function GetFilesWebkitDataTransferItems(dataTransferItems: DataTransferItemList): Promise<File[]> {
    function traverseFileTreePromise(item: any, path = '') {
        return new Promise(resolve => {
            if (item.isFile) {
                item.file((file: any) => {
                    file.filepath = path + file.name //save full path
                    files.push(file)
                    resolve(file)
                })
            } else if (item.isDirectory) {
                let dirReader = item.createReader()
                dirReader.readEntries((entries: any) => {
                    let entriesPromises = []
                    for (let entr of entries)
                        entriesPromises.push(traverseFileTreePromise(entr, path + item.name + "/"))
                    resolve(Promise.all(entriesPromises))
                })
            }
        })
    }

    let files: File[] = [];
    return new Promise((resolve) => {
        let entriesPromises = [];
        for (let i = 0; i < dataTransferItems.length; i++) {
            entriesPromises.push(traverseFileTreePromise(dataTransferItems[i].webkitGetAsEntry()));
        }
        Promise.all(entriesPromises)
            .then(() => {
                //console.log(entries)
                resolve(files)
            })
    })
}

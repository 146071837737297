import {
    AppSessionRemembersActionTypes,
    ASR_SET_APPROVALUSERID,
    ASR_SET_INVOICINGSETTINGS,
    ASR_SET_PASSWORDRESET,
    ASR_SET_PAYROLLDATE,
    ASR_SET_PROJECTACTIVE,
    ASR_SET_PROJECTSORT,
    ASR_SET_REPORTEMPTTIMEDATES,
    ASR_SET_USERTAB,
    ASR_SET_VIEWDATE,
    ASR_SET_VIEWPROJECTID,
    ASR_SET_VIEWUSERID
} from './types'
import {IInvoicingSettings} from "../../WebControls/Invoice/Invoicing";

export const ASRSetPasswordReset = (passwordReset: boolean) => {
    return (dispatch: any) => {
        dispatch({
            type: ASR_SET_PASSWORDRESET,
            payload: passwordReset
        } as AppSessionRemembersActionTypes);
    }
}

export const ASRSetViewDate = (viewDate: string) => {
    return (dispatch: any) => {
        dispatch({
            type: ASR_SET_VIEWDATE,
            payload: viewDate
        } as AppSessionRemembersActionTypes);
    }
}

export const ASRSetViewUserID = (viewUserID: number | null) => {
    return (dispatch: any) => {
        dispatch({
            type: ASR_SET_VIEWUSERID,
            payload: viewUserID
        } as AppSessionRemembersActionTypes);
    }
}

export const ASRSetViewProjectID = (viewProjectID: number | null) => {
    return (dispatch: any) => {
        dispatch({
            type: ASR_SET_VIEWPROJECTID,
            payload: viewProjectID
        } as AppSessionRemembersActionTypes);
    }
}

export const ASRSetApprovalUserID = (approvalUserID: number | null) => {
    return (dispatch: any) => {
        dispatch({
            type: ASR_SET_APPROVALUSERID,
            payload: approvalUserID
        } as AppSessionRemembersActionTypes);
    }
}

export const ASRSetProjectSort = (projectSort: 'Name' | 'NoAsc' | 'NoDesc') => {
    return (dispatch: any) => {
        dispatch({
            type: ASR_SET_PROJECTSORT,
            payload: projectSort
        } as AppSessionRemembersActionTypes);
    }
}

export const ASRSetProjectActive = (projectActive: 1 | 0 | -1) => {
    return (dispatch: any) => {
        dispatch({
            type: ASR_SET_PROJECTACTIVE,
            payload: projectActive
        } as AppSessionRemembersActionTypes);
    }
}

export const ASRSetPayrollDate = (payrollDate: string) => {
    return (dispatch: any) => {
        dispatch({
            type: ASR_SET_PAYROLLDATE,
            payload: payrollDate
        } as AppSessionRemembersActionTypes);
    }
}

export const ASRSetReportEmpDates = (startDate: string | null, endDate: string | null) => {
    return (dispatch: any) => {
        dispatch({
            type: ASR_SET_REPORTEMPTTIMEDATES,
            payload: [startDate, endDate]
        } as AppSessionRemembersActionTypes);
    }
}

export const ASRSetUserTab = (userTab: string | null) => {
    return (dispatch: any) => {
        dispatch({
            type: ASR_SET_USERTAB,
            payload: userTab
        } as AppSessionRemembersActionTypes);
    }
}

export const ASRSetInvoicingSettings = (invoicingSettings: IInvoicingSettings) => {
    return (dispatch: any) => {
        dispatch({
            type: ASR_SET_INVOICINGSETTINGS,
            payload: invoicingSettings
        } as AppSessionRemembersActionTypes);
    }
}

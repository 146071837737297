export interface SystemState {
    app_version: string,
    html_version: string,
    db_version: string,
    environment: string,
    environment_name: string
}

export const UPDATE_SYSTEM = 'UPDATE_SYSTEM';

interface UpdateSystemAction {
    type: typeof UPDATE_SYSTEM
    payload: SystemState
}

export type SystemActionTypes = UpdateSystemAction

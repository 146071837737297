import {LocalStoreActionTypes, LocalStoreState, UPDATE_LOCAL_STORE} from './types'
import { ObjectPropertiesEqual } from '@denjpeters/intelliwakereact';

export const initialState: LocalStoreState = {
    DBPhotoQueue: 0
};

export function reducerLocalStore(
    state = initialState,
    action: LocalStoreActionTypes
): LocalStoreState {
    switch (action.type) {
        case UPDATE_LOCAL_STORE: {
            if (ObjectPropertiesEqual(state, action.payload)) {
                return state;
            }
            return {
                ...state,
                ...action.payload
            };
        }
        default:
            return state
    }
}

import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import {Button, Col, FormGroup, Label, Row, Table} from "reactstrap";
import {IWake} from "../../IWake";
import {APIProcess, GenerateUUID, ShowPromptOKCancel, ShowActivityOverlay, ShowMessageBox, HideActivityOverlay, ElementCustomValue, InputDate, InputSelect, InputText, InputTextArea, InputSwitch} from '@denjpeters/intelliwakereact';
import {Itbluser_diary} from "../../Data/Tables/tbluser_diary";
import {Itbluser_diary_file} from "../../Data/Tables/tbluser_diary_file";
import {GetFilesWebkitDataTransferItems} from "../../Data/Controllers/tbluser_file";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFile, faSpinner} from "@fortawesome/pro-regular-svg-icons";
import {DownloadFile, UploadFile} from "../../Data/Controllers/tbluser_diary_file";
import BlockNav from "../Generics/BlockNav";
import moment from "moment";

interface IProps {
    tbluser_diary: Itbluser_diary,
    tbluser_diary_files: Itbluser_diary_file[],
    tbluser_diary_types: Itbluser_diary[],
    updateDiary: ((tbluser_diary: Itbluser_diary) => void),
    updateFile: ((tbluser_diary_file: Itbluser_diary_file) => void),
    forceRefresh: (() => void)
}

interface IUploadStructure {
    uuid: string,
    file: File,
    isUploading: boolean,
    uploadFailed: boolean
}

const UserDiary = (props: IProps) => {
    const dispatch = useDispatch();
    const {user} = useSelector((state: AppState) => state);
    const fileUploader = useRef(null as HTMLInputElement | null);
    const [editMode, setEditMode] = useState(false);
    const [changes, setChanges] = useState({} as { [key: string]: any });
    const [uploadFiles, setUploadFiles] = useState([] as IUploadStructure[]);
    const [showInactiveFiles, setShowInactiveFiles] = useState(false);
    const simultaneousUploads = 2;

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    const onDrop = (e: DragEvent) => {
        e.preventDefault();

        if (!!e.dataTransfer) {
            GetFilesWebkitDataTransferItems(e.dataTransfer.items)
                .then((files) => {
                    setUploadFiles(prevState => [
                        ...prevState,
                        ...files.map(file => {
                            return {
                                uuid: GenerateUUID(),
                                file: file,
                                isUploading: false,
                                uploadFailed: false
                            } as IUploadStructure
                        })]);
                });
        }
    }

    useEffect(() => {
        window.addEventListener('drop', onDrop);

        return () => {
            window.removeEventListener('drop', onDrop);
        }
    });

    const showFileUpload = () => {
        if (fileUploader.current) {
            fileUploader.current.click();
        }
    };

    const executeFileUpload = (e: any) => {
        let uploads: IUploadStructure[] = [];

        for (let file of e.target.files)
            uploads.push({uuid: GenerateUUID(), file: file, isUploading: false, uploadFailed: false} as IUploadStructure);

        setUploadFiles(prevState => [
            ...prevState,
            ...uploads
        ]);
    }

    useEffect(() => {
        if (uploadFiles.length > 0) {
            const currentlyUploading = uploadFiles.filter(us => us.isUploading).length;
            let notYetUploading = uploadFiles.filter(us => !us.isUploading && !us.uploadFailed);

            if (currentlyUploading < simultaneousUploads && notYetUploading.length > 0) {
                let nextToUpload = notYetUploading.find(() => true);
                if (!!nextToUpload) {
                    nextToUpload.isUploading = true;

                    setUploadFiles(prevState => [
                        ...prevState.filter(us => us.uuid !== nextToUpload!.uuid),
                        nextToUpload!
                    ]);

                    UploadFile(nextToUpload.file!, {
                        uuid: nextToUpload.uuid,
                        userID: props.tbluser_diary.userID,
                        tbluser_diary_id: props.tbluser_diary.id
                    })
                        .then((result) => {
                            setUploadFiles(prevState => [
                                ...prevState.filter(prev => prev.uuid !== nextToUpload!.uuid)
                            ]);

                            props.updateFile(result as Itbluser_diary_file);
                        })
                        .catch(() => {
                            nextToUpload!.isUploading = false;
                            nextToUpload!.uploadFailed = true;

                            setUploadFiles(prevState => [
                                ...prevState.filter(prev => prev.uuid !== nextToUpload!.uuid),
                                nextToUpload!
                            ]);
                        });
                }
            }
        }
    }, [uploadFiles, props]);

    const retryUpload = (uploadFile: IUploadStructure) => {
        if (!!uploadFile && uploadFile.uploadFailed)
            setUploadFiles(prevState => [
                ...prevState.filter(prev => prev.uuid !== uploadFile.uuid),
                {
                    ...uploadFile,
                    uploadFailed: false
                }
            ])
    }

    const cancelUpload = (uuid: string) => {
        setUploadFiles(prevState => [
            ...prevState.filter(prev => prev.uuid !== uuid)
        ])
    }

    const deleteFile = async (id: number) => {
        if (await ShowPromptOKCancel('Delete?', 'Are you sure you want to delete this file?', 'danger', 'Delete')(dispatch)) {
            ShowActivityOverlay()(dispatch);
            APIProcess('UserDiaryFile', 'Save', {id: id, active: 0})(iWake)
                .then(() => {
                    ShowMessageBox("Deleted")(dispatch);
                    const file = props.tbluser_diary_files.find(file => file.id === id);
                    if (!!file) {
                        props.updateFile({...file, active: 0});
                    }
                })
                .catch(() => {
                })
                .finally(() => {
                    HideActivityOverlay()(dispatch);
                });
        }
    }

    const undeleteFile = async (id: number) => {
        if (await ShowPromptOKCancel('Un-Delete?', 'Are you sure you want to un-delete this file?', 'warning', 'Un-Delete')(dispatch)) {
            ShowActivityOverlay()(dispatch);
            APIProcess('UserDiaryFile', 'Save', {id: id, active: 1})(iWake)
                .then(() => {
                    ShowMessageBox("Deleted")(dispatch);
                    const file = props.tbluser_diary_files.find(file => file.id === id);
                    if (!!file) {
                        props.updateFile({...file, active: 1});
                    }
                })
                .catch(() => {
                })
                .finally(() => {
                    HideActivityOverlay()(dispatch);
                });
        }
    }

    const tbluser_diary = useMemo(() => {
        return {...props.tbluser_diary, ...changes}
    }, [props.tbluser_diary, changes]);

    const handleInputChange = (e: React.ChangeEvent<any>) => {
        const target: any = e.target;

        if (!!target) {
            const name = target.name;
            const value = ElementCustomValue(e);
            setChanges(prevState => {
                return {
                    ...prevState,
                    [name]: value
                }
            });
        }
    }

    const saveDiary = () => {
        if (Object.keys(changes).length > 0) {
            const updates = {...props.tbluser_diary, ...changes} as Itbluser_diary;
            if (moment(updates.diary_date).isValid()) {
                const isNew = !updates.id;

                ShowActivityOverlay()(dispatch);
                APIProcess('UserDiary', 'Save', updates)(iWake)
                    .then((result) => {
                        props.updateDiary({...updates, id: result.id});
                        setChanges({});
                        setEditMode(isNew);
                        ShowMessageBox("Saved")(dispatch);
                    })
                    .catch(() => {
                    })
                    .finally(() => {
                        HideActivityOverlay()(dispatch);
                    });
            } else {
                ShowMessageBox('A valid diary date is required', 'danger')(dispatch);
            }
        }
    }

    const cancelChanges = () => {
        setChanges({});
        setEditMode(false);
    }

    const files = useMemo(() =>
            props.tbluser_diary_files
                .filter(file => file.tbluser_diary_id === props.tbluser_diary.id && (showInactiveFiles || file.active === 1))
                .sort((a, b) => a.name.localeCompare(b.name, undefined, {sensitivity: 'base'}))
        , [props.tbluser_diary_files, props.tbluser_diary.id, showInactiveFiles]);

    useEffect(() => {
        if (!props.tbluser_diary.id) {
            setEditMode(true);
        } else {
            setEditMode(false);
        }
    }, [props.tbluser_diary.id]);

    return (
        <Row className="fill-height">
            <Col className="fill-height-scroll">
                <Row className="p-4">
                    <Col className="text-right">
                        {editMode ?
                            <>
                                {Object.keys(changes).length > 0 ?
                                    <Button type="button" color="success" size="sm" onClick={saveDiary} className="mr-2">Save</Button>
                                    :
                                    null
                                }
                                <Button type="button" color="secondary" size="sm" onClick={cancelChanges}>Cancel</Button>
                            </>
                            :
                            <Button type="button" color="secondary" size="sm" onClick={() => setEditMode(true)}>Edit</Button>
                        }
                    </Col>
                </Row>
                <Row className="p-4">
                    <Col className="form-sm-label form-condensed">
                        <FormGroup>
                            <Label>Date</Label>
                            <Col className="short">
                                <InputDate name="diary_date" onChange={handleInputChange} value={tbluser_diary.diary_date} plainText={!editMode} invalid={!tbluser_diary.diary_date}/>
                            </Col>
                            {(editMode || !!tbluser_diary.tbluser_diary_type_id) ?
                                <>
                                    <Label>Type</Label>
                                    <Col className="short">
                                        <InputSelect name="tbluser_diary_type_id" onChange={handleInputChange} value={tbluser_diary.tbluser_diary_type_id ?? 0} plainText={!editMode} isNumericOrNull>
                                            <option/>
                                            {props.tbluser_diary_types
                                                .filter(type => type.active === 1 || type.id === tbluser_diary.tbluser_diary_type_id)
                                                .sort((a, b) => a.name.localeCompare(b.name, undefined, {sensitivity: 'base'}))
                                                .map(type =>
                                                    <option key={type.id} value={type.id}>{type.name}</option>
                                                )
                                            }
                                        </InputSelect>
                                    </Col>
                                </>
                                :
                                null
                            }
                        </FormGroup>
                        <FormGroup>
                            <Label>Title</Label>
                            <Col>
                                <InputText name="name" onChange={handleInputChange} value={tbluser_diary.name} plainText={!editMode}/>
                            </Col>
                        </FormGroup>
                        {(editMode || !!tbluser_diary.notes) ?
                            <FormGroup>
                                <Label>Notes</Label>
                                <Col>
                                    <InputTextArea name="notes" onChange={handleInputChange} value={tbluser_diary.notes ?? ""} rows={4} plainText={!editMode}/>
                                </Col>
                            </FormGroup>
                            :
                            null
                        }
                        {editMode ?
                            <FormGroup>
                                <Col className="offset">
                                    <InputSwitch name="active" onChange={(e) => {
                                        handleInputChange(e)
                                    }} value={tbluser_diary.active} label="Active" plainText={!editMode}/>
                                </Col>
                            </FormGroup>
                            :
                            null
                        }
                        {(editMode || files.length > 0) ?
                            <FormGroup>
                                <Label>Files</Label>
                                <Col>
                                    {!props.tbluser_diary.id ?
                                        <i className="form-control-plaintext">
                                            Save diary to add files
                                        </i>
                                        :
                                        editMode ?
                                            <>
                                                <Button size="sm" onClick={showFileUpload} className="mb-3">
                                                    <FontAwesomeIcon icon={faFile} fixedWidth/> Upload Files(s)
                                                </Button>
                                                <input type="file" accept="*/*" multiple ref={fileUploader} onChange={executeFileUpload}/>
                                            </>
                                            :
                                            null
                                    }
                                    <Table size="sm" borderless>
                                        <tbody>
                                        {uploadFiles
                                            .sort((a, b) =>
                                                ((b.isUploading ? 1 : 0) - (a.isUploading ? 1 : 0)) ??
                                                ((b.uploadFailed ? 1 : 0) - (a.uploadFailed ? 1 : 0))
                                            )
                                            .map(uploadFile =>
                                                <tr key={uploadFile.uuid}>
                                                    <td>
                                                        <FontAwesomeIcon icon={faSpinner} size="1x" fixedWidth spin={uploadFile.isUploading} className={uploadFile.isUploading ? "" : "invisible"}/>
                                                        {uploadFile.uploadFailed ?
                                                            "Failed... "
                                                            :
                                                            !uploadFile.isUploading ?
                                                                "Pending... "
                                                                :
                                                                "Uploading... "
                                                        }
                                                        "{uploadFile.file.name}"
                                                    </td>
                                                    <td className="text-right text-nowrap">
                                                        {uploadFile.uploadFailed ?
                                                            <>
                                                                <Button type="button" size="sm" color="outline-success" onClick={() => retryUpload(uploadFile)}>Retry</Button>
                                                                <Button type="button" size="sm" color="outline-danger" className="ml-2" onClick={() => cancelUpload(uploadFile.uuid)}>Delete</Button>
                                                            </>
                                                            :
                                                            null
                                                        }
                                                    </td>
                                                </tr>
                                            )}
                                        {files.map(file =>
                                            <tr key={file.id}>
                                                <td>
                                                    <Button type="button" color="link" className="btn-link-inline" onClick={() => DownloadFile(file.id, file.type ?? '', iWake)}>
                                                        {file.name}
                                                    </Button>
                                                </td>
                                                {editMode ?
                                                    <td className="td-lg text-right">
                                                        {file.active === 1 ?
                                                            <Button type="button" color="link" className="btn-link-inline text-danger" onClick={() => deleteFile(file.id)}>
                                                                (Delete)
                                                            </Button>
                                                            :
                                                            <Button type="button" color="link" className="btn-link-inline text-warning" onClick={() => undeleteFile(file.id)}>
                                                                (Un-Delete)
                                                            </Button>
                                                        }
                                                    </td>
                                                    :
                                                    null
                                                }
                                            </tr>
                                        )}
                                        </tbody>
                                    </Table>
                                    {(editMode && !!props.tbluser_diary_files.find(file => file.active === 0)) ?
                                        <InputSwitch value={showInactiveFiles ? 1 : 0} onChange={() => setShowInactiveFiles(prevState => !prevState)} label="Show deleted files"/>
                                        :
                                        null
                                    }
                                </Col>
                            </FormGroup>
                            :
                            null
                        }
                    </Col>
                    <BlockNav when={uploadFiles.length > 0}/>
                </Row>
            </Col>
        </Row>
    );
};

export default UserDiary;

import {UserState} from "../Stores/user/types";

export enum TTSFeatures {
    Feature_TimeSheet_Admin = 'TimeSheet Admin',
    Feature_TimeSheet_Entry = 'TimeSheet Entry',
    Feature_TimeSheet_Approval = 'TimeSheet Approval',
    Feature_Enter_Time_GT_2_Weeks = 'Enter Time Greater Than 2 Weeks Back',
    Feature_Mileage_Admin = 'Mileage Admin',
    Feature_User_Admin = 'User Admin',
    Feature_User_Vacation_Illness_Admin = 'User Vacation Illness Admin',
    Feature_User_Vacation_Illness_View = 'User Vacation Illness View',
    Feature_Project_Admin = 'Project Admin',
    Feature_Project_Maintain = 'Project Maintain',
    Feature_Invoicing = 'Invoicing',
    Feature_Project_Reports = 'Project Reports',
    Feature_Payroll = 'Payroll',
    Feature_Payroll_Reports = 'Payroll Reports',
    Feature_Salary_Reports = 'Salary Reports',
    Feature_Customer_Admin = 'Customer Admin',
    Feature_Customer_Agreement_Admin = 'Customer Agreement Admin'
}

export const TTSFeatureName = (ttsFeature: TTSFeatures): string => {
    return ttsFeature;
}

export const IsAdmin = (user: UserState): boolean => {
    return user.caller > 0;
}

export const HasFeature = (user: UserState, features: string | string[]): boolean => {
    if (user.caller > 1) {
        return true;
    }

    let featureList: string[];

    if (typeof features === 'string') {
        featureList = [features];
    } else {
        featureList = features;
    }

    for (const feature of featureList) {
        if (user.features.includes(feature)) {
            return true;
        }
    }

    return false;
}

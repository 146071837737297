import React, {useEffect, useMemo, useRef, useState} from 'react';
import {
    Button,
    Col,
    Row, Table
} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import axios, {CancelTokenSource} from "axios";
import {APIProcess, initialSortColumn, ISortColumn, ShowActivityOverlay, SearchSort, HideActivityOverlay, SortColumnUpdate, ToDigitsBlank, InputSearch} from '@denjpeters/intelliwakereact';
import {Itblproject} from "../../Data/Tables/tblproject";
import ProjectDD from "../Generics/ProjectDD";
import {useHistory} from 'react-router-dom';
import {ASRSetViewUserID} from "../../Stores/appsessionremembers/actions";
import {IWake} from "../../IWake";

interface IReportData {
    userID: number,
    activityID: number,
    fullname: string,
    activity: string,
    start_date: string,
    end_date: string,
    regular_billed: number,
    overtime_billed: number,
    total_billed: number,
    budgeted_hours: number,
    available_hours: number
}

interface IReportStructure {
    project: Itblproject,
    reportData: IReportData[]
}

const Project = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const isMounted = useRef(true);
    const {user, appSessionRemembersChange} = useSelector((state: AppState) => state);
    const [reportStructure, setReportStructure] = useState(null as IReportStructure | null);
    const [search, setSearch] = useState('');
    const [sortColumn, setSortColumn] = useState({
        ...initialSortColumn,
        primarySort: "fullname",
        secondarySort: "activity"
    } as ISortColumn);

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    const openProject = (projectID: number) => {
        history.push('/Project/' + projectID);
    }

    const openProjectEmployee = (userID: number) => {
        ASRSetViewUserID(userID)(dispatch);
        history.push('/Reports/ProjectEmployee');
    }

    useEffect(() => {
        let cancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();
        isMounted.current = true;

        ShowActivityOverlay()(dispatch);
        APIProcess('project', 'TimeByProject', {
            projectID: appSessionRemembersChange.viewProjectID ?? 0
        }, cancelTokenSource)(iWake)
            .then((results) => {
                // console.log(results.reportStructure);
                if (isMounted.current && cancelTokenSource) {
                    setReportStructure(results);
                }
            })
            .catch(() => {
                if (isMounted.current && cancelTokenSource) {
                    setReportStructure(null);
                }
            })
            .finally(() => {
                HideActivityOverlay()(dispatch);
                cancelTokenSource = null;
            });

        return () => {
            isMounted.current = false;
            if (cancelTokenSource) {
                cancelTokenSource.cancel();
                cancelTokenSource = null;
            }
        }
    }, [appSessionRemembersChange.viewProjectID, dispatch, iWake]);

    const reportDataList: IReportData[] = useMemo(() => {
        if (!reportStructure) return [];
        return SearchSort(reportStructure.reportData ?? [], search, sortColumn)
    }, [reportStructure, search, sortColumn]);

    const startMin: string = useMemo(() => {
        return reportDataList
            .map(reportData => reportData.start_date ?? "")
            .reduce((prev, next) => !next ? prev : ((!prev ? next : ((next < prev) ? next : prev))), "");
    }, [reportDataList]);

    const endMax: string = useMemo(() => {
        return reportDataList
            .map(reportData => reportData.end_date ?? "")
            .reduce((prev, next) => !next ? prev : ((!prev ? (next ?? "") : ((next > prev) ? next : prev))), "");
    }, [reportDataList]);

    const regularTotal: number = useMemo(() => {
        return reportDataList
            .map(reportData => reportData.regular_billed ?? 0)
            .reduce((prev, next) => +prev + +next, 0)
    }, [reportDataList]);

    const overtimeTotal: number = useMemo(() => {
        return reportDataList
            .map(reportData => reportData.overtime_billed ?? 0)
            .reduce((prev, next) => +prev + +next, 0)
    }, [reportDataList]);

    const totalTotal: number = useMemo(() => {
        return reportDataList
            .map(reportData => reportData.total_billed ?? 0)
            .reduce((prev, next) => +prev + +next, 0)
    }, [reportDataList]);

    const budgetedTotal: number = useMemo(() => {
        return reportDataList
            .map(reportData => reportData.budgeted_hours ?? 0)
            .reduce((prev, next) => +prev + +next, 0)
    }, [reportDataList]);

    const avaialableTotal: number = useMemo(() => {
        return reportDataList
            .map(reportData => reportData.available_hours ?? 0)
            .reduce((prev, next) => +prev + +next, 0)
    }, [reportDataList]);

    return (
        <>
            <Row>
                <Col>
                    <h3 className="text-center">Time-by-Project</h3>
                    <h4 className="text-center">
                        <ProjectDD/>
                    </h4>
                </Col>
            </Row>
            <Row className="d-print-none">
                <Col md={4}>
                    <InputSearch triggerSearchText={setSearch} placeholder="Search"/>
                </Col>
                {!!reportStructure ?
                    <Col md={8} className="text-right">
                        <div className="form-control-plaintext">
                            Budgeted Hours: {ToDigitsBlank(reportStructure.project.budgeted_hours)}
                            <Button color="link" className="btn-link-inline ml-3" onClick={() => openProject(reportStructure.project.projectID)}>
                                (Edit)
                            </Button>
                        </div>
                    </Col>
                    :
                    null
                }
            </Row>
            {!!reportStructure ?
                <Row className="mt-3 small fill-height">
                    <Col className="fill-height">
                        <Table bordered hover size="sm" className="table-scrollable table-sortable">
                            <thead>
                            <tr className="table-secondary">
                                <th onClick={() => setSortColumn(SortColumnUpdate('fullname', sortColumn))}>Employee</th>
                                <th onClick={() => setSortColumn(SortColumnUpdate('activity', sortColumn))}>Activity</th>
                                <th className="td-lg" onClick={() => setSortColumn(SortColumnUpdate('start_date', sortColumn))}>Start
                                    Date
                                </th>
                                <th className="td-lg" onClick={() => setSortColumn(SortColumnUpdate('end_date', sortColumn))}>Last
                                    Date
                                </th>
                                <th className="text-right td-md" onClick={() => setSortColumn(SortColumnUpdate('regular_billed', sortColumn))}>Regular
                                    Hours
                                </th>
                                <th className="text-right td-md" onClick={() => setSortColumn(SortColumnUpdate('overtime_billed', sortColumn))}>Overtime
                                    Hours
                                </th>
                                <th className="text-right td-md" onClick={() => setSortColumn(SortColumnUpdate('total_billed', sortColumn))}>Reg + OT
                                    Hours
                                </th>
                                <th className="text-right td-md" onClick={() => setSortColumn(SortColumnUpdate('budgeted_hours', sortColumn))}>Assigned
                                    Hours
                                </th>
                                <th className="text-right td-md" onClick={() => setSortColumn(SortColumnUpdate('available_hours', sortColumn))}>Available
                                    Hours
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {reportDataList.map((reportData, idx) =>
                                <tr key={idx} onClick={() => openProjectEmployee(reportData.userID)}>
                                    <td>{reportData.fullname}</td>
                                    <td>{reportData.activity}</td>
                                    <td className="td-lg">{reportData.start_date}</td>
                                    <td className="td-lg">{reportData.end_date}</td>
                                    <td className="text-right td-md">{ToDigitsBlank(reportData.regular_billed, 2)}</td>
                                    <td className="text-right td-md">{ToDigitsBlank(reportData.overtime_billed, 2)}</td>
                                    <td className="text-right td-md">{ToDigitsBlank(reportData.total_billed, 2)}</td>
                                    <td className="text-right td-md">{ToDigitsBlank(reportData.budgeted_hours, 2)}</td>
                                    <td className="text-right td-md">{ToDigitsBlank(reportData.available_hours, 2)}</td>
                                </tr>
                            )}
                            </tbody>
                            <tfoot>
                            <tr>
                                <th/>
                                <th className="text-right">Totals:</th>
                                <th className="td-lg">{startMin}</th>
                                <th className="td-lg">{endMax}</th>
                                <th className="text-right td-md">{ToDigitsBlank(regularTotal, 2)}</th>
                                <th className="text-right td-md">{ToDigitsBlank(overtimeTotal, 2)}</th>
                                <th className="text-right td-md">{ToDigitsBlank(totalTotal, 2)}</th>
                                <th className="text-right td-md">{ToDigitsBlank(budgetedTotal, 2)}</th>
                                <th className="text-right td-md">{ToDigitsBlank(avaialableTotal, 2)}</th>
                            </tr>
                            </tfoot>
                        </Table>
                    </Col>
                </Row>
                :
                null
            }
        </>
    );
};

export default Project;

import React, {useEffect, useMemo, useState} from 'react';
import {Itbluser} from "../../Data/Tables/tbluser";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import {Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table} from "reactstrap";
import moment from "moment";
import {initialtblaccrualbank, Itblaccrualbank} from "../../Data/Tables/tblaccrualbank";
import {APIProcess, ElementCustomValue, ShowActivityOverlay, HideActivityOverlay, ToDigits, InputNumber} from '@denjpeters/intelliwakereact';
import {IWake} from "../../IWake";

interface IProps {
    isOpen: boolean,
    doForceRefresh: (() => void),
    closeModal: (() => void),
    tbluser: Itbluser,
    tblAccrualBanks: Itblaccrualbank[]
}

const UserVacationReserveBankModal = (props: IProps) => {
    const dispatch = useDispatch();
    const user = useSelector((state: AppState) => state.user);

    const [editTblAccuralBank, setEditTblAccrualBank] = useState({
        ...initialtblaccrualbank,
        type: 'Vacation'
    });

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEditTblAccrualBank({
            ...editTblAccuralBank,
            [e.target.name]: ElementCustomValue(e)
        } as Itblaccrualbank);
    }

    const addItem = () => {
        ShowActivityOverlay()(dispatch);
        APIProcess('User', 'AccrualBankSave', {
            ...editTblAccuralBank,
            userID: props.tbluser.userID
        })(iWake)
            .then(() => {
                props.doForceRefresh();
                setEditTblAccrualBank({
                    ...initialtblaccrualbank,
                    type: 'Vacation'
                });
            })
            .catch(() => {
            })
            .finally(() => {
                HideActivityOverlay()(dispatch);
            });
    }

    const removeItem = (accrualbankID: number) => {
        ShowActivityOverlay()(dispatch);
        APIProcess('User', 'AccrualBankDelete', {accrualbankID: accrualbankID})(iWake)
            .then(() => {
                props.doForceRefresh();
            })
            .catch(() => {
            })
            .finally(() => {
                HideActivityOverlay()(dispatch);
            });
    }

    useEffect(() => {
        setEditTblAccrualBank({
            ...initialtblaccrualbank,
            type: 'Vacation'
        });
    }, [props.isOpen]);

    return (
        <Modal backdrop keyboard toggle={props.closeModal} isOpen={props.isOpen} size="lg">
            <ModalHeader className="alert-info">
                Vacation Reserve Bank - {props.tbluser.lastname}, {props.tbluser.firstname}
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        Start date: {props.tbluser.startdate ?? "Not set"}
                    </Col>
                </Row>
                {!!props.tbluser.startdate ?
                    <Row>
                        <Col>
                            Current year of employment: {moment().diff(moment(props.tbluser.startdate), 'years')}
                        </Col>
                    </Row>
                    :
                    null
                }
                <Row className="mt-3">
                    <Col className="pre-scrollable">
                        <Table borderless size="sm" className="table-inputs">
                            <thead>
                            <tr className="table-secondary table-bordered">
                                <th>Start Year</th>
                                <th>Maximum Rollover Allowed</th>
                                <th/>
                            </tr>
                            </thead>
                            <tbody>
                            {props.tblAccrualBanks.map(tblAccrualBank =>
                                <tr key={tblAccrualBank.accrualbankID} className="table-bordered">
                                    <td>
                                        <span className="form-control-plaintext text-right">
                                            {ToDigits(tblAccrualBank.startYear, 0)}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="form-control-plaintext text-right">
                                            {ToDigits(tblAccrualBank.limithours, 2)}
                                        </span>
                                    </td>
                                    <td>
                                        <Button color="link" onClick={() => {
                                            removeItem(tblAccrualBank.accrualbankID)
                                        }}>Remove</Button>
                                    </td>
                                </tr>
                            )}
                            <tr className="table-borderless">
                                <td>
                                    <InputNumber name="startYear" value={editTblAccuralBank.startYear} onChange={handleInputChange} integerScale={3}/>
                                </td>
                                <td>
                                    <InputNumber name="limithours" value={editTblAccuralBank.limithours} onChange={handleInputChange} integerScale={4} decimalScale={2}/>
                                </td>
                                <td>
                                    <Button color="link" onClick={addItem}>Add New</Button>
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <small>This Employee can only roll over hours beginning with the Start Year (usually 0, but some
                            employees were grandfathered in October 6, 2018), and each anniversary thereafter until
                            another Start Year is defined.</small>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={props.closeModal}>Close</Button>
            </ModalFooter>
        </Modal>
    );
};

export default UserVacationReserveBankModal;

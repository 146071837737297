import React, {useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import {Button, Col, FormGroup, Label, Row} from "reactstrap";
import {IWake} from "../../IWake";
import {Itbluser_file_type} from "../../Data/Tables/tbluser_file_type";
import {APIProcess, ElementCustomValue, ShowMessageBox, InputText, InputSwitch, InputSelect} from '@denjpeters/intelliwakereact';
import {Itbluser_file_type_group} from "../../Data/Tables/tbluser_file_type_group";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDown, faArrowUp} from "@fortawesome/pro-solid-svg-icons";

interface IProps {
    tbluser_file_type_group: Itbluser_file_type_group,
    tbluser_file_type_groups: Itbluser_file_type_group[],
    tbluser_file_type: Itbluser_file_type,
    updateType: ((tbluser_file_type: Itbluser_file_type) => void),
    forceRefresh: (() => void)
}

const FileType = (props: IProps) => {
    const dispatch = useDispatch();
    const {user} = useSelector((state: AppState) => state);
    const [changes, setChanges] = useState({} as { [key: string]: any });

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    const tbluser_file_type = useMemo(() => {
        return {...props.tbluser_file_type, ...changes}
    }, [props.tbluser_file_type, changes]);

    const handleInputChangeName = (name: string, value: any) => {
        setChanges(prevState => {
            return {
                ...prevState,
                [name]: value
            }
        });
    }

    const handleInputChange = (e: React.ChangeEvent<any>) => {
        const target: any = e.target;

        if (!!target) {
            const name = target.name;
            const value = ElementCustomValue(e);
            handleInputChangeName(name, value);
        }
    }

    const saveType = () => {
        if (Object.keys(changes).length > 0) {
            saveGroupWithChanges(changes);
        }
    }

    const saveGroupWithChanges = (saveChanges: { [key: string]: any }) => {
        let updates = {
            ...props.tbluser_file_type,
            ...changes,
            ...saveChanges
        } as Itbluser_file_type;

        if (!updates.tbluser_file_type_group_id)
            updates.tbluser_file_type_group_id = props.tbluser_file_type_group.id;

        APIProcess('UserFileType', 'SaveType', updates)(iWake)
            .then((result) => {
                props.updateType({...updates, id: result.id});
                setChanges({});
                ShowMessageBox("Saved")(dispatch);
            })
            .catch(() => {
            })
            .finally(() => {
            });
    }

    return (
        <Row className="p-4">
            <Col className="form-sm" onBlur={saveType}>
                <FormGroup>
                    <Label>Document Name</Label>
                    <Col className="medium">
                        <InputText name="name" onChange={handleInputChange} value={tbluser_file_type.name}/>
                    </Col>
                    <Col className="short">
                        <Button type="button" size="sm" color="outline-primary" className="mr-2" onClick={() => saveGroupWithChanges({'sort_order': tbluser_file_type.sort_order - 15})}>
                            <FontAwesomeIcon icon={faArrowUp}/>
                        </Button>
                        <Button type="button" size="sm" color="outline-primary" className="mr-2" onClick={() => saveGroupWithChanges({'sort_order': tbluser_file_type.sort_order + 15})}>
                            <FontAwesomeIcon icon={faArrowDown}/>
                        </Button>
                    </Col>
                </FormGroup>
                <FormGroup>
                    <Col className="offset">
                        <InputSwitch name="ismultiple" onChange={(e) => {
                            handleInputChange(e)
                        }} value={tbluser_file_type.ismultiple} label="Multiple Active Docs"/>
                    </Col>
                </FormGroup>
                <FormGroup>
                    <Col className="offset">
                        <InputSwitch name="active" onChange={(e) => {
                            handleInputChange(e)
                        }} value={tbluser_file_type.active} label="Active"/>
                    </Col>
                </FormGroup>
                <FormGroup>
                    <Label>Category</Label>
                    <Col className="medium">
                        <InputSelect name="tbluser_file_type_group_id" onChange={handleInputChange} value={!!tbluser_file_type.tbluser_file_type_group_id ? tbluser_file_type.tbluser_file_type_group_id : props.tbluser_file_type_group.id} isNumeric>
                            {props.tbluser_file_type_groups.map(group =>
                                <option key={group.id} value={group.id}>{group.name}</option>
                            )}
                        </InputSelect>
                    </Col>
                </FormGroup>
            </Col>
        </Row>
    );
};

export default FileType;

import React, {FormEvent, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import {
    Col,
    Form,
    FormGroup,
    Label, Row, Table
} from "reactstrap";
import {APIProcess, ShowActivityOverlay, ShowMessageBox, HideActivityOverlay, ElementCustomValue, InputDate, InputNumber, ToDigits, ToDigitsBlank, InputSelect, CleanNumber, ActivityOverlayControl} from '@denjpeters/intelliwakereact';
import BlockNav from "../Generics/BlockNav";
import {HasFeature, TTSFeatures} from "../../Data/TTSFeatures";
import {Itblproject} from "../../Data/Tables/tblproject";
import {IMissingActuals, IProjectBudgets} from "./Project";
import ProjectBudgetEditModal from "./ProjectBudgetEditModal";
import {initialtblproject_budget, Itblproject_budget} from "../../Data/Tables/tblproject_budget";
import {Itblactivitycode} from "../../Data/Tables/tblactivitycode";
import {IUsers} from "../../Data/Views/Users";
import moment from "moment";
import {IWake} from "../../IWake";

interface IProps {
    tblproject: Itblproject,
    setTblproject: ((tblproject: Itblproject) => void),
    project_budgets: IProjectBudgets[],
    missing_actuals: IMissingActuals[],
    tblactivitycodes: Itblactivitycode[],
    tblusers: IUsers[],
    doForceRefresh: (() => void)
}

const ProjectBudget = (props: IProps) => {
    const dispatch = useDispatch();
    const isMounted = useRef(true);
    const passUserName = useRef("");
    const user = useSelector((state: AppState) => state.user);
    const [blockNavigation, setBlockNavigation] = useState(false);
    const [editProjectBudget, setEditProjectBudget] = useState(null as Itblproject_budget | null);

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    const formSubmit = (e: FormEvent) => {
        e.preventDefault();

        if (blockNavigation) {
            if (HasFeature(user, [TTSFeatures.Feature_Project_Admin, TTSFeatures.Feature_Project_Reports])) {

                if (!!props.tblproject) {
                    ShowActivityOverlay()(dispatch);
                    APIProcess('project', 'Save', props.tblproject)(iWake)
                        .then((result) => {
                            if (isMounted.current) {
                                setBlockNavigation(false);
                                const newState = {...props.tblproject, projectID: result.projectID};
                                props.setTblproject(newState);
                                ShowMessageBox('Saved')(dispatch);
                            }
                        })
                        .catch(() => {
                        })
                        .finally(() => {
                            HideActivityOverlay()(dispatch);
                        });
                }
            }
        }
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.setTblproject({
            ...props.tblproject,
            [e.target.name]: ElementCustomValue(e)
        } as Itblproject);

        setBlockNavigation(true);
    }

    useEffect(() => {
        setBlockNavigation(false);
    }, [props.tblproject.projectID]);

    const assignedHours: number = useMemo(() =>
            props.project_budgets
                .map(projectBudget => projectBudget.hours ?? 0)
                .reduce((prev, next) => +prev + +next, 0)
        , [props.project_budgets]);

    const actualHours: number = useMemo(() =>
            props.project_budgets
                .map(projectBudget => projectBudget.actual_hours ?? 0)
                .reduce((prev, next) => +prev + +next, 0)
        , [props.project_budgets]);

    const unassignedEmployeeHours: number = useMemo(() =>
            props.missing_actuals
                .map(missingActuals => missingActuals.actual_hours ?? 0)
                .reduce((prev, next) => +prev + +next, 0)
        , [props.missing_actuals]);

    const addUsers: IUsers[] = useMemo(() =>
            props.tblusers
                .filter(tblUser =>
                    !props.project_budgets.map(projectBudget => projectBudget.userID).includes(tblUser.userID) &&
                    !props.missing_actuals.map(missingActual => missingActual.userID).includes(tblUser.userID))
                .sort((a, b) => (a.lastname ?? "").localeCompare(b.lastname ?? "", undefined, {sensitivity: 'base'}) ??
                    (a.firstname ?? "").localeCompare(b.firstname ?? "", undefined, {sensitivity: 'base'}))
        , [props.project_budgets, props.missing_actuals, props.tblusers]);

    return (
        <>
            {!!props.tblproject ?
                <Row>
                    <Col>
                        <Form onSubmitCapture={formSubmit} onBlur={formSubmit} className="form-sm">
                            <FormGroup>
                                <Label>Bill Start Date</Label>
                                <Col className="short">
                                    <InputDate name="start_date" value={props.tblproject.start_date ?? ""} onChange={handleInputChange}/>
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Label>Bill End Date</Label>
                                <Col className="short">
                                    <InputDate name="end_date" value={props.tblproject.end_date ?? ""} onChange={handleInputChange}/>
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Label>Budgeted Hours</Label>
                                <Col className="short">
                                    <InputNumber name={"budgeted_hours"} value={props.tblproject.budgeted_hours} onChange={handleInputChange} integerScale={10} decimalScale={2}/>
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Label>Unssigned Hours</Label>
                                <Col className="short text-right">
                                    {ToDigits((props.tblproject.budgeted_hours ?? 0) - assignedHours, 2)}
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Label>Assigned Hours</Label>
                                <Col className="small">
                                    <Table hover bordered size="sm" className="table-scrollable" style={{maxHeight: "30em"}}>
                                        <thead>
                                        <tr className="table-secondary">
                                            <th>Name</th>
                                            <th className="text-right td-md">Assigned Hours</th>
                                            <th className="text-right td-md">Actual Hours</th>
                                            <th className="text-right td-md">Remaining Hours</th>
                                            <th>Assigned Activity</th>
                                            <th className="td-lg">Start</th>
                                            <th className="td-lg">End</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {props.project_budgets
                                            .sort((a, b) =>
                                                (a.fullname ?? "").localeCompare(b.fullname ?? "", undefined, {sensitivity: 'base'}) ??
                                                (a.activityname ?? "").localeCompare(b.activityname ?? "", undefined, {sensitivity: 'base'})
                                            )
                                            .map((projectBudget, idx) =>
                                                <tr key={idx} onClick={() => {
                                                    passUserName.current = projectBudget.fullname;
                                                    setEditProjectBudget(projectBudget);
                                                }}>
                                                    <td>{projectBudget.fullname}</td>
                                                    <td className="text-right td-md">{ToDigitsBlank(projectBudget.hours ?? 0, 2)}</td>
                                                    <td className="text-right td-md">{ToDigitsBlank(projectBudget.actual_hours ?? 0, 2)}</td>
                                                    <td className="text-right td-md">{ToDigitsBlank((projectBudget.hours ?? 0) - (projectBudget.actual_hours ?? 0), 2)}</td>
                                                    <td>{projectBudget.activityname}</td>
                                                    <td className="td-lg">{!!projectBudget.validity_date ? moment(projectBudget.validity_date).format('YYYY-MM-DD') : ""}</td>
                                                    <td className="td-lg">{!!projectBudget.end_date ? moment(projectBudget.end_date).format('YYYY-MM-DD') : ""}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                        <tfoot>
                                        <tr>
                                            <th className="text-right">Totals:</th>
                                            <th className="text-right td-md">{ToDigits(assignedHours, 2)}</th>
                                            <th className="text-right td-md">{ToDigits(actualHours, 2)}</th>
                                            <th className="text-right td-md">{ToDigits(assignedHours - actualHours, 2)}</th>
                                            <th/>
                                            <th className="td-lg"/>
                                            <th className="td-lg"/>
                                        </tr>
                                        </tfoot>
                                    </Table>
                                </Col>
                            </FormGroup>
                            {(!!props.missing_actuals && props.missing_actuals.length > 0) ?
                                <FormGroup>
                                    <Label>Unassigned Employees</Label>
                                    <Col className="small medium">
                                        <Table hover bordered size="sm" className="table-scrollable" style={{maxHeight: "30em"}}>
                                            <thead>
                                            <tr className="table-secondary">
                                                <th>Name</th>
                                                <th className="text-right td-md">Actual Hours</th>
                                                <th>Activity</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {props.missing_actuals
                                                .sort((a, b) =>
                                                    (a.fullname ?? "").localeCompare(b.fullname ?? "", undefined, {sensitivity: 'base'}) ??
                                                    (a.activityname ?? "").localeCompare(b.activityname ?? "", undefined, {sensitivity: 'base'})
                                                )
                                                .map((missingActuals, idx) =>
                                                    <tr key={idx} onClick={() => {
                                                        passUserName.current = missingActuals.fullname;
                                                        setEditProjectBudget({
                                                            ...initialtblproject_budget,
                                                            projectID: props.tblproject.projectID,
                                                            userID: missingActuals.userID,
                                                            activityID: missingActuals.activityID ?? 0,
                                                            hours: missingActuals.actual_hours,
                                                            amendmentNo: props.tblproject.amendmentNo
                                                        });
                                                    }}>
                                                        <td>{missingActuals.fullname}</td>
                                                        <td className="text-right td-md">{ToDigitsBlank(missingActuals.actual_hours ?? 0, 2)}</td>
                                                        <td>{missingActuals.activityname}</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                            <tfoot>
                                            <tr>
                                                <th className="text-right">Totals:</th>
                                                <th className="text-right td-md">{ToDigits(unassignedEmployeeHours, 2)}</th>
                                                <th/>
                                            </tr>
                                            </tfoot>
                                        </Table>
                                    </Col>
                                </FormGroup>
                                :
                                null
                            }
                            <FormGroup>
                                <Label/>
                                <Col className="small medium">
                                    <InputSelect value={0} onChange={(e) => {
                                        const userID = CleanNumber(ElementCustomValue(e)) ?? 0;

                                        if (!!userID) {
                                            const user = props.tblusers.find(tbluser => tbluser.userID === userID);

                                            if (!!user) {
                                                passUserName.current = (user.lastname ?? "") + (user.firstname ?? "");
                                                setEditProjectBudget({
                                                    ...initialtblproject_budget,
                                                    projectID: props.tblproject.projectID,
                                                    userID: userID,
                                                    amendmentNo: props.tblproject.amendmentNo
                                                });
                                            }
                                        }
                                    }} isNumeric>
                                        <option value={0}>Add Employee to Project...</option>
                                        {addUsers.map(addUser =>
                                            <option key={addUser.userID} value={addUser.userID}>
                                                {addUser.lastname}, {addUser.firstname}
                                            </option>
                                        )}
                                    </InputSelect>
                                </Col>
                            </FormGroup>
                        </Form>
                        <ProjectBudgetEditModal doForceRefresh={props.doForceRefresh} tblproject={props.tblproject} tblproject_budget={editProjectBudget} userName={passUserName.current} setTblProjectBudget={setEditProjectBudget} tblactivitycodes={props.tblactivitycodes} tblusers={props.tblusers}/>
                    </Col>
                </Row>
                :
                null
            }
            <ActivityOverlayControl show={!props.tblproject}/>
            <BlockNav when={blockNavigation}/>
        </>
    );
};

export default ProjectBudget;

import React, {useEffect, useMemo, useRef, useState} from 'react';
import {
    Button,
    Col,
    Row, Table
} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import axios, {CancelTokenSource} from "axios";
import {APIProcess, initialSortColumn, ISortColumn, ShowActivityOverlay, SearchSort, HideActivityOverlay, SortColumnUpdate, ToDigitsBlank, DataToCSVExportNoQuotes, SelectDD, InputSearch, ToCurrencyBlank} from '@denjpeters/intelliwakereact';
import {IView_EmployeeNo} from "../../Data/Views/View_EmployeeNo";
import moment from "moment";
import PayPeriodNav from "../Generics/PayPeriodNav";
import {ASRSetViewDate, ASRSetViewUserID} from "../../Stores/appsessionremembers/actions";
import {useHistory} from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faPencil} from "@fortawesome/pro-solid-svg-icons";
import PayrollSummaryNotesModal from "./PayrollSummaryNotesModal";
import {IWake} from "../../IWake";
import { CookieRead, CookieCreate } from '@denjpeters/intelliwakereact';

interface ISummaryData extends IView_EmployeeNo {
    miles: number | null,
    rate_amount_total: number | null,
    end_date: string,
    isEmployeeNo: boolean,
    min_status: number,
    start_date: string,
    total_admin: number,
    total_bill_ot: number,
    total_bill_reg: number,
    total_bill: number,
    total_holiday: number,
    total_illness: number,
    total_ot_adjust_amount: number,
    total_pay_ot: number,
    total_pay_reg: number,
    total_pay: number,
    total_unbillable: number,
    total_raw_pay_base: number,
    total_raw_pay_non_base: number,
    total_raw_pay_ot: number,
    total_vacation: number,
    user: number
}

interface IReportStructure {
    startDate: string,
    endDate: string,
    PaychexSteps: string,
    summary: ISummaryData[]
}

const PayrollSummary = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const isMounted = useRef(true);
    const {user} = useSelector((state: AppState) => state);
    const [reportStructure, setReportStructure] = useState(null as IReportStructure | null);
    const [endDate, setEndDate] = useState(CookieRead('selectedEndDate', moment().subtract(1, 'week').endOf('isoWeek').format('YYYY-MM-DD')) as string);
    const [payrollNotesModalOpen, setPayrollNotesModalOpen] = useState(false);
    const [search, setSearch] = useState('');
    const [sortColumn, setSortColumn] = useState({
        ...initialSortColumn,
        primarySort: "fullname"
    } as ISortColumn);
    const [payType, setPayType] = useState('');
    const [forceRefresh, setForceRefresh] = useState(false);

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    const closePayrollNotesModal = (notes: string) => {
        setReportStructure({
            ...reportStructure,
            PaychexSteps: notes
        } as IReportStructure);
        setPayrollNotesModalOpen(false);
    }

    const openTimeEntry = (userID: number) => {
        ASRSetViewDate(endDate)(dispatch);
        ASRSetViewUserID(userID)(dispatch);
        history.push('/TimeEntry');
    }

    const openMileage = (userID: number) => {
        ASRSetViewDate(endDate)(dispatch);
        ASRSetViewUserID(userID)(dispatch);
        history.push('/Mileage');
    }

    useEffect(() => {
        let cancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();
        isMounted.current = true;

        ShowActivityOverlay()(dispatch);
        APIProcess('Payroll', 'Summary', {}, cancelTokenSource)(iWake)
            .then((results) => {
                // console.log(results);
                if (isMounted.current && cancelTokenSource) {
                    setReportStructure(results);
                    CookieCreate('selectedEndDate', results.endDate, 0.1);
                    setEndDate(results.endDate);
                }
            })
            .catch(() => {
                if (isMounted.current && cancelTokenSource) {
                    setReportStructure(null);
                }
            })
            .finally(() => {
                HideActivityOverlay()(dispatch);
                cancelTokenSource = null;
            });

        return () => {
            isMounted.current = false;
            if (cancelTokenSource) {
                cancelTokenSource.cancel();
                cancelTokenSource = null;
            }
        }
    }, [forceRefresh, dispatch, iWake]);

    const paychexDownload = () => {
        ShowActivityOverlay()(dispatch);
        APIProcess('Payroll', 'PayChex', {})(iWake)
            .then((results) => {
                if (isMounted.current) {
                    console.log(results);
                    DataToCSVExportNoQuotes('Payroll' + endDate + '.csv', results.Output ?? []);
                }
            })
            .catch(() => {
            })
            .finally(() => {
                HideActivityOverlay()(dispatch);
            });
    }

    const summaryList = useMemo(() => {
        if (!reportStructure) return [];
        return SearchSort(reportStructure.summary.filter(summ => !!summ.employeeNo && (!payType || summ.payment_types === payType)), search, sortColumn);
    }, [reportStructure, sortColumn, search, payType]);

    const totalPay: number = useMemo(() => {
        return summaryList
            .map(reportData => reportData.total_pay ?? 0)
            .reduce((prev, next) => +prev + +next, 0)
    }, [summaryList]);

    const totalPayReg: number = useMemo(() => {
        return summaryList
            .map(reportData => reportData.total_pay_reg ?? 0)
            .reduce((prev, next) => +prev + +next, 0)
    }, [summaryList]);

    const totalPayOT: number = useMemo(() => {
        return summaryList
            .map(reportData => reportData.total_pay_ot ?? 0)
            .reduce((prev, next) => +prev + +next, 0)
    }, [summaryList]);

    const totalMiles: number = useMemo(() => {
        return summaryList
            .map(reportData => reportData.miles ?? 0)
            .reduce((prev, next) => +prev + +next, 0)
    }, [summaryList]);

    const totalRateAmount: number = useMemo(() => {
        return summaryList
            .map(reportData => reportData.rate_amount_total ?? 0)
            .reduce((prev, next) => +prev + +next, 0)
    }, [summaryList]);

    const totalBillReg: number = useMemo(() => {
        return summaryList
            .map(reportData => reportData.total_bill_reg ?? 0)
            .reduce((prev, next) => +prev + +next, 0)
    }, [summaryList]);

    const totalBillOT: number = useMemo(() => {
        return summaryList
            .map(reportData => reportData.total_bill_ot ?? 0)
            .reduce((prev, next) => +prev + +next, 0)
    }, [summaryList]);

    const totalUnbillable: number = useMemo(() => {
        return summaryList
            .map(reportData => reportData.total_unbillable ?? 0)
            .reduce((prev, next) => +prev + +next, 0)
    }, [summaryList]);

    return (
        <>
            <Row>
                <Col>
                    <h3 className="text-center">Payroll Summary</h3>
                    {!!reportStructure ?
                        <h4 className="text-center">Period: {reportStructure.startDate} - {reportStructure.endDate}</h4>
                        :
                        null
                    }
                </Col>
            </Row>
            {!!reportStructure ?
                <>
                    <Row className="mb-1">
                        <Col className="text-right">
                            <Button size="sm" color="primary" onClick={paychexDownload} className="ml-2">
                                <FontAwesomeIcon icon={faDownload} fixedWidth/>
                                Paychex Download
                            </Button>
                            <Button size="sm" color="primary" onClick={() => setPayrollNotesModalOpen(true)} className="ml-2">
                                <FontAwesomeIcon icon={faPencil} fixedWidth/>
                                Payroll Notes
                            </Button>
                        </Col>
                    </Row>
                    <Row className="mb-1">
                        <Col xs={6}>
                            <PayPeriodNav endDate={reportStructure.endDate} periodUpdated={() => setForceRefresh(!forceRefresh)}/>
                        </Col>
                        <Col xs={2} className="text-right form-control-plaintext">
                            <SelectDD items={[
                                {id: '', name: 'All'},
                                {id: 'Salary', name: 'Salary'},
                                {id: 'Hourly', name: 'Hourly'}
                            ]} selectedID={payType} handleSelectID={setPayType} inline caret/>
                        </Col>
                        <Col xs={4}>
                            <InputSearch triggerSearchText={setSearch} placeholder="Filter"/>
                        </Col>
                    </Row>
                    <Row className="fill-height-scroll">
                        <Col>
                            <Table bordered hover size="sm" className="table-sortable small">
                                <thead>
                                <tr className="table-secondary">
                                    <th onClick={() => setSortColumn(SortColumnUpdate('fullname', sortColumn))}>Employee</th>
                                    <th className="text-right border-left-bold" onClick={() => setSortColumn(SortColumnUpdate('total_pay', sortColumn))}>
                                        Total Hours
                                    </th>
                                    <th className="text-right" onClick={() => setSortColumn(SortColumnUpdate('total_pay_reg', sortColumn))}>
                                        Pay Regular
                                    </th>
                                    <th className="text-right" onClick={() => setSortColumn(SortColumnUpdate('total_pay_ot', sortColumn))}>
                                        Pay Overtime
                                    </th>
                                    <th className="text-right border-left-bold" onClick={() => setSortColumn(SortColumnUpdate('miles', sortColumn))}>
                                        Miles
                                    </th>
                                    <th className="text-right" onClick={() => setSortColumn(SortColumnUpdate('rate_amount_total', sortColumn))}>
                                        Mile $'s
                                    </th>
                                    <th className="text-right border-left-bold" onClick={() => setSortColumn(SortColumnUpdate('total_bill_reg', sortColumn))}>
                                        Billable Regular
                                    </th>
                                    <th className="text-right" onClick={() => setSortColumn(SortColumnUpdate('total_bill_ot', sortColumn))}>
                                        Billable Overtime
                                    </th>
                                    <th className="text-right" onClick={() => setSortColumn(SortColumnUpdate('total_unbillable', sortColumn))}>
                                        Unbillable
                                    </th>
                                </tr>
                                <tr className="strong">
                                    <td className="text-right">Totals:</td>
                                    <td className="text-right border-left-bold">{ToDigitsBlank(totalPay, 2)}</td>
                                    <td className="text-right">{ToDigitsBlank(totalPayReg, 2)}</td>
                                    <td className="text-right">{ToDigitsBlank(totalPayOT, 2)}</td>
                                    <td className="text-right border-left-bold">{ToDigitsBlank(totalMiles, 0)}</td>
                                    <td className="text-right">{ToCurrencyBlank(totalRateAmount, 2)}</td>
                                    <td className="text-right border-left-bold">{ToDigitsBlank(totalBillReg, 2)}</td>
                                    <td className="text-right">{ToDigitsBlank(totalBillOT, 2)}</td>
                                    <td className="text-right">{ToDigitsBlank(totalUnbillable, 2)}</td>
                                </tr>
                                </thead>
                                <tbody>
                                {summaryList.map((reportData, idx) =>
                                    <tr key={idx} onClick={() => openTimeEntry(parseInt((reportData.userids ?? "").split(',')[0]))}>
                                        <td>
                                            {reportData.fullname}
                                            <span className="text-muted"> ({(reportData.payment_types ?? "").substr(0, 1)})</span>
                                            {reportData.min_status === 0 ?
                                                <span className="d-print-none float-right text-danger" title="Not Submitted">Not Sub</span>
                                                :
                                                null
                                            }
                                            {reportData.min_status === 2 ?
                                                <span className="d-print-none float-right text-warning" title="Not Approved">Not App</span>
                                                :
                                                null
                                            }
                                        </td>
                                        <td className="text-right border-left-bold">{ToDigitsBlank(reportData.total_pay, 2)}</td>
                                        <td className="text-right">{ToDigitsBlank(reportData.total_pay_reg, 2)}</td>
                                        <td className="text-right">{ToDigitsBlank(reportData.total_pay_ot, 2)}</td>
                                        <td className="text-right border-left-bold" onClick={(e) => {
                                            e.stopPropagation();
                                            openMileage(parseInt((reportData.userids ?? "").split(',')[0]));
                                        }}>{ToDigitsBlank(reportData.miles, 0)}</td>
                                        <td className="text-right" onClick={(e) => {
                                            e.stopPropagation();
                                            openMileage(parseInt((reportData.userids ?? "").split(',')[0]));
                                        }}>{ToCurrencyBlank(reportData.rate_amount_total, 2)}</td>
                                        <td className="text-right border-left-bold">{ToDigitsBlank(reportData.total_bill_reg, 2)}</td>
                                        <td className="text-right">{ToDigitsBlank(reportData.total_bill_ot, 2)}</td>
                                        <td className="text-right">{ToDigitsBlank(reportData.total_unbillable, 2)}</td>
                                    </tr>
                                )}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <PayrollSummaryNotesModal isOpen={payrollNotesModalOpen} closeOpen={closePayrollNotesModal} PaychexSteps={reportStructure.PaychexSteps}/>
                </>
                :
                null
            }
        </>
    );
};

export default PayrollSummary;

import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import {Link, Redirect, useHistory} from "react-router-dom";
import {HasFeature, TTSFeatures} from "../../Data/TTSFeatures";
import {Button, Col, Container, ListGroup, ListGroupItem, Row} from "reactstrap";
import axios, {CancelTokenSource} from "axios";
import {APIProcess, GetPathThrough, MasterDetail, MDMaster, InputSearch, MDDetail, MDLink, GetPathComponentAfter} from '@denjpeters/intelliwakereact';
import ActiveDD from "../Generics/ActiveDD";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter} from "@fortawesome/pro-regular-svg-icons";
import Customer from "./Customer";
import {Itblcustomer} from "../../Data/Tables/tblcustomer";
import {IWake} from "../../IWake";

const Customers = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const isMounted = useRef(true);
    const {user} = useSelector((state: AppState) => state);
    const [customers, setCustomers] = useState(null as Itblcustomer[] | null);
    const [activeView, setActiveView] = useState(1 as 0 | 1 | -1);
    const [search, setSearch] = useState("");

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    const updateCustomer = (updatedCustomer: Itblcustomer) => {
        if (!!customers) {
            if (!!updatedCustomer.customerID) {
                if (!window.location.pathname.endsWith('/Customer' + updatedCustomer.customerID)) {
                    history.push(GetPathThrough("/Customers") + "/Customer/" + updatedCustomer.customerID);
                }

                setCustomers([
                    ...customers.filter(customer => customer.customerID !== updatedCustomer.customerID),
                    updatedCustomer
                ]);
            }
        }
    };

    useEffect(() => {
        let cancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();
        isMounted.current = true;

        APIProcess('customer', 'GetList', {showActive: activeView}, cancelTokenSource)(iWake)
            .then((results) => {
                if (isMounted.current && cancelTokenSource) {
                    for (let customerItem of results.customers) {
                        customerItem.active = Number(customerItem.active);
                    }
                    setCustomers((results.customers as Itblcustomer[]).sort(function (a, b) {
                        return (a.name).localeCompare((b.name), undefined, {sensitivity: 'accent'})
                    }));
                }
            })
            .catch(() => {
                if (isMounted.current && cancelTokenSource) {
                    setCustomers([]);
                }
            })
            .finally(() => {
                cancelTokenSource = null;
            });

        return () => {
            isMounted.current = false;
            if (cancelTokenSource) {
                cancelTokenSource.cancel();
                cancelTokenSource = null;
            }
        }
    }, [iWake, activeView]);

    const searchTerms = useMemo(() => !search ? [] : search
        .split(' ')
        .map(term => term.trim().toLowerCase())
        .filter(term => !!term), [search]);

    const customerList = useMemo(() => (customers ?? [])
        .filter(userItem => searchTerms.length === 0 || (searchTerms.every(term => (userItem.name.toLowerCase().includes(term)))))
        .sort((a, b) => a.name.localeCompare(b.name, undefined, {sensitivity: 'base'})), [searchTerms, customers]);

    if (!HasFeature(user, TTSFeatures.Feature_Customer_Admin)) {
        return (
            <Redirect to="/TimeEntry"/>
        );
    }

    return (
        <Container fluid className="px-0">
            <MasterDetail breakAt="sm" mdPath="/Customers" backText="Customers">
                <MDMaster width="15em">
                    <Row className="p-2">
                        <Col>
                            <ActiveDD size="sm" handleSelectID={setActiveView} selectedID={activeView} caret/>
                        </Col>
                        <Col className="text-right">
                            <Button color="secondary" size="sm" tag={Link} to={GetPathThrough("/Customers") + "/Customer/New"}>Add</Button>
                        </Col>
                    </Row>
                    <Row className="p-2">
                        <Col>
                            <div className="input-group input-group-filter">
                                <div className="input-group-prepend">
                                    <span className="input-group-text"><FontAwesomeIcon icon={faFilter} fixedWidth/></span>
                                </div>
                                <InputSearch className="noFocusRing" placeholder="Filter" aria-label="Filter" triggerSearchText={setSearch}/>
                            </div>
                        </Col>
                    </Row>
                    <ListGroup flush className="fill-height-scroll text-large-md-smaller">
                        {!customers ?
                            <ListGroupItem className="text-secondary">Loading...</ListGroupItem>
                            : customerList.length === 0 ?
                                <ListGroupItem>No customers</ListGroupItem>
                                :
                                customerList.map((customer) =>
                                    <MDLink tag="li" panel="Customer" className={"list-group-item list-group-item-action py-1" + (customer.active === 1 ? "" : " text-gray")} key={customer.customerID} id={customer.customerID}>
                                        <span>{customer.name}</span>
                                    </MDLink>
                                )
                        }
                    </ListGroup>
                </MDMaster>
                <MDDetail panel="Customer" titleText="Customer">
                    <Customer customerID={GetPathComponentAfter('/Customer')} updateCustomer={updateCustomer}/>
                </MDDetail>
            </MasterDetail>
        </Container>
    );
};

export default Customers;

import React, {useState} from 'react';
import {Itbluser} from "../../Data/Tables/tbluser";
import {IAccrualsVacation, IBankAnalysis} from "./User";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import {HasFeature, TTSFeatures} from "../../Data/TTSFeatures";
import {Button, Row, Table} from "reactstrap";
import UserVacationReserveBankModal from "./UserVacationReserveBankModal";
import {Itblaccrualbank} from "../../Data/Tables/tblaccrualbank";
import UserAccrualsModal from "./UserAccrualsModal";
import {Itblaccrual} from "../../Data/Tables/tblaccrual";
import {useHistory} from "react-router-dom";
import {ASRSetViewDate, ASRSetViewUserID} from "../../Stores/appsessionremembers/actions";
import moment from "moment";
import { ToDigits } from '@denjpeters/intelliwakereact';

interface IProps {
    tbluser: Itbluser,
    bankAnalysis: IBankAnalysis | null,
    accrualsVacation: IAccrualsVacation[],
    tblAccrualBanks: Itblaccrualbank[],
    vacationAccruals: Itblaccrual[],
    doForceRefresh: (() => void)
}

const UserVacation = (props: IProps) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {user} = useSelector((state: AppState) => state);
    const [showVacationReserveBankModal, setShowVacationReserveBankModal] = useState(false);
    const [showVacationAccrualsModal, setShowVacationAccrualsModal] = useState(false);

    const closeVacationReserveBankModal = () => {
        setShowVacationReserveBankModal(false);
    }

    const closeVacationAccrualsModal = () => {
        setShowVacationAccrualsModal(false);
    }

    const openTimeEntry = (userID: number, date: string) => {
        ASRSetViewDate(moment(date).format('YYYY-MM-DD'))(dispatch);
        ASRSetViewUserID(userID)(dispatch);
        history.push('/TimeEntry');
    }

    const openVacaBank = (userID: number) => {
        ASRSetViewUserID(userID)(dispatch);
        history.push('/Reports/VacaBank');
    }

    return (
        <>
            <div className="form-horizontal">
                <Row>
                    <label className="col-sm-4 strong text-right">
                        Vacation Reserve Bank {!!props.bankAnalysis ? props.bankAnalysis.anniversaryStartDate : ""}
                    </label>
                    <div className="col-sm-1">
                        <p className="form-control-static text-right">
                            {!!props.bankAnalysis ?
                                ToDigits(props.bankAnalysis.bankPrev ?? 0.0, 2)
                                :
                                "N/A"
                            }
                        </p>
                    </div>
                    {(HasFeature(user, [TTSFeatures.Feature_User_Admin])) ?
                        <div className="col-sm-1">
                            <Button color="link" className="btn-link-inline" onClick={() => {
                                setShowVacationReserveBankModal(true)
                            }}>(Edit)</Button>
                        </div>
                        :
                        null
                    }
                </Row>
                <Row>
                    <label className="col-sm-4 strong text-right">Vacation Earned this Year</label>
                    <div className="col-sm-1">
                        <p className="form-control-static text-right">
                            {!!props.bankAnalysis ?
                                ToDigits(props.bankAnalysis.accrued ?? 0.0, 2)
                                :
                                "N/A"
                            }
                        </p>
                    </div>
                    {(HasFeature(user, [TTSFeatures.Feature_User_Admin])) ?
                        <div className="col-sm-1">
                            <p className="form-control-static">
                                <Button color="link" className="btn-link-inline" onClick={() => {
                                    setShowVacationAccrualsModal(true)
                                }}>(Edit)</Button>
                            </p>
                        </div>
                        :
                        null
                    }
                </Row>
                {!!props.bankAnalysis ?
                    <>
                        <Row>
                            <label className="col-sm-4 strong text-right">Vacation Used this Year</label>
                            <div className="col-sm-1">
                                <p className="form-control-static text-right">
                                    {!!props.bankAnalysis ?
                                        ToDigits(props.bankAnalysis.used ?? 0.0, 2)
                                        :
                                        "N/A"
                                    }
                                </p>
                            </div>
                        </Row>
                        <Row>
                            <label className="col-sm-4 strong text-right">Vacation Available</label>
                            <div className="col-sm-1">
                                <p className="form-control-static text-right">
                                    <strong>
                                        {!!props.bankAnalysis ?
                                            ToDigits(props.bankAnalysis.availToEOY ?? 0.0, 2)
                                            :
                                            null
                                        }
                                    </strong>
                                </p>
                            </div>
                            {HasFeature(user, [TTSFeatures.Feature_User_Admin]) ?
                                <div className="col-sm-1">
                                    <p className="form-control-static">
                                        <Button color="link" className="btn-link-inline" onClick={() => openVacaBank(props.tbluser.userID)}>
                                            (Details)
                                        </Button>
                                    </p>
                                </div>
                                :
                                null
                            }
                        </Row>
                        <Row>
                            <label className="col-sm-10 col-sm-offset-2 strong text-left"><u>Forecast
                                Reserve Bank changes on {props.bankAnalysis.anniversaryNextDate} if no further vacation
                                days taken:</u></label>
                        </Row>
                        <Row>
                            <label className="col-sm-4 strong text-right">Change to Reserve Bank +/-</label>
                            <div className="col-sm-1">
                                <p className="form-control-static text-right">
                                    {ToDigits(props.bankAnalysis.bankContribution ?? 0.0, 2)}
                                </p>
                            </div>
                        </Row>
                        <Row>
                            <label className="col-sm-4 strong text-right">Hours Forfeited if not Used</label>
                            <div className="col-sm-1">
                                <p className="form-control-static text-right">
                                    {ToDigits(props.bankAnalysis.hoursForfeited ?? 0.0, 2)}
                                </p>
                            </div>
                        </Row>
                        <Row>
                            <label className="col-sm-4 strong text-right">Reserve Bank Start Next Year</label>
                            <div className="col-sm-1">
                                <p className="form-control-static text-right">
                                    {ToDigits(props.bankAnalysis.bankSum ?? 0.0, 2)}
                                </p>
                            </div>
                        </Row>
                        <Row>
                            <label className="col-sm-4 strong text-right">Vacation History</label>
                            <div className="col-sm-4 small">
                                <Table bordered hover className="table-scrollable" style={{maxHeight: "15em"}} size="sm">
                                    <thead>
                                    <tr>
                                        <th className="text-center">Date</th>
                                        <th className="text-center">Hours Used</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {props.accrualsVacation.map((accrualVacation, idx) =>
                                        <tr key={idx} onClick={() => {
                                            openTimeEntry(props.tbluser.userID, accrualVacation.date)
                                        }}>
                                            <td>{accrualVacation.date}</td>
                                            <td className="text-right">{ToDigits(accrualVacation.amount ?? 0, 2)}</td>
                                        </tr>
                                    )}
                                    </tbody>
                                </Table>
                            </div>
                        </Row>
                    </>
                    :
                    null
                }
            </div>
            <UserVacationReserveBankModal isOpen={showVacationReserveBankModal} closeModal={closeVacationReserveBankModal} tbluser={props.tbluser} tblAccrualBanks={props.tblAccrualBanks} doForceRefresh={props.doForceRefresh}/>
            <UserAccrualsModal isOpen={showVacationAccrualsModal} doForceRefresh={props.doForceRefresh} closeModal={closeVacationAccrualsModal} tbluser={props.tbluser} accruals={props.vacationAccruals} accrualName={'Vacation'}/>
        </>
    );
};

export default UserVacation;

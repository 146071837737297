import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {
    Col,
    Container,
    Row, Table
} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../Stores/rootReducer";
import moment from "moment";
import axios, {CancelTokenSource} from "axios";
import {APIProcess, ShowActivityOverlay, HideActivityOverlay, ToDigits, ToCurrency} from '@denjpeters/intelliwakereact';
import {HasFeature, TTSFeatures} from "../Data/TTSFeatures";
import './Mileage.scss';
import EmployeeDD from "../WebControls/Generics/EmployeeDD";
import {Itbluser} from "../Data/Tables/tbluser";
import {initialmileage} from "../Data/Tables/mileage";
import MileageReadWrite from "../WebControls/Mileage/MileageReadWrite";
import WeekNav from "../WebControls/Generics/WeekNav";
import {IWake} from "../IWake";

export interface IFocusElement {
    rowID: number,
    columnName: string
}

export interface IMile {
    userID: number,
    mileageID: number,
    date: string,
    destination: string,
    businesspurpose: string,
    startodometer: number,
    endodometer: number,
    rate_amount: number,
    localID: number
}

export interface IMileData {
    curDate: Date,
    mileageRate: number,
    user: Itbluser,
    mileage: IMile[]
}

const initialMile: IMile = {
    ...initialmileage,
    localID: 0
};

const Mileage = () => {
    const dispatch = useDispatch();
    const isMounted = useRef(true);
    const localID = useRef(1);
    const {user, appSessionRemembersChange} = useSelector((state: AppState) => state);
    const [mileData, setMileData] = useState(null as IMileData | null);
    const [focusElement, setFocusElement] = useState(null as IFocusElement | null);

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    const days: string[] = useMemo(() => [
        moment(appSessionRemembersChange.viewDate).startOf("isoWeek").format('Y-MM-DD'),
        moment(appSessionRemembersChange.viewDate).startOf("isoWeek").add(1, 'day').format('Y-MM-DD'),
        moment(appSessionRemembersChange.viewDate).startOf("isoWeek").add(2, 'days').format('Y-MM-DD'),
        moment(appSessionRemembersChange.viewDate).startOf("isoWeek").add(3, 'days').format('Y-MM-DD'),
        moment(appSessionRemembersChange.viewDate).startOf("isoWeek").add(4, 'days').format('Y-MM-DD'),
        moment(appSessionRemembersChange.viewDate).startOf("isoWeek").add(5, 'days').format('Y-MM-DD'),
        moment(appSessionRemembersChange.viewDate).startOf("isoWeek").add(6, 'days').format('Y-MM-DD')
    ], [appSessionRemembersChange.viewDate]);

    const focusMoveUp = (fromRowID: number, fromColumnName: string) => {
        // Get all possible time rows
        const sortedTimes = (!!mileData ? mileData.mileage
            .sort((a, b) => (a.mileageID === 0 ? 1 : (b.mileageID === 0 ? -1 : (a.mileageID - b.mileageID)))) : [])
            .filter(time => fromRowID === 0 || time.mileageID < fromRowID);

        if (sortedTimes.length > 0) {
            const selectedTimeRow = sortedTimes.reduce((prev, next) => prev.mileageID > next.mileageID ? prev : next);

            setFocusElement({rowID: selectedTimeRow.mileageID, columnName: fromColumnName});
        }
    };

    const focusMoveDown = (fromRowID: number, fromColumnName: string) => {
        // Get all possible time rows
        const sortedTimes = (!!mileData ? mileData.mileage
            .sort((a, b) => (a.mileageID === 0 ? 1 : (b.mileageID === 0 ? -1 : (a.mileageID - b.mileageID)))) : [])
            .filter(time => time.mileageID === 0 || time.mileageID > fromRowID);

        if (sortedTimes.length === 1) {
            const selectedTimeRow = sortedTimes.find(() => true);
            setFocusElement({rowID: selectedTimeRow!.mileageID, columnName: fromColumnName});
        } else {
            const selectedTimeRow = sortedTimes
                .filter(time => time.mileageID !== 0)
                .reduce((prev, next) => prev.mileageID < next.mileageID ? prev : next);

            setFocusElement({rowID: selectedTimeRow.mileageID, columnName: fromColumnName});
        }
    };

    const clearFocusElement = useCallback(() => {
        setFocusElement(null);
    }, []);

    const updateMileRow = (mileRow: IMile, newID: number) => {
        if (!!mileData) {
            if ((mileRow.mileageID === 0 && newID > 0) || !mileData.mileage.find(mileRow => mileRow.mileageID === 0)) {
                setMileData({
                    ...mileData,
                    mileage: [
                        ...mileData.mileage.filter(tm => tm.mileageID !== mileRow.mileageID),
                        {
                            ...mileRow,
                            mileageID: newID
                        },
                        {
                            ...initialMile,
                            date: moment(appSessionRemembersChange.viewDate).startOf("isoWeek").format('Y-MM-DD'),
                            rate_amount: mileData.mileageRate,
                            userID: mileData.user.userID,
                            localID: localID.current++
                        }
                    ]
                } as IMileData);
            } else {
                setMileData({
                    ...mileData,
                    mileage: [
                        ...mileData.mileage.filter(tm => tm.mileageID !== mileRow.mileageID),
                        {
                            ...mileRow,
                            mileageID: newID
                        }
                    ]
                } as IMileData);
            }
        }
    };

    const deleteMileRow = (mileRow: IMile) => {
        if (!!mileData) {
            setMileData({
                ...mileData,
                mile: [
                    ...mileData.mileage.filter(tm => tm.mileageID !== mileRow.mileageID)
                ]
            } as IMileData);
        }
    };

    useEffect(() => {
        let cancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();
        isMounted.current = true;

        ShowActivityOverlay()(dispatch);
        APIProcess('mileage', 'GetData', {
            userID: appSessionRemembersChange.viewUserID ?? user.userID,
            date: moment(appSessionRemembersChange.viewDate).format('Y-MM-DD')
        }, cancelTokenSource)(iWake)
            .then((results) => {
                if (isMounted.current && cancelTokenSource) {
                    const loadedMileData = {
                        ...results,
                        mileage: [
                            ...results.mileage.map((mileRow: IMile) => {
                                return {...mileRow, localID: localID.current++};
                            }),
                            {
                                ...initialMile,
                                date: moment(appSessionRemembersChange.viewDate).startOf("isoWeek").format('Y-MM-DD'),
                                rate_amount: results.mileageRate,
                                userID: results.user.userID,
                                localID: localID.current++
                            }
                        ]
                    } as IMileData;

                    setMileData(loadedMileData);
                }
            })
            .catch(() => {
                if (isMounted.current && cancelTokenSource) {
                    setMileData(null);
                }
            })
            .finally(() => {
                HideActivityOverlay()(dispatch);
                cancelTokenSource = null;
            });

        return () => {
            isMounted.current = false;
            if (cancelTokenSource) {
                cancelTokenSource.cancel();
                cancelTokenSource = null;
            }
        }
    }, [dispatch, user.userID, iWake, appSessionRemembersChange.viewUserID, appSessionRemembersChange.viewDate]);

    const sortedMiles = useMemo(() =>
            !!mileData ? mileData.mileage
                .sort((a, b) => (a.mileageID === 0 ? 1 : (b.mileageID === 0 ? -1 : (a.mileageID - b.mileageID)))) : []
        , [mileData]);

    const totalMiles = useMemo(() =>
            !!mileData ?
                mileData.mileage
                    .map(mile => (!!mile.startodometer && !!mile.endodometer && mile.endodometer > mile.startodometer) ? mile.endodometer - mile.startodometer : 0)
                    .reduce((prev, curr) => +prev + +curr, 0)
                :
                0
        , [mileData]);

    const totalDollars = useMemo(() =>
            !!mileData ?
                mileData.mileage
                    .map(mile => (!!mile.startodometer && !!mile.endodometer && mile.endodometer > mile.startodometer) ? ((+mile.endodometer - +mile.startodometer) * +mile.rate_amount) : 0)
                    .reduce((prev, curr) => +prev + +curr, 0)
                :
                0
        , [mileData]);

    // console.log(mileData);

    return (
        <Container fluid>
            {!!mileData ?
                <>
                    <Row className="p-2">
                        <Col>
                            <WeekNav/>
                        </Col>
                        <Col className="text-center strong">
                            <h4>
                                {HasFeature(user, [TTSFeatures.Feature_TimeSheet_Admin, TTSFeatures.Feature_TimeSheet_Entry]) ?
                                    <EmployeeDD/>
                                    :
                                    <>
                                        Employee:
                                        {' ' + mileData.user.firstname + ' ' + mileData.user.lastname}
                                    </>
                                }
                            </h4>
                        </Col>
                        <Col/>
                    </Row>
                    <Row className="fill-height-scroll narrowed">
                        <Col xs={12}>
                            <Table size="sm" bordered className="tableMile">
                                <thead>
                                <tr className="text-center">
                                    <th>Date</th>
                                    <th>Destination</th>
                                    <th>Business Purpose</th>
                                    <th>Start Odometer</th>
                                    <th>Stop Odometer</th>
                                    <th>Total Miles</th>
                                    <th>Amount</th>
                                </tr>
                                </thead>
                                <tfoot>
                                <tr className="text-right">
                                    <th colSpan={5}>
                                        Total
                                    </th>
                                    <th>
                                        {ToDigits(totalMiles, 0)}
                                    </th>
                                    <th>
                                        {ToCurrency(totalDollars, 2)}
                                    </th>
                                </tr>
                                </tfoot>
                                <tbody>
                                {sortedMiles.map((mileRow) =>
                                    <MileageReadWrite key={mileRow.localID} mileData={mileData!} mileRow={mileRow} days={days} updateMileRow={updateMileRow} deleteMileRow={deleteMileRow} focusElementColumn={!!focusElement ? (focusElement.rowID === mileRow.mileageID ? focusElement.columnName : null) : null} focusMoveUp={focusMoveUp} focusMoveDown={focusMoveDown} clearFocusElement={clearFocusElement}/>
                                )}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </>
                :
                null
            }
        </Container>
    );
};

export default Mileage;

import React, {useEffect, useMemo, useRef, useState} from 'react';
import axios, {CancelTokenSource} from "axios";
import {APIProcess} from '@denjpeters/intelliwakereact';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import {ASRSetViewUserID} from "../../Stores/appsessionremembers/actions";
import {IUsers} from "../../Data/Views/Users";
import {IWake} from "../../IWake";

const EmployeeDD = () => {
    const dispatch = useDispatch();
    const isMounted = useRef(true);
    const {user, appSessionRemembersChange} = useSelector((state: AppState) => state);
    const [users, setUsers] = useState([] as IUsers[]);

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    useEffect(() => {
        if (user.loggedIn) {
            let cancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();
            isMounted.current = true;

            APIProcess('User', 'GetList', {}, cancelTokenSource)(iWake)
                .then((results) => {
                    if (isMounted.current && cancelTokenSource) {
                        setUsers((results.Users as IUsers[]).sort(function (a, b) {
                            return ((a.lastname ?? "") + "aaa" + (a.firstname ?? "")).localeCompare((b.lastname ?? "") + "aaa" + (b.firstname), undefined, {sensitivity: 'base'})
                        }));
                    }
                })
                .catch(() => {
                })
                .finally(() => {
                    cancelTokenSource = null;
                });

            return () => {
                isMounted.current = false;
                if (cancelTokenSource) {
                    cancelTokenSource.cancel();
                    cancelTokenSource = null;
                }
            }
        } else {
            setUsers([]);
        }
    }, [iWake, user.loggedIn]);

    const currentUser = useMemo(() => users.find(listUser => listUser.userID === (appSessionRemembersChange.viewUserID ?? user.userID)), [user, users, appSessionRemembersChange.viewUserID]);

    return (
        !!currentUser ?
            <UncontrolledDropdown className="d-inline-block">
                <DropdownToggle color="link" className="no-underline small btn-link-inline d-print-inline-block" caret>
                    Employee: {!!currentUser ? (currentUser.lastname + ', ' + currentUser.firstname) : ""}
                </DropdownToggle>
                <DropdownMenu right>
                    {users.length > 0 ?
                        <>
                            {!!appSessionRemembersChange.viewUserID ?
                                <>
                                    <DropdownItem onClick={() => ASRSetViewUserID(null)(dispatch)}>
                                        Me
                                    </DropdownItem>
                                    <DropdownItem divider/>
                                </>
                                :
                                null
                            }
                            {users.map((userList) =>
                                <DropdownItem key={userList.userID} onClick={() => ASRSetViewUserID(userList.userID)(dispatch)}>
                                    {((userList.lastname ?? '') + ', ' + (userList.firstname))}
                                </DropdownItem>
                            )}
                        </>
                        :
                        <DropdownItem disabled>Loading users...</DropdownItem>
                    }
                </DropdownMenu>
            </UncontrolledDropdown>
            :
            null
    );
};

export default EmployeeDD;

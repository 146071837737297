import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Itbluser} from "../../Data/Tables/tbluser";
import {Button, Col, Row, Table} from "reactstrap";
import axios, {CancelTokenSource} from "axios";
import {APIProcess, ToCurrency, ToPercent} from '@denjpeters/intelliwakereact';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import {IWake} from "../../IWake";
import {initialtblpayhistory, Itblpayhistory} from "../../Data/Tables/tblpayhistory";
import UserPayHistoryModal from "./UserPayHistoryModal";
import moment from "moment";

interface IProps {
    tbluser: Itbluser
}

interface IPayHistory extends Itblpayhistory {
    increase: number
}

const UserPayHistory = (props: IProps) => {
    const dispatch = useDispatch();
    const isMounted = useRef(true);
    const user = useSelector((state: AppState) => state.user);
    const [forceRefresh, setForceRefresh] = useState(false);
    const [payHistory, setPayHistory] = useState(null as Itblpayhistory[] | null);
    const [modalPayHistory, setModalPayHistory] = useState(null as Itblpayhistory | null);

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    useEffect(() => {
        let cancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();
        isMounted.current = true;

        APIProcess('PayHistory', 'GetList', {userID: props.tbluser.userID})(iWake)
            .then((results) => {
                if (isMounted.current && cancelTokenSource) {
                    // console.log(results);
                    setPayHistory(results.PayHistory);
                }
            })
            .catch(() => {
                if (isMounted.current && cancelTokenSource) {
                    setPayHistory(null);
                }
            })
            .finally(() => {
                cancelTokenSource = null;
            });

        return () => {
            isMounted.current = false;
            if (cancelTokenSource) {
                cancelTokenSource.cancel();
                cancelTokenSource = null;
            }
        }
    }, [iWake, props.tbluser.userID, forceRefresh]);

    const payHistoryList = useMemo(() => {
        let lastRate = 0.0;

        return !payHistory ?
            []
            :
            payHistory
                .sort((a, b) => a.date.localeCompare(b.date))
                .map(pay => {
                    const actualLastRate = lastRate;
                    lastRate = pay.rate;
                    return {...pay, increase: !actualLastRate ? 0.0 : (pay.rate / actualLastRate) - 1} as IPayHistory
                });
    }, [payHistory]);

    const newPayHistory = () => {
        setModalPayHistory({
            ...initialtblpayhistory,
            date: payHistoryList.length === 0 ?
                moment(props.tbluser.startdate ?? undefined).format('YYYY-MM-DD')
                :
                moment(payHistoryList.reduce((prev, cur) => !prev ? cur.date : (cur.date > prev ? cur.date : prev), "")).add(1, 'year').format('YYYY-MM-DD'),
            userID: props.tbluser.userID,
            payment_type: props.tbluser.payment_type,
            rate: !payHistory ? 0 : payHistory.reduce((prev, cur) => cur.rate > prev ? +cur.rate : prev, 0)
        });
    }

    return (
        <>
            <Row>
                <Col className="narrow my-2">
                    <Button size="sm" className="float-right" onClick={newPayHistory}>Add</Button>
                </Col>
            </Row>
            <Row className="table-height-scroll">
                <Col className="narrow">
                    <Table bordered size="sm" hover={payHistoryList.length > 0}>
                        <thead>
                        <tr className="table-secondary">
                            <th>Date</th>
                            <th>Type</th>
                            <th className="text-right">Rate</th>
                            <th className="text-right">Increase</th>
                        </tr>
                        </thead>
                        <tbody>
                        {!payHistory ?
                            <tr>
                                <td colSpan={4} className="text-center">Loading...</td>
                            </tr>
                            :
                            payHistoryList.length === 0 ?
                                <tr>
                                    <td colSpan={4} className="text-center">No history available</td>
                                </tr>
                                :
                                payHistoryList.map(pay =>
                                    <tr key={pay.id} onClick={() => setModalPayHistory(pay)}>
                                        <td>{pay.date}</td>
                                        <td>{pay.payment_type}</td>
                                        <td className="text-right">{ToCurrency(pay.rate)}</td>
                                        <td className="text-right">{!pay.increase ? "" : ToPercent(pay.increase, 1)}</td>
                                    </tr>
                                )
                        }
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <UserPayHistoryModal tblpayhistory={modalPayHistory} closeModal={() => setModalPayHistory(null)} doForceUpdate={() => setForceRefresh(prevState => !prevState)}/>
        </>
    );
};

export default UserPayHistory;

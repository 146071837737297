/**
 * Automatically generated: 2020-06-15 15:30:50
 * © 2020, Solid Basis Ventures, LLC.
 * DO NOT MODIFY
 *
 * Table Manager for: mileage
 */

export interface Imileage {
	userID: number,
	mileageID: number,
	date: string,
	destination: string,
	businesspurpose: string,
	startodometer: number,
	endodometer: number,
	rate_amount: number
}

export const initialmileage: Imileage = {
	userID: 0,
	mileageID: 0,
	date: '',
	destination: '',
	businesspurpose: '',
	startodometer: 0,
	endodometer: 0,
	rate_amount: 0.0000
};

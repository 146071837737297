/**
 * Automatically generated: 2020-06-15 15:30:50
 * © 2020, Solid Basis Ventures, LLC.
 * DO NOT MODIFY
 *
 * Table Manager for: tblholiday
 */

export interface Itblholiday {
	holidayID: number,
	active: number,
	name: string,
	date: string | null,
	is_recurring: number,
	recurring_rule: string | null
}

export const initialtblholiday: Itblholiday = {
	holidayID: 0,
	active: 1,
	name: '',
	date: null,
	is_recurring: 0,
	recurring_rule: null
};

import React, {useMemo} from 'react';
import {
    Col,
    Row
} from "reactstrap";
import TimeSheet from "./TimeSheet";
import {ITimeDataGeneric, ITimeDataUser} from "../../Data/Views/Time";

interface IProps {
    timeDataGeneric: ITimeDataGeneric,
    timeDataUsers: ITimeDataUser[],
    forceRefresh: (() => void),
    className?: string,
    highlightDateRange?: [string, string],
    highlightProjectNo?: string
}

const TimeSheets = (props: IProps) => {
    let addBreak = 0;

    const timeUsers = useMemo(() =>
            props.timeDataUsers
                .filter(timeData => timeData.time.length > 0)
                .sort((a, b) =>
                    (a.user.userID === b.user.userID) ? (a.curDate).localeCompare(b.curDate) :
                        a.user.lastname.localeCompare(b.user.lastname, undefined, {sensitivity: 'base'}) ??
                        a.user.firstname.localeCompare(b.user.firstname, undefined, {sensitivity: 'base'}) ??
                        (a.curDate).localeCompare(b.curDate)
                )
        , [props.timeDataUsers]);
    
    console.log(timeUsers);

    return (
        <>
            {timeUsers.map((timeDataUser, idx) =>
                <React.Fragment key={idx}>
                    {((addBreak++) > 0) ?
                        <Row className="d-print-none my-3">
                            <Col>
                                <hr/>
                            </Col>
                        </Row>
                        :
                        null
                    }
                    <TimeSheet timeDataGeneric={props.timeDataGeneric} timeDataUser={timeDataUser} className="page-break-after" forceRefresh={props.forceRefresh} highlightDateRange={props.highlightDateRange} highlightProjectNo={props.highlightProjectNo}/>
                </React.Fragment>
            )
            }
        </>
    );
};

export default TimeSheets;

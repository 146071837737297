/**
 * Automatically generated: 2020-06-15 15:30:50
 * © 2020, Solid Basis Ventures, LLC.
 * DO NOT MODIFY
 *
 * Table Manager for: tblproject_budget
 */

export interface Itblproject_budget {
	project_budgetID: number,
	projectID: number,
	userID: number,
	hours: number,
	activityID: number | null,
	validity_date: string | null,
	end_date: string | null,
	amendmentNo: string | null,
	active: number
}

export const initialtblproject_budget: Itblproject_budget = {
	project_budgetID: 0,
	projectID: 0,
	userID: 0,
	hours: 0,
	activityID: null,
	validity_date: null,
	end_date: null,
	amendmentNo: null,
	active: 1
};

import {SystemState, SystemActionTypes, UPDATE_SYSTEM} from './types'
import { ObjectPropertiesEqual } from '@denjpeters/intelliwakereact';

export const initialSystemState: SystemState = {
    app_version: "",
    html_version: "",
    db_version: "",
    environment: "",
    environment_name: ""
};

export function reducerSystem(
    state = initialSystemState,
    action: SystemActionTypes
): SystemState {
    switch (action.type) {
        case UPDATE_SYSTEM: {
            if (ObjectPropertiesEqual(state, action.payload)) {
                return state;
            }

            return {
                ...state,
                ...action.payload
            };
        }
        default:
            return state
    }
}

import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Itbluser} from "../../Data/Tables/tbluser";
import {Button, Card, CardHeader, Col, ListGroup, ListGroupItem, Row} from "reactstrap";
import axios, {CancelTokenSource} from "axios";
import {
    APIProcess,
    ElementCustomValue,
    HideActivityOverlay,
    InputSelect,
    ShowActivityOverlay
} from "@denjpeters/intelliwakereact";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import {Itbluserdelegate} from "../../Data/Tables/tbluserdelegate";
import {IUsers} from "../../Data/Views/Users";
import {IWake} from "../../IWake";

interface IProps {
    tbluser: Itbluser,
    setTbluser: ((tbluser: Itbluser) => void),
    updateUser?: Function | undefined
}

interface IUserDelegateStructure {
    userdelegates: Itbluserdelegate[],
    users: IUsers[]

}

const UserDelegates = (props: IProps) => {
    const dispatch = useDispatch();
    const isMounted = useRef(true);
    const {user} = useSelector((state: AppState) => state);
    const [forceRefresh, setForceRefresh] = useState(false);
    const [userDelegateStructure, setUserDelegateStructure] = useState(null as IUserDelegateStructure | null);

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    useEffect(() => {
        let cancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();
        isMounted.current = true;

        APIProcess('userdelegate', 'GetForUser', {userID: props.tbluser.userID}, cancelTokenSource)(iWake)
            .then((results) => {
                // console.log(results);
                if (isMounted.current && cancelTokenSource) {
                    setUserDelegateStructure(results);
                }
            })
            .catch(() => {
                if (isMounted.current && cancelTokenSource) {
                    setUserDelegateStructure(null);
                }
            })
            .finally(() => {
                cancelTokenSource = null;
            });

        return () => {
            isMounted.current = false;
            if (cancelTokenSource) {
                cancelTokenSource.cancel();
                cancelTokenSource = null;
            }
        }
    }, [iWake, forceRefresh, props.tbluser.userID]);

    const delegateAdd = (userID_delegate: number, userID: number) => {
        isMounted.current = true;

        if (!!userID && !!userID_delegate) {
            ShowActivityOverlay()(dispatch);
            APIProcess('userdelegate', 'Add', {userID: userID, userID_delegate: userID_delegate})(iWake)
                .then(() => {
                    if (isMounted.current) {
                        setForceRefresh(prevState => !prevState);
                    }
                })
                .catch(() => {
                })
                .finally(() => {
                    HideActivityOverlay()(dispatch);
                });
        }
    }

    const delegateRemove = (userID_delegate: number, userID: number) => {
        isMounted.current = true;

        if (!!userID && !!userID_delegate) {
            ShowActivityOverlay()(dispatch);
            APIProcess('userdelegate', 'Remove', {userID: userID, userID_delegate: userID_delegate})(iWake)
                .then(() => {
                    if (isMounted.current) {
                        setForceRefresh(prevState => !prevState);
                    }
                })
                .catch(() => {
                })
                .finally(() => {
                    HideActivityOverlay()(dispatch);
                });
        }
    }

    const canImpersonateSelect = useMemo(() =>
            !userDelegateStructure ? [] : userDelegateStructure.users
                .filter(usr => usr.userID !== props.tbluser.userID && usr.disable === 0 && !userDelegateStructure.userdelegates.map(ud => ud.userID).includes(usr.userID))
                .sort((a, b) => a.lastname.localeCompare(b.lastname, undefined, {sensitivity: 'base'}))
        , [userDelegateStructure, props.tbluser.userID]);

    const canBeImpersonatedSelect = useMemo(() =>
            !userDelegateStructure ? [] : userDelegateStructure.users
                .filter(usr => usr.userID !== props.tbluser.userID && usr.disable === 0 && !userDelegateStructure.userdelegates.map(ud => ud.userID_delegate).includes(usr.userID))
                .sort((a, b) => a.lastname.localeCompare(b.lastname, undefined, {sensitivity: 'base'}))
        , [userDelegateStructure, props.tbluser.userID]);

    const canImpersonate = useMemo(() =>
            !userDelegateStructure ? [] : userDelegateStructure.users
                .filter(usr => usr.userID !== props.tbluser.userID && usr.disable === 0 && userDelegateStructure.userdelegates.map(ud => ud.userID).includes(usr.userID))
                .sort((a, b) => a.lastname.localeCompare(b.lastname, undefined, {sensitivity: 'base'}))
        , [userDelegateStructure, props.tbluser.userID]);

    const canBeImpersonated = useMemo(() =>
            !userDelegateStructure ? [] : userDelegateStructure.users
                .filter(usr => usr.userID !== props.tbluser.userID && usr.disable === 0 && userDelegateStructure.userdelegates.map(ud => ud.userID_delegate).includes(usr.userID))
                .sort((a, b) => a.lastname.localeCompare(b.lastname, undefined, {sensitivity: 'base'}))
        , [userDelegateStructure, props.tbluser.userID]);

    return (
        <>
            <Row>
                <Col>
                    <Card>
                        <CardHeader className="alert-secondary">
                            Can Impersonate
                        </CardHeader>
                        <ListGroup>
                            <ListGroupItem>
                                <InputSelect value="" onChange={(e) => delegateAdd(props.tbluser.userID, ElementCustomValue(e))} isStringOrNull>
                                    <option>Add...</option>
                                    {canImpersonateSelect.map(usr =>
                                        <option key={usr.userID} value={usr.userID}>
                                            {usr.lastname}, {usr.firstname}
                                        </option>
                                    )}
                                </InputSelect>
                            </ListGroupItem>
                            {canImpersonate.map(usr =>
                                <ListGroupItem key={usr.userID}>
                                    {usr.lastname}, {usr.firstname}
                                    <Button type="button" size="sm" color="outline-secondary" className="float-right" onClick={(e) => delegateRemove(props.tbluser.userID, usr.userID)}>
                                        Remove
                                    </Button>
                                </ListGroupItem>
                            )}
                        </ListGroup>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <CardHeader className="alert-secondary">
                            Can be Impersonated by
                        </CardHeader>
                        <ListGroup>
                            <ListGroupItem>
                                <InputSelect value="" onChange={(e) => delegateAdd(ElementCustomValue(e), props.tbluser.userID)} isStringOrNull>
                                    <option>Add...</option>
                                    {canBeImpersonatedSelect.map(usr =>
                                        <option key={usr.userID} value={usr.userID}>
                                            {usr.lastname}, {usr.firstname}
                                        </option>
                                    )}
                                </InputSelect>
                            </ListGroupItem>
                            {canBeImpersonated.map(usr =>
                                <ListGroupItem key={usr.userID}>
                                    {usr.lastname}, {usr.firstname}
                                    <Button type="button" size="sm" color="outline-secondary" className="float-right" onClick={(e) => delegateRemove(usr.userID, props.tbluser.userID)}>
                                        Remove
                                    </Button>
                                </ListGroupItem>
                            )}
                        </ListGroup>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default UserDelegates;

import React, {useRef, useState} from 'react';
import {ITimeDataGeneric, ITimeDataUser} from "../../Data/Views/Time";
import {Button, Table} from "reactstrap";
import {HasFeature, TTSFeatures} from "../../Data/TTSFeatures";
import {useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import {objTimeSummary} from "../../Data/Models/objTimeSummary";
import {DaysOfWeek} from "../../Data/Enums/DaysOfWeek";
import WeekSummaryOTAdjust from "./WeekSummaryOTAdjust";
import { CleanNumber, ToDigitsBlank } from '@denjpeters/intelliwakereact';

interface IProps {
    timeDataGeneric: ITimeDataGeneric,
    timeDataUser: ITimeDataUser,
    otAdjustAmount: number,
    setOTAdjustAmount: ((otAdjustAmount: number) => void),
    forceRefresh: (() => void)
}

const WeekSummary = (props: IProps) => {
    const {user} = useSelector((state: AppState) => state);
    const timeSummary = useRef(objTimeSummary);
    const [otOpen, setOTOpen] = useState(false);

    timeSummary.current.reset();

    timeSummary.current.ot_adjust = CleanNumber(props.otAdjustAmount) ?? 0.0;

    for (const timeRow of props.timeDataUser.time) {
        const activity = props.timeDataGeneric.activities.find(activity => activity.activityID === timeRow.activityID);

        if (!!activity) {
            timeSummary.current.addEntry(timeRow.projectID, DaysOfWeek.map(dayOfWeek => (timeRow as any)[dayOfWeek]).reduce((prev, next) => +prev + +next), activity.is_billable, activity.is_overtime, activity.is_base_to_ot_pay);
        }
    }

    timeSummary.current.calcResults();

    const openOT = () => {
        setOTOpen(true);
    };

    const closeOpen = () => {
        setOTOpen(false);
    };

    return (
        <>
            <Table className="text-right w-auto">
                <thead>
                <tr>
                    <th/>
                    <th>Billing</th>
                    <th>Pay</th>
                </tr>
                </thead>
                <tfoot>
                <tr>
                    <th>Total:</th>
                    <th>{ToDigitsBlank(timeSummary.current.calcBilling.total)}</th>
                    <th>{ToDigitsBlank(timeSummary.current.calcPayroll.total)}</th>
                </tr>
                {HasFeature(user, TTSFeatures.Feature_TimeSheet_Admin) ?
                    <tr>
                        <td colSpan={2}/>
                        <td>
                            <Button color="link" onClick={openOT}>
                                (Adjust)
                            </Button>
                        </td>
                    </tr>
                    :
                    null
                }
                </tfoot>
                <tbody>
                <tr>
                    <th>Regular:</th>
                    <td>{ToDigitsBlank(timeSummary.current.calcBilling.regular)}</td>
                    <td>{ToDigitsBlank(timeSummary.current.calcPayroll.regular)}</td>
                </tr>
                <tr>
                    <th>Overtime:</th>
                    <td>{ToDigitsBlank(timeSummary.current.calcBilling.overtime)}</td>
                    <td>{ToDigitsBlank(timeSummary.current.calcPayroll.overtime)}</td>
                </tr>
                </tbody>
            </Table>
            <WeekSummaryOTAdjust timeDataUser={props.timeDataUser} isOpen={otOpen} closeOpen={closeOpen} timeSummary={timeSummary.current} otAdjustAmount={props.otAdjustAmount} setOTAdjustAmount={props.setOTAdjustAmount} forceRefresh={props.forceRefresh}/>
        </>
    );
};

export default WeekSummary;

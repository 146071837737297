/**
 * Automatically generated: 2020-06-15 15:30:50
 * © 2020, Solid Basis Ventures, LLC.
 * DO NOT MODIFY
 *
 * Table Manager for: tblpayhistory
 */

export interface Itblpayhistory {
	id: number,
	userID: number,
	rate: number,
	payment_type: string,
	date: string,
	create_userID: number,
	update_userID: number,
	create_ts: number,
	update_ts: number
}

export const initialtblpayhistory: Itblpayhistory = {
	id: 0,
	userID: 0,
	rate: 0,
	payment_type: '',
	date: '',
	create_userID: 0,
	update_userID: 0,
	create_ts: 0,
	update_ts: 0
};

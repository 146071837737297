import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import {
    Button, Modal, ModalBody, ModalFooter, ModalHeader, Input
} from "reactstrap";
import {APIProcess, ShowActivityOverlay, HideActivityOverlay, ShowMessageBox} from '@denjpeters/intelliwakereact';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil} from "@fortawesome/pro-solid-svg-icons";
import {IWake} from "../../IWake";

interface IProps {
    isOpen: boolean,
    closeOpen: ((newValue: string) => void),
    PaychexSteps: string
}

const PayrollSummaryNotesModal = (props: IProps) => {
    const dispatch = useDispatch();
    const user = useSelector((state: AppState) => state.user);
    const [editMode, setEditMode] = useState(false);
    const [payrollText, setPayrollText] = useState('');

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    useEffect(() => {
        setPayrollText(props.PaychexSteps);
        setEditMode(false);
    }, [props.isOpen, props.PaychexSteps]);

    const save = () => {
        ShowActivityOverlay()(dispatch);

        APIProcess('Payroll', 'UpdateNotes', {Notes: payrollText})(iWake)
            .then(() => {
                props.closeOpen(payrollText);
                ShowMessageBox('Project notes saved')(dispatch);
            })
            .catch(() => {
            })
            .finally(() => {
                HideActivityOverlay()(dispatch);
            });
    };

    return (
        <Modal backdrop keyboard toggle={() => props.closeOpen(props.PaychexSteps)} isOpen={props.isOpen} size="lg">
            <ModalHeader className="alert-info">
                <FontAwesomeIcon icon={faPencil} fixedWidth/>
                Payroll Notes
            </ModalHeader>
            <ModalBody>
                {editMode ?
                    <Input type="textarea" value={payrollText} rows={10} onChange={(e) => {setPayrollText(e.target.value)}}/>
                    :
                    <pre>{payrollText}</pre>
                }
            </ModalBody>
            <ModalFooter>
                <Button type="button" color="link" className="mr-auto" onClick={() => props.closeOpen(props.PaychexSteps)}>Close</Button>
                {editMode ?
                <Button type="button" color="success" onClick={save}>Save</Button>
                :
                    <Button type="button" onClick={() => setEditMode(true)}>Edit</Button>
                }
            </ModalFooter>
        </Modal>
    );
};

export default PayrollSummaryNotesModal;

import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import {Button} from "reactstrap";
import {ASRSetViewDate} from "../../Stores/appsessionremembers/actions";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDoubleLeft, faAngleDoubleRight} from "@fortawesome/pro-regular-svg-icons";
import { ClassNames, InputDate } from '@denjpeters/intelliwakereact';

const WeekNav = () => {
    const dispatch = useDispatch();
    const {appSessionRemembersChange} = useSelector((state: AppState) => state);
    const [dateEdit, setDateEdit] = useState(moment(appSessionRemembersChange.viewDate).format('YYYY-MM-DD'));

    const isCurrentWeek = useMemo(() => moment().startOf("isoWeek").diff(moment(appSessionRemembersChange.viewDate).startOf("isoWeek"), 'days') === 0, [appSessionRemembersChange.viewDate]);

    const onKeyDown = (e: React.KeyboardEvent) => {
        if (e.keyCode === 13) {
            toDateEdit();
        }
    }

    const toDateEdit = () => {
        ASRSetViewDate(dateEdit)(dispatch);
    }

    useEffect(() => {
        setDateEdit(moment(appSessionRemembersChange.viewDate).endOf('isoWeek').format('YYYY-MM-DD'));
    }, [appSessionRemembersChange.viewDate]);

    return (
        <>
            <Button color="link" onClick={() => {
                ASRSetViewDate(moment(appSessionRemembersChange.viewDate).subtract(1, 'weeks').format('YYYY-MM-DD'))(dispatch)
            }}>
                <FontAwesomeIcon icon={faAngleDoubleLeft} className="mr-2"/>
                Previous Week
            </Button>
            <Button color="link" className={ClassNames({"d-none": isCurrentWeek})} onClick={() => {
                ASRSetViewDate(moment(appSessionRemembersChange.viewDate).add(1, 'weeks').format('YYYY-MM-DD'))(dispatch)
            }}>
                Next Week
                <FontAwesomeIcon icon={faAngleDoubleRight} className="ml-2"/>
            </Button>
            <InputDate value={dateEdit} onChange={(e) => setDateEdit(e.target.value)} className="d-inline-block border-0 w-auto" onBlur={toDateEdit} onKeyDown={onKeyDown}/>
        </>
    );
};

export default WeekNav;

import React, {useMemo, useRef} from 'react';
import {Button, Col, Row, Table} from "reactstrap";
import {ITimeDataGeneric, ITimeDataUser} from "../../Data/Views/Time";
import moment from "moment";
import {DaysOfWeek} from "../../Data/Enums/DaysOfWeek";
import LineReadOnly from "./LineReadOnly";
import GCILogo from "../../Assets/GCILogo.png";
import {HasFeature, TTSFeatures} from "../../Data/TTSFeatures";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import {useHistory} from 'react-router-dom';
import {ASRSetViewDate, ASRSetViewUserID} from "../../Stores/appsessionremembers/actions";
import {TimeStatus} from "../../Data/Enums/TimeStatus";
import {faCheck, faHandPaper, faHandPointRight, faTimes} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import axios, {CancelTokenSource} from "axios";
import {APIProcess, ShowPromptOKCancel, ShowActivityOverlay, HideActivityOverlay, ToDigitsBlank} from '@denjpeters/intelliwakereact';
import {IWake} from "../../IWake";

interface IProps {
    timeDataGeneric: ITimeDataGeneric,
    timeDataUser: ITimeDataUser,
    forceRefresh: (() => void),
    className?: string,
    highlightDateRange?: [string, string],
    highlightProjectNo?: string
}

const TimeSheet = (props: IProps) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const isMounted = useRef(true);
    const {user, appSessionRemembersChange} = useSelector((state: AppState) => state);

    const days: string[] = useMemo(() => [
        moment(props.timeDataUser.curDate).startOf("isoWeek").format('YYYY-MM-DD'),
        moment(props.timeDataUser.curDate).startOf("isoWeek").add(1, 'day').format('YYYY-MM-DD'),
        moment(props.timeDataUser.curDate).startOf("isoWeek").add(2, 'days').format('YYYY-MM-DD'),
        moment(props.timeDataUser.curDate).startOf("isoWeek").add(3, 'days').format('YYYY-MM-DD'),
        moment(props.timeDataUser.curDate).startOf("isoWeek").add(4, 'days').format('YYYY-MM-DD'),
        moment(props.timeDataUser.curDate).startOf("isoWeek").add(5, 'days').format('YYYY-MM-DD'),
        moment(props.timeDataUser.curDate).startOf("isoWeek").add(6, 'days').format('YYYY-MM-DD')
    ], [props.timeDataUser.curDate]);

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    const holdForUser = async (userID: number, fullName: string, hold_for_review: number, week: string) => {
        let cancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();
        isMounted.current = true;

        if (await ShowPromptOKCancel((hold_for_review === 1 ? 'Hold All' : 'Release Hold') + ' for ' + fullName + '?', 'Are you sure you want to ' + (hold_for_review === 1 ? 'hold' : 'release from hold') + ' all hours that are ready for ' + fullName + '?', (hold_for_review === 1 ? 'warning' : 'success'), (hold_for_review === 1 ? 'Hold All' : 'Release From Hold'))(dispatch)) {
            ShowActivityOverlay()(dispatch);
            APIProcess('timeapproval', 'SetHoldForReview', {
                userID: userID,
                hold_for_review: hold_for_review,
                date: moment(week).format('Y-MM-DD'),
                status: TimeStatus.SUBMITTED
            }, cancelTokenSource)(iWake)
                .then(() => {
                    if (isMounted.current && cancelTokenSource) {
                        props.forceRefresh();
                    }
                })
                .catch(() => {
                    if (isMounted.current && cancelTokenSource) {
                        props.forceRefresh();
                    }
                })
                .finally(() => {
                    HideActivityOverlay()(dispatch);
                    cancelTokenSource = null;
                });
        }
    };

    const statusForUser = async (userID: number, fullName: string, status: number, week: string) => {
        let cancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();
        isMounted.current = true;

        if (await ShowPromptOKCancel((status === TimeStatus.APPROVED ? 'Approve' : 'Reject') + ' All for ' + fullName + '?', 'Are you sure you want to ' + (status === TimeStatus.APPROVED ? 'approve' : 'reject') + ' all hours that are ready for ' + fullName + '?', (status === TimeStatus.APPROVED ? 'success' : 'warning'), (status === TimeStatus.APPROVED ? 'Approve' : 'Reject'))(dispatch)) {
            ShowActivityOverlay()(dispatch);
            APIProcess('timeapproval', 'SetStatus', {
                userID: userID,
                approveFor: appSessionRemembersChange.approvalUserID ?? user.userID,
                status: status,
                date: moment(week).format('Y-MM-DD')
            }, cancelTokenSource)(iWake)
                .then(() => {
                    if (isMounted.current && cancelTokenSource) {
                        props.forceRefresh();
                    }
                })
                .catch(() => {
                    if (isMounted.current && cancelTokenSource) {
                        props.forceRefresh();
                    }
                })
                .finally(() => {
                    HideActivityOverlay()(dispatch);
                    cancelTokenSource = null;
                });
        }
    };

    const sortedTimes = useMemo(() => !!props.timeDataUser ? props.timeDataUser.time.sort((a, b) => (a.timeID === 0 ? 1 : (b.timeID === 0 ? -1 : (a.timeID - b.timeID)))) : [], [props.timeDataUser]);

    return (
        <Row className={"small narrowed " + (props.className ?? "")}>
            <Col>
                <Row>
                    <Col className="text-center p-1">
                        <img src={GCILogo} alt="GCI" className="img-xxl"/>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center strong p-1">
                        GCI Weekly Timesheet
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center strong p-1">
                        Employee: {props.timeDataUser.user.lastname}, {props.timeDataUser.user.firstname}
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Table size="sm" borderless className="tableTime mb-0 tsBorderStandard">
                            <thead>
                            <tr className="text-center">
                                <th>Project No.</th>
                                <th>Activity Code</th>
                                {days.map(day =>
                                    <th key={day.toString()} style={{minWidth: "4em"}}>{moment(day).format('ddd')}<br/>{moment(day).format('M/D')}
                                    </th>
                                )}
                                <th>Total</th>
                            </tr>
                            </thead>
                            <tfoot>
                            <tr className="text-right">
                                <th colSpan={2}>
                                    Total
                                </th>
                                {DaysOfWeek.map(dayOfWeek =>
                                    <th key={dayOfWeek}>
                                        {ToDigitsBlank(props.timeDataUser.time.length > 0 ? props.timeDataUser.time.map(time => (time as any)[dayOfWeek]).reduce((prev, next) => +prev + +next) : 0)}
                                    </th>
                                )}
                                <th>
                                    {ToDigitsBlank(props.timeDataUser.time.length > 0 ? props.timeDataUser.time.map(time => (DaysOfWeek.map(dayOfWeek => (time as any)[dayOfWeek]).reduce((prev, next) => +prev + +next))).reduce((prev, next) => +prev + +next) : 0)}
                                </th>
                            </tr>
                            </tfoot>
                            <tbody>
                            {sortedTimes.map((timeRow) =>
                                <LineReadOnly key={timeRow.localID ?? timeRow.timeID} timeDataGeneric={props.timeDataGeneric} timeDataUser={props.timeDataUser} timeRow={timeRow} highlightDateRange={props.highlightDateRange} highlightProjectNo={props.highlightProjectNo}/>
                            )}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                {props.timeDataUser.time.length > 0 ?
                    props.timeDataUser.status === TimeStatus.APPROVED ?
                        <Row>
                            <Col>
                                Approved by {props.timeDataUser.time.find(() => true)!.approved_name}
                            </Col>
                        </Row>
                        :
                        null
                    :
                    null
                }
                {HasFeature(user, TTSFeatures.Feature_TimeSheet_Admin) ?
                    <Row className="d-print-none mt-4">
                        {props.timeDataUser.status === TimeStatus.SUBMITTED ?
                            <Col xs={12}>
                                {!!props.timeDataUser.time.find(timeRow => timeRow.hold_for_review === 1) ?
                                    <Button color="light-gray" size="sm" onClick={() => {
                                        holdForUser(props.timeDataUser.user.userID, props.timeDataUser.user.lastname + ", " + props.timeDataUser.user.firstname, 0, props.timeDataUser.curDate);
                                    }}>
                                        <FontAwesomeIcon icon={faHandPointRight} fixedWidth/>
                                        Release from Hold
                                    </Button>
                                    :
                                    <>
                                        <Button color="success" size="sm" className="mr-1" onClick={() => {
                                            statusForUser(props.timeDataUser.user.userID, props.timeDataUser.user.lastname + ", " + props.timeDataUser.user.firstname, TimeStatus.APPROVED, props.timeDataUser.curDate);
                                        }}>
                                            <FontAwesomeIcon icon={faCheck} fixedWidth/>
                                            Approve
                                        </Button>
                                        <Button color="danger" size="sm" className="mr-1" onClick={() => {
                                            statusForUser(props.timeDataUser.user.userID, props.timeDataUser.user.lastname + ", " + props.timeDataUser.user.firstname, TimeStatus.DRAFT, props.timeDataUser.curDate);
                                        }}>
                                            <FontAwesomeIcon icon={faTimes} fixedWidth/>
                                            Reject
                                        </Button>
                                        <Button color="light-gray" size="sm" className="mr-1" onClick={() => {
                                            holdForUser(props.timeDataUser.user.userID, props.timeDataUser.user.lastname + ", " + props.timeDataUser.user.firstname, 1, props.timeDataUser.curDate);
                                        }}>
                                            <FontAwesomeIcon icon={faHandPaper} fixedWidth/>
                                            Hold for Review
                                        </Button>
                                    </>
                                }
                            </Col>
                            :
                            null
                        }
                        <Col xs={12}>
                            <Button color="link" onClick={() => {
                                ASRSetViewUserID(props.timeDataUser.user.userID)(dispatch);
                                ASRSetViewDate(props.timeDataUser.curDate)(dispatch);
                                history.push('/TimeEntry');
                            }}>
                                Edit TimeSheet
                            </Button>
                        </Col>
                    </Row>
                    :
                    null
                }
            </Col>
        </Row>
    );
};

export default TimeSheet;

import React, {useEffect, useMemo, useRef, useState} from 'react';
import {
    Col,
    Row, Table
} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import axios, {CancelTokenSource} from "axios";
import {APIProcess, initialSortColumn, ISortColumn, ShowActivityOverlay, SearchSort, HideActivityOverlay, SortColumnUpdate, ToDigitsBlank} from '@denjpeters/intelliwakereact';
import {IWake} from "../../IWake";
import { useHistory } from 'react-router-dom';
import {ASRSetViewUserID} from "../../Stores/appsessionremembers/actions";

const VacaIll = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const isMounted = useRef(true);
    const {user, appSessionRemembersChange} = useSelector((state: AppState) => state);
    const [users, setUsers] = useState(null as any[] | null);
    const [sortColumn, setSortColumn] = useState({
        ...initialSortColumn,
        primarySort: "fullname"
    } as ISortColumn);

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    useEffect(() => {
        let cancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();
        isMounted.current = true;

        ShowActivityOverlay()(dispatch);
        APIProcess('reports', 'VacaIll', {}, cancelTokenSource)(iWake)
            .then((results) => {
                console.log(results.userData);
                if (isMounted.current && cancelTokenSource) {
                    setUsers(results.userData);
                }
            })
            .catch(() => {
                if (isMounted.current && cancelTokenSource) {
                    setUsers(null);
                }
            })
            .finally(() => {
                HideActivityOverlay()(dispatch);
                cancelTokenSource = null;
            });

        return () => {
            isMounted.current = false;
            if (cancelTokenSource) {
                cancelTokenSource.cancel();
                cancelTokenSource = null;
            }
        }
    }, [appSessionRemembersChange.approvalUserID, appSessionRemembersChange.projectActive, dispatch, iWake]);

    const usersList: any[] = useMemo(() => {
        return SearchSort(users ?? [], '', sortColumn)
    }, [users, sortColumn]);

    const bankPrev: number = useMemo(() => {
        return usersList
            .map(hour => hour.bankPrev ?? 0)
            .reduce((prev, next) => +prev + +next, 0)
    }, [usersList]);

    const accrued: number = useMemo(() => {
        return usersList
            .map(hour => hour.accrued ?? 0)
            .reduce((prev, next) => +prev + +next, 0)
    }, [usersList]);

    const used: number = useMemo(() => {
        return usersList
            .map(hour => hour.used ?? 0)
            .reduce((prev, next) => +prev + +next, 0)
    }, [usersList]);

    const availToEOY: number = useMemo(() => {
        return usersList
            .map(hour => hour.availToEOY ?? 0)
            .reduce((prev, next) => +prev + +next, 0)
    }, [usersList]);

    const bankContribution: number = useMemo(() => {
        return usersList
            .map(hour => hour.bankContribution ?? 0)
            .reduce((prev, next) => +prev + +next, 0)
    }, [usersList]);

    const hoursForfeited: number = useMemo(() => {
        return usersList
            .map(hour => hour.hoursForfeited ?? 0)
            .reduce((prev, next) => +prev + +next, 0)
    }, [usersList]);

    const bankSum: number = useMemo(() => {
        return usersList
            .map(hour => hour.bankSum ?? 0)
            .reduce((prev, next) => +prev + +next, 0)
    }, [usersList]);

    const illnessAvailable: number = useMemo(() => {
        return usersList
            .map(hour => hour.illnessAvailable ?? 0)
            .reduce((prev, next) => +prev + +next, 0)
    }, [usersList]);

    const illnessEarned: number = useMemo(() => {
        return usersList
            .map(hour => hour.illnessEarned ?? 0)
            .reduce((prev, next) => +prev + +next, 0)
    }, [usersList]);

    const illness_used: number = useMemo(() => {
        return usersList
            .map(hour => hour.illness_used ?? 0)
            .reduce((prev, next) => +prev + +next, 0)
    }, [usersList]);

    const illness_used_adjust= useMemo(() => {
        return usersList
            .map(hour => hour.illness_used_adjust ?? 0)
            .reduce((prev, next) => +prev + +next, 0)
    }, [usersList]);

    const openVacaBank = (userID: number) => {
        ASRSetViewUserID(userID)(dispatch);
        history.push("/Reports/VacaBank");
    }

    return (
        <>
            <Row>
                <Col>
                    <h3 className="text-center">Vacation and Illness Report</h3>
                </Col>
            </Row>
            {!!users ?
                <Row className="mt-3 small fill-height">
                    <Col className="fill-height horizontal-scroll">
                        <Table bordered hover size="sm" className="table-scrollable table-sortable" style={{minWidth: "110em"}}>
                            <thead>
                            <tr className="table-secondary">
                                <th onClick={() => setSortColumn(SortColumnUpdate('fullname', sortColumn))}>Name</th>
                                <th className="td-lg" onClick={() => setSortColumn(SortColumnUpdate('startdate', sortColumn))}>Start Date</th>
                                <th className="text-right td-md" onClick={() => setSortColumn(SortColumnUpdate('months', sortColumn))}>Month</th>
                                <th className="td-lg" onClick={() => setSortColumn(SortColumnUpdate('anniversaryStartDate', sortColumn))}>Rollover Date</th>
                                <th className="text-right td-md" onClick={() => setSortColumn(SortColumnUpdate('bankPrev', sortColumn))}>Vacation Rolled Over</th>
                                <th className="text-right td-md" onClick={() => setSortColumn(SortColumnUpdate('accrued', sortColumn))}>Earned this Year</th>
                                <th className="text-right td-md" onClick={() => setSortColumn(SortColumnUpdate('used', sortColumn))}>Used
                                    this Year
                                </th>
                                <th className="text-right td-md" onClick={() => setSortColumn(SortColumnUpdate('availToEOY', sortColumn))}>Available
                                    to EOY
                                </th>
                                <th className="td-lg" onClick={() => setSortColumn(SortColumnUpdate('anniversaryNextDate', sortColumn))}>Next Anniversary
                                </th>
                                <th className="td-md" onClick={() => setSortColumn(SortColumnUpdate('bankContribution', sortColumn))}>Change to Bank +/-
                                </th>
                                <th className="td-md" onClick={() => setSortColumn(SortColumnUpdate('hoursForfeited', sortColumn))}>Lost if not Used
                                </th>
                                <th className="td-md" onClick={() => setSortColumn(SortColumnUpdate('bankSum', sortColumn))}>Potential Rollover
                                </th>
                                <th className="td-md" onClick={() => setSortColumn(SortColumnUpdate('illnessAvailable', sortColumn))}>Illness Available
                                </th>
                                <th className="td-md" onClick={() => setSortColumn(SortColumnUpdate('illnessEarned', sortColumn))}>Illness Earned
                                </th>
                                <th className="td-md" onClick={() => setSortColumn(SortColumnUpdate('illness_used', sortColumn))}>Illness Used
                                </th>
                                <th className="td-md" onClick={() => setSortColumn(SortColumnUpdate('illness_used_adjust', sortColumn))}>Manual Used Adjustment
                                </th>
                            </tr>
                            <tr className="text-right strong">
                                <td>Totals:</td>
                                <td className="td-lg"/>
                                <td className="td-md"/>
                                <td className="td-lg"/>
                                <td className="td-md">{ToDigitsBlank(bankPrev, 2)}</td>
                                <td className="td-md">{ToDigitsBlank(accrued, 2)}</td>
                                <td className="td-md">{ToDigitsBlank(used, 2)}</td>
                                <td className="td-md strong">{ToDigitsBlank(availToEOY, 2)}</td>
                                <td className="td-lg"/>
                                <td className="td-md">{ToDigitsBlank(bankContribution, 2)}</td>
                                <td className="td-md">{ToDigitsBlank(hoursForfeited, 2)}</td>
                                <td className="td-md">{ToDigitsBlank(bankSum, 2)}</td>
                                <td className="td-md">{ToDigitsBlank(illnessAvailable, 2)}</td>
                                <td className="td-md">{ToDigitsBlank(illnessEarned, 2)}</td>
                                <td className="td-md">{ToDigitsBlank(illness_used, 2)}</td>
                                <td className="td-md">{ToDigitsBlank(illness_used_adjust, 2)}</td>
                            </tr>
                            </thead>
                            <tbody>
                            {usersList.map(userItem =>
                                <tr key={userItem.userID} onClick={() => {openVacaBank(userItem.userID)}}>
                                    <td>{userItem.fullname}</td>
                                    <td className="td-lg">{userItem.startdate}</td>
                                    <td className="text-right td-md">{ToDigitsBlank(userItem.months, 0)}</td>
                                    <td className="td-lg">{userItem.anniversaryStartDate}</td>
                                    <td className="text-right td-md">{ToDigitsBlank(userItem.bankPrev, 2)}</td>
                                    <td className="text-right td-md">{ToDigitsBlank(userItem.accrued, 2)}</td>
                                    <td className="text-right td-md">{ToDigitsBlank(userItem.used, 2)}</td>
                                    <td className="text-right td-md strong">{ToDigitsBlank(userItem.availToEOY, 2)}</td>
                                    <td className="td-lg">{userItem.anniversaryNextDate}</td>
                                    <td className="text-right td-md">{ToDigitsBlank(userItem.bankContribution, 2)}</td>
                                    <td className="text-right td-md">{ToDigitsBlank(userItem.hoursForfeited, 2)}</td>
                                    <td className="text-right td-md">{ToDigitsBlank(userItem.bankSum, 2)}</td>
                                    <td className="text-right td-md">{ToDigitsBlank(userItem.illnessAvailable, 2)}</td>
                                    <td className="text-right td-md">{ToDigitsBlank(userItem.illnessEarned, 2)}</td>
                                    <td className="text-right td-md">{ToDigitsBlank(userItem.illness_used, 2)}</td>
                                    <td className="text-right td-md">{ToDigitsBlank(userItem.illness_used_adjust, 2)}</td>
                                </tr>
                            )}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                :
                null
            }
        </>
    );
};

export default VacaIll;

import React, {FormEvent, useMemo} from 'react';
import {ITimeDataUser} from "../../Data/Views/Time";
import {Button, Col, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/pro-solid-svg-icons";
import {APIProcess, ElementCustomValue, ShowActivityOverlay, ShowMessageBox, HideActivityOverlay, ToDigits, InputNumber} from '@denjpeters/intelliwakereact';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import moment from "moment";
import {IWake} from "../../IWake";

interface IProps {
    timeDataUser: ITimeDataUser,
    isOpen: boolean,
    closeOpen: (() => void),
    timeSummary: any,
    otAdjustAmount: number,
    setOTAdjustAmount: ((otAdjustAmount: number) => void),
    forceRefresh: (() => void)
}

const WeekSummaryOTAdjust = (props: IProps) => {
    const dispatch = useDispatch();
    const {user, appSessionRemembersChange} = useSelector((state: AppState) => state);

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    const handleInputChange = (e: React.ChangeEvent<any>) => {
        props.setOTAdjustAmount(ElementCustomValue(e));
    };

    const submit = (e: FormEvent) => {
        e.preventDefault();

        ShowActivityOverlay()(dispatch);

        APIProcess('time', 'OT_Adjust', {
            userID: appSessionRemembersChange.viewUserID ?? user.userID!,
            date: moment(appSessionRemembersChange.viewDate).format('Y-MM-DD'),
            ot_adjust: props.otAdjustAmount
        })(iWake)
            .then(() => {
                props.forceRefresh();
                props.closeOpen();
                ShowMessageBox('Overtime adjustment saved')(dispatch);
            })
            .catch(() => {
            })
            .finally(() => {
                HideActivityOverlay()(dispatch);
            });
    };

    let newBase = props.timeSummary.payrollHours.base_to_ot_pay;
    let newOT = 0.0;
    if (newBase > 40) {
        newOT = newBase - 40;
        newBase = 40;
    }

    return (
        <Modal isOpen={props.isOpen}>
            <Form onSubmitCapture={submit}>
                <ModalHeader className="alert-primary">
                    Overtime Adjustment
                </ModalHeader>
                <ModalBody>
                    <Row className="my-3">
                        <Col>
                            These adjustments will reflect on payroll, not on invoices. To adjust invoices,
                            change the activity codes on the line items.
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="form-half">
                                <FormGroup>
                                    <Label>Hours that cannot OT</Label>
                                    <div className="form-control-plaintext text-right short">{ToDigits(props.timeSummary.payrollHours.non_base_to_ot_pay)}</div>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Regular Hours</Label>
                                    <div className="form-control-plaintext text-right short">{ToDigits(newBase)}</div>
                                </FormGroup>
                                <FormGroup>
                                    <Label>OT Hours</Label>
                                    <div className="form-control-plaintext text-right short">{ToDigits(newOT)}</div>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Adjust Pay OT
                                        by</Label>
                                    <div className="short">
                                        <InputNumber name="otAdjustAmount" value={props.otAdjustAmount} onChange={handleInputChange} autoFocus integerScale={3} decimalScale={2}/>
                                    </div>
                                </FormGroup>
                                <Row>
                                    <Col xs={12}>
                                        <hr/>
                                    </Col>
                                </Row>
                                <FormGroup>
                                    <Label>Pay Regular</Label>
                                    <div className="form-control-plaintext text-right short">{ToDigits(props.timeSummary.calcPayroll.regular)}</div>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Pay Overtime</Label>
                                    <div className="form-control-plaintext text-right short">{ToDigits(props.timeSummary.calcPayroll.overtime)}</div>
                                </FormGroup>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button type="button" color="link" onClick={props.closeOpen}>
                        Cancel
                    </Button>{' '}
                    <Button color="primary">
                        <FontAwesomeIcon icon={faSave} fixedWidth/>
                        Save
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export default WeekSummaryOTAdjust;

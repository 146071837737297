import React, {FormEvent, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import {APIProcess, ShowActivityOverlay, CleanNumber, ShowMessageBox, HideActivityOverlay, ElementCustomValue, InputDate, InputNumber, ActivityOverlayControl} from '@denjpeters/intelliwakereact';
import axios, {CancelTokenSource} from "axios";
import {Col, Form, FormFeedback, FormGroup, Label} from "reactstrap";
import {initialmileage_rate, Imileage_rate} from "../../Data/Tables/mileage_rate";
import BlockNav from "../Generics/BlockNav";
import moment from "moment";
import {IWake} from "../../IWake";

interface IProps {
    mileage_rateID?: number | null,
    updateMileage_Rate?: Function | undefined
}

const MileageRate = (props: IProps) => {
    const dispatch = useDispatch();
    const isMounted = useRef(true);
    const user = useSelector((state: AppState) => state.user);
    const [mileage_rate, setMileage_Rate] = useState(null as Imileage_rate | null);
    const [submitAttempted, setSubmitAttempted] = useState(false);
    const [blockNavigation, setBlockNavigation] = useState(false);

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    const formSubmit = (e: FormEvent) => {
        e.preventDefault();

        if (blockNavigation) {
            setSubmitAttempted(true);

            if (!!mileage_rate) {
                if (!!mileage_rate.effective_date) {
                    ShowActivityOverlay()(dispatch);
                    APIProcess('mileage_rate', 'Save', mileage_rate)(iWake)
                        .then((result) => {
                            if (isMounted.current) {
                                setSubmitAttempted(false);
                                setBlockNavigation(false);
                                const newState = {...mileage_rate, mileage_rateID: CleanNumber(result.mileage_rateID)};
                                setMileage_Rate(newState);
                                if (props.updateMileage_Rate instanceof Function) {
                                    props.updateMileage_Rate(newState);
                                }
                                ShowMessageBox('Saved')(dispatch);
                            }
                        })
                        .catch(() => {
                        })
                        .finally(() => {
                            HideActivityOverlay()(dispatch);
                        });
                } else {
                    ShowMessageBox('Name required', "danger")(dispatch);
                }
            }
        }
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMileage_Rate({
            ...mileage_rate,
            [e.target.name]: ElementCustomValue(e)
        } as Imileage_rate);

        setBlockNavigation(true);
    }

    useEffect(() => {
        let cancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();
        isMounted.current = true;

        APIProcess('mileage_rate', 'Get', {mileage_rateID: props.mileage_rateID})(iWake)
            .then((results) => {
                if (isMounted.current && cancelTokenSource) {
                    setBlockNavigation(false);
                    setSubmitAttempted(false);
                    setMileage_Rate({...initialmileage_rate, ...results.mileage_rate});
                }
            })
            .catch(() => {
                if (isMounted.current && cancelTokenSource) {
                    setBlockNavigation(false);
                    setSubmitAttempted(false);
                    setMileage_Rate(initialmileage_rate);
                }
            })
            .finally(() => {
                cancelTokenSource = null;
            });

        return () => {
            isMounted.current = false;
            if (cancelTokenSource) {
                cancelTokenSource.cancel();
                cancelTokenSource = null;
            }
        }
    }, [iWake, props.mileage_rateID]);

    return (
        <>
            {!!mileage_rate ?
                <>
                    <Form onSubmitCapture={formSubmit} onBlur={formSubmit} className="form-sm fill-height-scroll mt-4 p-2">
                        <FormGroup>
                            <Label>Effective Date</Label>
                            <Col className="short">
                                <InputDate name="effective_date" value={moment(mileage_rate.effective_date ?? "").format('YYYY-MM-DD')} valid={false} invalid={submitAttempted && !(mileage_rate.effective_date.length > 0)} onChange={handleInputChange}/>
                                <FormFeedback valid={!submitAttempted && mileage_rate.effective_date.length > 0}>Date
                                    required</FormFeedback>
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Label>Rate</Label>
                            <Col className="short">
                                <InputNumber name={"amount"} value={mileage_rate.amount} onChange={handleInputChange} integerScale={5} decimalScale={4}/>
                            </Col>
                        </FormGroup>
                    </Form>
                </>
                :
                null
            }
            <ActivityOverlayControl show={!mileage_rate}/>
            <BlockNav when={blockNavigation}/>
        </>
    );
};

export default MileageRate;

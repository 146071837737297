import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import {Link, Redirect, useHistory} from "react-router-dom";
import {HasFeature, TTSFeatures} from "../../Data/TTSFeatures";
import {Button, Col, Container, ListGroup, ListGroupItem, Row} from "reactstrap";
import axios, {CancelTokenSource} from "axios";
import {APIProcess, GetPathThrough, MasterDetail, MDMaster, MDDetail, MDLink, GetPathComponentAfter} from '@denjpeters/intelliwakereact';
import ActiveDD from "../Generics/ActiveDD";
import {IWake} from "../../IWake";
import {initialtbluser_diary_type, Itbluser_diary_type} from "../../Data/Tables/tbluser_diary_type";
import DiaryType from "./DiaryType";

interface IDiaryTypeStructure {
    tbluser_diary_types: Itbluser_diary_type[]
}

const DiaryTypes = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const isMounted = useRef(true);
    const {user} = useSelector((state: AppState) => state);
    const [diaryTypeStructure, setDiaryTypeStructure] = useState(null as IDiaryTypeStructure | null);
    const [activeView, setActiveView] = useState(1 as 0 | 1 | -1);
    const [forceRefresh, setForceRefresh] = useState(false);

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    const doForceRefresh = () => {
        setForceRefresh(!forceRefresh);
    }

    const updateType = (tbluser_diary_type: Itbluser_diary_type) => {
        setDiaryTypeStructure(prevState => !prevState ? null : {
            tbluser_diary_types: [
                ...prevState.tbluser_diary_types.filter(type => type.id !== tbluser_diary_type.id),
                tbluser_diary_type
            ]
        });

        history.replace(GetPathThrough('DiaryType') + "/" + tbluser_diary_type.id);
    }

    useEffect(() => {
        let cancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();
        isMounted.current = true;

        APIProcess('UserDiaryType', 'GetList', {}, cancelTokenSource)(iWake)
            .then((results) => {
                if (isMounted.current && cancelTokenSource) {
                    setDiaryTypeStructure(results);
                }
            })
            .catch(() => {
                if (isMounted.current && cancelTokenSource) {
                    setDiaryTypeStructure(null);
                }
            })
            .finally(() => {
                cancelTokenSource = null;
            });

        return () => {
            isMounted.current = false;
            if (cancelTokenSource) {
                cancelTokenSource.cancel();
                cancelTokenSource = null;
            }
        }
    }, [iWake, activeView, forceRefresh]);

    const types = useMemo(() =>
            !diaryTypeStructure ? [] : diaryTypeStructure.tbluser_diary_types
                .filter(type => activeView === -1 || type.active === activeView)
                .sort((a, b) => a.name.localeCompare(b.name, undefined, {sensitivity: 'base'}))
        , [diaryTypeStructure, activeView]);

    const selectedID = parseInt(GetPathComponentAfter('DiaryType') ?? 0);

    const diaryType = useMemo(() =>
        types.find(type => type.id === selectedID) ?? initialtbluser_diary_type
    ,[types, selectedID])

    if (!HasFeature(user, TTSFeatures.Feature_TimeSheet_Admin)) {
        return (
            <Redirect to="/TimeEntry"/>
        );
    }

    return (
        <Container fluid className="px-0">
            <MasterDetail breakAt="sm" mdPath="/DiaryTypes" backText="Diary Types">
                <MDMaster width="15em">
                    <Row className="p-2">
                        <Col>
                            <ActiveDD size="sm" handleSelectID={setActiveView} selectedID={activeView} caret/>
                        </Col>
                        <Col className="text-right">
                            <Button color="secondary" size="sm" tag={Link} to={GetPathThrough("/DiaryTypes") + "/DiaryType/New"}>Add</Button>
                        </Col>
                    </Row>
                    <ListGroup flush className="fill-height-scroll text-large-md-smaller">
                        {!diaryTypeStructure ?
                            <ListGroupItem className="text-secondary">Loading...</ListGroupItem>
                            :
                            types.length === 0 ?
                                <ListGroupItem>No Categories</ListGroupItem>
                                :
                                types.map((diaryType) =>
                                    <MDLink postPath="DiaryTypes" tag="li" panel="DiaryType" className={"list-group-item list-group-item-action py-1" + (diaryType.active === 1 ? "" : " text-gray")} key={diaryType.id} id={diaryType.id}>
                                        <span>{diaryType.name}</span>
                                    </MDLink>
                                )
                        }
                    </ListGroup>
                </MDMaster>
                <MDDetail panel="DiaryType" titleText="Diary Type">
                    {!!diaryType ?
                        <DiaryType tbluser_diary_type={diaryType} updateType={updateType} forceRefresh={doForceRefresh}/>
                        :
                        null
                    }
                </MDDetail>
            </MasterDetail>
        </Container>
    );
};

export default DiaryTypes;

import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import {Link, Redirect, useHistory} from "react-router-dom";
import {HasFeature, TTSFeatures} from "../../Data/TTSFeatures";
import {Button, Col, Container, ListGroup, ListGroupItem, Row} from "reactstrap";
import axios, {CancelTokenSource} from "axios";
import {APIProcess, GetPathThrough, GetPathComponentAfter, MasterDetail, MDMaster, MDLink, MDDetail} from '@denjpeters/intelliwakereact';
import ActiveDD from "../Generics/ActiveDD";
import {IWake} from "../../IWake";
import {Itbluser_file_type} from "../../Data/Tables/tbluser_file_type";
import {initialtbluser_file_type_group, Itbluser_file_type_group} from "../../Data/Tables/tbluser_file_type_group";
import FileTypeGroup from "./FileTypeGroup";

interface IFileTypeGroupStructure {
    tbluser_file_types: Itbluser_file_type[],
    tbluser_file_type_groups: Itbluser_file_type_group[]
}

const FileTypeGroups = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const isMounted = useRef(true);
    const {user} = useSelector((state: AppState) => state);
    const [fileTypeGroupStructure, setFileTypeGroupStructure] = useState(null as IFileTypeGroupStructure | null);
    const [activeView, setActiveView] = useState(1 as 0 | 1 | -1);
    const [forceRefresh, setForceRefresh] = useState(false);

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    const doForceRefresh = () => {
        setForceRefresh(!forceRefresh);
    }

    const updateGroup = (tbluser_file_type_group: Itbluser_file_type_group) => {
        setFileTypeGroupStructure(prevState => !prevState ? null : {
            tbluser_file_type_groups: [
                ...prevState.tbluser_file_type_groups.filter(group => group.id !== tbluser_file_type_group.id),
                tbluser_file_type_group
            ],
            tbluser_file_types: [...prevState.tbluser_file_types]
        });

        history.replace(GetPathThrough('FileTypeGroup') + "/" + tbluser_file_type_group.id);
    }

    const updateType = (tbluser_file_type: Itbluser_file_type) => {
        setFileTypeGroupStructure(prevState => !prevState ? null : {
            tbluser_file_types: [
                ...prevState.tbluser_file_types.filter(type => type.id !== tbluser_file_type.id),
                tbluser_file_type
            ],
            tbluser_file_type_groups: [...prevState.tbluser_file_type_groups]
        });

        history.replace(GetPathThrough('FileType') + "/" + tbluser_file_type.id);
    }

    useEffect(() => {
        let cancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();
        isMounted.current = true;

        APIProcess('UserFileType', 'GetList', {}, cancelTokenSource)(iWake)
            .then((results) => {
                if (isMounted.current && cancelTokenSource) {
                    setFileTypeGroupStructure(results);
                }
            })
            .catch(() => {
                if (isMounted.current && cancelTokenSource) {
                    setFileTypeGroupStructure(null);
                }
            })
            .finally(() => {
                cancelTokenSource = null;
            });

        return () => {
            isMounted.current = false;
            if (cancelTokenSource) {
                cancelTokenSource.cancel();
                cancelTokenSource = null;
            }
        }
    }, [iWake, activeView, forceRefresh]);

    const groups = useMemo(() =>
            !fileTypeGroupStructure ? [] : fileTypeGroupStructure.tbluser_file_type_groups
                .filter(group => activeView === -1 || group.active === activeView)
                .sort((a, b) => a.sort_order - b.sort_order)
        , [fileTypeGroupStructure, activeView]);

    const selectedGroupID = parseInt(GetPathComponentAfter('FileTypeGroup') ?? 0);

    const group = useMemo(() => !fileTypeGroupStructure ? initialtbluser_file_type_group : (fileTypeGroupStructure.tbluser_file_type_groups.find(group => group.id === selectedGroupID) ?? initialtbluser_file_type_group) as Itbluser_file_type_group, [fileTypeGroupStructure, selectedGroupID]);

    const grouptypes = useMemo(() =>
            !fileTypeGroupStructure ? [] : fileTypeGroupStructure.tbluser_file_types
                .filter(type => type.tbluser_file_type_group_id === selectedGroupID)
        , [fileTypeGroupStructure, selectedGroupID]);

    if (!HasFeature(user, TTSFeatures.Feature_TimeSheet_Admin)) {
        return (
            <Redirect to="/TimeEntry"/>
        );
    }

    return (
        <Container fluid className="px-0">
            <MasterDetail breakAt="sm" mdPath="/FileTypeGroups" backText="File Groups">
                <MDMaster width="15em">
                    <Row className="p-2">
                        <Col>
                            <ActiveDD size="sm" handleSelectID={setActiveView} selectedID={activeView} caret/>
                        </Col>
                        <Col className="text-right">
                            <Button color="secondary" size="sm" tag={Link} to={GetPathThrough("/FileTypeGroups") + "/FileTypeGroup/New"}>Add</Button>
                        </Col>
                    </Row>
                    <ListGroup flush className="fill-height-scroll text-large-md-smaller">
                        {!fileTypeGroupStructure ?
                            <ListGroupItem className="text-secondary">Loading...</ListGroupItem>
                            :
                            groups.length === 0 ?
                                <ListGroupItem>No Categories</ListGroupItem>
                                :
                                groups.map((fileTypeGroup) =>
                                    <MDLink postPath="FileTypes" tag="li" panel="FileTypeGroup" className={"list-group-item list-group-item-action py-1" + (fileTypeGroup.active === 1 ? "" : " text-gray")} key={fileTypeGroup.id} id={fileTypeGroup.id}>
                                        <span>{fileTypeGroup.name}</span>
                                    </MDLink>
                                )
                        }
                    </ListGroup>
                </MDMaster>
                <MDDetail panel="FileTypeGroup" titleText="File Group">
                    {!!fileTypeGroupStructure ?
                        <FileTypeGroup tbluser_file_type_group={group} tbluser_file_type_groups={groups} tbluser_file_types={grouptypes} updateGroup={updateGroup} updateType={updateType} forceRefresh={doForceRefresh}/>
                        :
                        null
                    }
                </MDDetail>
            </MasterDetail>
        </Container>
    );
};

export default FileTypeGroups;

/**
 * Automatically generated: 2020-06-15 15:30:50
 * © 2020, Solid Basis Ventures, LLC.
 * DO NOT MODIFY
 *
 * Table Manager for: tblaccrual
 */

export interface Itblaccrual {
	accrualID: number,
	userID: number | null,
	employeeNo: string | null,
	startYear: number,
	type: string,
	amount: number
}

export const initialtblaccrual: Itblaccrual = {
	accrualID: 0,
	userID: null,
	employeeNo: null,
	startYear: 0,
	type: '',
	amount: 0
};

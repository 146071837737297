import React, {FormEvent, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import {Redirect, useHistory} from "react-router-dom";
import {Button, Col, Container, Form, FormFeedback, FormGroup, Input, Label, Row} from "reactstrap";
import {
    APIProcess,
    IsENV,
    Environments,
    ShowActivityOverlay,
    ShowMessageBox,
    HideActivityOverlay,
    ShowPromptOKCancel,
    ElementCustomValue,
    GetPathComponentAfter, GetPathThrough
} from "@denjpeters/intelliwakereact";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuestion, faSignInAlt} from '@fortawesome/pro-solid-svg-icons';
import appIconBlueTransparent from '../../Assets/GCILogo.png';
import {IWake} from "../../IWake";
import {ASRSetPasswordReset} from "../../Stores/appsessionremembers/actions";

interface ILoginInformation {
    email: string,
    password: string,
    submitAttempted: boolean
}

const initialLoginInformation: ILoginInformation = {
    email: IsENV(Environments.ENV_Local) ? (process.env.REACT_APP_DEFAULT_LOGIN ?? "") : "",
    password: IsENV(Environments.ENV_Local) ? (process.env.REACT_APP_DEFAULT_PASSWORD ?? "") : "",
    submitAttempted: false
};

const Login = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector((state: AppState) => state.user);
    const [loginInformation, setLoginInformation] = useState(initialLoginInformation);

    const iWake = useMemo(() => new IWake(null, dispatch), [dispatch]);

    const formSubmit = (e: FormEvent) => {
        e.preventDefault();

        if (loginInformation.email && loginInformation.password) {
            ShowActivityOverlay()(dispatch);
            APIProcess('User', 'Login', loginInformation)(iWake)
                .then((result) => {
                    if (!!result.PasswordReset) {
                        ASRSetPasswordReset(true)(dispatch);
                        ShowMessageBox('Password reset required')(dispatch);
                    } else {
                        ASRSetPasswordReset(false)(dispatch);
                        ShowMessageBox('Logged in')(dispatch);
                    }
                })
                .catch(() => {
                })
                .finally(() => {
                    HideActivityOverlay()(dispatch);
                });
        } else {
            setLoginInformation({
                ...loginInformation,
                submitAttempted: true
            });

            ShowMessageBox('E-mail and password required', "danger")(dispatch);
        }
    }

    const forgotPassword = async () => {
        if (loginInformation.email) {
            if (await ShowPromptOKCancel('Send Temporary Password?', "To create a new password we must validate the login id or e-mail address: " + loginInformation.email + ".  The system will send you an e-mail with a temporary password that will get you into the system.", "primary", "Send Temporary Password")(dispatch)) {
                ShowActivityOverlay()(dispatch);
                APIProcess('User', 'SendTempPassword', {username: loginInformation.email})(iWake)
                    .then(() => {
                        ShowMessageBox('Temporary password sent')(dispatch);
                    })
                    .catch(() => {
                    })
                    .finally(() => {
                        HideActivityOverlay()(dispatch);
                    });
            }
        } else {
            ShowPromptOKCancel('Login id or e-mail required', "Please enter your login id or e-mail address so the system can send you a temporary password.", "primary", "OK", null)(dispatch);
        }
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLoginInformation({
            ...loginInformation,
            [e.target.name]: ElementCustomValue(e)
        });
    }

    useEffect(() => {
        const tempCode = GetPathComponentAfter('Login');

        if (!!tempCode) {
            ShowActivityOverlay()(dispatch);
            APIProcess('User', 'Login', {password: tempCode})(iWake)
                .then(() => {
                    ASRSetPasswordReset(true)(dispatch);
                    ShowMessageBox('Password reset required')(dispatch);
                })
                .catch(() => {
                    history.replace(GetPathThrough('Login'));
                })
                .finally(() => {
                    HideActivityOverlay()(dispatch);
                });
        }
    }, [dispatch, history, iWake])

    if (user.loggedIn) {
        return (
            <Redirect to="/"/>
        );
    } else {
        return (
            <Container className="vertical-scroll">
                <Row>
                    <Col className="text-center my-2">
                        <img className="img-xl" src={appIconBlueTransparent} alt="GCI TTS"/>
                    </Col>
                </Row>
                <Row>
                    <Col md={{size: 9, offset: 3}} lg={{size: 8, offset: 4}} className="my-2">
                        <h3>Login</h3>
                    </Col>
                </Row>
                <Form onSubmitCapture={formSubmit}>
                    <Row>
                        <Col md={{size: 6, offset: 3}} lg={{size: 4, offset: 4}}>
                            <FormGroup>
                                <Label>Login ID or E-mail</Label>
                                <Input type="text" name="email" placeholder="Login ID" value={loginInformation.email} valid={false} invalid={loginInformation.submitAttempted && !(loginInformation.email.length > 0)} onChange={handleInputChange}/>
                                <FormFeedback valid={!loginInformation.submitAttempted && loginInformation.email.length > 0}>Login
                                    ID
                                    required</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={{size: 6, offset: 3}} lg={{size: 4, offset: 4}}>
                            <FormGroup>
                                <Label>Password</Label>
                                <Input type="password" name="password" placeholder="*****" value={loginInformation.password} valid={false} onChange={handleInputChange} required={true}/>
                                <FormFeedback>Password required for login</FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={{size: 6, offset: 3}} lg={{size: 4, offset: 4}}>
                            <Button color="primary"><FontAwesomeIcon icon={faSignInAlt} fixedWidth/> Login</Button>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col md={{size: 6, offset: 3}} lg={{size: 4, offset: 4}}>
                                <Button type="button" color="link" className="btn-link-inline" onClick={forgotPassword}><FontAwesomeIcon icon={faQuestion} fixedWidth/> Request New
                                    Password</Button>
                        </Col>
                    </Row>
                    <Row className="mt-5 text-danger narrow">
                        <Col>
                            <p><strong>Note:  IMPORTANT INFORMATION REGARDING GCI EMAIL ACCOUNTS</strong></p>
                            <p>

                                Please be reminded that GCI intra-company communication will be sent to all employees via their GCI email account.  Information that does not relate to our clients or their projects or is of a personal nature should be communicated via GCI INTL email addresses only.  Note that our clients’ email addresses may be screened occasionally and may also be subject to public records requests.  Your GCI email account should also be on your company phone to enhance/facilitate access to your emails.  If you need assistance with your GCI email address and password, please contact Amber Parker at <a href="mailto:aparker@gciintl.com ">aparker@gciintl.com</a>.</p>
                        </Col>
                    </Row>
                </Form>
            </Container>
        );
    }
};

export default Login;

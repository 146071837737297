import React, {useEffect, useMemo, useRef, useState} from 'react';
import axios, {CancelTokenSource} from "axios";
import {APIProcess} from '@denjpeters/intelliwakereact';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import {ASRSetViewProjectID} from "../../Stores/appsessionremembers/actions";
import {Itblproject} from "../../Data/Tables/tblproject";
import {IWake} from "../../IWake";

interface IProjectList extends Itblproject {
    customerName: string
}

const ProjectDD = () => {
    const dispatch = useDispatch();
    const isMounted = useRef(true);
    const {user, appSessionRemembersChange} = useSelector((state: AppState) => state);
    const [projects, setProjects] = useState([] as IProjectList[]);

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    const changeProject = (projectID: number | null) => {
        ASRSetViewProjectID(projectID)(dispatch);
    };

    useEffect(() => {
        if (user.loggedIn) {
            let cancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();
            isMounted.current = true;

            APIProcess('project', 'GetList', {my: true}, cancelTokenSource)(iWake)
                .then((results) => {
                    if (isMounted.current && cancelTokenSource) {
                        setProjects(results.projects ?? [] as IProjectList[]);
                    }
                })
                .catch(() => {
                    setProjects([]);
                })
                .finally(() => {
                    cancelTokenSource = null;
                });

            return () => {
                isMounted.current = false;
                if (cancelTokenSource) {
                    cancelTokenSource.cancel();
                    cancelTokenSource = null;
                }
            }
        } else {
            setProjects([]);
        }
    }, [iWake, user]);

    const projectList = useMemo(() =>
        !projects ? [] : projects.sort((a: IProjectList, b: IProjectList) => {
                switch (appSessionRemembersChange.projectSort) {
                    case "Name":
                        return a.name.localeCompare(b.name, undefined, {sensitivity: 'base'});
                    case "NoAsc":
                        return a.projectNo.localeCompare(b.projectNo, undefined, {sensitivity: 'base'});
                    default:
                        return b.projectNo.localeCompare(a.projectNo, undefined, {sensitivity: 'base'});
                }
            }
        )
    , [appSessionRemembersChange.projectSort, projects]);

    const currentProject = useMemo(() =>
        projects.find(project => !appSessionRemembersChange.viewProjectID || project.projectID === (appSessionRemembersChange.viewProjectID))
        , [projects, appSessionRemembersChange.viewProjectID]);

    return (
        projects.length > 0 ?
            <UncontrolledDropdown className="d-inline-block">
                <DropdownToggle color="link" className="no-underline small btn-link-inline d-print-inline-block" caret>
                    {!!currentProject ? (currentProject.projectNo + ' - ' + currentProject.name) : "No project"}
                </DropdownToggle>
                <DropdownMenu right>
                    {projectList.length > 0 ?
                        <>
                            {projectList
                                .filter(project => project.projectID === appSessionRemembersChange.viewProjectID || project.disable === 'no')
                                .map((projectList) =>
                                <DropdownItem key={projectList.projectID} onClick={() => changeProject(projectList.projectID)}>
                                    {((projectList.projectNo ?? '') + ' - ' + (projectList.name))}
                                </DropdownItem>
                            )}
                        </>
                        :
                        <DropdownItem disabled>Loading projects...</DropdownItem>
                    }
                </DropdownMenu>
            </UncontrolledDropdown>
            :
            null
    );
};

export default ProjectDD;

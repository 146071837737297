import React, {FormEvent, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import {
    Button,
    Col,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Row
} from "reactstrap";
import {APIProcess, ShowActivityOverlay, GetPathThrough, ShowMessageBox, HideActivityOverlay, ElementCustomValue, InputSelect, InputSwitch, ActivityOverlayControl} from '@denjpeters/intelliwakereact';
import BlockNav from "../Generics/BlockNav";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/pro-regular-svg-icons";
import {HasFeature, TTSFeatures} from "../../Data/TTSFeatures";
import {Itblproject} from "../../Data/Tables/tblproject";
import {Itblcustomer} from "../../Data/Tables/tblcustomer";
import {IWake} from "../../IWake";
import { useHistory } from 'react-router-dom';

interface IProps {
    tblproject: Itblproject,
    setTblproject: ((tblproject: Itblproject) => void),
    customers: Itblcustomer[]
}

const ProjectData = (props: IProps) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const isMounted = useRef(true);
    const user = useSelector((state: AppState) => state.user);
    const [submitAttempted, setSubmitAttempted] = useState(false);
    const [blockNavigation, setBlockNavigation] = useState(false);

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    const formSubmit = (e: FormEvent) => {
        e.preventDefault();

        if (HasFeature(user, TTSFeatures.Feature_Project_Admin)) {
            setSubmitAttempted(true);

            if (!!props.tblproject) {
                if (props.tblproject.projectNo && props.tblproject.name) {
                    ShowActivityOverlay()(dispatch);
                    APIProcess('project', 'Save', props.tblproject)(iWake)
                        .then((result) => {
                            if (isMounted.current) {
                                setSubmitAttempted(false);
                                setBlockNavigation(false);
                                const newState = {...props.tblproject, projectID: result.projectID};
                                const isNew = !props.tblproject.projectID;
                                props.setTblproject(newState);
                                if (isNew) {
                                    history.replace(GetPathThrough('/Project') + '/' + result.projectID);
                                }
                                ShowMessageBox('Saved')(dispatch);
                            }
                        })
                        .catch(() => {
                        })
                        .finally(() => {
                            HideActivityOverlay()(dispatch);
                        });
                } else {
                    props.setTblproject({
                        ...props.tblproject
                    });

                    ShowMessageBox('Name and project number required', "danger")(dispatch);
                }
            }
        }
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target;

        if (target && HasFeature(user, TTSFeatures.Feature_Project_Admin)) {
            props.setTblproject({
                ...props.tblproject,
                [target.name]: ElementCustomValue(e)
            } as Itblproject);

            setBlockNavigation(true);
        }
    }

    useEffect(() => {
        setBlockNavigation(false);
        setSubmitAttempted(false);
    }, [props.tblproject.projectID]);

    return (
        <>
            {!!props.tblproject ?
                <Row>
                    <Col>
                        <Form onSubmitCapture={formSubmit} className="form-sm">
                            <FormGroup>
                                <Label>Project No</Label>
                                <div className="short">
                                    <Input type="text" name="projectNo" placeholder="Project Number" value={props.tblproject.projectNo ?? ""} valid={false} invalid={submitAttempted && !(props.tblproject.projectNo.length > 0)} onChange={handleInputChange}/>
                                    <FormFeedback valid={!submitAttempted && props.tblproject.projectNo.length > 0}>Project
                                        No
                                        required</FormFeedback>
                                </div>
                                <div className="short form-control-plaintext">
                                    {props.tblproject.projectID}
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Label>Project Name</Label>
                                <div className="medium">
                                    <Input type="text" name="name" placeholder="Name" value={props.tblproject.name ?? ""} valid={false} invalid={submitAttempted && !(props.tblproject.name.length > 0)} onChange={handleInputChange}/>
                                    <FormFeedback valid={!submitAttempted && props.tblproject.name.length > 0}>Name
                                        required</FormFeedback>
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Label>Customer</Label>
                                <div className="short">
                                    <InputSelect name="customerID" value={props.tblproject.customerID ?? 0} onChange={handleInputChange} isNumeric>
                                        <option value={0}>Select a Customer...</option>
                                        {props.customers
                                            .filter(customer => customer.customerID === props.tblproject.customerID || customer.active === 1)
                                            .sort((a, b) => (a.name ?? "").localeCompare(b.name ?? "", undefined, {sensitivity: 'base'}))
                                            .map(customer =>
                                                <option key={customer.customerID} value={customer.customerID}>
                                                    {customer.name}
                                                </option>
                                            )}
                                    </InputSelect>
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Label>Addendum No</Label>
                                <div className="medium">
                                    <Input type="text" name="addendumNo" placeholder="Addendum No" value={props.tblproject.addendumNo ?? ""} onChange={handleInputChange}/>
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Label>Amendment No</Label>
                                <div className="medium">
                                    <Input type="text" name="amendmentNo" placeholder="Amendment No" value={props.tblproject.amendmentNo ?? ""} onChange={handleInputChange}/>
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Label>Amendment Notes</Label>
                                <div>
                                    <Input type="textarea" name="amendment_notes" placeholder="Amendment Notes" value={props.tblproject.amendment_notes ?? ""} onChange={handleInputChange}/>
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Label>Customer Project No</Label>
                                <div className="medium">
                                    <Input type="text" name="customer_projectNo" placeholder="Customer Project No" value={props.tblproject.customer_projectNo ?? ""} onChange={handleInputChange}/>
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <div className="offset">
                                    <InputSwitch name="disable" onChange={handleInputChange} value={props.tblproject.disable} label="Disabled" valuesOnOff={['yes', 'no']}/>
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <div className="offset">
                                    <Button color="primary" hidden={!blockNavigation}><FontAwesomeIcon icon={faSave} fixedWidth/> Save</Button>
                                </div>
                            </FormGroup>
                        </Form>
                    </Col>
                </Row>
                :
                null
            }
            <ActivityOverlayControl show={!props.tblproject}/>
            <BlockNav when={blockNavigation}/>
        </>
    );
};

export default ProjectData;

import React from 'react';
import {useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import User from "../User/User";

const Profile = () => {
    const user = useSelector((state: AppState) => state.user);

    return (
        <User userID={user.userID}/>
    );
};

export default Profile;

import React, {useMemo} from 'react';
import {Button, Table} from "reactstrap";
import {IHours} from "./Invoice";
import {Itblinvoice} from "../../Data/Tables/tblinvoice";
import { useHistory } from 'react-router-dom';
import {useDispatch} from "react-redux";
import {ASRSetViewProjectID} from "../../Stores/appsessionremembers/actions";
import { ToDigits } from '@denjpeters/intelliwakereact';

interface IProps {
    projectID: number,
    invoice: Itblinvoice,
    summaryHours: IHours[],
    hideHeadersScreen?: boolean,
    showOpenButton?: boolean
}

interface IProjectHoursTotal extends IHours {
    totalHours: number
}

const InvoiceHourSummary = (props: IProps) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const projectHours = useMemo(() => {
        return props.summaryHours
            .reduce((groupedHours, hour) => {
                let prevTotal = groupedHours.find(groupedHour => groupedHour.projectID === hour.projectID);

                if (!!prevTotal) {
                    prevTotal.totalHours += +hour.hours;
                } else {
                    prevTotal = {...hour, totalHours: +hour.hours} as IProjectHoursTotal;
                }

                return [...groupedHours.filter(groupedHour => groupedHour.projectID !== hour.projectID), prevTotal];
            }, [] as IProjectHoursTotal[])
            .sort((a, b) => a.projectName.localeCompare(b.projectName, undefined, {sensitivity: 'base'})) as IProjectHoursTotal[];
    }, [props.summaryHours]);
    const totalHours = useMemo(() => props.summaryHours.reduce((prev, next) => prev + +next.hours, 0), [props.summaryHours])

    // console.log(props.hours, projectHours);

    const openInvoice = () => {
        if (props.invoice.status === 0) {
            history.push('/Invoicing/ProjectID/' + props.projectID);
        } else {
            ASRSetViewProjectID(+props.projectID)(dispatch);
            history.push('/Reports/Invoices/Invoice/' + props.invoice.invoiceID);
        }
    }
    
    return (
        <>
            <h4 className={"text-center" + (props.hideHeadersScreen ? " d-none d-print-block" : "")}>
                Invoice Summary for: {props.invoice.projectNo}
                {!!props.showOpenButton ?
                    <Button type="button" onClick={openInvoice} color="link" style={{position: "absolute", right: "1em"}}>(Open)</Button>
                    :
                    null
                }
            </h4>
            <h5 className={"text-center" + (props.hideHeadersScreen ? " d-none d-print-block" : "")}>{props.invoice.start_date} to {props.invoice.end_date}</h5>
            <h5 className="text-center">Total Hours: {ToDigits(totalHours, 2)}</h5>
            <Table borderless size="sm" className="mt-3">
                {projectHours.map(projectHour =>
                    <React.Fragment key={projectHour.projectID}>
                        <thead>
                        <tr>
                            <th colSpan={3}>
                                {projectHour.projectName}
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {props.summaryHours
                            .filter(hour => hour.projectID === projectHour.projectID)
                            .sort((a, b) =>
                                a.userName.localeCompare(b.userName, undefined, {sensitivity: 'base'}) ??
                                a.activityName.localeCompare(b.activityName, undefined, {sensitivity: 'base'}))
                            .map(hour =>
                                <tr key={"H" + hour.userID + hour.activityID}>
                                    <td className="border">{hour.userName}</td>
                                    <td className="border">{hour.activityName}</td>
                                    <td className="border text-right">{ToDigits(hour.hours, 2)}</td>
                                </tr>
                            )}
                        <tr>
                            <th colSpan={2} className="text-right">Total</th>
                            <th className="border text-right">{ToDigits(projectHour.totalHours, 2)}</th>
                        </tr>
                        <tr>
                            <td colSpan={3}>&nbsp;</td>
                        </tr>
                        </tbody>
                    </React.Fragment>
                )}
            </Table>
        </>
    );
};

export default InvoiceHourSummary;

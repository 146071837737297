import React, {FormEvent, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import axios, {CancelTokenSource} from "axios";
import {
    APIProcess,
    ElementCustomValue,
    RemoveDupProperties,
    ShowMessageBox,
    TextToHTML,
    InputDate,
    InputNumber,
    InputSelect,
    ActivityOverlayControl,
    ShowActivityOverlay, HideActivityOverlay, ShowPromptOKCancel
} from '@denjpeters/intelliwakereact';
import {IWake} from "../../IWake";
import {ItblinvoiceCalcs} from "../../Data/Views/Invoice";
import {Button, Col, Form, FormGroup, InputGroup, InputGroupAddon, Label, Row} from "reactstrap";
import {Itblcustomer} from "../../Data/Tables/tblcustomer";
import moment from "moment";
import InvoiceHourSummary from "./InvoiceHourSummary";
import {Link, useHistory} from "react-router-dom";
import {ASRSetViewProjectID} from "../../Stores/appsessionremembers/actions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/pro-light-svg-icons";

interface IProps {
    projectNo?: string,
    projectID?: number,
    invoiceID?: number,
    forceUpdate: (() => void)
}

export interface IHours {
    projectID: number,
    projectName: string,
    userID: number,
    userName: string,
    activityID: number,
    activityName: string,
    hours: number
}

interface IInvoiceStructure {
    projectID: number,
    invoice: ItblinvoiceCalcs,
    customer: Itblcustomer,
    hours: IHours[]
}

interface IDuration {
    weeks: number,
    days: number
}

const Invoice = (props: IProps) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const isMounted = useRef(true);
    const {user} = useSelector((state: AppState) => state);
    const [invoiceStructure, setInvoiceStructure] = useState(null as IInvoiceStructure | null);
    const [changes, setChanges] = useState({} as { [key: string]: any });
    const showAsDays = useRef(false);
    
    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    const calcDuration = (start_date: string, end_date: string): IDuration => {
        const result = {weeks: 0, days: 0};

        const days = +moment(end_date).diff(moment(start_date), 'days') + 1;

        if (showAsDays.current) {
            result.weeks = 0;
            result.days = days;
        } else {
            result.weeks = Math.floor(days / 7);
            result.days = days - (result.weeks * 7);
        }

        return result;
    }

    const invoice = useMemo(() => !invoiceStructure ? null : {...invoiceStructure.invoice, ...changes}, [invoiceStructure, changes]);
    const duration = useMemo(() => !invoice ? {
        weeks: 0,
        days: 0
    } : calcDuration(invoice.start_date, invoice.end_date), [invoice]);

    const handleInputChange = (e: React.ChangeEvent<any>) => {
        const target: any = e.target;

        if (!!target && !!invoiceStructure) {
            const name = target.name;
            const value = ElementCustomValue(e);
            handleInputChangeName(name, value);
        }
    }

    const handleInputChangeName = (name: string, value: any) => {
        setChanges(c => {
            return {
                ...c,
                [name]: value
            } as IInvoiceStructure
        });
    }

    const deleteInvoice = async () => {
        if (await ShowPromptOKCancel('Delete Invoice?', 'Are you sure you want to delete this invoice?', 'danger', 'Delete')(dispatch)) {
            const saveChanges = {
                ...changes,
                active: 0
            };

            saveInvoice(saveChanges);
        }
    }

    const formSubmit = (e: FormEvent) => {
        e.preventDefault();

        saveInvoice(changes);
    }

    const saveInvoice = (saveChanges: { [key: string]: any }) => {
        if (!!invoiceStructure) {
            if ((!props.invoiceID && !invoiceStructure.invoice.invoiceID) || Object.keys(saveChanges).length > 0) {
                ShowActivityOverlay()(dispatch);
                APIProcess('Invoice', 'Save', {...invoiceStructure.invoice, ...saveChanges})(iWake)
                    .then((result) => {
                        if (isMounted.current) {
                            setInvoiceStructure(prevState => {
                                return {
                                    ...prevState!,
                                    invoice: {
                                        ...invoiceStructure.invoice,
                                        ...saveChanges,
                                        invoiceID: result.invoiceID
                                    }
                                }
                            });
                            setChanges(prevState => RemoveDupProperties(prevState, saveChanges));
                            props.forceUpdate();
                            ShowMessageBox('Saved')(dispatch);
                        }
                    })
                    .catch(() => {
                        setInvoiceStructure(null);
                    })
                    .finally(() => {
                        HideActivityOverlay()(dispatch);
                    });
            }
        }
    }

    useEffect(() => {
        let cancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();
        isMounted.current = true;

        APIProcess('Invoice', 'Get', props, cancelTokenSource)(iWake)
            .then((results) => {
                // console.log(results);
                if (isMounted.current && cancelTokenSource) {
                    setInvoiceStructure(results);
                    setChanges({});
                }
            })
            .catch(() => {
                if (isMounted.current && cancelTokenSource) {
                    setInvoiceStructure(null);
                    setChanges({});
                }
            })
            .finally(() => {
                cancelTokenSource = null;
            });

        return () => {
            isMounted.current = false;
            if (cancelTokenSource) {
                cancelTokenSource.cancel();
                cancelTokenSource = null;
            }
        }
    }, [iWake, props]);

    const changeDays = (days: number) => {
        if (!!invoice) {
            showAsDays.current = days > 6;
            const modifyDays = days - duration.days;

            setChanges(c => {
                return {
                    ...c,
                    end_date: moment((invoice as any).end_date ?? null).add(modifyDays, 'days').format('YYYY-MM-DD')
                }
            });
        }
    }

    const changeWeeks = (weeks: number) => {
        if (!!invoice) {
            const durationDays = (weeks * 7) + duration.days - 1;

            setChanges(c => {
                return {
                    ...c,
                    end_date: moment((invoice as any).start_date ?? null).add(durationDays, 'days').format('YYYY-MM-DD')
                }
            });
        }
    }

    const openProjectInvoices = (projectID: number) => {
        if (!!projectID) {
            ASRSetViewProjectID(+projectID)(dispatch);
            history.push('/Reports/Invoices');
        }
    }

    return (
        <Row className="fill-height-scroll">
            <Col>
                {!!invoiceStructure && !!invoice ?
                    <>
                        <Form className="form-sm form-condensed d-print-none" onSubmitCapture={formSubmit}>
                            <Row>
                                <Col>
                                    <h4 className="text-center">
                                        Invoice
                                        <Button size="sm" color="primary" style={{
                                            position: "absolute",
                                            right: "2em"
                                        }} className={((!props.invoiceID && !invoice.invoiceID) || Object.keys(changes).length > 0) ? "" : "invisible"}>
                                            Save
                                        </Button>
                                    </h4>
                                </Col>
                            </Row>
                            <FormGroup>
                                <Label>Customer</Label>
                                <Col className="form-control-plaintext">{invoiceStructure.customer.name}</Col>
                            </FormGroup>
                            <FormGroup>
                                <Label>Project No</Label>
                                <Col className="form-control-plaintext">
                                    {invoice.projectNo}
                                    {!!props.projectID ?
                                        <Button type="button" color="link" size="sm" onClick={() => {
                                            openProjectInvoices(props.projectID ?? 0);
                                        }}>(Past Invoices)</Button>
                                        :
                                        null
                                    }
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Label>Project Name</Label>
                                <Col className="form-control-plaintext">
                                    <small dangerouslySetInnerHTML={{__html: TextToHTML(invoice.projectNoNames)}}/>
                                </Col>
                            </FormGroup>
                            <FormGroup className="mt-3">
                                <Label>Invoice Frequency</Label>
                                <Col>{invoiceStructure.customer.invoice_notes}</Col>
                            </FormGroup>
                            <FormGroup>
                                <Label>
									Start Date
									<span className="d-none">{invoice.invoiceID}</span>
                                </Label>
                                {invoice.status === 0 ?
                                    <>
                                        <Col className="medium">
                                            <InputDate name="start_date" value={invoice.start_date} onChange={handleInputChange}/>
                                        </Col>
                                        <Col className="short">
                                            {moment(invoice.start_date).format('ddd')}
                                        </Col>
                                    </>
                                    :
                                    <Col>
                                        {moment(invoice.start_date).format('MMM D, Y  (ddd)')}
                                    </Col>
                                }
                            </FormGroup>
                            <FormGroup>
                                <Label>Duration</Label>
                                <Col className="medium">
                                    {invoice.status === 0 ?
                                        <Row>
                                            <Col>
                                                <InputGroup className={showAsDays.current ? "invisible" : ""}>
                                                    <InputNumber name="durationWeeks" value={duration.weeks} onChange={(e) => {
                                                        changeWeeks(ElementCustomValue(e));
                                                    }}/>
                                                    <InputGroupAddon addonType="append">
                                                        Weeks
                                                    </InputGroupAddon>
                                                </InputGroup>
                                            </Col>
                                            <Col>
                                                <InputGroup>
                                                    <InputNumber name="durationDays" value={duration.days} onChange={(e) => {
                                                        changeDays(ElementCustomValue(e));
                                                    }}/>
                                                    <InputGroupAddon addonType="append">
                                                        Days
                                                    </InputGroupAddon>
                                                </InputGroup>
                                            </Col>

                                        </Row>
                                        :
                                        <>
                                            {duration.weeks} Weeks, {duration.days} Days </>
                                    }
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Label>End Date</Label>
                                {invoice.status === 0 ?
                                    <>
                                        <Col className="medium">
                                            <InputDate name="end_date" value={invoice.end_date} onChange={handleInputChange}/>
                                        </Col>
                                        <Col className="short">
                                            {moment(invoice.end_date).format('ddd')}
                                        </Col>
                                    </>
                                    :
                                    <Col>
                                        {moment(invoice.end_date).format('MMM D, Y  (ddd)')}
                                    </Col>
                                }
                            </FormGroup>
                            {invoice.invoiceID > 0 ?
                                <FormGroup className="mt-3 mb-5">
                                    <Label>Status</Label>
                                    <Col className="short">
                                        {invoice.end_date < moment().format('YYYY-MM-DD') ?
                                            <InputSelect name="status" value={invoice.status} onChange={handleInputChange} isNumeric>
                                                <option value={0}>Draft</option>
                                                <option value={10}>Completed</option>
                                            </InputSelect>
                                            :
                                            <div className="text-muted form-control-plaintext">
                                                {invoice.status === 0 ? "Draft" : "Completed"}
                                            </div>
                                        }
                                    </Col>
                                    <Col className="short">
                                        <Button type="button" color="link" size="sm" onClick={deleteInvoice}>
                                            <FontAwesomeIcon icon={faTrashAlt}/>
                                        </Button>
                                    </Col>
                                </FormGroup>
                                :
                                null
                            }
                        </Form>
                        {((!props.invoiceID && !invoice.invoiceID) || Object.keys(changes).length > 0) ?
                            <h5 className="text-center mt-4">Save Changes to view Summary</h5>
                            :
                            <>
                                <Row className="d-print-none">
                                    <Col className="text-center">
                                        <Link to={"/TimeSheet/Invoice/" + invoice.invoiceID}>(Open TimeSheet
                                            Detail)</Link>
                                    </Col>
                                </Row>
                                <Row className="p-2">
                                    <Col>
                                        <InvoiceHourSummary projectID={props.projectID ?? invoiceStructure.projectID} invoice={invoice} summaryHours={invoiceStructure.hours} hideHeadersScreen/>
                                    </Col>
                                </Row>
                            </>
                        }
                    </>
                    :
                    <ActivityOverlayControl show/>
                }
            </Col>
        </Row>
    );
};

export default Invoice;

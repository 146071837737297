import {
    APPSETTINGS_REPORTSINVOICINGCOLLAPSED,
    APPSETTINGS_ALL_UPDATED,
    AppSettingsActionTypes
} from './types'

export const AppSettingsAllUpdated = (appSettings: any) => {
    return (dispatch: any) => {
        dispatch({
            type: APPSETTINGS_ALL_UPDATED,
            payload: appSettings
        } as AppSettingsActionTypes);
    }
};

export const AppSettingsSetReportsInvoicingCollapsed = (reportsInvoicingCollapsed: boolean) => {
    return (dispatch: any) => {
        dispatch({
            type: APPSETTINGS_REPORTSINVOICINGCOLLAPSED,
            payload: reportsInvoicingCollapsed
        } as AppSettingsActionTypes);
    }
};

import {SystemState, UPDATE_SYSTEM, SystemActionTypes} from './types'

function updateSystem(newSession: SystemState): SystemActionTypes {
    return {
        type: UPDATE_SYSTEM,
        payload: newSession
    }
}

export const UpdateSystem = (
    app_version: string,
    html_version: string,
    db_version: string,
    environment: string,
    environment_name: string) => {
    return (dispatch: any) => {
        dispatch(updateSystem({
            app_version: app_version.toString(),
            html_version: html_version.toString(),
            db_version: db_version.toString(),
            environment: environment,
            environment_name: environment_name
        }));
    }
};

import React, {useEffect, useMemo} from 'react';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import NavBar from "./NavBar";
import About from "./About";
import Footer from "./Footer";
import Login from "./WebControls/System/Login";
import {AppState} from "./Stores/rootReducer";
import {useDispatch, useSelector} from "react-redux";
import ProtectedRoute from "./WebControls/System/ProtectedRoute";
import Page404 from "./WebControls/System/Page404";
import TimeEntry from "./WebControls/TimeEntry";
import {HasFeature, IsAdmin, TTSFeatures} from "./Data/TTSFeatures";
import {Session_Logout} from "./Stores/user/actions";
import System from "./WebControls/System/System";
import Users from "./WebControls/User/Users";
import Profile from "./WebControls/System/Profile";
import ChangePassword from "./WebControls/System/ChangePassword";
import {APIProcess, ShowActivityOverlay, HideActivityOverlay, ResetActivityOverlay, DismissMessageBox, DismissPromptOKCancel, ActivityOverlay, MessageBox, PromptOKCancel} from '@denjpeters/intelliwakereact';
import ErrorBoundary from "./WebControls/System/ErrorBoundary";
import InfoPHP from "./WebControls/System/InfoPHP";
import Mileage from "./WebControls/Mileage";
import Approval from "./WebControls/Approval";
import TimeSheet from "./WebControls/TimeSheet";
import Projects from "./WebControls/Project/Projects";
import Project from "./WebControls/Project/Project";
import Customers from "./WebControls/System/Customers";
import Holidays from "./WebControls/System/Holidays";
import MileageRates from "./WebControls/System/MileageRates";
import ActivityCodes from "./WebControls/System/ActivityCodes";
import Reports, {ReportFeaturesRequired} from "./WebControls/Reports/Reports";
import AuthRoles from "./WebControls/System/AuthRoles";
import {IWake} from "./IWake";
import Invoicing from "./WebControls/Invoice/Invoicing";
import TestPage from "./WebControls/System/TestPage";
import FileTypeGroups from "./WebControls/System/FileTypeGroups";
import DiaryTypes from "./WebControls/System/DiaryTypes";
import { UnsupportedBrowser } from './WebControls/System/UnsupportedBrowser';

const App = () => {
    const dispatch = useDispatch();
    const {user, system, activityOverlay, messageBox, promptOKCancel, appSessionRemembersChange} = useSelector((state: AppState) => state);

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    // @ts-ignore
    const isIE = !!document.documentMode;

    useEffect(() => {
        // @ts-ignore
        if (!document.documentMode) {
            if (!system.app_version || !system.html_version) {
                APIProcess('System', 'HeartBeat')(iWake)
                    .then(() => {
                    })
                    .catch(() => {
                    });
            } else if (!!window.localStorage.getItem('device_token') && !user.loggedIn && !!system.html_version) {
                ShowActivityOverlay()(dispatch);
                APIProcess('User', 'EstablishLogin')(iWake)
                    .then((results) => {
                        HideActivityOverlay()(dispatch);
                        if (!results.IsLoggedIn) {
                            window.sessionStorage.setItem('referrer', window.location.pathname);
                            window.localStorage.removeItem('device_token');
                            console.log("Can't establish login", window.localStorage.getItem('device_token'), user.loggedIn, system.html_version);
                            Session_Logout()(dispatch);
                        }
                    })
                    .catch(() => {
                        HideActivityOverlay()(dispatch);
                    });
            }
        }
    }, [system.html_version, system.app_version, iWake, user.loggedIn, dispatch]);

    return (
        <BrowserRouter>
            <ErrorBoundary>
                <div className="App IWApp">
                    {isIE ?
                        <UnsupportedBrowser/>
                        :
                        <>
                            <NavBar/>
                            {!system.html_version || !window.localStorage.getItem('html_version') ?
                                <div className="p-4 text-muted font-italic text-center">
                                    Loading... </div>
                                :
                                <>
                                    <div className="AppContent">
                                        {user.loggedIn && appSessionRemembersChange.requiresPasswordReset ?
                                            <ChangePassword/>
                                            :
                                            <Switch>
                                                <Route path="/About" component={About}/>
                                                <ProtectedRoute path="/Login" component={Login} forceHide={window.localStorage.getItem('device_token') !== null && !user.loggedIn} isAllowed={!user.loggedIn} restrictedPath={window.sessionStorage.getItem('referrer') ?? "/"}/>

                                                <ProtectedRoute exact path="/" component={() =>
                                                    <Redirect to={user.loggedIn ? '/TimeEntry' : '/Login'}/>}/>
                                                <ProtectedRoute path="/TimeEntry" component={TimeEntry} requireAuthentication/>
                                                <ProtectedRoute path="/TimeSheet" component={TimeSheet} requireAuthentication/>
                                                <ProtectedRoute path="/Mileage" component={Mileage} requireAuthentication/>
                                                <ProtectedRoute path="/Approvals" component={Approval} requireAuthentication/>

                                                <ProtectedRoute path="/Projects" component={Projects} requireAuthentication/>
                                                <ProtectedRoute path="/Project" component={Project} requireAuthentication/>
                                                <ProtectedRoute path="/Invoicing" component={Invoicing} requireAuthentication/>

                                                <ProtectedRoute path="/Reports" component={Reports} requireAuthentication isAllowed={HasFeature(user, ReportFeaturesRequired)}/>

                                                <ProtectedRoute path="/Profile" component={Profile} requireAuthentication/>
                                                <ProtectedRoute path="/ChangePassword" component={ChangePassword} requireAuthentication/>

                                                <ProtectedRoute path="/Customers" component={Customers} requireAuthentication isAllowed={HasFeature(user, TTSFeatures.Feature_Customer_Admin)}/>
                                                <ProtectedRoute path="/Holidays" component={Holidays} requireAuthentication isAllowed={HasFeature(user, TTSFeatures.Feature_TimeSheet_Admin)}/>
                                                <ProtectedRoute path="/ActivityCodes" component={ActivityCodes} requireAuthentication isAllowed={HasFeature(user, TTSFeatures.Feature_TimeSheet_Admin)}/>
                                                <ProtectedRoute path="/MileageRates" component={MileageRates} requireAuthentication isAllowed={HasFeature(user, TTSFeatures.Feature_TimeSheet_Admin)}/>
                                                <ProtectedRoute path="/Users" component={Users} requireAuthentication isAllowed={HasFeature(user, TTSFeatures.Feature_User_Admin)}/>
                                                <ProtectedRoute path="/FileTypeGroups" component={FileTypeGroups} requireAuthentication isAllowed={HasFeature(user, TTSFeatures.Feature_User_Admin)}/>
                                                <ProtectedRoute path="/DiaryTypes" component={DiaryTypes} requireAuthentication isAllowed={HasFeature(user, TTSFeatures.Feature_User_Admin)}/>
                                                <ProtectedRoute path="/AuthRoles" component={AuthRoles} requireAuthentication isAllowed={IsAdmin(user)}/>
                                                <ProtectedRoute path="/System" component={System} requireAuthentication isAllowed={IsAdmin(user)}/>
                                                <ProtectedRoute path="/InfoPHP" component={InfoPHP} isAllowed/>
                                                <ProtectedRoute path="/TestPage" component={TestPage} isAllowed/>
                                                <Route path="*" component={Page404}/>
                                            </Switch>
                                        }
                                    </div>
                                    <ActivityOverlay activityOverlayState={activityOverlay} resetActivityOverlay={() => ResetActivityOverlay()(dispatch)}/>
                                    <MessageBox messageBoxState={messageBox} dismissMessageBox={() => DismissMessageBox()(dispatch)}/>
                                    <PromptOKCancel promptOKCancelState={promptOKCancel} dismissPromptOKCancel={() => DismissPromptOKCancel()(dispatch)}/>
                                </>
                            }
                            <Footer/>
                        </>
                    }
                </div>
            </ErrorBoundary>
        </BrowserRouter>
    );
};

export default App;

import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import {Link, Redirect} from "react-router-dom";
import {HasFeature, TTSFeatures} from "../../Data/TTSFeatures";
import {Button, Col, Container, ListGroup, ListGroupItem, Row} from "reactstrap";
import axios, {CancelTokenSource} from "axios";
import {APIProcess, GetPathThrough, MasterDetail, MDMaster, MDLink, MDDetail, GetPathComponentAfter, CleanNumber} from '@denjpeters/intelliwakereact';
import ActiveDD from "../Generics/ActiveDD";
import AuthRole from "./AuthRole";
import {initialtblauth_role, Itblauth_role} from "../../Data/Tables/tblauth_role";
import {Itblauth_role_has_feature} from "../../Data/Tables/tblauth_role_has_feature";
import {IWake} from "../../IWake";

interface IAuthRoleStructure {
    auth_roles: Itblauth_role[],
    auth_role_has_features: Itblauth_role_has_feature[],
    features: string[]
}

const AuthRoles = () => {
    const dispatch = useDispatch();
    const isMounted = useRef(true);
    const {user} = useSelector((state: AppState) => state);
    const [authRoleStructure, setAuthRoleStructure] = useState(null as IAuthRoleStructure | null);
    const [activeView, setActiveView] = useState(1 as 0 | 1 | -1);
    const [forceRefresh, setForceRefresh] = useState(false);

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    const doForceRefresh = () => {
        setForceRefresh(!forceRefresh);
    }

    useEffect(() => {
        let cancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();
        isMounted.current = true;

        APIProcess('Auth_Role', 'GetList', {showActive: activeView}, cancelTokenSource)(iWake)
            .then((results) => {
                if (isMounted.current && cancelTokenSource) {
                    // for (let authRoleItem of results.authRoles) {
                    //     authRoleItem.active = Number(authRoleItem.active);
                    // }
                    setAuthRoleStructure({
                            ...results,
                            auto_roles: (results.auth_roles as Itblauth_role[])
                                .sort((a, b) => {
                                    return (a.name).localeCompare((b.name), undefined, {sensitivity: 'accent'})
                                })
                        }
                    );
                }
            })
            .catch(() => {
                if (isMounted.current && cancelTokenSource) {
                    setAuthRoleStructure(null);
                }
            })
            .finally(() => {
                cancelTokenSource = null;
            });

        return () => {
            isMounted.current = false;
            if (cancelTokenSource) {
                cancelTokenSource.cancel();
                cancelTokenSource = null;
            }
        }
    }, [iWake, activeView, forceRefresh]);

    if (!HasFeature(user, TTSFeatures.Feature_TimeSheet_Admin)) {
        return (
            <Redirect to="/TimeEntry"/>
        );
    }

    return (
        <Container fluid className="px-0">
            <MasterDetail breakAt="sm" mdPath="/AuthRoles" backText="AuthRoles">
                <MDMaster width="15em">
                    <Row className="p-2">
                        <Col>
                            <ActiveDD size="sm" handleSelectID={setActiveView} selectedID={activeView} caret/>
                        </Col>
                        <Col className="text-right">
                            <Button color="secondary" size="sm" tag={Link} to={GetPathThrough("/AuthRoles") + "/AuthRole/New"}>Add</Button>
                        </Col>
                    </Row>
                    <ListGroup flush className="fill-height-scroll text-large-md-smaller">
                        {!authRoleStructure ?
                            <ListGroupItem className="text-secondary">Loading...</ListGroupItem>
                            : authRoleStructure.auth_roles.length === 0 ?
                                <ListGroupItem>No authRoles</ListGroupItem>
                                :
                                authRoleStructure.auth_roles.map((authRole) =>
                                    <MDLink tag="li" panel="AuthRole" className={"list-group-item list-group-item-action py-1" + (authRole.active === 1 ? "" : " text-gray")} key={authRole.auth_roleID} id={authRole.auth_roleID}>
                                        <span>{authRole.name}</span>
                                    </MDLink>
                                )
                        }
                    </ListGroup>
                </MDMaster>
                <MDDetail panel="AuthRole" titleText="AuthRole">
                    <AuthRole auth_role={
                        !authRoleStructure ? initialtblauth_role
                            :
                            authRoleStructure.auth_roles.find(auth_role => auth_role.auth_roleID === CleanNumber(GetPathComponentAfter('/AuthRole'))) ?? initialtblauth_role
                    } auth_role_has_features={!authRoleStructure ? [] : authRoleStructure.auth_role_has_features ?? []} features={!authRoleStructure ? [] : authRoleStructure.features ?? []} forceRefresh={doForceRefresh}/>
                </MDDetail>
            </MasterDetail>
        </Container>
    );
};

export default AuthRoles;

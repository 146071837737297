import React, {useEffect, useMemo, useRef, useState} from 'react';
import {
    Col,
    Row, Table
} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import axios, {CancelTokenSource} from "axios";
import {APIProcess, ShowActivityOverlay, HideActivityOverlay} from '@denjpeters/intelliwakereact';
import {useHistory} from 'react-router-dom';
import {IWake} from "../../IWake";

interface IFile {
    userID: number,
    fullName: string,
    tbluser_file_type_names: string
}

interface IReportStructure {
    files: IFile[]
}

const MissingDocs = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const isMounted = useRef(true);
    const {user} = useSelector((state: AppState) => state);
    const [reportStructure, setReportStructure] = useState(null as IReportStructure | null);

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    useEffect(() => {
        let cancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();
        isMounted.current = true;

        ShowActivityOverlay()(dispatch);
        APIProcess('UserFile', 'Missing', {}, cancelTokenSource)(iWake)
            .then((results) => {
                // console.log(results.reportStructure);
                if (isMounted.current && cancelTokenSource) {
                    setReportStructure({
                        ...results,
                        files: results.files.sort((a: IFile, b: IFile) => a.fullName.localeCompare(b.fullName, undefined, {sensitivity: 'base'}))
                    });
                }
            })
            .catch(() => {
                if (isMounted.current && cancelTokenSource) {
                    setReportStructure(null);
                }
            })
            .finally(() => {
                HideActivityOverlay()(dispatch);
                cancelTokenSource = null;
            });

        return () => {
            isMounted.current = false;
            if (cancelTokenSource) {
                cancelTokenSource.cancel();
                cancelTokenSource = null;
            }
        }
    }, [dispatch, iWake]);

    return (
        <>
            <Row>
                <Col>
                    <h3 className="text-center">Missing Documents</h3>
                </Col>
            </Row>
            {!!reportStructure ?
                <Row className="mt-3 fill-height">
                    <Col className="fill-height">
                        <Table bordered hover size="sm" className="table-scrollable table-sortable">
                            <thead>
                            <tr className="table-secondary">
                                <th className="td-xxxl">Employee</th>
                                <th>Document(s)</th>
                            </tr>
                            </thead>
                            <tbody>
                            {reportStructure.files.map((reportData, idx) =>
                                <tr key={idx} onClick={() => history.push('/Users/User/' + reportData.userID + "/Documents")}>
                                    <td className="td-xxxl">{reportData.fullName}</td>
                                    <td>{reportData.tbluser_file_type_names}</td>
                                </tr>
                            )}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                :
                null
            }
        </>
    );
};

export default MissingDocs;

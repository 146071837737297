/**
 * Automatically generated: 2020-06-15 15:30:50
 * © 2020, Solid Basis Ventures, LLC.
 * DO NOT MODIFY
 *
 * Table Manager for: tblproject
 */

export interface Itblproject {
	projectID: number,
	projectNo: string,
	name: string,
	disable: string,
	customerID: number | null,
	addendumNo: string | null,
	amendmentNo: string | null,
	amendment_notes: string | null,
	customer_projectNo: string | null,
	customer_approved_hours: number,
	budgeted_hours: number | null,
	start_date: string | null,
	end_date: string | null
}

export const initialtblproject: Itblproject = {
	projectID: 0,
	projectNo: '',
	name: '',
	disable: 'no',
	customerID: null,
	addendumNo: null,
	amendmentNo: null,
	amendment_notes: null,
	customer_projectNo: null,
	customer_approved_hours: 0,
	budgeted_hours: null,
	start_date: null,
	end_date: null
};

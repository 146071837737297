import React, {useEffect, useMemo, useRef, useState} from 'react';
import {
    Col,
    Row, Table
} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import axios, {CancelTokenSource} from "axios";
import {APIProcess, ShowActivityOverlay, HideActivityOverlay, ToDigitsBlank} from '@denjpeters/intelliwakereact';
import EmployeeDD from "../Generics/EmployeeDD";
import {IWake} from "../../IWake";

interface IData {
    username: string,
    accrualBanks: any[]
}

const VacaBank = () => {
    const dispatch = useDispatch();
    const isMounted = useRef(true);
    const {user, appSessionRemembersChange} = useSelector((state: AppState) => state);
    const [vacaBank, setVacaBank] = useState(null as IData | null);

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    useEffect(() => {
        let cancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();
        isMounted.current = true;

        ShowActivityOverlay()(dispatch);
        APIProcess('accrualbank', 'Get', {
            userID: appSessionRemembersChange.viewUserID ?? user.userID
        }, cancelTokenSource)(iWake)
            .then((results) => {
                console.log(results);
                if (isMounted.current && cancelTokenSource) {
                    setVacaBank(results as IData);
                }
            })
            .catch(() => {
                if (isMounted.current && cancelTokenSource) {
                    setVacaBank(null);
                }
            })
            .finally(() => {
                HideActivityOverlay()(dispatch);
                cancelTokenSource = null;
            });

        return () => {
            isMounted.current = false;
            if (cancelTokenSource) {
                cancelTokenSource.cancel();
                cancelTokenSource = null;
            }
        }
    }, [appSessionRemembersChange.viewUserID, dispatch, iWake, user.userID]);

    const maxBank = !!vacaBank ? vacaBank.accrualBanks.reduce((prev, next) => prev.anniversaryStartDate > next.anniversaryStartDate ? prev : next, '') : '';

    return (
        <>
            <Row>
                <Col>
                    <h3 className="text-center">Vacation Bank Report</h3>
                    <h4 className="text-center"><EmployeeDD/></h4>
                </Col>
            </Row>
            {!!vacaBank ?
                <Row className="mt-3 small fill-height-scroll">
                    <Col>
                        <Table bordered size="sm">
                            <thead>
                            <tr>
                                <th>Start of Year</th>
                                <th>Year #</th>
                                <th>Bank at Start</th>
                                <th>Accrued</th>
                                <th>Used</th>
                                <th>Available to Anniversary</th>
                                <th>Next Anniversary</th>
                                <th>Est. Bank Contribution</th>
                                <th>Est. Bank at Anniversary</th>
                                <th>Est. Hours Forfeited</th>
                            </tr>
                            </thead>
                            <tbody>
                            {vacaBank.accrualBanks.map((accrualBank, idx) =>
                                <tr key={idx}>
                                    <td>{accrualBank.anniversaryStartDate}</td>
                                    <td className="text-right">{ToDigitsBlank(accrualBank.year, 0)}</td>
                                    <td className="text-right">{ToDigitsBlank(accrualBank.bankPrev, 2)}</td>
                                    <td className="text-right">{ToDigitsBlank(accrualBank.accrued, 2)}</td>
                                    <td className="text-right">{ToDigitsBlank(accrualBank.used * -1, 2)}</td>
                                    <td className={"text-right" + (accrualBank.anniversaryStartDate === maxBank.anniversaryStartDate ? " alert-success" : "")}>{ToDigitsBlank(accrualBank.availToEOY, 2)}</td>
                                    <td>{accrualBank.anniversaryNextDate}</td>
                                    <td className="text-right">{ToDigitsBlank(accrualBank.bankContribution, 2)}</td>
                                    <td className="text-right">{ToDigitsBlank(accrualBank.bankSum, 2)}</td>
                                    <td className={"text-right" + (accrualBank.anniversaryStartDate === maxBank.anniversaryStartDate ? " alert-danger" : "")}>{ToDigitsBlank(accrualBank.hoursForfeited, 2)}</td>
                                </tr>
                            )}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                :
                null
            }
        </>
    );
};

export default VacaBank;

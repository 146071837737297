import React, {useEffect, useMemo, useRef, useState} from 'react';
import {
    Button,
    Col,
    Row, Table
} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import axios, {CancelTokenSource} from "axios";
import {APIProcess, initialSortColumn, ISortColumn, ShowActivityOverlay, SearchSort, HideActivityOverlay, InputSelect, ElementCustomValue, SortColumnUpdate, ToDigitsBlank} from '@denjpeters/intelliwakereact';
import {ASRSetReportEmpDates} from "../../Stores/appsessionremembers/actions";
import {IWake} from "../../IWake";

interface IReportStructure {
    startDate: string,
    endDate: string,
    timeData: any[],
    payrollDates: any[]
}

const EmpTime = () => {
    const dispatch = useDispatch();
    const isMounted = useRef(true);
    const {user, appSessionRemembersChange} = useSelector((state: AppState) => state);
    const [reportStructure, setReportStructure] = useState(null as IReportStructure | null);
    const [sortColumn, setSortColumn] = useState({
        ...initialSortColumn,
        primarySort: "fullname"
    } as ISortColumn);
    const [startDate, setStartDate] = useState(null as null | string);
    const [endDate, setEndDate] = useState(null as null | string);

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    const applyDates = () => {
        ASRSetReportEmpDates(startDate, endDate)(dispatch);
    }

    const applyDatesKeyDown = (e: React.KeyboardEvent) => {
        if (e.keyCode === 13) {
            applyDates();
        }
    }

    useEffect(() => {
        let cancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();
        isMounted.current = true;

        ShowActivityOverlay()(dispatch);
        APIProcess('reports', 'EmpTime', {
            startDate: appSessionRemembersChange.reportEmpTimeDateStart,
            endDate: appSessionRemembersChange.reportEmpTimeDateEnd
        }, cancelTokenSource)(iWake)
            .then((results) => {
                console.log(results);
                if (isMounted.current && cancelTokenSource) {
                    setReportStructure(results);
                    setStartDate(results.startDate);
                    setEndDate(results.endDate);
                }
            })
            .catch(() => {
                if (isMounted.current && cancelTokenSource) {
                    setReportStructure(null);
                }
            })
            .finally(() => {
                HideActivityOverlay()(dispatch);
                cancelTokenSource = null;
            });

        return () => {
            isMounted.current = false;
            if (cancelTokenSource) {
                cancelTokenSource.cancel();
                cancelTokenSource = null;
            }
        }
    }, [appSessionRemembersChange.reportEmpTimeDateStart, appSessionRemembersChange.reportEmpTimeDateEnd, dispatch, iWake]);

    const timeList: any[] = useMemo(() => {
        return SearchSort(!reportStructure ? [] : (reportStructure.timeData ?? []), '', sortColumn)
    }, [reportStructure, sortColumn]);

    const total_pay: number = useMemo(() => {
        return timeList
            .map(hour => hour.total_pay ?? 0)
            .reduce((prev, next) => +prev + +next, 0)
    }, [timeList]);

    const total_pay_reg: number = useMemo(() => {
        return timeList
            .map(hour => hour.total_pay_reg ?? 0)
            .reduce((prev, next) => +prev + +next, 0)
    }, [timeList]);

    const total_pay_ot: number = useMemo(() => {
        return timeList
            .map(hour => hour.total_pay_ot ?? 0)
            .reduce((prev, next) => +prev + +next, 0)
    }, [timeList]);

    const total_bill_reg: number = useMemo(() => {
        return timeList
            .map(hour => hour.total_bill_reg ?? 0)
            .reduce((prev, next) => +prev + +next, 0)
    }, [timeList]);

    const total_bill_ot: number = useMemo(() => {
        return timeList
            .map(hour => hour.total_bill_ot ?? 0)
            .reduce((prev, next) => +prev + +next, 0)
    }, [timeList]);

    const totalUnbillable: number = useMemo(() => {
        return timeList
            .map(hour => hour.totalUnbillable ?? 0)
            .reduce((prev, next) => +prev + +next, 0)
    }, [timeList]);

    const total_vacation: number = useMemo(() => {
        return timeList
            .map(hour => hour.total_vacation ?? 0)
            .reduce((prev, next) => +prev + +next, 0)
    }, [timeList]);

    const total_illness: number = useMemo(() => {
        return timeList
            .map(hour => hour.total_illness ?? 0)
            .reduce((prev, next) => +prev + +next, 0)
    }, [timeList]);

    const total_holiday: number = useMemo(() => {
        return timeList
            .map(hour => hour.total_holiday ?? 0)
            .reduce((prev, next) => +prev + +next, 0)
    }, [timeList]);

    const total_admin: number = useMemo(() => {
        return timeList
            .map(hour => hour.total_admin ?? 0)
            .reduce((prev, next) => +prev + +next, 0)
    }, [timeList]);

    return (
        <>
            <Row>
                <Col>
                    <h3 className="text-center">Employees Time Report</h3>
                    {!!reportStructure ?
                        <>
                            <div className="text-center d-print-none">
                                <InputSelect value={startDate ?? reportStructure.startDate} onChange={(e) => setStartDate(ElementCustomValue(e))} className="d-inline-block w-auto mr-2" onKeyDown={applyDatesKeyDown}>
                                    {reportStructure.payrollDates
                                        .map(payrollDate =>
                                            <option key={payrollDate[0]} value={payrollDate[0]}>
                                                {payrollDate[0]}
                                            </option>
                                        )}
                                </InputSelect>
                                to
                                <InputSelect value={endDate ?? reportStructure.endDate} onChange={(e) => setEndDate(ElementCustomValue(e))} className="d-inline-block w-auto mx-2" onKeyDown={applyDatesKeyDown}>
                                    {reportStructure.payrollDates
                                        .map(payrollDate =>
                                            <option key={payrollDate[1]} value={payrollDate[1]}>
                                                {payrollDate[1]}
                                            </option>
                                        )}
                                </InputSelect>
                                <Button type="button" size="sm" onClick={applyDates}>&crarr; Go</Button>
                            </div>
                            <h4 className="text-center d-none d-print-block">
                                {reportStructure.startDate} to {reportStructure.endDate}
                            </h4>
                        </>
                        :
                        null
                    }
                </Col>
            </Row>
            {!!reportStructure ?
                <Row className="mt-3 small fill-height">
                    <Col className="fill-height horizontal-scroll">
                        <Table bordered size="sm" className="table-scrollable table-sortable" style={{minWidth: "60em"}}>
                            <thead>
                            <tr className="table-secondary">
                                <th onClick={() => setSortColumn(SortColumnUpdate('fullname', sortColumn))}>Name</th>
                                <th className="text-right td-md border-left-bold" onClick={() => setSortColumn(SortColumnUpdate('total_pay', sortColumn))}>Total
                                    Hours
                                </th>
                                <th className="text-right td-md" onClick={() => setSortColumn(SortColumnUpdate('total_pay_reg', sortColumn))}>Pay
                                    Regular
                                </th>
                                <th className="text-right td-md" onClick={() => setSortColumn(SortColumnUpdate('total_pay_ot', sortColumn))}>Pay
                                    Overtime
                                </th>
                                <th className="text-right td-md border-left-bold" onClick={() => setSortColumn(SortColumnUpdate('total_bill_reg', sortColumn))}>Billable
                                    Regular
                                </th>
                                <th className="text-right td-md" onClick={() => setSortColumn(SortColumnUpdate('total_bill_ot', sortColumn))}>Billable
                                    Overtime
                                </th>
                                <th className="text-right td-md" onClick={() => setSortColumn(SortColumnUpdate('totalUnbillable', sortColumn))}>Unbillable
                                </th>
                                <th className="text-right td-md border-left-bold" onClick={() => setSortColumn(SortColumnUpdate('total_vacation', sortColumn))}>Vacation
                                </th>
                                <th className="text-right td-md" onClick={() => setSortColumn(SortColumnUpdate('total_illness', sortColumn))}>Illness
                                </th>
                                <th className="text-right td-md" onClick={() => setSortColumn(SortColumnUpdate('total_holiday', sortColumn))}>Holiday
                                </th>
                                <th className="text-right td-md" onClick={() => setSortColumn(SortColumnUpdate('total_admin', sortColumn))}>Admin
                                </th>
                            </tr>
                            <tr className="text-right strong">
                                <td>Totals:</td>
                                <td className="td-md border-left-bold">{ToDigitsBlank(total_pay, 2)}</td>
                                <td className="td-md">{ToDigitsBlank(total_pay_reg, 2)}</td>
                                <td className="td-md">{ToDigitsBlank(total_pay_ot, 2)}</td>
                                <td className="td-md border-left-bold">{ToDigitsBlank(total_bill_reg, 2)}</td>
                                <td className="td-md">{ToDigitsBlank(total_bill_ot, 2)}</td>
                                <td className="td-md">{ToDigitsBlank(totalUnbillable, 2)}</td>
                                <td className="td-md border-left-bold">{ToDigitsBlank(total_vacation, 2)}</td>
                                <td className="td-md">{ToDigitsBlank(total_illness, 2)}</td>
                                <td className="td-md">{ToDigitsBlank(total_holiday, 2)}</td>
                                <td className="td-md">{ToDigitsBlank(total_admin, 2)}</td>
                            </tr>
                            </thead>
                            <tbody>
                            {timeList.map(timeItem =>
                                <tr key={timeItem.employeeNo} onClick={() => {
                                }}>
                                    <td>{timeItem.fullname}</td>
                                    <td className="text-right td-md border-left-bold">{ToDigitsBlank(timeItem.total_pay, 2)}</td>
                                    <td className="text-right td-md">{ToDigitsBlank(timeItem.total_pay_reg, 2)}</td>
                                    <td className="text-right td-md">{ToDigitsBlank(timeItem.total_pay_ot, 2)}</td>
                                    <td className="text-right td-md border-left-bold">{ToDigitsBlank(timeItem.total_bill_reg, 2)}</td>
                                    <td className="text-right td-md">{ToDigitsBlank(timeItem.total_bill_ot, 2)}</td>
                                    <td className="text-right td-md">{ToDigitsBlank(timeItem.totalUnbillable, 2)}</td>
                                    <td className="text-right td-md border-left-bold">{ToDigitsBlank(timeItem.total_vacation, 2)}</td>
                                    <td className="text-right td-md">{ToDigitsBlank(timeItem.total_illness, 2)}</td>
                                    <td className="text-right td-md">{ToDigitsBlank(timeItem.total_holiday, 2)}</td>
                                    <td className="text-right td-md">{ToDigitsBlank(timeItem.total_admin, 2)}</td>
                                </tr>
                            )}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                :
                null
            }
        </>
    );
};

export default EmpTime;

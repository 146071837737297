import React, {useEffect, useMemo, useRef, useState} from 'react';
import {
    Col,
    Row
} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import axios, {CancelTokenSource} from "axios";
import {APIProcess, ShowActivityOverlay, HideActivityOverlay} from '@denjpeters/intelliwakereact';
import {ITimeDataSet, ITimeDataUser} from "../../Data/Views/Time";
import PayPeriodNav from "../Generics/PayPeriodNav";
import {IWake} from "../../IWake";
import TimeSheets from "./TimeSheets";

const TimeSheetsPayroll = () => {
    const dispatch = useDispatch();
    const isMounted = useRef(true);
    const {user, appSessionRemembersChange} = useSelector((state: AppState) => state);
    const [timeDataSet, setTimeDataSet] = useState(null as ITimeDataSet | null);
    const [forceRefresh, setForceRefresh] = useState(false);

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    useEffect(() => {
        let cancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();
        isMounted.current = true;

        ShowActivityOverlay()(dispatch);
        APIProcess('time', 'GetPRDatum', {}, cancelTokenSource)(iWake)
            .then((results) => {
                if (isMounted.current && cancelTokenSource) {
                    const loadedTimeDataSet = {
                        ...results,
                        timeDataUsers: [
                            ...results.timeDataUsers.sort((a: ITimeDataUser, b: ITimeDataUser) => a.user.lastname.localeCompare(b.user.lastname, undefined, {sensitivity: 'base'}) ?? a.user.firstname.localeCompare(b.user.firstname, undefined, {sensitivity: 'base'}) ?? a.curDate.localeCompare(b.curDate, undefined, {sensitivity: 'base'}))
                        ]
                    } as ITimeDataSet;

                    setTimeDataSet(loadedTimeDataSet);
                }
            })
            .catch(() => {
                if (isMounted.current && cancelTokenSource) {
                    setTimeDataSet(null);
                }
            })
            .finally(() => {
                HideActivityOverlay()(dispatch);
                cancelTokenSource = null;
            });

        return () => {
            isMounted.current = false;
            if (cancelTokenSource) {
                cancelTokenSource.cancel();
                cancelTokenSource = null;
            }
        }
    }, [appSessionRemembersChange.viewUserID, appSessionRemembersChange.viewDate, forceRefresh, dispatch, user, iWake]);

    return (
        <>
            {!!timeDataSet ?
                <>
                    <Row className="mb-1">
                        <Col xs={6}>
                            <PayPeriodNav endDate={timeDataSet.endDate ?? ""} periodUpdated={() => setForceRefresh(!forceRefresh)}/>
                        </Col>
                    </Row>
                    <Row className="fill-height-scroll">
                        <Col>
                            <TimeSheets timeDataGeneric={timeDataSet.timeDataGeneric} timeDataUsers={timeDataSet.timeDataUsers} forceRefresh={() => setForceRefresh(prevState => !prevState)}/>
                        </Col>
                    </Row>
                </>
                :
                null
            }
        </>
    );
};

export default TimeSheetsPayroll;

import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import {Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import axios, {CancelTokenSource} from "axios";
import {APIProcess, GetPathComponentAfter, ClassNames, ActivityOverlayControl} from '@denjpeters/intelliwakereact';
import {initialtblproject, Itblproject} from "../../Data/Tables/tblproject";
import {HasFeature, TTSFeatures} from "../../Data/TTSFeatures";
import ProjectData from "./ProjectData";
import {Itblcustomer} from "../../Data/Tables/tblcustomer";
import ProjectBudget from "./ProjectBudget";
import {Itblproject_budget} from "../../Data/Tables/tblproject_budget";
import {Itblactivitycode} from "../../Data/Tables/tblactivitycode";
import {IUsers} from "../../Data/Views/Users";
import ProjectApprovals from "./ProjectApprovals";
import {Itblprojectsuper} from "../../Data/Tables/tblprojectsuper";
import {IWake} from "../../IWake";

export interface IProjectBudgets extends Itblproject_budget {
    fullname: string,
    actual_hours: number,
    activityname: string
}

export interface IMissingActuals {
    activityID: number,
    activityname: string,
    actual_hours: number,
    fullname: string,
    userID: number
}

interface IProjectStructure {
    project: Itblproject,
    customers: Itblcustomer[],
    project_budgets: IProjectBudgets[],
    missing_actuals: IMissingActuals[],
    tblactivitycodes: Itblactivitycode[],
    tblusers: IUsers[],
    tblprojectsupers: Itblprojectsuper[],
    tblusers_timesheet_approval: IUsers[],
    tblusers_project_reports: IUsers[]
}

const initialProjectStructure: IProjectStructure = {
    project: initialtblproject,
    customers: [],
    project_budgets: [],
    missing_actuals: [],
    tblactivitycodes: [],
    tblusers: [],
    tblprojectsupers: [],
    tblusers_timesheet_approval: [],
    tblusers_project_reports: []
}

const Project = () => {
    const dispatch = useDispatch();
    const isMounted = useRef(true);
    const {user} = useSelector((state: AppState) => state);
    const [forceRefresh, setForceRefresh] = useState(false);
    const [projectStructure, setProjectStructure] = useState(null as IProjectStructure | null);
    const [activeTab, setActiveTab] = useState('Data'); // Data

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    const doForceRefresh = () => {
        setForceRefresh(!forceRefresh);
    }

    const toggle = (tab: string) => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    const setTblproject = (tblProject: Itblproject) => {
        setProjectStructure({
            ...projectStructure,
            project: tblProject
        } as IProjectStructure);
    }

    useEffect(() => {
        let cancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();
        isMounted.current = true;

        APIProcess('project', 'Get', {projectID: GetPathComponentAfter('Project') ?? 0}, cancelTokenSource)(iWake)
            .then((results) => {
                if (isMounted.current && cancelTokenSource) {
                    // console.log(results);
                    setProjectStructure({
                        ...initialProjectStructure,
                        ...results
                    });
                }
            })
            .catch(() => {
                if (isMounted.current && cancelTokenSource) {
                    setProjectStructure(initialProjectStructure);
                }
            })
            .finally(() => {
                cancelTokenSource = null;
            });

        return () => {
            isMounted.current = false;
            if (cancelTokenSource) {
                cancelTokenSource.cancel();
                cancelTokenSource = null;
            }
        }
    }, [iWake, forceRefresh]);

    const isSavedProject = useMemo(() => {
        return (!!projectStructure && projectStructure.project.projectID > 0);
    }, [projectStructure]);

    const computedTab = useMemo(() => {
        if (isSavedProject) {
            return activeTab;
        } else {
            return 'Data';
        }
    }, [isSavedProject, activeTab]);

    return (
        <Container>
            {!!projectStructure ?
                <>
                    <Row className="p-2">
                        <Col>
                            <h4>Project: {projectStructure.project.projectNo} - {projectStructure.project.name}</h4>
                        </Col>
                    </Row>
                    <Row className="p-2 fill-height">
                        <Col className="fill-height">
                            <Nav tabs>
                                <NavItem>
                                    <NavLink className={ClassNames({active: computedTab === 'Data'})} onClick={() => {
                                        toggle('Data');
                                    }}>
                                        Data
                                    </NavLink>
                                </NavItem>
                                {isSavedProject ?
                                    <>
                                        {HasFeature(user, TTSFeatures.Feature_Project_Admin) ?
                                            <NavItem>
                                                <NavLink className={ClassNames({active: computedTab === 'Approvals'})} onClick={() => {
                                                    toggle('Approvals');
                                                }}>
                                                    Approvals
                                                </NavLink>
                                            </NavItem>
                                            :
                                            null
                                        }
                                        <NavItem>
                                            <NavLink className={ClassNames({active: computedTab === 'Budget'})} onClick={() => {
                                                toggle('Budget');
                                            }}>
                                                Budget
                                            </NavLink>
                                        </NavItem>
                                    </>
                                    :
                                    null
                                }
                            </Nav>
                            <TabContent activeTab={computedTab} className="fill-height-scroll pt-4">
                                <TabPane tabId="Data">
                                    <ProjectData tblproject={projectStructure.project} setTblproject={setTblproject} customers={projectStructure.customers}/>
                                </TabPane>
                                {isSavedProject ?
                                    <>
                                        {HasFeature(user, TTSFeatures.Feature_Project_Admin) ?
                                            <TabPane tabId="Approvals">
                                                <ProjectApprovals tblproject={projectStructure.project} setTblproject={setTblproject} tblusers={projectStructure.tblusers} tblprojectsupers={projectStructure.tblprojectsupers} tblusers_timesheet_approval={projectStructure.tblusers_timesheet_approval} tblusers_project_reports={projectStructure.tblusers_project_reports} doForceRefresh={doForceRefresh}/>
                                            </TabPane>
                                            :
                                            null
                                        }
                                        <TabPane tabId="Budget">
                                            <ProjectBudget tblproject={projectStructure.project} setTblproject={setTblproject} project_budgets={projectStructure.project_budgets} missing_actuals={projectStructure.missing_actuals} doForceRefresh={doForceRefresh} tblactivitycodes={projectStructure.tblactivitycodes} tblusers={projectStructure.tblusers}/>
                                        </TabPane>
                                    </>
                                    :
                                    null
                                }
                            </TabContent>
                        </Col>
                    </Row>
                </>
                :
                null
            }
            <ActivityOverlayControl show={!projectStructure}/>
        </Container>
    );
};

export default Project;

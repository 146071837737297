import React, {useEffect, useMemo, useRef, useState} from 'react';
import {
    Button,
    Col,
    Row, Table
} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import axios, {CancelTokenSource} from "axios";
import {APIProcess, MasterDetail, MDMaster, MDLink, ToDigitsBlank, ActivityOverlayControl, MDDetail, GetPathComponentAfter} from '@denjpeters/intelliwakereact';
import ProjectDD from "../Generics/ProjectDD";
import {useHistory} from 'react-router-dom';
import {IWake} from "../../IWake";
import {Itblinvoice} from "../../Data/Tables/tblinvoice";
import Invoice from "./Invoice";

interface IInvoiceList extends Itblinvoice {
    status_desc: string,
    total_hours: number,
}

interface IReportStructure {
    invoices: IInvoiceList[]
}

const Invoices = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const isMounted = useRef(true);
    const {user, appSessionRemembersChange} = useSelector((state: AppState) => state);
    const [reportStructure, setReportStructure] = useState(null as IReportStructure | null);
    const [forceUpdate, setForceUpdate] = useState(false);

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    const doForceUpdate = () => {
        setForceUpdate(prevState => !prevState);
    }

    useEffect(() => {
        let cancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();
        isMounted.current = true;

        APIProcess('reports', 'Invoices', {
            projectID: appSessionRemembersChange.viewProjectID ?? 0
        }, cancelTokenSource)(iWake)
            .then((results) => {
                // console.log(results);
                if (isMounted.current && cancelTokenSource) {
                    setReportStructure(results);
                }
            })
            .catch(() => {
                if (isMounted.current && cancelTokenSource) {
                    setReportStructure(null);
                }
            })
            .finally(() => {
                cancelTokenSource = null;
            });

        return () => {
            isMounted.current = false;
            if (cancelTokenSource) {
                cancelTokenSource.cancel();
                cancelTokenSource = null;
            }
        }
    }, [appSessionRemembersChange.viewProjectID, forceUpdate, dispatch, iWake]);

    const openNewInvoice = () => {
        if (!!reportStructure) {
            history.push("/Invoicing/ProjectID/" + appSessionRemembersChange.viewProjectID);
        }
    }

    return (
        <>
            <Row className="d-print-none border-bottom">
                <Col>
                    <h3 className="text-center position-relative">
                        Invoices </h3>
                    <h4 className="text-center"><ProjectDD/></h4>
                </Col>
            </Row>
            {!!reportStructure ?
                <MasterDetail breakAt="sm" mdPath="/Invoices" backText="Invoices">
                    <MDMaster width="22em">
                        <Row className="p-2">
                            <Col>
                                <h4 className="text-center">
                                    Invoices
                                    <Button type="button" color="link" style={{
                                        position: "absolute",
                                        right: "1em"
                                    }} onClick={openNewInvoice}>
                                        New
                                    </Button>
                                </h4>
                            </Col>
                        </Row>
                        <Row className="fill-height">
                            <Col className="fill-height">
                                <Table hover bordered size="sm" className="table-scrollable small">
                                    <thead>
                                    <tr className="table-secondary">
                                        <th className="td-lg">Start</th>
                                        <th className="td-lg">End</th>
                                        <th>Status</th>
                                        <th className="text-right td-md">Hours</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {reportStructure.invoices.map(invoice =>
                                        <MDLink tag="tr" key={invoice.invoiceID} panel="Invoice" id={invoice.invoiceID}>
                                            <td className="td-lg">{invoice.start_date}</td>
                                            <td className="td-lg">{invoice.end_date}</td>
                                            <td>{invoice.status_desc}</td>
                                            <td className="text-right td-md">{ToDigitsBlank(invoice.total_hours, 2)}</td>
                                        </MDLink>
                                    )}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        <ActivityOverlayControl show={!reportStructure}/>
                    </MDMaster>
                    <MDDetail panel="Invoice" titleText="Project Invoice">
                        <Invoice invoiceID={GetPathComponentAfter('/Invoice')} forceUpdate={doForceUpdate}/>
                    </MDDetail>
                </MasterDetail>
                :
                <ActivityOverlayControl show/>
            }
        </>
    );
};

export default Invoices;

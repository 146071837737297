import React, {useMemo, useState} from 'react';
import {Link} from "react-router-dom";
import {Button, Col, ListGroup, ListGroupItem, Row} from "reactstrap";
import {GetPathThrough, MasterDetail, MDMaster, MDDetail, MDLink, GetPathComponentAfter} from '@denjpeters/intelliwakereact';
import ActiveDD from "../Generics/ActiveDD";
import {initialtbluser_file_type, Itbluser_file_type} from "../../Data/Tables/tbluser_file_type";
import FileType from "./FileType";
import {Itbluser_file_type_group} from "../../Data/Tables/tbluser_file_type_group";

interface IProps {
    tbluser_file_type_group: Itbluser_file_type_group,
    tbluser_file_type_groups: Itbluser_file_type_group[],
    tbluser_file_types: Itbluser_file_type[],
    updateType: ((tbluser_file_type: Itbluser_file_type) => void),
    forceRefresh: (() => void)
}

const FileTypes = (props: IProps) => {
    const [activeView, setActiveView] = useState(1 as 0 | 1 | -1);

    const selectedGroupID = parseInt(GetPathComponentAfter('FileType') ?? 0);

    const type = useMemo(() => (props.tbluser_file_types.find(type => type.id === selectedGroupID) ?? initialtbluser_file_type) as Itbluser_file_type, [props.tbluser_file_types, selectedGroupID]);

    const types = useMemo(() =>
            props.tbluser_file_types
                .filter(type => activeView === -1 || type.active === activeView)
                .sort((a, b) => a.sort_order - b.sort_order)
        , [props.tbluser_file_types, activeView]);

    return (
            <MasterDetail breakAt="sm" mdPath="/FileTypes" backText="File Types" className="border-top">
                <MDMaster width="15em">
                    <Row className="p-2">
                        <Col>
                            <ActiveDD size="sm" handleSelectID={setActiveView} selectedID={activeView} caret/>
                        </Col>
                        <Col className="text-right">
                            <Button color="secondary" size="sm" tag={Link} to={GetPathThrough("/FileTypes") + "/FileType/New"}>Add</Button>
                        </Col>
                    </Row>
                    <ListGroup flush className="fill-height-scroll text-large-md-smaller">
                        {types.length === 0 ?
                                <ListGroupItem>No Types</ListGroupItem>
                                :
                                types.map((fileType) =>
                                    <MDLink tag="li" panel="FileType" className={"list-group-item list-group-item-action py-1" + (fileType.active === 1 ? "" : " text-gray")} key={fileType.id} id={fileType.id}>
                                        <span>{fileType.name}</span>
                                    </MDLink>
                                )
                        }
                    </ListGroup>
                </MDMaster>
                <MDDetail panel="FileType" titleText="File Type">
                    <FileType tbluser_file_type_group={props.tbluser_file_type_group} tbluser_file_type_groups={props.tbluser_file_type_groups} tbluser_file_type={type} updateType={props.updateType} forceRefresh={props.forceRefresh}/>
                </MDDetail>
            </MasterDetail>
    );
};

export default FileTypes;

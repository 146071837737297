import React, {FormEvent, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import {
    Button,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon, InputGroupText,
    Label
} from "reactstrap";
import {
    APIProcess,
    ShowActivityOverlay,
    ShowMessageBox,
    HideActivityOverlay,
    ElementCustomValue,
    InputEmail,
    InputTel,
    InputDate,
    InputSelect,
    ActivityOverlayControl,
    InputText, InputState, InputZip
} from '@denjpeters/intelliwakereact';
import {Update_User} from "../../Stores/user/actions";
import BlockNav from "../Generics/BlockNav";
import {faEnvelope, faMapPin, faPhone} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelopeOpen, faMobile, faSave} from "@fortawesome/pro-regular-svg-icons";
import {UserUpdateState} from "../../Stores/user/types";
import {Itbluser} from "../../Data/Tables/tbluser";
import {HasFeature, TTSFeatures} from "../../Data/TTSFeatures";
import {IWake} from "../../IWake";

interface IProps {
    tbluser: Itbluser,
    setTbluser: ((tbluser: Itbluser) => void),
    updateUser?: Function | undefined,
    doForceRefresh: (() => void)
}

const UserProfile = (props: IProps) => {
    const dispatch = useDispatch();
    const isMounted = useRef(true);
    const [submitAttempted, setSubmitAttempted] = useState(false);
    const [blockNavigation, setBlockNavigation] = useState(false);
    const user = useSelector((state: AppState) => state.user);

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    const sendTempPassword = () => {
        if (!!props.tbluser && !!props.tbluser.userID && !!props.tbluser.email && !!props.tbluser.firstname && !!props.tbluser.lastname) {
            ShowActivityOverlay()(dispatch);
            APIProcess('User', 'SendTempPassword', {username: props.tbluser.username})(iWake)
                .then(() => {
                    if (isMounted.current) {
                        ShowMessageBox('Temporary password sent')(dispatch);
                    }
                })
                .catch(() => {
                })
                .finally(() => {
                    HideActivityOverlay()(dispatch);
                });
        }
    }

    const formSubmit = (e: FormEvent) => {
        e.preventDefault();

        setSubmitAttempted(true);

        if (!!props.tbluser) {
            if (props.tbluser.email && props.tbluser.firstname && props.tbluser.lastname) {
                ShowActivityOverlay()(dispatch);
                APIProcess('User', 'Save', props.tbluser)(iWake)
                    .then((result) => {
                        if (isMounted.current) {
                            setSubmitAttempted(false);
                            setBlockNavigation(false);
                            const newState = {...props.tbluser, userID: result.userID, blockNavigation: false};
                            props.setTbluser(newState);
                            if (props.tbluser.userID === user.userID) {
                                Update_User({
                                    firstname: newState.firstname,
                                    lastname: newState.lastname,
                                    email: newState.email,
                                    smsphone: newState.smsphone
                                } as UserUpdateState)(dispatch);
                            }
                            if (props.updateUser instanceof Function) {
                                props.updateUser(newState);
                            }
                            ShowMessageBox('Saved')(dispatch);
                            props.doForceRefresh();
                        }
                    })
                    .catch(() => {
                    })
                    .finally(() => {
                        HideActivityOverlay()(dispatch);
                    });
            } else {
                props.setTbluser({
                    ...props.tbluser
                });

                ShowMessageBox('Name and e-mail address required', "danger")(dispatch);
            }
        }
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.setTbluser({
            ...props.tbluser,
            [e.target.name]: ElementCustomValue(e)
        } as Itbluser);

        setBlockNavigation(true);
    }

    useEffect(() => {
        setBlockNavigation(false);
        setSubmitAttempted(false);
    }, [props.tbluser.userID]);

    return (
        <>
            {!!props.tbluser ?
                <>
                    <Form onSubmitCapture={formSubmit} className="form-sm">
                        {HasFeature(user, [TTSFeatures.Feature_User_Admin]) ?
                            <>
                                <FormGroup>
                                    <Label>Login Name</Label>
                                    <div className="medium">
                                        <Input type="text" name="username" placeholder="Login Name" value={props.tbluser.username ?? ""} valid={false} invalid={submitAttempted && !(props.tbluser.username.length > 0)} onChange={handleInputChange}/>
                                        <FormFeedback valid={!submitAttempted && props.tbluser.username.length > 0}>Login
                                            name
                                            required</FormFeedback>
                                    </div>
                                    <div className="short form-control-plaintext">
                                        {props.tbluser.userID}
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <Label>First Name</Label>
                                    <div className="medium">
                                        <Input type="text" name="firstname" placeholder="First Name" value={props.tbluser.firstname ?? ""} valid={false} invalid={submitAttempted && !(props.tbluser.firstname.length > 0)} onChange={handleInputChange}/>
                                        <FormFeedback valid={!submitAttempted && props.tbluser.firstname.length > 0}>First
                                            name
                                            required</FormFeedback>
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Last Name</Label>
                                    <div className="medium">
                                        <Input type="text" name="lastname" placeholder="Last Name" value={props.tbluser.lastname ?? ""} valid={false} invalid={submitAttempted && !(props.tbluser.lastname.length > 0)} onChange={handleInputChange}/>
                                        <FormFeedback valid={!submitAttempted && props.tbluser.lastname.length > 0}>Last
                                            name
                                            required</FormFeedback>
                                    </div>
                                </FormGroup>
                            </>
                            :
                            null
                        }
                        <FormGroup>
                            <Label>GCI Email</Label>
                            <div className="medium">
                                <InputGroup>
                                    <InputEmail name="email" placeholder="E-mail address" value={props.tbluser.email ?? ""} valid={false} invalid={submitAttempted && !((props.tbluser.email ?? "").length > 0)} onChange={handleInputChange}/>
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText>
                                            <FontAwesomeIcon icon={faEnvelope} fixedWidth/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                                <FormFeedback valid={!submitAttempted && (props.tbluser.email ?? "").length > 0}>E-mail
                                    address required</FormFeedback>
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <Label>GCI Cell Phone</Label>
                            <div className="medium">
                                <InputGroup>
                                    <InputTel name="smsphone" placeholder="Cell Phone" value={props.tbluser.smsphone ?? ""} valid={false} onChange={handleInputChange}/>
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText>
                                            <FontAwesomeIcon icon={faMobile} fixedWidth/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <Label>Office Phone</Label>
                            <div className="medium">
                                <InputGroup>
                                    <InputTel name="officephone" placeholder="Office Phone" value={props.tbluser.officephone ?? ""} onChange={handleInputChange}/>
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText>
                                            <FontAwesomeIcon icon={faPhone} fixedWidth/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </div>
                        </FormGroup>
                        {HasFeature(user, [TTSFeatures.Feature_User_Admin]) ?
                            <>
                                <FormGroup>
                                    <Label>Work Location</Label>
                                    <div className="medium">
                                        <InputGroup>
                                            <Input type="text" name="location" placeholder="Location" value={props.tbluser.location ?? ""} onChange={handleInputChange}/>
                                            <InputGroupAddon addonType="append">
                                                <InputGroupText>
                                                    <FontAwesomeIcon icon={faMapPin} fixedWidth/>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Start Date</Label>
                                    <div className="medium">
                                        <InputDate name="startdate" placeholder="Start Date" value={props.tbluser.startdate ?? ""} onChange={handleInputChange}/>
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Termination Date</Label>
                                    <div className="medium">
                                        <InputDate name="termdate" placeholder="Termination Date" value={props.tbluser.termdate ?? ""} onChange={handleInputChange}/>
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Employee #</Label>
                                    <div className="short">
                                        <Input type="text" name="employeeNo" placeholder="Employee #" value={props.tbluser.employeeNo ?? ""} onChange={handleInputChange}/>
                                    </div>
                                    <div className="short">
                                        <small>
                                            Note: Must match PayChex for download to work
                                        </small>
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Payment Type</Label>
                                    <div className="short">
                                        <InputSelect name="payment_type" value={props.tbluser.payment_type ?? ""} onChange={handleInputChange}>
                                            <option value="">None</option>
                                            <option value="Hourly">Hourly</option>
                                            <option value="Salary">Salary</option>
                                        </InputSelect>
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Full/Part Time</Label>
                                    <div className="short">
                                        <InputSelect name="is_fulltime" value={props.tbluser.is_fulltime ?? 1} onChange={handleInputChange} isNumeric>
                                            <option value={1}>Full-Time</option>
                                            <option value={0}>Part-Time</option>
                                        </InputSelect>
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Client Email</Label>
                                    <div className="medium">
                                        <InputGroup>
                                            <InputEmail name="email_client" placeholder="Clients' e-mail address for employee" value={props.tbluser.email_client ?? ""} onChange={handleInputChange}/>
                                            <InputGroupAddon addonType="append">
                                                <InputGroupText>
                                                    <FontAwesomeIcon icon={faEnvelope} fixedWidth/>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Personal Email</Label>
                                    <div className="medium">
                                        <InputGroup>
                                            <InputEmail name="email_personal" placeholder="Personal e-mail address" value={props.tbluser.email_personal ?? ""} onChange={handleInputChange}/>
                                            <InputGroupAddon addonType="append">
                                                <InputGroupText>
                                                    <FontAwesomeIcon icon={faEnvelope} fixedWidth/>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Personal Phone</Label>
                                    <div className="medium">
                                        <InputGroup>
                                            <InputTel name="phone_personal" placeholder="Personal Phone" value={props.tbluser.phone_personal ?? ""} onChange={handleInputChange}/>
                                            <InputGroupAddon addonType="append">
                                                <InputGroupText>
                                                    <FontAwesomeIcon icon={faMobile} fixedWidth/>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Personal Address</Label>
                                    <div className="medium">
                                        <InputText name="address1" placeholder="Address line 1" value={props.tbluser.address1 ?? ""} onChange={handleInputChange}/>
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <div className="medium offset">
                                        <InputText name="address2" placeholder="Address line 2" value={props.tbluser.address2 ?? ""} onChange={handleInputChange}/>
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <Label>City</Label>
                                    <div className="medium">
                                        <InputText name="city" placeholder="City" value={props.tbluser.city ?? ""} onChange={handleInputChange}/>
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <Label>State, Zip</Label>
                                    <div className="short">
                                        <InputState name="state" placeholder="State" value={props.tbluser.state ?? ""} onChange={handleInputChange}/>
                                    </div>
                                    <div className="short">
                                        <InputZip name="zip" placeholder="Zip" value={props.tbluser.zip ?? ""} onChange={handleInputChange}/>
                                    </div>
                                </FormGroup>

                                <FormGroup>
                                    <Label>Enabled?</Label>
                                    <div className="short">
                                        <InputSelect name="disable" value={props.tbluser.disable ?? 0} onChange={handleInputChange} isNumeric>
                                            <option value={0}>Enabled</option>
                                            <option value={1}>Disabled</option>
                                        </InputSelect>
                                    </div>
                                </FormGroup>
                            </>
                            :
                            null
                        }
                        <FormGroup>
                            <div className="offset">
                                <Button color="primary" hidden={!blockNavigation}><FontAwesomeIcon icon={faSave} fixedWidth/> Save</Button>
                            </div>
                        </FormGroup>
                    </Form>
                    {(HasFeature(user, [TTSFeatures.Feature_User_Admin]) && !!props.tbluser && !!props.tbluser.userID && !blockNavigation) ?
                        <div className="form-sm">
                            <FormGroup>
                                <div className="offset">
                                    <Button color="info" onClick={sendTempPassword}><FontAwesomeIcon icon={faEnvelopeOpen} fixedWidth/> Send
                                        Temp
                                        Password</Button>
                                </div>
                            </FormGroup>
                        </div>
                        :
                        null
                    }
                </>
                :
                null
            }
            <ActivityOverlayControl show={!props.tbluser}/>
            <BlockNav when={blockNavigation}/>
        </>
    );
};

export default UserProfile;

import React, {FormEvent, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import {
    Button,
    Col, CustomInput, Form,
    FormGroup,
    Label, Modal, ModalBody, ModalFooter, ModalHeader
} from "reactstrap";
import {Itblproject} from "../../Data/Tables/tblproject";
import {Itblproject_budget} from "../../Data/Tables/tblproject_budget";
import {Itblactivitycode} from "../../Data/Tables/tblactivitycode";
import {IUsers} from "../../Data/Views/Users";
import {IProjectBudgets} from "./Project";
import {APIProcess, ElementCustomValue, ShowActivityOverlay, ShowMessageBox, HideActivityOverlay, InputSelect, InputNumber, InputDate} from '@denjpeters/intelliwakereact';
import {IWake} from "../../IWake";

interface IProps {
    doForceRefresh: (() => void),
    tblproject: Itblproject,
    tblproject_budget: Itblproject_budget | null,
    setTblProjectBudget: ((tblproject_budget: Itblproject_budget | null) => void),
    userName: string,
    tblactivitycodes: Itblactivitycode[],
    tblusers: IUsers[]
}

const ProjectBudgetEditModal = (props: IProps) => {
    const dispatch = useDispatch();
    const user = useSelector((state: AppState) => state.user);
    const [editProjectBudget, setEditProjectBudget] = useState(null as Itblproject_budget | null);
    const [changed, setChanged] = useState(false);

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    const handleInputChange = (e: React.ChangeEvent<any>) => {
        setEditProjectBudget({
            ...editProjectBudget,
            [e.target.name]: ElementCustomValue(e)
        } as IProjectBudgets);

        setChanged(true);
    };

    const submit = (e: FormEvent) => {
        e.preventDefault();

        ShowActivityOverlay()(dispatch);

        APIProcess('project_budget', 'Save', editProjectBudget)(iWake)
            .then(() => {
                props.doForceRefresh();
                props.setTblProjectBudget(null);
                ShowMessageBox('Project budget saved')(dispatch);
            })
            .catch(() => {
            })
            .finally(() => {
                HideActivityOverlay()(dispatch);
            });
    };

    const deleteBudget = (project_budgetID: number) => {
        ShowActivityOverlay()(dispatch);

        APIProcess('project_budget', 'Delete', {project_budgetID: project_budgetID})(iWake)
            .then(() => {
                props.doForceRefresh();
                props.setTblProjectBudget(null);
                ShowMessageBox('Project budget deleted')(dispatch);
            })
            .catch(() => {
            })
            .finally(() => {
                HideActivityOverlay()(dispatch);
            });
    };

    useEffect(() => {
        setChanged(!(!!props.tblproject_budget && !!props.tblproject_budget.project_budgetID));
        setEditProjectBudget(props.tblproject_budget);
    }, [props.tblproject_budget]);

    return (
        <Modal backdrop toggle={() => {
            props.setTblProjectBudget(null)
        }} isOpen={!!editProjectBudget}>
            <ModalHeader className="alert-info">
                Budget for: {props.userName}
            </ModalHeader>
            <Form onSubmitCapture={submit}>
                <ModalBody>
                    {!!editProjectBudget ?
                        <div className="form-sm">
                            <FormGroup>
                                <Label>Employee</Label>
                                <Col className="form-control-plaintext">{props.userName}</Col>
                            </FormGroup>
                            <FormGroup>
                                <Label>Activity</Label>
                                <Col>
                                    <InputSelect name="activityID" value={editProjectBudget.activityID ?? 0} onChange={handleInputChange} isNumeric>
                                        <option value={0}>Select an Activity Code...</option>
                                        {props.tblactivitycodes
                                            .filter(tblActivityCode => tblActivityCode.activityID === editProjectBudget!.activityID || tblActivityCode.active === 1)
                                            .sort((a, b) => (a.name ?? "").localeCompare(b.name ?? "", undefined, {sensitivity: 'base'}))
                                            .map(tblActivityCode =>
                                                <option key={tblActivityCode.activityID} value={tblActivityCode.activityID}>
                                                    {tblActivityCode.name}
                                                </option>
                                            )}
                                    </InputSelect>
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Label>Hours</Label>
                                <Col className="medium">
                                    <InputNumber name="hours" value={editProjectBudget.hours} onChange={handleInputChange} integerScale={10} decimalScale={2}/>
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Label>Start Date</Label>
                                <Col className="medium">
                                    <InputDate name="validity_date" value={editProjectBudget.validity_date ?? ""} onChange={handleInputChange}/>
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Label>End Date</Label>
                                <Col className="medium">
                                    <InputDate name="end_date" value={editProjectBudget.end_date ?? ""} onChange={handleInputChange}/>
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Label/>
                                <Col>
                                    <CustomInput type="switch" label="Active" name="active" id="projectbudgeteditmodalactive" checked={!!editProjectBudget.active} onChange={handleInputChange}/>
                                </Col>
                            </FormGroup>
                        </div>
                        :
                        null
                    }
                </ModalBody>
                <ModalFooter>
                    {!!editProjectBudget && !!editProjectBudget.project_budgetID ?
                        <Button type="button" color="danger" onClick={() => {
                            deleteBudget(editProjectBudget.project_budgetID)
                        }} className="mr-auto">Remove</Button>
                        :
                        null
                    }
                    <Button type="submit" color="info" className={changed ? "" : "invisible"}>
                        Save
                    </Button>
                    <Button type="button" color="secondary" onClick={() => {
                        props.setTblProjectBudget(null)
                    }}>Close</Button>
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export default ProjectBudgetEditModal;

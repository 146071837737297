import { createStore, applyMiddleware } from "redux";
import rootReducer, {loadState, saveState} from "./rootReducer";
import thunk from 'redux-thunk';

const persistedState = loadState();

const store = createStore(rootReducer, persistedState, applyMiddleware(thunk));

let storeTimeout: any = null;

store.subscribe(() => {
    clearTimeout(storeTimeout);
    storeTimeout = setTimeout(() => {
        saveState(store.getState());
    },1000);
});

export default store;

/**
 * Automatically generated: 2020-06-15 15:30:50
 * © 2020, Solid Basis Ventures, LLC.
 * DO NOT MODIFY
 *
 * Table Manager for: tblauth_role
 */

export interface Itblauth_role {
	auth_roleID: number,
	name: string,
	active: number
}

export const initialtblauth_role: Itblauth_role = {
	auth_roleID: 0,
	name: '',
	active: 1
};

import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import {APIProcess, ClassNames, Environments, GetPathComponentAfter, IsENV} from '@denjpeters/intelliwakereact';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import axios, {CancelTokenSource} from "axios";
import {initialtbluser, Itbluser} from "../../Data/Tables/tbluser";
import UserProfile from "./UserProfile";
import {Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import {
    faBookUser,
    faCoffee,
    faFileUser,
    faKeySkeleton,
    faThermometerHalf,
    faUser,
    faUserCircle
} from "@fortawesome/pro-solid-svg-icons";
import UserVacation from "./UserVacation";
import UserAuthorizations from "./UserAuthorizations";
import UserDocuments from "./UserDocuments";
import UserDiarys from "./UserDiarys";
import UserIllness from "./UserIllness";
import {Itblaccrualbank} from "../../Data/Tables/tblaccrualbank";
import {Itblaccrual} from "../../Data/Tables/tblaccrual";
import {Itblproject} from "../../Data/Tables/tblproject";
import {Itblauth_role} from "../../Data/Tables/tblauth_role";
import {HasFeature, TTSFeatures} from "../../Data/TTSFeatures";
import {faDollarSign} from "@fortawesome/pro-regular-svg-icons";
import UserPayHistory from "./UserPayHistory";
import {IWake} from "../../IWake";
import {ASRSetUserTab} from "../../Stores/appsessionremembers/actions";
import UserDelegates from "./UserDelegates";

interface IProps {
    userID?: number | null,
    updateUser?: Function | undefined
}

export interface IBankAnalysis {
    accrued: number,
    accruedPrev: number,
    accruedSum: number
    anniversaryNextDate: string,
    anniversaryStartDate: string,
    availToEOY: number,
    bankContribution: number,
    bankLimit: number,
    bankPrev: number,
    bankSum: number,
    hireDate: string,
    hoursForfeited: number,
    used: number,
    usedPrev: number,
    usedSum: number,
    var: number,
    varSum: number,
    year: number
}

export interface IAccrualsVacation {
    activityName: string,
    amount: number,
    date: string,
    userID: number
}

export interface IAccrualsIllness {
    activityName: string,
    amount: number,
    date: string,
    userID: number
}

export interface IAccrualValues {
    Vacation: number,
    Illness: number
}

export interface IProjectWithSuper extends Itblproject {
    projectsuperid: number,
    userid: number
}

interface IUserStructure {
    User: Itbluser,
    BankAnalysis: IBankAnalysis | null,
    AccrualsVacation: IAccrualsVacation[],
    AccrualsIllness: IAccrualsIllness[],
    AccrualBanks: Itblaccrualbank[],
    VacationAccruals: Itblaccrual[],
    IllnessAccruals: Itblaccrual[],
    AccrualValues: IAccrualValues,
    IllnessUsed: number,
    IllnessAdjustment: number,
    ProjectsWithSuper: IProjectWithSuper[],
    ProjectsWithoutSuper: Itblproject[],
    AuthRoles: Itblauth_role[],
    AuthRoleIDs: number[],
    TimeApprover: boolean
}

const initialUserStructure: IUserStructure = {
    User: initialtbluser,
    BankAnalysis: null,
    AccrualsVacation: [],
    AccrualsIllness: [],
    AccrualBanks: [],
    VacationAccruals: [],
    IllnessAccruals: [],
    AccrualValues: {Vacation: 0, Illness: 0},
    IllnessUsed: 0,
    IllnessAdjustment: 0,
    ProjectsWithSuper: [],
    ProjectsWithoutSuper: [],
    AuthRoles: [],
    AuthRoleIDs: [],
    TimeApprover: false
}

const User = (props: IProps) => {
    const dispatch = useDispatch();
    const isMounted = useRef(true);
    const followedRequest = useRef(false);
    const {user, appSessionRemembersChange} = useSelector((state: AppState) => state);
    const [forceRefresh, setForceRefresh] = useState(false);
    const [forceChangeTab, setForceChangeTab] = useState(false);
    const [userStructure, setUserStructure] = useState(null as IUserStructure | null);

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    const doForceRefresh = () => {
        setForceRefresh(!forceRefresh);
    }

    const activeTab = useMemo(() => {
        const requestedTab = GetPathComponentAfter('/User/' + props.userID);

        if (!!requestedTab && !followedRequest.current) {
            followedRequest.current = true;
            return requestedTab;
        }

        if (!followedRequest.current || forceChangeTab) {
            followedRequest.current = true;
        }

        return appSessionRemembersChange.userTab ?? (IsENV(Environments.ENV_Local) ? 'Profile' : 'Profile');
    }, [appSessionRemembersChange.userTab, props.userID, forceChangeTab])

    const setTbluser = (tblUser: Itbluser) => {
        setUserStructure({
            ...userStructure,
            User: tblUser
        } as IUserStructure);
    }

    useEffect(() => {
        let cancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();
        isMounted.current = true;

        APIProcess('User', 'Get', {id: props.userID})(iWake)
            .then((results) => {
                if (isMounted.current && cancelTokenSource) {
                    if (results.User.userID) {
                        // console.log(results);
                        setUserStructure({...results as IUserStructure});
                    } else {
                        setUserStructure(initialUserStructure);
                    }
                }
            })
            .catch(() => {
                if (isMounted.current && cancelTokenSource) {
                    setUserStructure(initialUserStructure);
                }
            })
            .finally(() => {
                cancelTokenSource = null;
            });

        return () => {
            isMounted.current = false;
            if (cancelTokenSource) {
                cancelTokenSource.cancel();
                cancelTokenSource = null;
            }
        }
    }, [iWake, props.userID, forceRefresh]);

    const isSavedUser = useMemo(() => {
        return (!!userStructure && userStructure.User.userID > 0);
    }, [userStructure]);

    const computedTab = useMemo(() => {
        if (isSavedUser) {
            return activeTab;
        } else {
            return 'Profile';
        }
    }, [isSavedUser, activeTab]);

    const toggle = (tab: string) => {
        if (computedTab !== tab) {
            if (tab === appSessionRemembersChange.userTab) {
                setForceChangeTab(prevState => !prevState);
            } else {
                ASRSetUserTab(tab)(dispatch);
            }
        }
    }

    return (
        <>
            {!!userStructure ?
                <>
                    <h4 className="text-center py-3">
                        {userStructure.User.firstname} {userStructure.User.lastname}
                    </h4>
                    <div className="fill-height">
                        <Nav tabs>
                            <NavItem>
                                <NavLink className={ClassNames({
                                    "ml-4": true,
                                    active: computedTab === 'Profile'
                                })} onClick={() => {
                                    toggle('Profile');
                                }}>
                                    <FontAwesomeIcon icon={faUser} fixedWidth/> User Data
                                </NavLink>
                            </NavItem>
                            {isSavedUser ?
                                <>
                                    <NavItem>
                                        <NavLink className={ClassNames({active: computedTab === 'Vacation'})} onClick={() => {
                                            toggle('Vacation');
                                        }}>
                                            <FontAwesomeIcon icon={faCoffee} fixedWidth/> Vacation
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={ClassNames({active: computedTab === 'Illness'})} onClick={() => {
                                            toggle('Illness');
                                        }}>
                                            <FontAwesomeIcon icon={faThermometerHalf} fixedWidth/> Illness
                                        </NavLink>
                                    </NavItem>
                                    {HasFeature(user, [TTSFeatures.Feature_User_Admin]) ?
                                        <>
                                            <NavItem>
                                                <NavLink className={ClassNames({active: computedTab === 'PayHistory'})} onClick={() => {
                                                    toggle('PayHistory');
                                                }}>
                                                    <FontAwesomeIcon icon={faDollarSign} fixedWidth/> Pay History
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className={ClassNames({active: computedTab === 'Authorizations'})} onClick={() => {
                                                    toggle('Authorizations');
                                                }}>
                                                    <FontAwesomeIcon icon={faKeySkeleton} fixedWidth/> Authorizations
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className={ClassNames({active: computedTab === 'Documents'})} onClick={() => {
                                                    toggle('Documents');
                                                }}>
                                                    <FontAwesomeIcon icon={faFileUser} fixedWidth/> Documents
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className={ClassNames({active: computedTab === 'UserDiarys'})} onClick={() => {
                                                    toggle('UserDiarys');
                                                }}>
                                                    <FontAwesomeIcon icon={faBookUser} fixedWidth/> Diary
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className={ClassNames({active: computedTab === 'Delegates'})} onClick={() => {
                                                    toggle('Delegates');
                                                }}>
                                                    <FontAwesomeIcon icon={faUserCircle} fixedWidth/> Delegates
                                                </NavLink>
                                            </NavItem>
                                        </>
                                        :
                                        null
                                    }
                                </>
                                :
                                null
                            }
                        </Nav>
                        <TabContent activeTab={computedTab} className="fill-height-scroll pt-4">
                            <TabPane tabId="Profile">
                                <UserProfile tbluser={userStructure.User} setTbluser={setTbluser} updateUser={props.updateUser} doForceRefresh={doForceRefresh}/>
                            </TabPane>
                            {isSavedUser ?
                                <>
                                    <TabPane tabId="Vacation">
                                        <UserVacation tbluser={userStructure.User} bankAnalysis={userStructure.BankAnalysis} accrualsVacation={userStructure.AccrualsVacation} tblAccrualBanks={userStructure.AccrualBanks} doForceRefresh={doForceRefresh} vacationAccruals={userStructure.VacationAccruals}/>
                                    </TabPane>
                                    <TabPane tabId="Illness">
                                        <UserIllness tbluser={userStructure.User} accrualsIllness={userStructure.AccrualsIllness} tblAccrualBanks={userStructure.AccrualBanks} doForceRefresh={doForceRefresh} illnessAccruals={userStructure.IllnessAccruals} accrualValues={userStructure.AccrualValues} illnessUsed={userStructure.IllnessUsed} illnessAdjustment={userStructure.IllnessAdjustment}/>
                                    </TabPane>
                                    {HasFeature(user, [TTSFeatures.Feature_User_Admin]) ?
                                        <>
                                            <TabPane tabId="PayHistory">
                                                <UserPayHistory tbluser={userStructure.User}/>
                                            </TabPane>
                                            <TabPane tabId="Authorizations">
                                                <UserAuthorizations tbluser={userStructure.User} timeApprover={userStructure.TimeApprover} doForceRefresh={doForceRefresh} AuthRoleIDs={userStructure.AuthRoleIDs} ProjectsWithSuper={userStructure.ProjectsWithSuper} ProjectsWithoutSuper={userStructure.ProjectsWithoutSuper} AuthRoles={userStructure.AuthRoles}/>
                                            </TabPane>
                                            {computedTab === "Documents" ?
                                                <TabPane tabId="Documents" className="fill-height d-flex">
                                                    <UserDocuments tbluser={userStructure.User} setTbluser={setTbluser} updateUser={props.updateUser}/>
                                                </TabPane>
                                                :
                                                null
                                            }
                                            {computedTab === "UserDiarys" ?
                                                <TabPane tabId="UserDiarys" className="fill-height d-flex">
                                                    <UserDiarys tbluser={userStructure.User} setTbluser={setTbluser} updateUser={props.updateUser}/>
                                                </TabPane>
                                                :
                                                null
                                            }
                                            {computedTab === "Delegates" ?
                                                <TabPane tabId="Delegates">
                                                    <UserDelegates tbluser={userStructure.User} setTbluser={setTbluser} updateUser={props.updateUser}/>
                                                </TabPane>
                                                :
                                                null
                                            }
                                        </>
                                        :
                                        null
                                    }
                                </>
                                :
                                null
                            }
                        </TabContent>
                    </div>
                </>
                :
                null
            }
        </>
    );
};

export default User;

import React, {useMemo} from 'react';
import {Itbluser} from "../../Data/Tables/tbluser";
import {Button, Col, CustomInput, FormGroup, Label, Row, Table} from "reactstrap";
import {Itblproject} from "../../Data/Tables/tblproject";
import {Itblauth_role} from "../../Data/Tables/tblauth_role";
import {IProjectWithSuper} from "./User";
import {APIProcess, ShowActivityOverlay, HideActivityOverlay, InputSelect, ElementCustomValue} from '@denjpeters/intelliwakereact';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import {IWake} from "../../IWake";

interface IProps {
    tbluser: Itbluser,
    timeApprover: boolean,
    ProjectsWithSuper: IProjectWithSuper[],
    ProjectsWithoutSuper: Itblproject[],
    AuthRoles: Itblauth_role[],
    AuthRoleIDs: number[],
    doForceRefresh: (() => void)
}

const UserAuthorizations = (props: IProps) => {
    const dispatch = useDispatch();
    const user = useSelector((state: AppState) => state.user);

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    const removeProjectSuper = (projectsuperid: number) => {
        ShowActivityOverlay()(dispatch);
        APIProcess('projectsuper', 'RemoveUser', {projectsuperid: projectsuperid})(iWake)
            .then(() => {
                props.doForceRefresh();
            })
            .catch(() => {
            })
            .finally(() => {
                HideActivityOverlay()(dispatch);
            });
    }

    const addProjectSuper = (projectid: any) => {
        ShowActivityOverlay()(dispatch);
        APIProcess('projectsuper', 'AddUser', {projectid: projectid, userid: props.tbluser.userID})(iWake)
            .then(() => {
                props.doForceRefresh();
            })
            .catch(() => {
            })
            .finally(() => {
                HideActivityOverlay()(dispatch);
            });
    }

    const changeAuthRole = (authRoleID: number, isChecked: boolean) => {
        ShowActivityOverlay()(dispatch);
        APIProcess('User', isChecked ? 'Auth_RoleAdd' : 'Auth_RoleRemove', {user_id: props.tbluser.userID, auth_role_id: authRoleID})(iWake)
            .then(() => {
                props.doForceRefresh();
            })
            .catch(() => {
            })
            .finally(() => {
                HideActivityOverlay()(dispatch);
            });
    }

    return (
        <Row>
            <Col className="form-sm">
                {(props.timeApprover || props.ProjectsWithSuper.length > 0) ?
                    <>
                        <FormGroup>
                            <Label>Supervisor Projects</Label>
                            <Col className="small">
                                <Table bordered size="sm" className="table-scrollable" style={{maxHeight: "25em"}}>
                                    <thead>
                                    <tr>
                                        <th colSpan={2}>Project</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {props.ProjectsWithSuper
                                        .sort((a, b) => (a.projectNo ?? "").localeCompare(b.projectNo ?? "", undefined, {sensitivity: 'base'}))
                                        .map(project =>
                                            <tr key={project.projectID}>
                                                <td>{project.projectNo} - {project.name}</td>
                                                <td className="td-md">
                                                    <Button color="link" className="btn-link-inline" onClick={() => {
                                                        removeProjectSuper(project.projectsuperid)
                                                    }}>Remove</Button>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Label>Add Supervisor Project</Label>
                            <Col>
                                <InputSelect value={0} onChange={(e) => {addProjectSuper(ElementCustomValue(e))}} isNumeric>
                                    <option value={0}>Add employee to project...</option>
                                    {props.ProjectsWithoutSuper
                                        .sort((a, b) => (a.projectNo ?? "").localeCompare(b.projectNo ?? "", undefined, {sensitivity: 'base'}))
                                        .map(project =>
                                        <option key={project.projectID} value={project.projectID}>{project.projectNo} - {project.name}</option>
                                        )}
                                </InputSelect>
                            </Col>
                        </FormGroup>
                    </>
                    :
                    null
                }
                <FormGroup>
                    <Label>Authorization Roles</Label>
                    <Col className="form-control-plaintext">
                        {props.AuthRoles
                            .sort((a, b) => (a.name ?? "").localeCompare(b.name ?? "", undefined, {sensitivity: 'base'}))
                            .map(authRole =>
                                <React.Fragment key={authRole.auth_roleID}>
                                    <CustomInput type="switch" id={"usrAR" + authRole.auth_roleID} label={authRole.name} checked={props.AuthRoleIDs.includes(authRole.auth_roleID)} onChange={(e) => {changeAuthRole(authRole.auth_roleID, e.target.checked)}}/>
                                </React.Fragment>
                            )
                        }
                    </Col>
                </FormGroup>
            </Col>
        </Row>
    );
};

export default UserAuthorizations;

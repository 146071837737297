import React, {useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import {Col, FormGroup, Label, Row} from "reactstrap";
import {IWake} from "../../IWake";
import {APIProcess, ElementCustomValue, ShowMessageBox, InputText, InputSwitch} from '@denjpeters/intelliwakereact';
import {Itbluser_diary_type} from "../../Data/Tables/tbluser_diary_type";

interface IProps {
    tbluser_diary_type: Itbluser_diary_type,
    updateType: ((tbluser_diary_type: Itbluser_diary_type) => void),
    forceRefresh: (() => void)
}

const DiaryType = (props: IProps) => {
    const dispatch = useDispatch();
    const {user} = useSelector((state: AppState) => state);
    const [changes, setChanges] = useState({} as { [key: string]: any });

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    const tbluser_diary_type = useMemo(() => {
        return {...props.tbluser_diary_type, ...changes}
    }, [props.tbluser_diary_type, changes]);

    const handleInputChange = (e: React.ChangeEvent<any>) => {
        const target: any = e.target;

        if (!!target) {
            const name = target.name;
            const value = ElementCustomValue(e);
            setChanges(prevState => {
                return {
                    ...prevState,
                    [name]: value
                }
            });
        }
    }

    const saveType = () => {
        if (Object.keys(changes).length > 0) {
            const updates = {...props.tbluser_diary_type, ...changes} as Itbluser_diary_type;

            APIProcess('UserDiaryType', 'SaveType', updates)(iWake)
                .then((result) => {
                    props.updateType({...updates, id: result.id});
                    setChanges({});
                    ShowMessageBox("Saved")(dispatch);
                })
                .catch(() => {
                })
                .finally(() => {
                });
        }
    }

    return (
        <Row className="fill-height">
            <Col className="fill-height">
                <Row className="p-4">
                    <Col className="form-sm" onBlur={saveType}>
                        <FormGroup>
                            <Label>Category Name</Label>
                            <Col className="medium">
                                <InputText name="name" onChange={handleInputChange} value={tbluser_diary_type.name}/>
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col className="offset">
                                <InputSwitch name="active" onChange={(e) => {
                                    handleInputChange(e)
                                }} value={tbluser_diary_type.active} label="Active"/>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default DiaryType;

import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import {Redirect} from "react-router-dom";
import {HasFeature, TTSFeatures} from "../../Data/TTSFeatures";
import {Button, Col, Container, Row, Table} from "reactstrap";
import {ASRSetInvoicingSettings} from "../../Stores/appsessionremembers/actions";
import {IWake} from "../../IWake";
import axios, {CancelTokenSource} from "axios";
import {APIProcess, ISortColumn, initialSortColumn, SearchSort, MasterDetail, MDMaster, InputSwitch, InputSearch, SortColumnUpdate, MDLink, TextToHTML, ToDigitsBlank, ActivityOverlayControl, MDDetail, GetPathComponentAfter} from '@denjpeters/intelliwakereact';
import {AppSettingsSetReportsInvoicingCollapsed} from "../../Stores/appsettings/actions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDoubleRight} from "@fortawesome/pro-regular-svg-icons";
import Invoice from "./Invoice";
import PMDD from "../Generics/PMDD";

interface IProjectInvoice {
    end_date: string,
    invoice_id: number,
    names: string,
    next_start_date: string,
    projectNo: string,
    start_date: string,
    status: number,
    status_desc: string,
    total_hours: number,
    invoice_notes: string,
    next_invoice_id: number,
    projectID: number
}

interface IReportStructure {
    projectnos: IProjectInvoice[]
}

export interface IInvoicingSettings {
    search: string,
    sortColumn: ISortColumn,
    pendingHours: boolean
}

export const initialInvoicingSettings: IInvoicingSettings = {
    search: '',
    sortColumn: {
        ...initialSortColumn,
        primarySort: "end_date"
    },
    pendingHours: true
}

const Invoicing = () => {
    const dispatch = useDispatch();
    const isMounted = useRef(true);
    const {user, appSettings, appSessionRemembersChange} = useSelector((state: AppState) => state);
    const [reportStructure, setReportStructure] = useState(null as IReportStructure | null);
    const [forceUpdate, setForceUpdate] = useState(false);

    const doForceUpdate = () => {
        setForceUpdate(prevState => !prevState);
    }

    const setSearch = (search: string) => {
        ASRSetInvoicingSettings({
            ...appSessionRemembersChange.invoicingSettings,
            search: search
        })(dispatch);
    }

    const setSortColumn = (sortColumn: ISortColumn) => {
        ASRSetInvoicingSettings({
            ...appSessionRemembersChange.invoicingSettings,
            sortColumn: sortColumn
        })(dispatch);
    }

    const togglePendingHours = () => {
        ASRSetInvoicingSettings({
            ...appSessionRemembersChange.invoicingSettings,
            pendingHours: !appSessionRemembersChange.invoicingSettings.pendingHours
        })(dispatch);
    }

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    useEffect(() => {
        let cancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();
        isMounted.current = true;

        APIProcess('Invoice', 'ProjectNos', {
            invoicingFor: appSessionRemembersChange.approvalUserID ?? user.userID
        }, cancelTokenSource)(iWake)
            .then((results) => {
                // console.log(results);
                if (isMounted.current && cancelTokenSource) {
                    setReportStructure(results);
                }
            })
            .catch(() => {
                if (isMounted.current && cancelTokenSource) {
                    setReportStructure(null);
                }
            })
            .finally(() => {
                cancelTokenSource = null;
            });

        return () => {
            isMounted.current = false;
            if (cancelTokenSource) {
                cancelTokenSource.cancel();
                cancelTokenSource = null;
            }
        }
    }, [iWake, forceUpdate, appSessionRemembersChange.approvalUserID, user]);

    const projectNoList = useMemo(() => {
        if (!reportStructure) return [];
        return SearchSort(reportStructure.projectnos.filter(projectNo => !appSessionRemembersChange.invoicingSettings.pendingHours || projectNo.total_hours > 0), appSessionRemembersChange.invoicingSettings.search, appSessionRemembersChange.invoicingSettings.sortColumn);
    }, [reportStructure, appSessionRemembersChange.invoicingSettings.search, appSessionRemembersChange.invoicingSettings.sortColumn, appSessionRemembersChange.invoicingSettings.pendingHours]);

    if (!HasFeature(user, TTSFeatures.Feature_Invoicing)) {
        return (
            <Redirect to="/TimeEntry"/>
        );
    }

    return (
        <Container fluid>
            <MasterDetail breakAt="sm" mdPath="/Invoicing" backText="Invoicing" rememberLast>
                <MDMaster className="lrExpanderDiv" width={`${appSettings.reportsInvoicingCollapsed ? 25 : 37}em`}>
                    <Row className="p-2 position-relative">
                        <Col>
                            <h4 className="text-center">
                                New Invoices
                                {HasFeature(user, TTSFeatures.Feature_User_Admin) ?
                                    <span> for: <PMDD includeAll/></span>
                                    :
                                    null
                                }
                            </h4>
                        </Col>
                        <Button type="button" size="sm" color="link" className="position-absolute lrExpanderButton" style={{transform: `rotate(${appSettings.reportsInvoicingCollapsed ? 180 : 0}deg)`}} onClick={() => AppSettingsSetReportsInvoicingCollapsed(!appSettings.reportsInvoicingCollapsed)(dispatch)}>
                            <FontAwesomeIcon icon={faChevronDoubleRight}/>
                        </Button>
                    </Row>
                    <Row className="p-1">
                        <Col className="text-center">
                            <InputSwitch name="pendingHours" onChange={togglePendingHours} value={appSessionRemembersChange.invoicingSettings.pendingHours ? 1 : 0} label="Only Pending Hours"/>
                        </Col>
                    </Row>
                    <Row className="p-1 form-condensed">
                        <Col>
                            <InputSearch triggerSearchText={setSearch} initialValue={appSessionRemembersChange.invoicingSettings.search} placeholder="Filter"/>
                        </Col>
                    </Row>
                    <Row className="fill-height">
                        <Col className="fill-height">
                            <Table hover bordered size="sm" className="table-sortable table-scrollable small">
                                <thead>
                                <tr className="table-secondary">
                                    <th className={appSettings.reportsInvoicingCollapsed ? "" : "td-md"} onClick={() => setSortColumn(SortColumnUpdate('projectNo', appSessionRemembersChange.invoicingSettings.sortColumn))}>No.</th>
                                    <th onClick={() => setSortColumn(SortColumnUpdate('names', appSessionRemembersChange.invoicingSettings.sortColumn))} className={appSettings.reportsInvoicingCollapsed ? "d-none" : ""}>Name</th>
                                    <th className="td-lg" onClick={() => setSortColumn(SortColumnUpdate('next_start_date', appSessionRemembersChange.invoicingSettings.sortColumn))}>Next
                                        Start
                                    </th>
                                    <th className="td-xl" onClick={() => setSortColumn(SortColumnUpdate('invoice_notes', appSessionRemembersChange.invoicingSettings.sortColumn))}>Inv
                                        Frequency
                                    </th>
                                    <th className="text-right td-md" onClick={() => setSortColumn(SortColumnUpdate('total_hours', appSessionRemembersChange.invoicingSettings.sortColumn))}>Hours</th>
                                </tr>
                                </thead>
                                <tbody>
                                {projectNoList.map(projectNo =>
                                    <MDLink tag="tr" key={projectNo.projectNo} panel="ProjectID" id={projectNo.projectID}>
                                        <td className={appSettings.reportsInvoicingCollapsed ? "" : "td-md"}>{projectNo.projectNo}</td>
                                        <td className={appSettings.reportsInvoicingCollapsed ? "d-none" : ""}>
                                            <span className="small">{TextToHTML(projectNo.names)}</span></td>
                                        <td className="td-lg">
                                            {projectNo.next_start_date}
                                            {!!projectNo.next_invoice_id ?
                                                <small className="text-muted">
                                                    <br/>Started
                                                </small>
                                                :
                                                null
                                            }
                                        </td>
                                        <td className="td-xl"><span className="small">{projectNo.invoice_notes}</span>
                                        </td>
                                        <td className="text-right td-md">{ToDigitsBlank(projectNo.total_hours, 2)}</td>
                                    </MDLink>
                                )}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <ActivityOverlayControl show={!reportStructure}/>
                </MDMaster>
                <MDDetail panel="ProjectID" titleText="Project Invoice" className="p-2">
                    <Invoice projectID={GetPathComponentAfter('ProjectID')} forceUpdate={doForceUpdate}/>
                </MDDetail>
            </MasterDetail>
        </Container>
    );
};

export default Invoicing;

import React from 'react';
import {useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import {Redirect} from "react-router-dom";
import {HasFeature, TTSFeatures} from "../../Data/TTSFeatures";
import {Col, Container, ListGroup, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAlarmExclamation,
    faCalendarAlt,
    faDollarSign, faFileTimes,
    faListAlt,
    faPiggyBank,
    faUserHardHat,
    faWrench as faWrenchOpen
} from "@fortawesome/pro-regular-svg-icons";
import VacaBank from "./VacaBank";
import {faFileInvoiceDollar, faSuitcase, faUsers, faWrench} from "@fortawesome/pro-solid-svg-icons";
import Projects from "./Projects";
import Project from "./Project";
import ProjectEmployee from "./ProjectEmployee";
import PayrollSummary from "./PayrollSummary";
import VacaIll from "./VacaIll";
import EmpTime from "./EmpTime";
import EmpTimeWeek from "./EmpTimeWeek";
import TimeSheetsPayroll from "../Time/TimeSheetsPayroll";
import Invoices from "../Invoice/Invoices";
import PayHistory from "./PayHistory";
import ExpiringDocs from "./ExpiringDocs";
import { MasterDetail, MDMaster, MDLink, MDDetail } from '@denjpeters/intelliwakereact';
import MissingDocs from "./MissingDocs";

export const ReportFeaturesRequired = [TTSFeatures.Feature_User_Admin, TTSFeatures.Feature_Payroll, TTSFeatures.Feature_Payroll_Reports, TTSFeatures.Feature_Invoicing, TTSFeatures.Feature_User_Vacation_Illness_View, TTSFeatures.Feature_Project_Reports, TTSFeatures.Feature_Salary_Reports];

const Reports = () => {
    const {user} = useSelector((state: AppState) => state);

    if (!HasFeature(user, ReportFeaturesRequired)) {
        return (
            <Redirect to="/TimeEntry"/>
        );
    }

    return (
        <Container fluid className="px-0">
            <MasterDetail breakAt="sm" mdPath="/Reports" backText="Reports" rememberLast>
                <MDMaster width="13em">
                    <Row className="p-2">
                        <Col>
                            <h4 className="text-center">Reports</h4>
                        </Col>
                    </Row>
                    <ListGroup flush className="fill-height-scroll text-large-md-smaller border-0">
                        {HasFeature(user, [TTSFeatures.Feature_Payroll_Reports, TTSFeatures.Feature_Payroll]) ?
                            <>
                                <MDLink tag="li" panel="PayrollSummary" className="list-group-item list-group-item-action border-0 py-1">
                                    <FontAwesomeIcon icon={faDollarSign} fixedWidth/>
                                    Payroll Summary
                                </MDLink>
                                <MDLink tag="li" panel="PayrollSheets" className="list-group-item list-group-item-action border-0 py-1">
                                    <FontAwesomeIcon icon={faListAlt} fixedWidth/>
                                    Payroll Sheets
                                </MDLink>
                            </>
                            :
                            null
                        }
                        {HasFeature(user, [TTSFeatures.Feature_Invoicing]) ?
                            <>
                                <MDLink tag="li" panel="Invoices" className="list-group-item list-group-item-action border-0 py-1 mt-4">
                                    <FontAwesomeIcon icon={faFileInvoiceDollar} fixedWidth/>
                                    Invoices
                                </MDLink>
                            </>
                            :
                            null
                        }
                        {HasFeature(user, [TTSFeatures.Feature_Project_Reports]) ?
                            <>
                                <MDLink tag="li" panel="Projects" className="list-group-item list-group-item-action border-0 py-1 mt-4">
                                    <FontAwesomeIcon icon={faWrench} fixedWidth/>
                                    Projects
                                </MDLink>
                                <MDLink tag="li" panel="Project" className="list-group-item list-group-item-action border-0 py-1">
                                    <FontAwesomeIcon icon={faWrenchOpen} fixedWidth/>
                                    Project
                                </MDLink>
                                <MDLink tag="li" panel="ProjectEmployee" className="list-group-item list-group-item-action border-0 py-1">
                                    <FontAwesomeIcon icon={faUserHardHat} fixedWidth/>
                                    Project/Employee
                                </MDLink>
                            </>
                            :
                            null
                        }
                        {HasFeature(user, [TTSFeatures.Feature_User_Admin, TTSFeatures.Feature_User_Vacation_Illness_View]) ?
                            <>
                                <MDLink tag="li" panel="EmpTime" className="list-group-item list-group-item-action border-0 py-1 mt-4">
                                    <FontAwesomeIcon icon={faUsers} fixedWidth/>
                                    Employee Time
                                </MDLink>
                                <MDLink tag="li" panel="EmpTimeWeek" className="list-group-item list-group-item-action border-0 py-1">
                                    <FontAwesomeIcon icon={faCalendarAlt} fixedWidth/>
                                    Employee Weekly
                                </MDLink>
                                <MDLink tag="li" panel="VacaIll" className="list-group-item list-group-item-action border-0 py-1">
                                    <FontAwesomeIcon icon={faSuitcase} fixedWidth/>
                                    Vaca / Ill Accrued
                                </MDLink>
                                <MDLink tag="li" panel="VacaBank" className="list-group-item list-group-item-action border-0 py-1">
                                    <FontAwesomeIcon icon={faPiggyBank} fixedWidth/>
                                    Vacation Bank
                                </MDLink>
                            </>
                            :
                            null
                        }
                        {HasFeature(user, [TTSFeatures.Feature_Payroll_Reports, TTSFeatures.Feature_Salary_Reports]) ?
                            <>
                                <MDLink tag="li" panel="PayHistory" className="list-group-item list-group-item-action border-0 py-1">
                                    <FontAwesomeIcon icon={faDollarSign} fixedWidth/>
                                    Salary History
                                </MDLink>
                            </>
                            :
                            null
                        }
                        {HasFeature(user, [TTSFeatures.Feature_User_Admin]) ?
                            <>
                                <MDLink tag="li" panel="MissingDocs" className="list-group-item list-group-item-action border-0 py-1">
                                    <FontAwesomeIcon icon={faFileTimes} fixedWidth/>
                                    Missing Docs
                                </MDLink>
                                <MDLink tag="li" panel="ExpiringDocs" className="list-group-item list-group-item-action border-0 py-1">
                                    <FontAwesomeIcon icon={faAlarmExclamation} fixedWidth/>
                                    Expiring Docs
                                </MDLink>
                            </>
                            :
                            null
                        }
                    </ListGroup>
                </MDMaster>
                {HasFeature(user, [TTSFeatures.Feature_Payroll_Reports, TTSFeatures.Feature_Payroll]) ?
                    <>
                        <MDDetail panel="PayrollSummary" titleText="Summary" className="p-2">
                            <PayrollSummary/>
                        </MDDetail>
                        <MDDetail panel="PayrollSheets" titleText="Payroll Sheets" className="p-2">
                            <TimeSheetsPayroll/>
                        </MDDetail>
                    </>
                    :
                    null
                }
                {HasFeature(user, [TTSFeatures.Feature_Invoicing]) ?
                    <>
                        <MDDetail panel="Invoices" titleText="Invoices" className="p-2">
                            <Invoices/>
                        </MDDetail>
                    </>
                    :
                    null
                }
                {HasFeature(user, [TTSFeatures.Feature_Project_Reports]) ?
                    <>
                        <MDDetail panel="Projects" titleText="Projects" className="p-2">
                            <Projects/>
                        </MDDetail>
                        <MDDetail panel="Project" titleText="Project" className="p-2">
                            <Project/>
                        </MDDetail>
                        <MDDetail panel="ProjectEmployee" titleText="Project/Employee" className="p-2">
                            <ProjectEmployee/>
                        </MDDetail>
                    </>
                    :
                    null
                }
                {HasFeature(user, [TTSFeatures.Feature_User_Admin, TTSFeatures.Feature_User_Vacation_Illness_View]) ?
                    <>
                        <MDDetail panel="EmpTime" titleText="Employee Time" className="p-2">
                            <EmpTime/>
                        </MDDetail>
                        <MDDetail panel="EmpTimeWeek" titleText="Employee Time Weekly" className="p-2">
                            <EmpTimeWeek/>
                        </MDDetail>
                        <MDDetail panel="VacaIll" titleText="Vaca / Ill" className="p-2">
                            <VacaIll/>
                        </MDDetail>
                        <MDDetail panel="VacaBank" titleText="Vacation Bank" className="p-2">
                            <VacaBank/>
                        </MDDetail>
                    </>
                    :
                    null
                }
                {HasFeature(user, [TTSFeatures.Feature_Payroll_Reports, TTSFeatures.Feature_Salary_Reports]) ?
                    <>
                        <MDDetail panel="PayHistory" titleText="Pay History" className="p-2">
                            <PayHistory/>
                        </MDDetail>
                    </>
                    :
                    null
                }
                {HasFeature(user, [TTSFeatures.Feature_User_Admin]) ?
                    <>
                        <MDDetail panel="MissingDocs" titleText="Missing Docs" className="p-2">
                            <MissingDocs/>
                        </MDDetail>
                        <MDDetail panel="ExpiringDocs" titleText="Expiring Docs" className="p-2">
                            <ExpiringDocs/>
                        </MDDetail>
                    </>
                    :
                    null
                }
            </MasterDetail>
        </Container>
    );
};

export default Reports;

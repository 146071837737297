import {
    AppSessionRemembers,
    AppSessionRemembersActionTypes,
    ASR_SET_APPROVALUSERID,
    ASR_SET_INVOICINGSETTINGS,
    ASR_SET_PASSWORDRESET,
    ASR_SET_PAYROLLDATE,
    ASR_SET_PROJECTACTIVE,
    ASR_SET_PROJECTSORT,
    ASR_SET_REPORTEMPTTIMEDATES,
    ASR_SET_USERTAB,
    ASR_SET_VIEWDATE,
    ASR_SET_VIEWPROJECTID,
    ASR_SET_VIEWUSERID
} from './types'
import moment from "moment";
import {initialInvoicingSettings} from "../../WebControls/Invoice/Invoicing";

export const initialAppSessionRemembersState: AppSessionRemembers = {
    requiresPasswordReset: false,
    viewDate: moment().format('YYYY-MM-DD'),
    viewUserID: null,
    viewProjectID: null,
    approvalUserID: null,
    projectSort: 'NoDesc',
    projectActive: 1,
    payrollDate: null,
    reportEmpTimeDateStart: null,
    reportEmpTimeDateEnd: null,
    userTab: null,
    invoicingSettings: initialInvoicingSettings
};

export function reducerAppSessionRemembers(
    state = initialAppSessionRemembersState,
    action: AppSessionRemembersActionTypes
): AppSessionRemembers {
    switch (action.type) {
        case ASR_SET_PASSWORDRESET: {
            return {
                ...state,
                requiresPasswordReset: action.payload
            };
        }
        case ASR_SET_VIEWDATE: {
            return {
                ...state,
                viewDate: action.payload
            };
        }
        case ASR_SET_VIEWUSERID: {
            return {
                ...state,
                viewUserID: action.payload
            };
        }
        case ASR_SET_VIEWPROJECTID: {
            return {
                ...state,
                viewProjectID: action.payload
            };
        }
        case ASR_SET_APPROVALUSERID: {
            return {
                ...state,
                approvalUserID: action.payload
            };
        }
        case ASR_SET_PROJECTSORT: {
            return {
                ...state,
                projectSort: action.payload
            };
        }
        case ASR_SET_PROJECTACTIVE: {
            return {
                ...state,
                projectActive: action.payload
            };
        }
        case ASR_SET_PAYROLLDATE: {
            return {
                ...state,
                payrollDate: action.payload
            };
        }
        case ASR_SET_REPORTEMPTTIMEDATES:
            return {
                ...state,
                reportEmpTimeDateStart: action.payload[0],
                reportEmpTimeDateEnd: action.payload[1]
            }
        case ASR_SET_USERTAB:
            return {
                ...state,
                userTab: action.payload
            }
        case ASR_SET_INVOICINGSETTINGS:
            return {
                ...state,
                invoicingSettings: action.payload
            }
        default:
            return state
    }
}

import React from 'react';
import {Col, Row} from "reactstrap";

const Footer = () => {
    return (
        <footer className="footer bg-light small d-print-none">
            <Row>
                <Col className="text-center d-xs-none-smaller">
                </Col>
                <Col className="text-center d-sm-none-smaller">GCI TTS</Col>
                <Col className="text-center">
                </Col>
            </Row>
        </footer>
    );
};

export default Footer;

/**
 * Automatically generated: 2020-06-15 15:30:50
 * © 2020, Solid Basis Ventures, LLC.
 * DO NOT MODIFY
 *
 * Table Manager for: tbluser
 */

export interface Itbluser {
	userID: number,
	firstname: string,
	lastname: string,
	username: string,
	email: string | null,
	email_client: string | null,
	email_personal: string | null,
	smsphone: string | null,
	officephone: string | null,
	phone_personal: string | null,
	address1: string | null,
	address2: string | null,
	city: string | null,
	state: string | null,
	zip: string | null,
	location: string | null,
	password: string | null,
	password_reset: number,
	password_temp: string | null,
	password_temp_expire: string | null,
	timezone: string | null,
	sick: number | null,
	vacation: number | null,
	hire_date: string | null,
	startdate: string | null,
	termdate: string | null,
	admin: number,
	admin_sys: number,
	disable: number,
	payment_type: string,
	is_fulltime: number,
	employeeNo: string | null
}

export const initialtbluser: Itbluser = {
	userID: 0,
	firstname: '',
	lastname: '',
	username: '',
	email: null,
	email_client: null,
	email_personal: null,
	smsphone: null,
	officephone: null,
	phone_personal: null,
	address1: null,
	address2: null,
	city: null,
	state: null,
	zip: null,
	location: null,
	password: null,
	password_reset: 0,
	password_temp: null,
	password_temp_expire: null,
	timezone: null,
	sick: 0.00,
	vacation: 0.00,
	hire_date: null,
	startdate: '',
	termdate: null,
	admin: 0,
	admin_sys: 0,
	disable: 0,
	payment_type: '',
	is_fulltime: 1,
	employeeNo: null
};

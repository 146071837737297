import React, {useEffect, useMemo, useRef, useState} from 'react';
import {
    Collapse,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    NavLink,
    UncontrolledDropdown
} from 'reactstrap';
import {Link, NavLink as NavLinkRR, useHistory} from 'react-router-dom';
import {HasFeature, IsAdmin, TTSFeatures} from "./Data/TTSFeatures";
import {Session_Logout} from "./Stores/user/actions";
import {AppState} from "./Stores/rootReducer";
import {useDispatch, useSelector} from "react-redux";
import logoWhiteTransparent from './Assets/GCILogo.png';
import logoSquare from './Assets/clearIcon.png';
import {
    APIProcess,
    initialMenuBackItem,
    ShowActivityOverlay,
    HideActivityOverlay,
    ShowMessageBox,
    IsENV,
    Environments,
    ClassNames,
    IsDevFocused
} from '@denjpeters/intelliwakereact';
import './NavBar.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBook, faChartLine, faClock, faKey, faMicrochip, faSignOut} from "@fortawesome/pro-regular-svg-icons";
import {
    faBooks,
    faBuilding,
    faCar,
    faCheck,
    faExternalLinkAlt,
    faFileInvoiceDollar,
    faFolderTree,
    faRoad,
    faSignIn,
    faTag,
    faUmbrellaBeach,
    faUser,
    faUsers,
    faVial,
    faWrench
} from "@fortawesome/pro-solid-svg-icons";
import axios, {CancelTokenSource} from "axios";
import {faDownload} from "@fortawesome/pro-duotone-svg-icons";
import {IWake} from "./IWake";
import {ReportFeaturesRequired} from "./WebControls/Reports/Reports";

const NavBar = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {user, menuBack} = useSelector((state: AppState) => state);
    const [menuBackItem, setMenuBackItem] = useState(initialMenuBackItem);
    const [isOpen, setIsOpen] = useState(false);

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    const logout = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();

        setIsOpen(false);

        ShowActivityOverlay()(dispatch);

        APIProcess('User', 'Logout')(iWake)
            .then(() => {
                HideActivityOverlay()(dispatch);
                Session_Logout()(dispatch);
            })
            .catch(() => {
                HideActivityOverlay()(dispatch);
            });
    }

    const runChronJobs = () => {
        setIsOpen(false);

        ShowActivityOverlay()(dispatch);

        APIProcess('System', 'ChronJobs')(iWake)
            .then(() => {
                ShowMessageBox('Chron Jobs ran')(dispatch);
            })
            .catch(() => {
            })
            .finally(() => {
                HideActivityOverlay()(dispatch);
            });
    }

    const reMigrate = () => {
        setIsOpen(false);

        ShowActivityOverlay()(dispatch);

        APIProcess('System', 'ReMigrate')(iWake)
            .then(() => {
                ShowMessageBox('Re-Migrated')(dispatch);
            })
            .catch(() => {
            })
            .finally(() => {
                HideActivityOverlay()(dispatch);
            });
    }

    const reDefinition = () => {
        setIsOpen(false);

        ShowActivityOverlay()(dispatch);

        APIProcess('System', 'ReDefinition')(iWake)
            .then(() => {
                ShowMessageBox('Re-Defined')(dispatch);
            })
            .catch(() => {
            })
            .finally(() => {
                HideActivityOverlay()(dispatch);
            });
    }

    useEffect(() => {
        const items = menuBack.menuBackItems.filter((item) => {
            return item.menuBackActive && item.menuBackButtonURL.length < window.location.pathname.length && window.location.pathname.startsWith(item.menuBackButtonURL);
        });

        if (items.length === 0) {
            setMenuBackItem(initialMenuBackItem);
        } else if (items.length === 1) {
            setMenuBackItem(items.find(() => true) ?? initialMenuBackItem);
        } else {
            let nextItem = initialMenuBackItem;

            for (const item of items) {
                if (nextItem.menuBackButtonURL.length < item.menuBackButtonURL.length) {
                    nextItem = item;
                }
            }

            setMenuBackItem(nextItem);
        }


    }, [menuBack]);

    const goBack = (url: string) => {
        window.sessionStorage.removeItem(url + '-LastURL');
        history.push(url);
    };

    return (
        <Navbar expand="lg" fixed="top" className={IsENV(Environments.ENV_Prod) ? "bg-light" : (IsENV([Environments.ENV_Test, Environments.ENV_Dev]) ? "alert-success" : "alert-primary")}>
            <div className={'backMenuTitle' + (menuBackItem.menuBackActive ? ' none-' + menuBackItem.menuDisplaySize : ' d-none')}>
                {menuBackItem.menuPageTitle ?? ''}
            </div>
            <NavLink onClick={() => {
                goBack(menuBackItem.menuBackButtonURL ?? '/')
            }} className={'backMenuButton' + (menuBackItem.menuBackActive ? ' none-' + menuBackItem.menuDisplaySize : ' d-none')}>
                &lt; {menuBackItem.menuBackButtonTitle ?? 'Back'}
            </NavLink>
            <NavbarBrand href="/" className={menuBackItem.menuBackActive ? 'invisible-' + menuBackItem.menuDisplaySize + '-smaller' : ''}>
                <img src={logoWhiteTransparent} alt="GCI" style={{height: "1.25em", objectFit: "contain"}}/>
            </NavbarBrand>
            <NavbarToggler onClick={() => {
                setIsOpen(!isOpen)
            }}/>
            <Collapse isOpen={isOpen} navbar>
                <Nav navbar hidden={!user.loggedIn} className="mr-auto">
                    <NavLinkRR to="/TimeEntry" className="nav-link mr-4" onClick={() => {
                        setIsOpen(false)
                    }}>
                        <FontAwesomeIcon icon={faClock} fixedWidth/>
                        Time Entry
                    </NavLinkRR>
                    <NavLinkRR to="/Mileage" className="nav-link mr-4" onClick={() => {
                        setIsOpen(false)
                    }}>
                        <FontAwesomeIcon icon={faCar} fixedWidth/>
                        Mileage
                    </NavLinkRR>
                    {HasFeature(user, [TTSFeatures.Feature_TimeSheet_Approval, TTSFeatures.Feature_Project_Reports]) ?
                        <NavLinkRR to="/Approvals" className="nav-link mr-4" onClick={() => {
                            setIsOpen(false)
                        }}>
                            <FontAwesomeIcon icon={faCheck} fixedWidth/>
                            Approvals
                        </NavLinkRR>
                        :
                        null
                    }
                </Nav>
                <Nav navbar className={ClassNames({"ml-auto": !user.loggedIn})}>
                    {user.loggedIn ?
                        <>
                            {HasFeature(user, TTSFeatures.Feature_User_Admin) ?
                                <NavLinkRR to="/Users" className="nav-link mr-4" onClick={() => {
                                    setIsOpen(false)
                                }}>
                                    <FontAwesomeIcon icon={faUsers} fixedWidth/>
                                    Users
                                </NavLinkRR>
                                :
                                null
                            }
                            {HasFeature(user, [TTSFeatures.Feature_Project_Admin, TTSFeatures.Feature_Project_Maintain]) ?
                                <NavLinkRR to="/Projects" className="nav-link mr-4" onClick={() => {
                                    setIsOpen(false)
                                }}>
                                    <FontAwesomeIcon icon={faWrench} fixedWidth/>
                                    Projects
                                </NavLinkRR>
                                :
                                null
                            }
                            {HasFeature(user, [TTSFeatures.Feature_Invoicing]) ?
                                <NavLinkRR to="/Invoicing" className="nav-link mr-4" onClick={() => {
                                    setIsOpen(false)
                                }}>
                                    <FontAwesomeIcon icon={faFileInvoiceDollar} fixedWidth/>
                                    Invoicing
                                </NavLinkRR>
                                :
                                null
                            }
                            {HasFeature(user, ReportFeaturesRequired) ?
                                <NavLinkRR to="/Reports" className="nav-link mr-4" onClick={() => {
                                    setIsOpen(false)
                                }}>
                                    <FontAwesomeIcon icon={faChartLine} fixedWidth/>
                                    Reports
                                </NavLinkRR>
                                :
                                null
                            }
                            <NavBarImpersonations/>
                        </>
                        :
                        null
                    }
                    <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav caret>
                            {(user.userID) ? ((user.firstname ?? '') + ' ' + (user.lastname ?? '')) : 'Welcome'}
                        </DropdownToggle>
                        <DropdownMenu right className="pr-3">
                            {user.loggedIn ?
                                <>
                                    <DropdownItem tag={Link} to="/Profile" onClick={() => setIsOpen(false)}>
                                        <FontAwesomeIcon icon={faUser} fixedWidth/> My Profile
                                    </DropdownItem>
                                    <DropdownItem tag={Link} to="/ChangePassword" onClick={() => setIsOpen(false)}>
                                        <FontAwesomeIcon icon={faKey} fixedWidth/> Change Password
                                    </DropdownItem>
                                    {HasFeature(user, TTSFeatures.Feature_Customer_Admin) ?
                                        <>
                                            <DropdownItem divider/>
                                            <DropdownItem tag={Link} to="/Customers" onClick={() => setIsOpen(false)}>
                                                <FontAwesomeIcon icon={faBuilding} fixedWidth/> Customers
                                            </DropdownItem>
                                        </>
                                        :
                                        null
                                    }
                                    {HasFeature(user, TTSFeatures.Feature_TimeSheet_Admin) ?
                                        <>
                                            <DropdownItem divider/>
                                            <DropdownItem tag={Link} to="/Holidays" onClick={() => setIsOpen(false)}>
                                                <FontAwesomeIcon icon={faUmbrellaBeach} fixedWidth/> Holidays
                                            </DropdownItem>
                                            <DropdownItem tag={Link} to="/ActivityCodes" onClick={() => setIsOpen(false)}>
                                                <FontAwesomeIcon icon={faTag} fixedWidth/> Activity Code
                                            </DropdownItem>
                                            <DropdownItem tag={Link} to="/MileageRates" onClick={() => setIsOpen(false)}>
                                                <FontAwesomeIcon icon={faRoad} fixedWidth/> Mileage Rates
                                            </DropdownItem>
                                        </>
                                        :
                                        null
                                    }
                                    {IsAdmin(user) ?
                                        <>
                                            <DropdownItem divider/>
                                            <DropdownItem tag={Link} to="/FileTypeGroups" onClick={() => setIsOpen(false)}>
                                                <FontAwesomeIcon icon={faFolderTree} fixedWidth/> Document Categories
                                            </DropdownItem>
                                            <DropdownItem tag={Link} to="/DiaryTypes" onClick={() => setIsOpen(false)}>
                                                <FontAwesomeIcon icon={faBooks} fixedWidth/> Diary Categories
                                            </DropdownItem>
                                            <DropdownItem tag={Link} to="/AuthRoles" onClick={() => setIsOpen(false)}>
                                                <FontAwesomeIcon icon={faKey} fixedWidth/> Auth Roles
                                            </DropdownItem>
                                            <DropdownItem tag={Link} to="/System" onClick={() => setIsOpen(false)}>
                                                <FontAwesomeIcon icon={faMicrochip} fixedWidth/> System
                                            </DropdownItem>
                                        </>
                                        :
                                        null}
                                    <DropdownItem divider/>
                                    <DropdownItem onClick={logout}>
                                        <FontAwesomeIcon icon={faSignOut} fixedWidth/> Logout
                                    </DropdownItem>
                                </>
                                :
                                <>
                                    <DropdownItem tag={Link} to="/Login" onClick={() => setIsOpen(false)}>
                                        <FontAwesomeIcon icon={faSignIn} fixedWidth/> Login
                                    </DropdownItem>
                                </>
                            }
                            <DropdownItem tag={Link} to="/About" onClick={() => setIsOpen(false)}>
                                    <span className="fa-fw mr-2">
                                        <img src={logoSquare} alt="IW" className="img-inline"/>
                                    </span> About TTS
                            </DropdownItem>
                            {IsDevFocused() && IsENV(Environments.ENV_Local) ?
                                <>
                                    <DropdownItem divider/>
                                    <DropdownItem tag={Link} to="/TestPage" onClick={() => setIsOpen(false)}>
                                        <FontAwesomeIcon icon={faVial} fixedWidth/> Test Page
                                    </DropdownItem>
                                    <DropdownItem onClick={runChronJobs}>
                                        <FontAwesomeIcon icon={faClock} fixedWidth/> Chron Job Run
                                    </DropdownItem>
                                    <DropdownItem onClick={reDefinition}>
                                        <FontAwesomeIcon icon={faBook} fixedWidth/> Re-Define
                                    </DropdownItem>
                                </>
                                :
                                null}
                            {IsDevFocused() && IsENV(Environments.ENV_Local) ?
                                <>
                                    <DropdownItem onClick={reMigrate}>
                                        <FontAwesomeIcon icon={faDownload} fixedWidth/> Re-Migrate
                                    </DropdownItem>
                                </>
                                :
                                null
                            }
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Nav>
            </Collapse>
        </Navbar>
    );
};

interface IUsers {
    userID: number,
    firstname: string,
    lastname: string
}

const NavBarImpersonations = () => {
    const dispatch = useDispatch();
    const isMounted = useRef(true);
    const {user} = useSelector((state: AppState) => state);

    const [users, setUsers] = useState([] as IUsers[]);

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    const impersonate = async (id: number) => {
        ShowActivityOverlay()(dispatch);
        window.sessionStorage.setItem("device_token_for", id.toString());
        APIProcess('User', 'Impersonate', {user_id: id})(iWake)
            .then(() => {
                ShowMessageBox('Impersonated')(dispatch);
            })
            .catch(() => {
                window.sessionStorage.removeItem("device_token_for");
            })
            .finally(() => {
                HideActivityOverlay()(dispatch);
            });
    };

    const deImpersonate = async () => {
        ShowActivityOverlay()(dispatch);
        APIProcess('User', 'DeImpersonate')(iWake)
            .then(() => {
                ShowMessageBox('De-Impersonated')(dispatch);
            })
            .catch(() => {
            })
            .finally(() => {
                HideActivityOverlay()(dispatch);
            });
    };

    useEffect(() => {
        if (user.loggedIn) {
            let cancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();
            isMounted.current = true;

            APIProcess('User', 'GetListImpersonators', {}, cancelTokenSource)(iWake)
                .then((results) => {
                    if (isMounted.current && cancelTokenSource) {
                        setUsers((results.Users as IUsers[]).sort(function (a, b) {
                            return ((a.lastname ?? "") + "aaa" + (a.firstname ?? "")).localeCompare((b.lastname ?? "") + "aaa" + (b.firstname), undefined, {sensitivity: 'base'})
                        }));
                    }
                })
                .catch(() => {
                })
                .finally(() => {
                    cancelTokenSource = null;
                });

            return () => {
                isMounted.current = false;
                if (cancelTokenSource) {
                    cancelTokenSource.cancel();
                    cancelTokenSource = null;
                }
            }
        } else {
            setUsers([]);
        }
    }, [iWake, user]);

    if (user.loggedIn && (HasFeature(user, [TTSFeatures.Feature_User_Admin]) || !!window.sessionStorage.getItem('device_token_for'))) {
        return (
            <UncontrolledDropdown nav inNavbar className="mr-4 navbar-nav">
                <DropdownToggle nav caret>
                    <FontAwesomeIcon icon={faUsers} fixedWidth/>
                </DropdownToggle>
                <DropdownMenu right className="pre-scrollable">
                    <DropdownItem onClick={deImpersonate} hidden={!window.sessionStorage.getItem('device_token_for')}>
                        <FontAwesomeIcon icon={faExternalLinkAlt} fixedWidth/>
                        De-Impersonate
                    </DropdownItem>
                    {users.length > 0 ?
                        <>
                            <DropdownItem divider hidden={!window.sessionStorage.getItem('device_token_for')}/>
                            {users.map((user) =>
                                <DropdownItem key={user.userID} onClick={() => impersonate(user.userID)}>
                                    {((user.lastname ?? '') + ', ' + (user.firstname))}
                                </DropdownItem>
                            )}
                        </>
                        :
                        null
                    }
                </DropdownMenu>
            </UncontrolledDropdown>
        );
    }

    return null;
};

export default NavBar;

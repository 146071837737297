import {Itblauth_role} from "../Tables/tblauth_role";
import {TTSFeatures} from "../TTSFeatures";

export interface IUsers {
    userID: number,
    firstname: string,
    lastname: string,
    disable: number
}

export const UserFeatureInAuthRoleIDs = (feature: TTSFeatures, AuthRoleIDs: number[], AuthRoles: Itblauth_role[]): boolean => {
    return !!AuthRoles.find(authRole => authRole.name === feature && AuthRoleIDs.includes(authRole.auth_roleID));
}

export const ExpirationDateWarningDays = 30;

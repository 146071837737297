/**
 * Automatically generated: 2020-06-15 15:30:50
 * © 2020, Solid Basis Ventures, LLC.
 * DO NOT MODIFY
 *
 * Table Manager for: tbluser_diary_type
 */

export interface Itbluser_diary_type {
	id: number,
	name: string,
	active: number
}

export const initialtbluser_diary_type: Itbluser_diary_type = {
	id: 0,
	name: '',
	active: 1
};

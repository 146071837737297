import React, {useEffect, useMemo, useRef, useState} from 'react';
import {
    Button,
    Col,
    Row, Table
} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import axios, {CancelTokenSource} from "axios";
import {APIProcess, initialSortColumn, ISortColumn, ShowActivityOverlay, SearchSort, HideActivityOverlay, SortColumnUpdate, InputSearch} from '@denjpeters/intelliwakereact';
import {useHistory} from 'react-router-dom';
import {IWake} from "../../IWake";
import moment from "moment";
import {DownloadFile} from "../../Data/Controllers/tbluser_file";

interface IFile {
    id: number,
    type: string,
    userID: number,
    name: string,
    valid_date: string | null,
    expiration_date: string | null,
    notes: string | null,
    source: string | null,
    type_name: string | null,
    fullname: string
}

interface IReportStructure {
    files: IFile[]
}

const ExpiringDocs = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const isMounted = useRef(true);
    const {user} = useSelector((state: AppState) => state);
    const [reportStructure, setReportStructure] = useState(null as IReportStructure | null);
    const [search, setSearch] = useState('');
    const [sortColumn, setSortColumn] = useState({
        ...initialSortColumn,
        primarySort: "expiration_date"
    } as ISortColumn);

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    useEffect(() => {
        let cancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();
        isMounted.current = true;

        ShowActivityOverlay()(dispatch);
        APIProcess('UserFile', 'Expiring', {}, cancelTokenSource)(iWake)
            .then((results) => {
                // console.log(results.reportStructure);
                if (isMounted.current && cancelTokenSource) {
                    setReportStructure(results);
                }
            })
            .catch(() => {
                if (isMounted.current && cancelTokenSource) {
                    setReportStructure(null);
                }
            })
            .finally(() => {
                HideActivityOverlay()(dispatch);
                cancelTokenSource = null;
            });

        return () => {
            isMounted.current = false;
            if (cancelTokenSource) {
                cancelTokenSource.cancel();
                cancelTokenSource = null;
            }
        }
    }, [dispatch, iWake]);

    const reportDataList: IFile[] = useMemo(() => {
        if (!reportStructure) return [];
        return SearchSort(reportStructure.files ?? [], search, sortColumn)
    }, [reportStructure, search, sortColumn]);

    return (
        <>
            <Row>
                <Col>
                    <h3 className="text-center">Expiring Documents</h3>
                </Col>
            </Row>
            <Row className="d-print-none">
                <Col md={4}>
                    <InputSearch triggerSearchText={setSearch} placeholder="Search"/>
                </Col>
            </Row>
            {!!reportStructure ?
                <Row className="mt-3 fill-height">
                    <Col className="fill-height">
                        <Table bordered hover size="sm" className="table-scrollable table-sortable">
                            <thead>
                            <tr className="table-secondary">
                                <th onClick={() => setSortColumn(SortColumnUpdate('expiration_date', sortColumn))}>Expiration Date</th>
                                <th onClick={() => setSortColumn(SortColumnUpdate('fullname', sortColumn))}>Employee</th>
                                <th onClick={() => setSortColumn(SortColumnUpdate('type_name', sortColumn))}>Document
                                </th>
                                <th className="td-lg"/>
                            </tr>
                            </thead>
                            <tbody>
                            {reportDataList.map((reportData, idx) =>
                                <tr key={idx} onClick={() => history.push('/Users/User/' + reportData.userID + "/Documents")}>
                                    <td className={moment(reportData.expiration_date).diff(moment(), 'days') < 0 ? "text-danger strong" : (moment(reportData.expiration_date).diff(moment(), 'days') < 30 ? "text-warning strong" : "")}>
                                        {moment(reportData.expiration_date ?? "").format('ll')}
                                    </td>
                                    <td>{reportData.fullname}</td>
                                    <td>{reportData.type_name}</td>
                                    <td className="td-lg bg-white" onClick={(e) => e.stopPropagation()}>
                                        <Button type="button" color="link" size="sm" className="btn-link-inline" onClick={() => DownloadFile(reportData.id, reportData.type, iWake)}>Download</Button>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                :
                null
            }
        </>
    );
};

export default ExpiringDocs;

import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {
    Badge,
    Button,
    Col,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row, Table,
    UncontrolledDropdown
} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPrint} from "@fortawesome/pro-regular-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../Stores/rootReducer";
import {ASRSetProjectSort} from "../Stores/appsessionremembers/actions";
import moment from "moment";
import {faCog} from "@fortawesome/pro-duotone-svg-icons";
import {faCheck, faCheckCircle, faPencil} from "@fortawesome/pro-solid-svg-icons";
import axios, {CancelTokenSource} from "axios";
import {
    APIProcess,
    ShowActivityOverlay,
    ShowPromptOKCancel,
    HideActivityOverlay,
    ClassNames,
    ToDigitsBlank
} from '@denjpeters/intelliwakereact';
import {HasFeature, TTSFeatures} from "../Data/TTSFeatures";
import './TimeEntry.scss';
import {DaysOfWeek} from "../Data/Enums/DaysOfWeek";
import LineReadWrite from "../WebControls/Time/LineReadWrite";
import {TimeStatus} from "../Data/Enums/TimeStatus";
import LineReadOnly from "../WebControls/Time/LineReadOnly";
import WeekSummary from "../WebControls/Time/WeekSummary";
import EmployeeDD from "../WebControls/Generics/EmployeeDD";
import WeekNav from "../WebControls/Generics/WeekNav";
import {useHistory} from 'react-router-dom';
import {initialTime, ITime, ITimeDataSet} from "../Data/Views/Time";
import {IWake} from "../IWake";

export interface IFocusElement {
    rowID: number,
    columnName: string
}

const TimeEntry = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const isMounted = useRef(true);
    const localID = useRef(1);
    const {user, appSessionRemembersChange} = useSelector((state: AppState) => state);
    const [timeDataSet, setTimeDataSet] = useState(null as ITimeDataSet | null);
    const [focusElement, setFocusElement] = useState(null as IFocusElement | null);
    const [forceRefresh, setForceRefresh] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [otAdjustAmount, setOTAdjustAmount] = useState(0);

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    const days: string[] = useMemo(() => [
        moment(appSessionRemembersChange.viewDate).startOf("isoWeek").format('YYYY-MM-DD'),
        moment(appSessionRemembersChange.viewDate).startOf("isoWeek").add(1, 'day').format('YYYY-MM-DD'),
        moment(appSessionRemembersChange.viewDate).startOf("isoWeek").add(2, 'days').format('YYYY-MM-DD'),
        moment(appSessionRemembersChange.viewDate).startOf("isoWeek").add(3, 'days').format('YYYY-MM-DD'),
        moment(appSessionRemembersChange.viewDate).startOf("isoWeek").add(4, 'days').format('YYYY-MM-DD'),
        moment(appSessionRemembersChange.viewDate).startOf("isoWeek").add(5, 'days').format('YYYY-MM-DD'),
        moment(appSessionRemembersChange.viewDate).startOf("isoWeek").add(6, 'days').format('YYYY-MM-DD')
    ], [appSessionRemembersChange.viewDate]);

    const focusMoveUp = (fromRowID: number, fromColumnName: string) => {
        // Get all possible time rows
        const sortedTimes = (!!timeDataSet ? timeDataSet.timeDataUsers[0].time
            .sort((a, b) => (a.timeID === 0 ? 1 : (b.timeID === 0 ? -1 : (a.timeID - b.timeID)))) : [])
            .filter(time => fromRowID === 0 || time.timeID < fromRowID);

        if (sortedTimes.length > 0) {
            const selectedTimeRow = sortedTimes.reduce((prev, next) => prev.timeID > next.timeID ? prev : next);

            setFocusElement({rowID: selectedTimeRow.timeID, columnName: fromColumnName});
        }
    };

    const focusMoveDown = (fromRowID: number, fromColumnName: string) => {
        // Get all possible time rows
        const sortedTimes = (!!timeDataSet ? timeDataSet.timeDataUsers[0].time
            .sort((a, b) => (a.timeID === 0 ? 1 : (b.timeID === 0 ? -1 : (a.timeID - b.timeID)))) : [])
            .filter(time => time.timeID === 0 || time.timeID > fromRowID);

        if (sortedTimes.length === 1) {
            const selectedTimeRow = sortedTimes.find(() => true);
            setFocusElement({rowID: selectedTimeRow!.timeID, columnName: fromColumnName});
        } else {
            const selectedTimeRow = sortedTimes
                .filter(time => time.timeID !== 0)
                .reduce((prev, next) => prev.timeID < next.timeID ? prev : next);

            setFocusElement({rowID: selectedTimeRow.timeID, columnName: fromColumnName});
        }
    };

    const clearFocusElement = useCallback(() => {
        setFocusElement(null);
    }, []);

    const updateTimeRow = (timeRow: any, newID: number) => {
        if (!!timeDataSet) {
            if ((timeRow.timeID === 0 && newID > 0) || !timeDataSet.timeDataUsers[0].time.find(timeRow => timeRow.timeID === 0)) {
                setTimeDataSet(timeData => {
                    return {
                        ...timeData,
                        timeDataUsers: [
                            {
                                ...timeData!.timeDataUsers[0],
                                time: [
                                    ...timeData!.timeDataUsers[0].time.filter(tm => tm.localID !== timeRow.localID),
                                    {
                                        ...timeData!.timeDataUsers[0].time.find(tm => tm.localID === timeRow.localID),
                                        ...timeRow,
                                        timeID: newID
                                    },
                                    {
                                        ...initialTime,
                                        date: moment(appSessionRemembersChange.viewDate).format('YYYY-MM-DD'),
                                        userID: appSessionRemembersChange.viewDate ?? timeData!.timeDataUsers[0].user.userID,
                                        localID: localID.current++
                                    }
                                ]
                            }
                        ]
                    } as ITimeDataSet
                });
            } else {
                setTimeDataSet(timeData => {
                    return {
                        ...timeData,
                        timeDataUsers: [
                            {
                                ...timeData!.timeDataUsers[0],
                                time: [
                                    ...timeData!.timeDataUsers[0].time.filter(tm => tm.localID !== timeRow.localID),
                                    {
                                        ...timeData!.timeDataUsers[0].time.find(tm => tm.localID === timeRow.localID),
                                        ...timeRow,
                                        timeID: newID
                                    }
                                ]
                            }
                        ]
                    } as ITimeDataSet
                });
            }
        }
    };

    const deleteTimeRow = (timeRowID: number) => {
        if (!!timeDataSet) {
            setTimeDataSet(timeData => {
                return {
                    ...timeData,
                    timeDataUsers: [
                        {
                            ...timeData!.timeDataUsers[0],
                            time: [
                                ...timeData!.timeDataUsers[0].time.filter(tm => tm.timeID !== timeRowID)
                            ]
                        }
                    ]
                } as ITimeDataSet
            });
        }
    };

    const submitToSupervisor = async () => {
        isMounted.current = true;

        if (await ShowPromptOKCancel('Submit to Supervisor?', 'Are you sure you are ready to submit your time to your supervisor?', 'primary', 'Submit to Supervisor')(dispatch)) {
            ShowActivityOverlay()(dispatch);

            APIProcess('time', 'Submit', {
                userID: appSessionRemembersChange.viewUserID ?? user.userID!,
                date: moment(appSessionRemembersChange.viewDate).endOf('isoWeek').format('Y-MM-DD')
            })(iWake)
                .then(() => {
                    if (isMounted.current) {
                        setForceRefresh(!forceRefresh);
                    }
                })
                .catch(() => {
                })
                .finally(() => {
                    HideActivityOverlay()(dispatch);
                });
        }
    };

    const doForceRefresh = () => {
        setForceRefresh(!forceRefresh);
    };

    useEffect(() => {
        let cancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();
        isMounted.current = true;

        ShowActivityOverlay()(dispatch);
        APIProcess('time', 'GetData', {
            userID: appSessionRemembersChange.viewUserID ?? user.userID,
            date: moment(appSessionRemembersChange.viewDate).format('Y-MM-DD')
        }, cancelTokenSource)(iWake)
            .then((results) => {
                if (isMounted.current && cancelTokenSource) {
                    // console.log(results);
                    const loadedTimeDataSet = {
                        ...results,
                        timeDataUsers: [
                            {
                                ...results.timeDataUsers[0],
                                time: results.timeDataUsers[0].time.map((timeRow: ITime) => {
                                    return {...timeRow, localID: localID.current++};
                                })
                            }
                        ]
                    } as ITimeDataSet;

                    if (loadedTimeDataSet.timeDataUsers[0].status === TimeStatus.DRAFT || editMode) {
                        loadedTimeDataSet.timeDataUsers[0].time = [
                            ...loadedTimeDataSet.timeDataUsers[0].time,
                            {
                                ...initialTime,
                                date: moment(appSessionRemembersChange.viewDate).format('Y-MM-DD'),
                                userID: appSessionRemembersChange.viewUserID ?? loadedTimeDataSet.timeDataUsers[0].user.userID,
                                localID: localID.current++
                            }
                        ];
                    }

                    setOTAdjustAmount(loadedTimeDataSet.timeDataUsers[0].ot_adjust);

                    setTimeDataSet(loadedTimeDataSet);
                }
            })
            .catch(() => {
                if (isMounted.current && cancelTokenSource) {
                    setTimeDataSet(null);
                }
            })
            .finally(() => {
                HideActivityOverlay()(dispatch);
                cancelTokenSource = null;
            });

        return () => {
            isMounted.current = false;
            if (cancelTokenSource) {
                cancelTokenSource.cancel();
                cancelTokenSource = null;
            }
        }
    }, [dispatch, user.userID, iWake, appSessionRemembersChange.viewUserID, appSessionRemembersChange.viewDate, forceRefresh, editMode]);

    const sortedTimes = useMemo(() => !!timeDataSet ? timeDataSet.timeDataUsers[0].time.sort((a, b) => (a.timeID === 0 ? 1 : (b.timeID === 0 ? -1 : (a.timeID - b.timeID)))) : [], [timeDataSet]);

    return (
        <Container fluid>
            {!!timeDataSet ?
                <>
                    {timeDataSet.expiringDocs.length > 0 ?
                        <Row className="p-2">
                            <Col>
                                Expired or expiring documents:
                                {timeDataSet.expiringDocs.map(expiringDoc =>
                                    <span className={moment(expiringDoc.expiration_date).diff(moment(), 'days') <= 0 ? "text-danger" : "text-warning"} key={expiringDoc.id}>&nbsp;&nbsp;&nbsp;
                                        <strong>{expiringDoc.type_name}</strong> <small><i>({moment(expiringDoc.expiration_date).format('ll')}</i></small>)</span>
                                )}
                            </Col>
                        </Row>
                        :
                        null
                    }
                    <Row className="p-2">
                        <Col>
                            <WeekNav/>
                        </Col>
                        <Col className="text-center strong">
                            <h4>
                                {HasFeature(user, [TTSFeatures.Feature_TimeSheet_Admin, TTSFeatures.Feature_TimeSheet_Entry]) ?
                                    <EmployeeDD/>
                                    :
                                    <>Employee: {' ' + timeDataSet.timeDataUsers[0].user.firstname + ' ' + timeDataSet.timeDataUsers[0].user.lastname}</>
                                }
                            </h4>
                        </Col>
                        <Col className="text-right">
                            <UncontrolledDropdown>
                                <DropdownToggle color="link" className="no-underline small btn-link-inline" caret>
                                    <FontAwesomeIcon icon={faCog} fixedWidth swapOpacity/>
                                    Options
                                </DropdownToggle>
                                <DropdownMenu right>
                                    {HasFeature(user, TTSFeatures.Feature_TimeSheet_Admin) ?
                                        <>
                                            {
                                                editMode ?
                                                    <>
                                                        <DropdownItem onClick={() => {
                                                            setEditMode(false)
                                                        }}>
                                                            <FontAwesomeIcon icon={faPencil} fixedWidth/>
                                                            Exit Edit Mode
                                                        </DropdownItem>
                                                        <DropdownItem divider/>
                                                    </>
                                                    :
                                                    timeDataSet.timeDataUsers[0].status > 0 ?
                                                        <>
                                                            <DropdownItem onClick={() => {
                                                                setEditMode(true)
                                                            }}>
                                                                <FontAwesomeIcon icon={faPencil} fixedWidth/>
                                                                Edit Mode
                                                            </DropdownItem>
                                                            <DropdownItem divider/>
                                                        </>
                                                        :
                                                        null
                                            }
                                        </>
                                        :
                                        null
                                    }
                                    <DropdownItem onClick={() => {
                                        history.push('/TimeSheet');
                                    }}>
                                        <FontAwesomeIcon icon={faPrint} fixedWidth/>
                                        Print View
                                    </DropdownItem>
                                    <DropdownItem divider/>
                                    <DropdownItem header>
                                        Project Sort Order
                                    </DropdownItem>
                                    <DropdownItem onClick={() => {
                                        ASRSetProjectSort('Name')(dispatch);
                                    }}>
                                        <FontAwesomeIcon icon={faCheck} fixedWidth className={ClassNames({"invisible": appSessionRemembersChange.projectSort !== 'Name'})}/>
                                        Project Name
                                    </DropdownItem>
                                    <DropdownItem onClick={() => {
                                        ASRSetProjectSort('NoAsc')(dispatch);
                                    }}>
                                        <FontAwesomeIcon icon={faCheck} fixedWidth className={ClassNames({"invisible": appSessionRemembersChange.projectSort !== 'NoAsc'})}/>
                                        Project #
                                    </DropdownItem>
                                    <DropdownItem onClick={() => {
                                        ASRSetProjectSort('NoDesc')(dispatch);
                                    }}>
                                        <FontAwesomeIcon icon={faCheck} fixedWidth className={ClassNames({"invisible": appSessionRemembersChange.projectSort !== 'NoDesc'})}/>
                                        Project #, Descending
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Col>
                    </Row>
                    <Row className="fill-height-scroll">
                        <Col xs={12}>
                            <Table size="sm" bordered className="tableTime">
                                <thead>
                                <tr className="text-center">
                                    <th>Project</th>
                                    <th>Activity</th>
                                    {days.map(day =>
                                        <th key={day.toString()} style={{minWidth: "4em"}}>{moment(day).format('ddd')}<br/>{moment(day).format('M/D')}
                                        </th>
                                    )}
                                    <th>Total</th>
                                </tr>
                                </thead>
                                <tfoot>
                                <tr className="text-right">
                                    <th colSpan={2}>
                                        Total
                                    </th>
                                    {DaysOfWeek.map(dayOfWeek =>
                                        <th key={dayOfWeek}>
                                            {ToDigitsBlank(timeDataSet.timeDataUsers[0].time.length > 0 ? timeDataSet.timeDataUsers[0].time.map(time => (time as any)[dayOfWeek]).reduce((prev, next) => +prev + +next) : 0)}
                                        </th>
                                    )}
                                    <th>
                                        {ToDigitsBlank(timeDataSet.timeDataUsers[0].time.length > 0 ? timeDataSet.timeDataUsers[0].time.map(time => (DaysOfWeek.map(dayOfWeek => (time as any)[dayOfWeek]).reduce((prev, next) => +prev + +next))).reduce((prev, next) => +prev + +next) : 0)}
                                    </th>
                                </tr>
                                </tfoot>
                                <tbody>
                                {sortedTimes.map((timeRow) =>
                                    (editMode || ((timeDataSet.timeDataUsers[0].status === TimeStatus.DRAFT && timeRow.status === TimeStatus.DRAFT) && (timeRow.activityID !== 2 || HasFeature(user, TTSFeatures.Feature_User_Admin)))) ?
                                        <LineReadWrite key={timeRow.localID} timeDataGeneric={timeDataSet.timeDataGeneric} timeDataUser={timeDataSet!.timeDataUsers[0]} timeRow={timeRow} updateTimeRow={updateTimeRow} deleteTimeRow={deleteTimeRow} focusElementColumn={!!focusElement ? (focusElement.rowID === timeRow.timeID ? focusElement.columnName : null) : null} focusMoveUp={focusMoveUp} focusMoveDown={focusMoveDown} clearFocusElement={clearFocusElement}/>
                                        :
                                        <LineReadOnly key={timeRow.localID} timeDataGeneric={timeDataSet.timeDataGeneric} timeDataUser={timeDataSet.timeDataUsers[0]} timeRow={timeRow}/>
                                )}
                                </tbody>
                            </Table>
                        </Col>
                        <Col xs={8}>
                            {timeDataSet.timeDataUsers[0].status === TimeStatus.DRAFT ?
                                <div className="p-4" style={{maxWidth: "35em"}}>
                                    <Button color="danger" onClick={submitToSupervisor}>
                                        <FontAwesomeIcon icon={faCheckCircle} fixedWidth/>
                                        Submit to Supervisor
                                    </Button>
                                    <br/>
                                    <small>
                                        <strong>
                                            Note: Once you click "Submit to Supervisor" you can no longer edit this
                                            weeks
                                            information. Please be sure to click "Submit to Supervisor" only after you
                                            have
                                            entered
                                            in all of your weekly payroll information.
                                        </strong>
                                    </small>
                                </div>
                                :
                                null
                            }
                            {timeDataSet.timeDataUsers[0].status === TimeStatus.SUBMITTED ?
                                <div className="p-4" style={{maxWidth: "35em"}}>
                                    <Badge>Submitted to Supervisor</Badge>
                                </div>
                                :
                                null
                            }
                            {timeDataSet.timeDataUsers[0].status === TimeStatus.APPROVED ?
                                <div className="p-4" style={{maxWidth: "35em"}}>
                                    <Badge color="success">Approved by Supervisor</Badge>
                                </div>
                                :
                                null
                            }
                        </Col>
                        <Col xs={4}>
                            <WeekSummary timeDataGeneric={timeDataSet.timeDataGeneric} timeDataUser={timeDataSet.timeDataUsers[0]} otAdjustAmount={otAdjustAmount} setOTAdjustAmount={setOTAdjustAmount} forceRefresh={doForceRefresh}/>
                        </Col>
                    </Row>
                </>
                :
                null
            }
        </Container>
    );
};

export default TimeEntry;

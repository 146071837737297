import React, {useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import {Button, Col, FormGroup, Label, Row} from "reactstrap";
import {IWake} from "../../IWake";
import {Itbluser_file_type_group} from "../../Data/Tables/tbluser_file_type_group";
import {Itbluser_file_type} from "../../Data/Tables/tbluser_file_type";
import {APIProcess, ElementCustomValue, ShowMessageBox, InputText, InputSwitch} from '@denjpeters/intelliwakereact';
import FileTypes from "./FileTypes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDown, faArrowUp} from "@fortawesome/pro-solid-svg-icons";

interface IProps {
    tbluser_file_type_group: Itbluser_file_type_group,
    tbluser_file_type_groups: Itbluser_file_type_group[],
    tbluser_file_types: Itbluser_file_type[],
    updateGroup: ((tbluser_file_type_group: Itbluser_file_type_group) => void),
    updateType: ((tbluser_file_type: Itbluser_file_type) => void),
    forceRefresh: (() => void)
}

const FileTypeGroup = (props: IProps) => {
    const dispatch = useDispatch();
    const {user} = useSelector((state: AppState) => state);
    const [changes, setChanges] = useState({} as { [key: string]: any });

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    const tbluser_file_type_group = useMemo(() => {
        return {...props.tbluser_file_type_group, ...changes}
    }, [props.tbluser_file_type_group, changes]);

    const handleInputChangeName = (name: string, value: any) => {
        setChanges(prevState => {
            return {
                ...prevState,
                [name]: value
            }
        });
    }

    const handleInputChange = (e: React.ChangeEvent<any>) => {
        const target: any = e.target;

        if (!!target) {
            const name = target.name;
            const value = ElementCustomValue(e);
            handleInputChangeName(name, value);
        }
    }

    const saveGroup = () => {
        if (Object.keys(changes).length > 0) {
            saveGroupWithChanges(changes);
        }
    }

    const saveGroupWithChanges = (saveChanges: { [key: string]: any }) => {
        const updates = {...props.tbluser_file_type_group, ...changes, ...saveChanges} as Itbluser_file_type_group;

        APIProcess('UserFileType', 'SaveTypeGroup', updates)(iWake)
            .then((result) => {
                props.updateGroup({...updates, id: result.id});
                setChanges({});
                ShowMessageBox("Saved")(dispatch);
            })
            .catch(() => {
            })
            .finally(() => {
            });
    }

    return (
        <Row className="fill-height">
            <Col className="fill-height">
                <Row className="p-4">
                    <Col className="form-sm" onBlur={saveGroup}>
                        <FormGroup>
                            <Label>Category Name</Label>
                            <Col className="medium">
                                <InputText name="name" onChange={handleInputChange} value={tbluser_file_type_group.name}/>
                            </Col>
                            <Col className="short">
                                <Button type="button" size="sm" color="outline-primary" className="mr-2" onClick={() => saveGroupWithChanges({'sort_order': tbluser_file_type_group.sort_order - 15})}>
                                    <FontAwesomeIcon icon={faArrowUp}/>
                                </Button>
                                <Button type="button" size="sm" color="outline-primary" className="mr-2" onClick={() => saveGroupWithChanges({'sort_order': tbluser_file_type_group.sort_order + 15})}>
                                    <FontAwesomeIcon icon={faArrowDown}/>
                                </Button>
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col className="offset">
                                <InputSwitch name="active" onChange={(e) => {
                                    handleInputChange(e)
                                }} value={tbluser_file_type_group.active} label="Active"/>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <FileTypes tbluser_file_type_group={props.tbluser_file_type_group} tbluser_file_type_groups={props.tbluser_file_type_groups} tbluser_file_types={props.tbluser_file_types} updateType={props.updateType} forceRefresh={props.forceRefresh}/>
            </Col>
        </Row>
    );
};

export default FileTypeGroup;

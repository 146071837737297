import React, {useMemo, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCabinetFiling, faFolderOpen} from "@fortawesome/pro-regular-svg-icons";
import {faUpload} from "@fortawesome/pro-light-svg-icons";
import {Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from 'reactstrap';
import {Itbluser_file_type} from "../../Data/Tables/tbluser_file_type";
import moment from "moment";
import {
    APIProcess,
    ElementCustomValue,
    ShowMessageBox,
    ShowPromptOKCancel,
    InputSelect,
    InputDate,
    InputText
} from '@denjpeters/intelliwakereact';
import {Itbluser_file} from "../../Data/Tables/tbluser_file";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import {IWake} from "../../IWake";
import {faCog, faSave, faTrashAlt, faDownload} from "@fortawesome/pro-solid-svg-icons";
import {DownloadFile} from "../../Data/Controllers/tbluser_file";
import {ITypeList} from "./UserDocuments";
import {ExpirationDateWarningDays} from "../../Data/Views/Users";

interface IProps {
    tbluser_file: Itbluser_file,
    typeList: ITypeList[],
    updateFile: (() => void),
    retryUpload?: ((uuid: string) => void),
    deleteUpload?: ((uuid: string) => void),
    hasUploading: boolean
}

const UserDocumentsTRItem = (props: IProps) => {
    const dispatch = useDispatch();
    const {user} = useSelector((state: AppState) => state);
    const [editMode, setEditMode] = useState(!props.tbluser_file.tbluser_file_type_id);
    const [changes, setChanges] = useState({} as { [key: string]: any });

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    const tbluser_file = useMemo(() => {
        return {...props.tbluser_file, ...changes}
    }, [props.tbluser_file, changes]);

    const handleInputChange = (e: React.ChangeEvent<any>) => {
        const target: any = e.target;

        if (!!target) {
            const name = target.name;
            const value = ElementCustomValue(e);
            setChanges(prevState => {
                return {
                    ...prevState,
                    [name]: value
                } as Itbluser_file_type
            });
        }
    }

    const saveFileDo = (otherChanges?: { [key: string]: any }) => {
        if (!!otherChanges || Object.keys(changes).length > 0) {
            let updates = {
                ...props.tbluser_file,
                ...changes,
                ...(otherChanges ?? {})
            } as Itbluser_file;

            APIProcess('UserFile', 'Save', updates)(iWake)
                .then(() => {
                    ShowMessageBox("Saved")(dispatch);
                    setEditMode(false);
                    setChanges({});
                    props.updateFile(); //{...updates, id: result.id}
                })
                .catch(() => {
                })
                .finally(() => {
                });
        } else {
            setEditMode(false);
        }
    }

    const saveFile = () => {
        saveFileDo();
    }

    const deleteFile = async () => {
        if (await ShowPromptOKCancel('Delete?', 'Are you sure you want to delete this file?', 'danger', 'Delete')(dispatch)) {
            saveFileDo({active: 0});
        }
    }

    const archiveFile = async () => {
        if (await ShowPromptOKCancel('Archive?', 'Are you sure you want to archive this file?', 'secondary', 'Archive')(dispatch)) {
            saveFileDo({archived: 1});
        }
    }

    const unarchiveFile = async () => {
        if (await ShowPromptOKCancel('Un-Archive?', 'Are you sure you want to un-archive this file?', 'primary', 'Un-Archive')(dispatch)) {
            saveFileDo({archived: 0});
        }
    }

    const typeName = useMemo(() =>
        ((props.typeList.find(type => type.id === tbluser_file.tbluser_file_type_id) ?? {})['typeName']) ?? ''
        , [tbluser_file, props.typeList]);

    return (
        <tr className={editMode ? "alert-gray text-dark border-bottom-dark" : ((!!tbluser_file.archived ? "alert-light " : "") + (!!tbluser_file.id ? "hoverAction" : ""))} onClick={() => (!!tbluser_file.id && !editMode) ? DownloadFile(tbluser_file.id, tbluser_file.type ?? '', iWake) : null}>
            {!props.hasUploading && editMode ?
                <>
                    <td className="pl-4">
                        {tbluser_file.archived === 1 ?
                            <FontAwesomeIcon icon={faCabinetFiling} fixedWidth/>
                            :
                            null
                        }
                        <strong>{!!tbluser_file.name ? tbluser_file.name : "No name given"}</strong>
                        <InputSelect name="tbluser_file_type_id" value={tbluser_file.tbluser_file_type_id ?? 0} onChange={handleInputChange} isNumericOrNull>
                            <option value="0">Select a Document Type...</option>
                            {props.typeList.map(type =>
                                <option key={type.id} value={type.id}>{type.name}</option>
                            )}
                        </InputSelect>
                    </td>
                    <td>
                        <strong>Notes</strong>
                        <InputText name="notes" value={tbluser_file.notes ?? ""} onChange={handleInputChange}/>
                    </td>
                    <td className="td-xxxl">
                        <strong>Expires</strong>
                        <InputDate name="expiration_date" value={tbluser_file.expiration_date ?? ""} onChange={handleInputChange}/>
                        <strong>Valid</strong>
                        <InputDate name="valid_date" value={tbluser_file.valid_date ?? ""} onChange={handleInputChange}/>
                    </td>
                    <td className="td-md text-right bg-white">
                        <Button type="button" size="sm" color={Object.keys(changes).length > 0 ? "success" : "secondary"} onClick={saveFile}>
                            {Object.keys(changes).length > 0 ?
                                <>
                                    <FontAwesomeIcon icon={faSave}/>
                                    Save </>
                                :
                                <>
                                    Cancel </>
                            }
                        </Button>
                        <br/>
                        <UncontrolledDropdown>
                            <DropdownToggle color="link" size="sm" caret>
                                <FontAwesomeIcon icon={faCog}/>
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem onClick={() => DownloadFile(tbluser_file.id, tbluser_file.type ?? '', iWake)}>
                                    <FontAwesomeIcon icon={faDownload} fixedWidth/>
                                    Download
                                </DropdownItem>
                                {tbluser_file.archived ?
                                    <DropdownItem onClick={unarchiveFile}>
                                        <FontAwesomeIcon icon={faFolderOpen} fixedWidth/>
                                        Un-Archive
                                    </DropdownItem>
                                    :
                                    <DropdownItem onClick={archiveFile}>
                                        <FontAwesomeIcon icon={faCabinetFiling} fixedWidth/>
                                        Archive
                                    </DropdownItem>
                                }
                                <DropdownItem onClick={deleteFile}>
                                    <FontAwesomeIcon icon={faTrashAlt} fixedWidth/>
                                    Delete
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </td>
                </>
                :
                <>
                    <td className="pl-4">
                        {tbluser_file.archived === 1 ?
                            <FontAwesomeIcon icon={faCabinetFiling} fixedWidth/>
                            :
                            null
                        }
                        <strong>{!!typeName ? typeName : (!!tbluser_file.name ? tbluser_file.name : "No name given")}</strong>
                    </td>
                    <td className="small">
                        {!!tbluser_file.notes ? <>{tbluser_file.notes}<br/></> : ""}
                        {!!tbluser_file.name ? <>{tbluser_file.name}<br/></> : ""}
                        <FontAwesomeIcon icon={faUpload} fixedWidth/> {moment(tbluser_file.create_ts).format('lll')}
                    </td>
                    <td className="td-xxxl">
                        {!!tbluser_file.expiration_date ?
                            <span className={moment(tbluser_file.expiration_date).diff(moment(), 'days') <= 0 ? "text-danger strong" : (moment(tbluser_file.expiration_date).diff(moment(), 'days') <= ExpirationDateWarningDays ? "text-warning strong" : "")}>Exp: {moment(tbluser_file.expiration_date).format('ll')}<br/></span>
                            :
                            null
                        }
                        {!!tbluser_file.valid_date ?
                            <small>Valid: {moment(tbluser_file.valid_date).format('ll')}<br/></small>
                            :
                            null
                        }
                    </td>
                    <td className="td-md text-right bg-white" onClick={(e) => e.stopPropagation()}>
                        {!props.hasUploading ?
                            <Button type="button" color="outline-secondary" size="sm" onClick={() => setEditMode(true)}>Edit</Button>
                            :
                            null
                        }
                    </td>
                </>
            }
        </tr>
    );
};

export default UserDocumentsTRItem;

import React, {useEffect, useMemo, useRef, useState} from 'react';
import {
    Button,
    Col,
    Row, Table
} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import axios, {CancelTokenSource} from "axios";
import {APIProcess, initialSortColumn, ISortColumn, ShowActivityOverlay, SearchSort, HideActivityOverlay, SortColumnUpdate, DataToCSVExport, ToCurrencyDash, ToPercentDash} from '@denjpeters/intelliwakereact';
import {IWake} from "../../IWake";
import {useHistory} from 'react-router-dom';
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExcel} from "@fortawesome/pro-regular-svg-icons";

const PayHistory = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const isMounted = useRef(true);
    const {user, appSessionRemembersChange} = useSelector((state: AppState) => state);
    const [payHistoryStats, setPayHistoryStats] = useState(null as any[] | null);
    const [sortColumn, setSortColumn] = useState({
        ...initialSortColumn,
        primarySort: "fullname"
    } as ISortColumn);

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    useEffect(() => {
        let cancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();
        isMounted.current = true;

        ShowActivityOverlay()(dispatch);
        APIProcess('reports', 'PayHistory', {}, cancelTokenSource)(iWake)
            .then((results) => {
                // console.log(results.PayHistory);
                if (isMounted.current && cancelTokenSource) {
                    setPayHistoryStats(results.PayHistory);
                }
            })
            .catch(() => {
                if (isMounted.current && cancelTokenSource) {
                    setPayHistoryStats(null);
                }
            })
            .finally(() => {
                HideActivityOverlay()(dispatch);
                cancelTokenSource = null;
            });

        return () => {
            isMounted.current = false;
            if (cancelTokenSource) {
                cancelTokenSource.cancel();
                cancelTokenSource = null;
            }
        }
    }, [appSessionRemembersChange.approvalUserID, appSessionRemembersChange.projectActive, dispatch, iWake]);

    const usersList: any[] = useMemo(() => {
        return SearchSort(payHistoryStats ?? [], '', sortColumn)
    }, [payHistoryStats, sortColumn]);

    const openUser = (userID: number) => {
        history.push('/Users/User/' + userID);
    }

    const exportCSV = () => {
        if (!!payHistoryStats) {
            DataToCSVExport("PayHistory-" + moment().format('YYYY-MM-DD_HH-mm-ss') + ".csv", [
                ['Name', 'Start Date', 'Last Date', 'Rate', 'H/S', 'Increase'],
                ...payHistoryStats.map(payHistoryStat => [
                    payHistoryStat.fullName,
                    payHistoryStat.startDate,
                    payHistoryStat.currentDate,
                    payHistoryStat.currentRate,
                    payHistoryStat.currentPaymentType,
                    (Math.round(payHistoryStat.percentIncrease * 1000) / 10)
                ])
            ]);
        }
    }

    return (
        <>
            <Row>
                <Col>
                    <h3 className="text-center">Salary History</h3>
                    <Button color="outline-success" size="sm" className="position-absolute" style={{
                        top: "0.25rem",
                        right: "1rem"
                    }} onClick={() => {
                        exportCSV()
                    }}>
                        <FontAwesomeIcon icon={faFileExcel} fixedWidth/>
                        Export
                    </Button>
                </Col>
            </Row>
            {!!payHistoryStats ?
                <Row className="mt-3 small fill-height narrowed">
                    <Col className="fill-height horizontal-scroll">
                        <Table bordered hover size="sm" className="table-scrollable table-sortable w-auto">
                            <thead>
                            <tr className="table-secondary">
                                <th onClick={() => setSortColumn(SortColumnUpdate('fullName', sortColumn))}>Name</th>
                                <th className="td-lg" onClick={() => setSortColumn(SortColumnUpdate('startDate', sortColumn))}>Start
                                    Date
                                </th>
                                <th className="td-lg" onClick={() => setSortColumn(SortColumnUpdate('currentDate', sortColumn))}>Last
                                    Date
                                </th>
                                <th className="text-right td-xxl" onClick={() => setSortColumn(SortColumnUpdate('currentRate', sortColumn))}>Rate</th>
                                <th className="td-md" onClick={() => setSortColumn(SortColumnUpdate('currentPaymentType', sortColumn))}>H/S</th>
                                <th className="text-right td-md" onClick={() => setSortColumn(SortColumnUpdate('percentIncrease', sortColumn))}>Increase</th>
                            </tr>
                            </thead>
                            <tbody>
                            {usersList.map(userItem =>
                                <tr key={userItem.userID} onClick={() => {
                                    openUser(userItem.userID)
                                }}>
                                    <td>{userItem.fullName}</td>
                                    <td className="td-lg">{userItem.startDate}</td>
                                    <td className="td-lg">{userItem.currentDate}</td>
                                    <td className="text-right td-xxl">{ToCurrencyDash(userItem.currentRate)}</td>
                                    <td className="td-md">{userItem.currentPaymentType}</td>
                                    <td className="text-right td-md">{ToPercentDash(userItem.percentIncrease, 1)}</td>
                                </tr>
                            )}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                :
                null
            }
        </>
    );
};

export default PayHistory;

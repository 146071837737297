/**
 * Automatically generated: 2020-06-15 15:30:50
 * © 2020, Solid Basis Ventures, LLC.
 * DO NOT MODIFY
 *
 * Table Manager for: tblcustomer
 */

export interface Itblcustomer {
	customerID: number,
	name: string,
	active: number,
	invoice_notes: string
}

export const initialtblcustomer: Itblcustomer = {
	customerID: 0,
	name: '',
	active: 1,
	invoice_notes: ''
};

import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Button, Col, Container, Row} from "reactstrap";
import {AppState} from "./Stores/rootReducer";
import {Session_Logout} from "./Stores/user/actions";
import {useHistory} from 'react-router-dom';
import appLogo from "./Assets/GCILogo.png";
import * as serviceWorker from "./serviceWorker";

export const About = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const system = useSelector((state: AppState) => state.system);

    const checkClick = (e: React.MouseEvent<HTMLElement>) => {
        if (e.nativeEvent.detail === 5) {
            Session_Logout()(dispatch);
            history.push('/TestData');
        }
    };

    const reloadPage = () => {
        serviceWorker.unregister();
        window.location.reload();
    };

    return (
        <Container>
            <Row>
                <Col className="text-center">
                    <img className="img-xl"
                         src={appLogo}
                         alt="TTS"/>
                </Col>
            </Row>
            <Row>
                <Col className="text-center no-user-select"
                    onClick={checkClick}>
                    v{system.app_version}
                </Col>
            </Row>
            <Row>
                <Col className="text-center">
                    <Button onClick={reloadPage}>Reload App</Button>
                </Col>
            </Row>
        </Container>
    );
};

export default About;

import React from 'react';
// import {Col, Container, Row} from "reactstrap";
import {Redirect} from "react-router-dom";

export default function Page404() {
    return (
        <Redirect to="/"/>
    );

    // return (
    //     <Container>
    //         <Row className="p-4">
    //             <Col>
    //                 <h4 className="text-center mt-4">Page Not Found</h4>
    //             </Col>
    //         </Row>
    //         <Row className="p-4">
    //             <Col className="text-center text-underline">
    //                 <Link to="/TimeEntry">Return to the Time Entry</Link>
    //             </Col>
    //         </Row>
    //     </Container>
    // );
}

import {Itblproject} from "../Tables/tblproject";
import {initialtbltime, Itbltime} from "../Tables/tbltime";
import {Itbluser} from "../Tables/tbluser";
import {TimeStatus} from "../Enums/TimeStatus";
import {Itblproject_budget} from "../Tables/tblproject_budget";
import {Itblactivitycode} from "../Tables/tblactivitycode";

export interface IProjects extends Itblproject {
    project_assign_count: number
}

export interface ITime extends Itbltime {
    total_hours: number,
    is_billable: number,
    is_overtime: number,
    is_vacation: number,
    is_illness: number,
    is_holiday: number,
    is_base_to_ot_pay: number,
    prevhrs: number,
    localID: number
}

export interface ITimeHistory {
    projectID: number | null,
    activityID: number,
    prev_hrs: number,
    budget_hrs: number
}

export interface ITimeDataGeneric {
    curDate: string,
    projects: IProjects[],
    activities: Itblactivitycode[]
}

export interface ITimeDataSetExpiringDocs {
    id: number,
    userID: number,
    expiration_date: string,
    type_name: string,
    fullName: string
}

export interface ITimeDataSet {
    timeDataGeneric: ITimeDataGeneric,
    timeDataUsers: ITimeDataUser[],
    startDate: string | null,
    endDate: string | null,
    expiringDocs: ITimeDataSetExpiringDocs[]
}

export interface ITimeDataUser {
    curDate: string,
    view: boolean,
    admin: boolean,
    user: Itbluser,
    status: TimeStatus, // 0 = Draft, 2 = Submitted, 4 = Approved
    project_budgets: Itblproject_budget[],
    time: ITime[],
    timehistory: ITimeHistory[],
    ot_adjust: number,
    time_employeeNo: ITime[]
}

export const initialTime: ITime = {
    ...initialtbltime,
    total_hours: 0,
    is_billable: 0,
    is_overtime: 0,
    is_vacation: 0,
    is_illness: 0,
    is_holiday: 0,
    is_base_to_ot_pay: 0,
    prevhrs: 0,
    localID: 0
};

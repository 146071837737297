/**
 * Automatically generated: 2020-06-15 15:30:50
 * © 2020, Solid Basis Ventures, LLC.
 * DO NOT MODIFY
 *
 * Table Manager for: tbluser_file_type_group
 */

export interface Itbluser_file_type_group {
	id: number,
	name: string,
	sort_order: number,
	active: number
}

export const initialtbluser_file_type_group: Itbluser_file_type_group = {
	id: 0,
	name: '',
	sort_order: 9999999,
	active: 1
};

export const objTimeSummary = {
    projectHours: {} as any,
    payrollHours: {
        base_to_ot_pay: 0,
        non_base_to_ot_pay: 0
    },
    ot_adjust: 0,
    calcBilling: {
        regular: 0,
        overtime: 0,
        total: 0
    },
    calcPayroll: {
        regular: 0,
        overtime: 0,
        total: 0
    },
    reset: function () {
        objTimeSummary.projectHours = [];
        objTimeSummary.payrollHours = {
            base_to_ot_pay: 0,
            non_base_to_ot_pay: 0
        };
        objTimeSummary.ot_adjust = 0;
        objTimeSummary.resetCalc();
    },
    resetCalc: function () {
        objTimeSummary.calcBilling = {
            regular: 0,
            overtime: 0,
            total: 0
        };
        objTimeSummary.calcPayroll = {
            regular: 0,
            overtime: 0,
            total: 0
        };
    },
    addEntry: function (projectID: number | null, hours: number, is_billable: number, is_overtime: number, is_base_to_ot_pay: number) {
        if (!!projectID && is_billable === 1) {
            if (objTimeSummary.projectHours[projectID.toString()] === undefined) {
                objTimeSummary.projectHours[projectID.toString()] = {
                    base_hours: 0,
                    ot_hours: 0
                }
            }

            if (is_overtime === 1) {
                objTimeSummary.projectHours[projectID.toString()].ot_hours += hours;
            } else {
                objTimeSummary.projectHours[projectID.toString()].base_hours += hours;
            }
        }

        if (is_base_to_ot_pay === 1) {
            objTimeSummary.payrollHours.base_to_ot_pay += hours;
        } else {
            objTimeSummary.payrollHours.non_base_to_ot_pay += hours;
        }
    },
    calcResults: function () {
        objTimeSummary.resetCalc();

        for (const projectID in objTimeSummary.projectHours) {
            const project = objTimeSummary.projectHours[projectID];
            if (project.base_hours <= 40) {
                objTimeSummary.calcBilling.regular += project.base_hours;
                objTimeSummary.calcBilling.overtime += project.ot_hours;
            } else {
                objTimeSummary.calcBilling.regular += 40;
                objTimeSummary.calcBilling.overtime += (project.ot_hours +project.base_hours - 40);
            }
        }
        objTimeSummary.calcBilling.total = objTimeSummary.calcBilling.regular + objTimeSummary.calcBilling.overtime;

        if (objTimeSummary.payrollHours.base_to_ot_pay <= 40) {
            objTimeSummary.calcPayroll.regular = objTimeSummary.payrollHours.base_to_ot_pay + objTimeSummary.payrollHours.non_base_to_ot_pay;
            objTimeSummary.calcPayroll.total = objTimeSummary.calcPayroll.regular;
        } else {
            objTimeSummary.calcPayroll.regular = 40 + objTimeSummary.payrollHours.non_base_to_ot_pay;
            objTimeSummary.calcPayroll.overtime = objTimeSummary.payrollHours.base_to_ot_pay - 40;
            objTimeSummary.calcPayroll.total = objTimeSummary.calcPayroll.regular + objTimeSummary.calcPayroll.overtime;
        }

        objTimeSummary.calcPayroll.regular -= objTimeSummary.ot_adjust;
        objTimeSummary.calcPayroll.overtime += objTimeSummary.ot_adjust;
    }
};

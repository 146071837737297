import React, {useEffect, useMemo, useState} from 'react';
import {Button, Col, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {APIProcess, ElementCustomValue, ShowActivityOverlay, ShowMessageBox, HideActivityOverlay, InputDate, InputNumber, InputSelect} from '@denjpeters/intelliwakereact';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import {IWake} from "../../IWake";
import {Itblpayhistory} from "../../Data/Tables/tblpayhistory";

interface IProps {
    tblpayhistory: Itblpayhistory | null,
    closeModal: (() => void),
    doForceUpdate: (() => void)
}

const UserPayHistoryModal = (props: IProps) => {
    const dispatch = useDispatch();
    const user = useSelector((state: AppState) => state.user);
    const [editPayHistory, setEditPayHistory] = useState(null as Itblpayhistory | null);

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    useEffect(() => {
        setEditPayHistory(props.tblpayhistory);
    }, [props.tblpayhistory])

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name;
        const value = ElementCustomValue(e);

        setEditPayHistory(prevState => {
                return {
                    ...prevState,
                    [name]: value
                } as Itblpayhistory
            }
        );
    }

    const saveItem = () => {
        if (!!editPayHistory) {
            ShowActivityOverlay()(dispatch);
            APIProcess('PayHistory', 'Save', editPayHistory)(iWake)
                .then(() => {
                    ShowMessageBox('Pay History item saved')(dispatch);
                    props.doForceUpdate();
                    props.closeModal();
                })
                .catch(() => {
                })
                .finally(() => {
                    HideActivityOverlay()(dispatch);
                });
        }
    }

    const deleteItem = () => {
        if (!!editPayHistory && editPayHistory.id > 0) {
            ShowActivityOverlay()(dispatch);
            APIProcess('PayHistory', 'Delete', editPayHistory)(iWake)
                .then(() => {
                    ShowMessageBox('Pay History item deleted')(dispatch);
                    props.doForceUpdate();
                    props.closeModal();
                })
                .catch(() => {
                })
                .finally(() => {
                    HideActivityOverlay()(dispatch);
                });
        }
    }

    return (
        <Modal backdrop keyboard toggle={props.closeModal} isOpen={!!props.tblpayhistory}>
            <ModalHeader className="alert-info">
                Pay History
            </ModalHeader>
            <ModalBody>
                {!!editPayHistory ?
                    <Row>
                        <Col className="form-sm">
                            <FormGroup>
                                <Label>Date</Label>
                                <Col className="medium">
                                    <InputDate name="date" value={editPayHistory.date} onChange={handleInputChange}/>
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Label>Rate</Label>
                                <Col className="medium">
                                    <InputNumber name="rate" value={editPayHistory.rate} currency onChange={handleInputChange}/>
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Label>Type</Label>
                                <Col className="medium">
                                    <InputSelect name="payment_type" value={editPayHistory.payment_type} onChange={handleInputChange}>
                                        <option value="Salary">Salary</option>
                                        <option value="Hourly">Hourly</option>
                                    </InputSelect>
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                    :
                    null
                }
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={props.closeModal} className="mr-auto">Close</Button>
                {!!props.tblpayhistory && props.tblpayhistory.id > 0 ?
                    <Button color="outline-danger" onClick={deleteItem}>Delete</Button>
                    :
                    null
                }
                <Button color="success" onClick={saveItem}>Save</Button>
            </ModalFooter>
        </Modal>
    );
};

export default UserPayHistoryModal;

import React, {FormEvent, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import {APIProcess, ShowActivityOverlay, HideActivityOverlay, CleanNumber, ShowMessageBox, ElementCustomValue, InputSelect, InputSwitch, ActivityOverlayControl} from '@denjpeters/intelliwakereact';
import {Col, Form, FormFeedback, FormGroup, Input, Label} from "reactstrap";
import {Itblauth_role} from "../../Data/Tables/tblauth_role";
import BlockNav from "../Generics/BlockNav";
import {Itblauth_role_has_feature} from "../../Data/Tables/tblauth_role_has_feature";
import {useHistory} from 'react-router-dom';
import {IWake} from "../../IWake";

interface IProps {
    auth_role: Itblauth_role,
    auth_role_has_features: Itblauth_role_has_feature[],
    features: string[],
    forceRefresh: (() => void)
}

const AuthRole = (props: IProps) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const isMounted = useRef(true);
    const user = useSelector((state: AppState) => state.user);
    const [authRole, setAuthRole] = useState(null as Itblauth_role | null);
    const [submitAttempted, setSubmitAttempted] = useState(false);
    const [blockNavigation, setBlockNavigation] = useState(false);

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    const formSubmit = (e: FormEvent) => {
        e.preventDefault();

        if (blockNavigation) {
            setSubmitAttempted(true);

            if (!!authRole) {
                if (authRole.name) {
                    ShowActivityOverlay()(dispatch);
                    APIProcess('Auth_Role', 'Save', authRole)(iWake)
                        .then((result) => {
                            if (isMounted.current) {
                                setSubmitAttempted(false);
                                setBlockNavigation(false);
                                const newState = {...authRole, auth_roleID: CleanNumber(result.auth_roleID)};
                                const newItem = !authRole.auth_roleID;

                                setAuthRole(newState);

                                props.forceRefresh();

                                if (newItem) {
                                    history.replace('/AuthRoles/AutoRole/' + newState.auth_roleID);
                                }

                                ShowMessageBox('Saved')(dispatch);
                            }
                        })
                        .catch(() => {
                        })
                        .finally(() => {
                            HideActivityOverlay()(dispatch);
                        });
                } else {
                    ShowMessageBox('Name required', "danger")(dispatch);
                }
            }
        }
    }

    const changeFeature = (featureID: string, isOn: number) => {
        if (!!props.auth_role.auth_roleID) {
            ShowActivityOverlay()(dispatch);
            APIProcess('Auth_Role', !!isOn ? 'FeatureAdd' : 'FeatureRemove', {
                auth_role_id: props.auth_role.auth_roleID,
                feature_id: featureID
            })(iWake)
                .then(() => {
                    if (isMounted.current) {
                        props.forceRefresh();

                        ShowMessageBox('Saved')(dispatch);
                    }
                })
                .catch(() => {
                })
                .finally(() => {
                    HideActivityOverlay()(dispatch);
                });
        }
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAuthRole({
            ...authRole,
            [e.target.name]: ElementCustomValue(e)
        } as Itblauth_role);

        setBlockNavigation(true);
    }

    useEffect(() => {
        setAuthRole(props.auth_role);
    }, [props.auth_role]);

    return (
        <>
            {!!authRole ?
                <>
                    <Form onSubmitCapture={formSubmit} onBlur={formSubmit} className="form-sm fill-height-scroll mt-4">
                        <FormGroup>
                            <Label>Auth Role Name</Label>
                            <Col className="medium">
                                <Input type="text" name="name" placeholder="AuthRole Name" value={authRole.name ?? ""} valid={false} invalid={submitAttempted && !(authRole.name.length > 0)} onChange={handleInputChange}/>
                                <FormFeedback valid={!submitAttempted && authRole.name.length > 0}>
                                    Name required
                                </FormFeedback>
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Label>Enabled?</Label>
                            <Col className="short">
                                <InputSelect name="active" value={authRole.active} onChange={handleInputChange} isNumeric>
                                    <option value={1}>Active</option>
                                    <option value={0}>Inactive</option>
                                </InputSelect>
                            </Col>
                        </FormGroup>
                        {!!props.auth_role.auth_roleID ?
                            <FormGroup>
                                <Label>Features</Label>
                                <Col>
                                    <div className="form-control-plaintext">
                                        {props.features.map(feature =>
                                            <InputSwitch key={feature} name={feature} onChange={(e) => {
                                                changeFeature(feature, ElementCustomValue(e))
                                            }} value={!!props.auth_role_has_features.find(arhf => (arhf.auth_roleID === authRole.auth_roleID && arhf.featureID === feature)) ? 1 : 0} label={feature}/>
                                        )}
                                    </div>
                                </Col>
                            </FormGroup>
                            :
                            null
                        }
                    </Form>
                </>
                :
                null
            }
            <ActivityOverlayControl show={!authRole}/>
            <BlockNav when={blockNavigation}/>
        </>
    );
};

export default AuthRole;

import React, {FormEvent, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import {APIProcess, ShowActivityOverlay, CleanNumber, ShowMessageBox, HideActivityOverlay, ElementCustomValue, InputSelect, ActivityOverlayControl} from '@denjpeters/intelliwakereact';
import axios, {CancelTokenSource} from "axios";
import {Col, Form, FormFeedback, FormGroup, Input, Label} from "reactstrap";
import {initialtblcustomer, Itblcustomer} from "../../Data/Tables/tblcustomer";
import BlockNav from "../Generics/BlockNav";
import {IWake} from "../../IWake";

interface IProps {
    customerID?: number | null,
    updateCustomer?: Function | undefined
}

const Customer = (props: IProps) => {
    const dispatch = useDispatch();
    const isMounted = useRef(true);
    const user = useSelector((state: AppState) => state.user);
    const [customer, setCustomer] = useState(null as Itblcustomer | null);
    const [submitAttempted, setSubmitAttempted] = useState(false);
    const [blockNavigation, setBlockNavigation] = useState(false);

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    const formSubmit = (e: FormEvent) => {
        e.preventDefault();

        if (blockNavigation) {
            setSubmitAttempted(true);

            if (!!customer) {
                if (customer.name) {
                    ShowActivityOverlay()(dispatch);
                    APIProcess('customer', 'Save', customer)(iWake)
                        .then((result) => {
                            if (isMounted.current) {
                                setSubmitAttempted(false);
                                setBlockNavigation(false);
                                const newState = {...customer, customerID: CleanNumber(result.customerID)};
                                setCustomer(newState);
                                if (props.updateCustomer instanceof Function) {
                                    props.updateCustomer(newState);
                                }
                                ShowMessageBox('Saved')(dispatch);
                            }
                        })
                        .catch(() => {
                        })
                        .finally(() => {
                            HideActivityOverlay()(dispatch);
                        });
                } else {
                    ShowMessageBox('Name required', "danger")(dispatch);
                }
            }
        }
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCustomer({
            ...customer,
            [e.target.name]: ElementCustomValue(e)
        } as Itblcustomer);

        setBlockNavigation(true);
    }

    useEffect(() => {
        let cancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();
        isMounted.current = true;

        APIProcess('customer', 'Get', {customerID: props.customerID})(iWake)
            .then((results) => {
                if (isMounted.current && cancelTokenSource) {
                    setBlockNavigation(false);
                    setSubmitAttempted(false);
                    setCustomer({...initialtblcustomer, ...results.customer});
                }
            })
            .catch(() => {
                if (isMounted.current && cancelTokenSource) {
                    setBlockNavigation(false);
                    setSubmitAttempted(false);
                    setCustomer(initialtblcustomer);
                }
            })
            .finally(() => {
                cancelTokenSource = null;
            });

        return () => {
            isMounted.current = false;
            if (cancelTokenSource) {
                cancelTokenSource.cancel();
                cancelTokenSource = null;
            }
        }
    }, [iWake, props.customerID]);

    return (
        <>
            {!!customer ?
                <>
                    <Form onSubmitCapture={formSubmit} onBlur={formSubmit} className="form-sm fill-height-scroll mt-4 p-2">
                        <FormGroup>
                            <Label>Customer Name</Label>
                            <Col className="medium">
                                <Input type="text" name="name" placeholder="Customer Name" value={customer.name ?? ""} valid={false} invalid={submitAttempted && !(customer.name.length > 0)} onChange={handleInputChange}/>
                                <FormFeedback valid={!submitAttempted && customer.name.length > 0}>Login
                                    name
                                    required</FormFeedback>
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Label>Enabled?</Label>
                            <Col className="short">
                                <InputSelect name="active" value={customer.active} onChange={handleInputChange} isNumeric>
                                    <option value="1">Active</option>
                                    <option value="0">Inactive</option>
                                </InputSelect>
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Label>Invoicing Frequency</Label>
                            <Col className="medium">
                                <Input type="text" name="invoice_notes" value={customer.invoice_notes} placeholder="Custom Invoicing Frequency" onChange={handleInputChange}/>
                            </Col>
                        </FormGroup>
                    </Form>

                </>
                :
                null
            }
            <ActivityOverlayControl show={!customer}/>
            <BlockNav when={blockNavigation}/>
        </>
    );
};

export default Customer;

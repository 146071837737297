export interface LocalStoreState {
    DBPhotoQueue: number
}

export const UPDATE_LOCAL_STORE = 'UPDATE_LOCAL_STORE';

interface UpdateLocalStoreAction {
    type: typeof UPDATE_LOCAL_STORE
    payload: LocalStoreState
}

export type LocalStoreActionTypes = UpdateLocalStoreAction

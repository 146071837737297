/**
 * Automatically generated: 2020-06-15 15:30:50
 * © 2020, Solid Basis Ventures, LLC.
 * DO NOT MODIFY
 *
 * Table Manager for: tbltime
 */

export interface Itbltime {
	timeID: number,
	userID: number,
	projectID: number | null,
	activityID: number,
	date: string,
	mon: number,
	tues: number,
	wed: number,
	thurs: number,
	fri: number,
	sat: number,
	sun: number,
	status: number,
	hold_for_review: number,
	projectID_dup_source: number | null,
	approved_userID: number | null,
	approved_name: string | null,
	approved_datetime: string | null,
	approved_delegate_userID: number | null
}

export const initialtbltime: Itbltime = {
	timeID: 0,
	userID: 0,
	projectID: null,
	activityID: 0,
	date: '',
	mon: 0.00,
	tues: 0.00,
	wed: 0.00,
	thurs: 0.00,
	fri: 0.00,
	sat: 0.00,
	sun: 0.00,
	status: 0,
	hold_for_review: 0,
	projectID_dup_source: null,
	approved_userID: null,
	approved_name: null,
	approved_datetime: null,
	approved_delegate_userID: null
};

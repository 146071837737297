import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import axios, {CancelTokenSource} from "axios";
import {APIProcess, ShowActivityOverlay, HideActivityOverlay} from '@denjpeters/intelliwakereact';
import {ITimeDataSet, ITimeDataUser} from "../../Data/Views/Time";
import {IWake} from "../../IWake";
import TimeSheets from "./TimeSheets";
import {IHours} from "../Invoice/Invoice";
import InvoiceHourSummary from "../Invoice/InvoiceHourSummary";
import {Itblinvoice} from "../../Data/Tables/tblinvoice";

export interface ITimeSheetProps {
    invoiceID: number
}

interface ITimeDataSetInvoice extends ITimeDataSet {
    projectID: number,
    invoice: Itblinvoice,
    hours: IHours[]
}

const TimeSheetsInvoice = (props: ITimeSheetProps) => {
    const dispatch = useDispatch();
    const isMounted = useRef(true);
    const {user, appSessionRemembersChange} = useSelector((state: AppState) => state);
    const [timeDataSet, setTimeDataSet] = useState(null as ITimeDataSetInvoice | null);
    const [forceRefresh, setForceRefresh] = useState(false);

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    useEffect(() => {
        let cancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();
        isMounted.current = true;

        ShowActivityOverlay()(dispatch);
        APIProcess('time', 'GetInvoiceDatum', {
            invoiceID: props.invoiceID
        }, cancelTokenSource)(iWake)
            .then((results) => {
                console.log(results);
                if (isMounted.current && cancelTokenSource) {
                    const loadedTimeDataSet = {
                        ...results,
                        timeDataUsers: [
                            ...results.timeDataUsers.sort((a: ITimeDataUser, b: ITimeDataUser) => a.user.lastname.localeCompare(b.user.lastname, undefined, {sensitivity: 'base'}) ?? a.user.firstname.localeCompare(b.user.firstname, undefined, {sensitivity: 'base'}) ?? a.curDate.localeCompare(b.curDate, undefined, {sensitivity: 'base'}))
                        ]
                    } as ITimeDataSetInvoice;

                    setTimeDataSet(loadedTimeDataSet);
                }
            })
            .catch(() => {
                if (isMounted.current && cancelTokenSource) {
                    setTimeDataSet(null);
                }
            })
            .finally(() => {
                HideActivityOverlay()(dispatch);
                cancelTokenSource = null;
            });

        return () => {
            isMounted.current = false;
            if (cancelTokenSource) {
                cancelTokenSource.cancel();
                cancelTokenSource = null;
            }
        }
    }, [appSessionRemembersChange.viewUserID, appSessionRemembersChange.viewDate, props.invoiceID, forceRefresh, dispatch, user, iWake]);

    return (
        <>
            {!!timeDataSet ?
                <>
                    <div className="page-break-after narrowed">
                        <InvoiceHourSummary projectID={timeDataSet.projectID} invoice={timeDataSet.invoice} summaryHours={timeDataSet.hours} showOpenButton/>
                    </div>
                    <TimeSheets timeDataGeneric={timeDataSet.timeDataGeneric} timeDataUsers={timeDataSet.timeDataUsers} forceRefresh={() => setForceRefresh(prevState => !prevState)} highlightDateRange={[timeDataSet.invoice.start_date, timeDataSet.invoice.end_date]} highlightProjectNo={timeDataSet.invoice.projectNo}/>
                </>
                :
                null
            }
        </>
    );
};

export default TimeSheetsInvoice;

/**
 * Automatically generated: 2020-06-15 15:30:50
 * © 2020, Solid Basis Ventures, LLC.
 * DO NOT MODIFY
 *
 * Table Manager for: tblactivitycode
 */

export interface Itblactivitycode {
	activityID: number,
	name: string,
	rate: number,
	active: number, // 0 = No; 1 = Yes
	is_billable: number, // 0 = No; 1 = Yes
	is_overtime: number, // 0 = No; 1 = Yes
	is_vacation: number, // 0 = No; 1 = Yes
	is_illness: number, // 0 = No; 1 = Yes
	is_holiday: number, // 0 = No; 1 = Yes
	is_base_to_ot_pay: number
}

export const initialtblactivitycode: Itblactivitycode = {
	activityID: 0,
	name: '',
	rate: 0,
	active: 1,
	is_billable: 0,
	is_overtime: 0,
	is_vacation: 0,
	is_illness: 0,
	is_holiday: 0,
	is_base_to_ot_pay: 1
};

import {UserState, UPDATE_USER, UserActionTypes, UserUpdateState, UPDATE_SESSION_USER} from './types'
import * as serviceWorker from "../../serviceWorker";

function updateSessionUser(newSession: UserState): UserActionTypes {
    return {
        type: UPDATE_SESSION_USER,
        payload: newSession
    }
}

function updateUser(newSession: UserUpdateState): UserActionTypes {
    return {
        type: UPDATE_USER,
        payload: newSession
    }
}

function resetApp() {
    return {
        type: 'RESET_APP',
        payload: undefined
    }
}

export const Update_User = (user: UserUpdateState) => {
    return (dispatch: any) => {
        dispatch(updateUser(user));
    }
};

export const Session_Login = (user: UserState) => {
    return (dispatch: any) => {
        dispatch(updateSessionUser(user));
    }
};

export const Session_Logout = () => {
    return (dispatch: any) => {
        const fullLogout = (!window.sessionStorage.getItem('device_token_for'));
        const html_version = window.localStorage.getItem('html_version');
        if (fullLogout) {
            window.localStorage.clear();
            if (html_version) {
                window.localStorage.setItem('html_version', html_version);
            }
        }

        window.sessionStorage.clear();
        dispatch(resetApp());

        if (fullLogout) {
            serviceWorker.unregister();
        }
    }
};

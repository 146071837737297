import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import {Link, useHistory} from "react-router-dom";
import {HasFeature, TTSFeatures} from "../../Data/TTSFeatures";
import {Button, Col, Container, Row, Table} from "reactstrap";
import axios, {CancelTokenSource} from "axios";
import {APIProcess, SelectDD, InputSearch, ActivityOverlayControl} from '@denjpeters/intelliwakereact';
import ActiveDD from "../Generics/ActiveDD";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter} from "@fortawesome/pro-regular-svg-icons";
import {ASRSetProjectSort} from "../../Stores/appsessionremembers/actions";
import {Itblproject} from "../../Data/Tables/tblproject";
import {IWake} from "../../IWake";

interface IProjectList extends Itblproject {
    customerName: string | null
}

const Projects = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const isMounted = useRef(true);
    const {user, appSessionRemembersChange} = useSelector((state: AppState) => state);
    const [projects, setProjects] = useState(null as IProjectList[] | null);
    const [activeView, setActiveView] = useState(1 as 1 | -1 | 0);
    const [search, setSearch] = useState("");

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    useEffect(() => {
        let cancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();
        isMounted.current = true;

        APIProcess('project', 'GetList', {all: true}, cancelTokenSource)(iWake)
            .then((results) => {
                if (isMounted.current && cancelTokenSource) {
                    setProjects(results.projects);
                }
            })
            .catch(() => {
                if (isMounted.current && cancelTokenSource) {
                    setProjects([]);
                }
            })
            .finally(() => {
                cancelTokenSource = null;
            });

        return () => {
            isMounted.current = false;
            if (cancelTokenSource) {
                cancelTokenSource.cancel();
                cancelTokenSource = null;
            }
        }
    }, [iWake, activeView]);

    const searchTerms = useMemo(() => !search ? [] : search
        .split(' ')
        .map(term => term.trim().toLowerCase())
        .filter(term => !!term), [search]);

    const projectList = useMemo(() =>
            (projects ?? [])
                .filter(projectItem =>
                    ((activeView === -1 || (activeView === 1 && projectItem.disable === 'no') || (activeView === 0 && projectItem.disable === 'yes')) &&
                        (searchTerms.length === 0 ||
                            (searchTerms.every(term => ((projectItem.projectNo ?? "").toLowerCase().includes(term) ||
                                (projectItem.name ?? "").toLowerCase().includes(term) ||
                                (projectItem.customer_projectNo ?? "").toLowerCase().includes(term) ||
                                (projectItem.addendumNo ?? "").toLowerCase().includes(term) ||
                                (projectItem.amendmentNo ?? "").toLowerCase().includes(term) ||
                                (projectItem.customerName ?? "").toLowerCase().includes(term)))))))
                .sort((a, b) => {
                        switch (appSessionRemembersChange.projectSort) {
                            case "Name":
                                return a.name.localeCompare(b.name, undefined, {sensitivity: 'base'});
                            case "NoAsc":
                                return a.projectNo.localeCompare(b.projectNo, undefined, {sensitivity: 'base'});
                            default:
                                return b.projectNo.localeCompare(a.projectNo, undefined, {sensitivity: 'base'});
                        }
                    }
                )
        , [searchTerms, projects, appSessionRemembersChange.projectSort, activeView]);

    return (
        <Container fluid>
            {!!projects ?
                <>
                    <Row className="p-2">
                        <Col xs={6}>
                            {HasFeature(user, TTSFeatures.Feature_Project_Admin) ?
                                <Button color="secondary" size="sm" tag={Link} to={"/Project/New"}>Add
                                    Project</Button>
                                :
                                null
                            }
                        </Col>
                        <Col xs={6} className="text-right">
                            <ActiveDD size="sm" handleSelectID={setActiveView} selectedID={activeView} caret className="d-inline-block ml-2"/>
                            <SelectDD size="sm" items={[
                                {id: 'Name', name: 'Project Name'},
                                {id: 'NoAsc', name: 'Project #, Ascending'},
                                {id: 'NoDesc', name: 'Project #, Descending'}
                            ]} selectedID={appSessionRemembersChange.projectSort} handleSelectID={(id: string | number | null) => {
                                ASRSetProjectSort(!!id ? (id as "Name" | "NoAsc" | "NoDesc") : 'NoDesc')(dispatch);
                            }} caret className="d-inline-block ml-2"/>
                        </Col>
                    </Row>
                    <Row className="p-2">
                        <Col xs={6}>
                            <h4>Projects</h4>
                        </Col>
                        <Col xs={6} className="text-right">
                            <div className="input-group input-group-filter">
                                <div className="input-group-prepend">
                                    <span className="input-group-text"><FontAwesomeIcon icon={faFilter} fixedWidth/></span>
                                </div>
                                <InputSearch className="noFocusRing" placeholder="Filter" aria-label="Filter" triggerSearchText={setSearch}/>
                            </div>
                        </Col>
                    </Row>
                    <Row className="p-2 fill-height">
                        <Col className="fill-height">
                            <Table size="sm" hover bordered className="table-scrollable">
                                <thead>
                                <tr className="table-secondary">
                                    <th className="td-lg">#</th>
                                    <th>Name</th>
                                    <th className="td-xxl">Customer</th>
                                    <th className="td-md">Addend.</th>
                                    <th className="td-md">Amend.</th>
                                    <th className="td-xl">Cust #</th>
                                    <th className="td-md">Status</th>
                                    <th className="td-md"/>
                                </tr>
                                </thead>
                                <tbody>
                                {projectList.map(project =>
                                    <tr key={project.projectID} onClick={() => {history.push('/Project/' + project.projectID)}}>
                                        <td className="td-lg">{project.projectNo}</td>
                                        <td>{project.name}</td>
                                        <td className="td-xxl">{project.customerName}</td>
                                        <td className="td-md">{project.addendumNo}</td>
                                        <td className="td-md">{project.amendmentNo}</td>
                                        <td className="td-xl">{project.customer_projectNo}</td>
                                        <td className="td-md">{project.disable === 'no' ? 'Active' : 'Disabled'}</td>
                                        <td className="td-md" onClick={(e) => {e.stopPropagation()}}>
                                            <Button type="button" color="link" className="btn-link-inline">Report</Button>
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </>
                :
                null
            }
            <ActivityOverlayControl show={!projects}/>
        </Container>
    );
};

export default Projects;

export interface AppSettings {
    shouldSave: boolean,
    reportsInvoicingCollapsed: boolean
}

export const APPSETTINGS_ALL_UPDATED = 'APPSETTINGS_ALL_UPDATED';
export const APPSETTINGS_REPORTSINVOICINGCOLLAPSED = 'APPSETTINGS_REPORTSINVOICINGCOLLAPSED';

interface AppSettingsAllUpdatedAction {
    type: typeof APPSETTINGS_ALL_UPDATED
    payload: any
}

interface AppSettingsReportsInvoicingCollapsed {
    type: typeof APPSETTINGS_REPORTSINVOICINGCOLLAPSED
    payload: boolean
}

export type AppSettingsActionTypes = AppSettingsAllUpdatedAction | AppSettingsReportsInvoicingCollapsed;

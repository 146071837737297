import React, {useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import {
    Button,
    Col,
    Row, Table
} from "reactstrap";
import {APIProcess, ShowActivityOverlay, ShowMessageBox, HideActivityOverlay, InputSelect, ElementCustomValue, ActivityOverlayControl} from '@denjpeters/intelliwakereact';
import {Itblproject} from "../../Data/Tables/tblproject";
import {IUsers} from "../../Data/Views/Users";
import {Itblprojectsuper} from "../../Data/Tables/tblprojectsuper";
import {IWake} from "../../IWake";

interface IProps {
    tblproject: Itblproject,
    setTblproject: ((tblproject: Itblproject) => void),
    tblusers: IUsers[],
    tblprojectsupers: Itblprojectsuper[],
    tblusers_timesheet_approval: IUsers[],
    tblusers_project_reports: IUsers[],
    doForceRefresh: (() => void)
}

const ProjectApprovals = (props: IProps) => {
    const dispatch = useDispatch();
    const user = useSelector((state: AppState) => state.user);

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    const userName = (userID: number): string => {
        const user = props.tblusers.find(tbluser => tbluser.userID === userID);

        if (!!user) {
            return user.lastname + ', ' + user.firstname;
        }

        return '';
    }

    const addUser = (userID: number, timeApprover: boolean) => {
        ShowActivityOverlay()(dispatch);

        APIProcess('projectsuper', timeApprover ? 'AddUser' : 'AddUserAdmin', {projectid: props.tblproject.projectID, userid: userID})(iWake)
            .then(() => {
                props.doForceRefresh();
                ShowMessageBox('Employee added')(dispatch);
            })
            .catch(() => {
            })
            .finally(() => {
                HideActivityOverlay()(dispatch);
            });
    };

    const deleteUser = (projectsuperid: number) => {
        ShowActivityOverlay()(dispatch);

        APIProcess('projectsuper', 'RemoveUser', {projectsuperid: projectsuperid})(iWake)
            .then(() => {
                props.doForceRefresh();
                ShowMessageBox('Employee removed')(dispatch);
            })
            .catch(() => {
            })
            .finally(() => {
                HideActivityOverlay()(dispatch);
            });
    };

    return (
        <>
            {!!props.tblproject ?
                <Row>
                    <Col md={5}>
                        <Row>
                            <Col xs={10}>
                                <InputSelect value={0} onChange={(e) => {
                                    const userID = ElementCustomValue(e) ?? 0;

                                    if (!!userID) {
                                        addUser(userID, true);
                                    }
                                }} isNumeric>
                                    <option value="0">Add Time-Sheet Approver to project...</option>
                                    {props.tblusers_timesheet_approval
                                        .filter(tblUser =>
                                            !props.tblprojectsupers.find(supers => tblUser.userID === supers.userid && supers.is_timeapprover === 1))
                                        .sort((a, b) => (a.lastname ?? "").localeCompare(b.lastname ?? "", undefined, {sensitivity: 'base'}) ??
                                            (a.firstname ?? "").localeCompare(b.firstname ?? "", undefined, {sensitivity: 'base'}))
                                        .map(addUser =>
                                        <option key={addUser.userID} value={addUser.userID}>
                                            {addUser.lastname}, {addUser.firstname}
                                        </option>
                                    )}
                                </InputSelect>
                            </Col>
                        </Row>
                        <Table size="sm" className="mt-3">
                            <thead>
                            <tr>
                                <th colSpan={2}>Timesheet Approvers</th>
                            </tr>
                            </thead>
                            <tbody>
                            {props.tblprojectsupers
                                .filter(tblprojectsuper => tblprojectsuper.is_timeapprover === 1)
                                .map(projectSuper =>
                                <tr key={projectSuper.projectsuperid}>
                                    <td>
                                        {userName(projectSuper.userid)}
                                    </td>
                                    <td className="text-right">
                                        <Button type="button" color="link" className="btn-link-inline" onClick={() => {deleteUser(projectSuper.projectsuperid)}}>Remove</Button>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </Table>
                    </Col>
                    <Col md={{size: 5, offset: 2}}>
                        <Row>
                            <Col xs={10}>
                                <InputSelect value={0} onChange={(e) => {
                                    const userID = ElementCustomValue(e) ?? 0;

                                    if (!!userID) {
                                        addUser(userID, false);
                                    }
                                }} isNumeric>
                                    <option value="0">Add Reporter to project...</option>
                                    {props.tblusers_project_reports
                                        .filter(tblUser =>
                                            !props.tblprojectsupers.find(supers => tblUser.userID === supers.userid && supers.is_timeapprover === 0))
                                        .sort((a, b) => (a.lastname ?? "").localeCompare(b.lastname ?? "", undefined, {sensitivity: 'base'}) ??
                                            (a.firstname ?? "").localeCompare(b.firstname ?? "", undefined, {sensitivity: 'base'}))
                                        .map(addUser =>
                                        <option key={addUser.userID} value={addUser.userID}>
                                            {addUser.lastname}, {addUser.firstname}
                                        </option>
                                    )}
                                </InputSelect>
                            </Col>
                        </Row>
                        <Table size="sm" className="mt-3">
                            <thead>
                            <tr>
                                <th colSpan={2}>Project Reporters</th>
                            </tr>
                            </thead>
                            <tbody>
                            {props.tblprojectsupers
                                .filter(tblprojectsuper => tblprojectsuper.is_timeapprover === 0)
                                .map(projectSuper =>
                                <tr key={projectSuper.projectsuperid}>
                                    <td>
                                        {userName(projectSuper.userid)}
                                    </td>
                                    <td className="text-right">
                                        <Button type="button" color="link" className="btn-link-inline" onClick={() => {deleteUser(projectSuper.projectsuperid)}}>Remove</Button>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                :
                null
            }
            <ActivityOverlayControl show={!props.tblproject}/>
        </>
    );
};

export default ProjectApprovals;

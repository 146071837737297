import {
    AppSettings, APPSETTINGS_ALL_UPDATED, APPSETTINGS_REPORTSINVOICINGCOLLAPSED,
    AppSettingsActionTypes
} from './types'

export const initialAppSettings: AppSettings = {
    shouldSave: false,
    reportsInvoicingCollapsed: false
};

export function reducerAppSettings(
    state = initialAppSettings,
    action: AppSettingsActionTypes
): AppSettings {
    switch (action.type) {
        case APPSETTINGS_ALL_UPDATED: {
            return {
                ...state,
                shouldSave: false,
                ...action.payload
            };
        }
        case APPSETTINGS_REPORTSINVOICINGCOLLAPSED: {
            return {
                ...state,
                shouldSave: true,
                reportsInvoicingCollapsed: action.payload
            };
        }
        default:
            return state
    }
}

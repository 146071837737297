import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Button, Input, Spinner} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import {APIProcess, ToDigitsBlank, ElementCustomValue, ShowActivityOverlay, ShowMessageBox, HideActivityOverlay, CleanNumber, InputSelect, InputNumber, ToCurrencyBlank} from '@denjpeters/intelliwakereact';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimesCircle} from "@fortawesome/pro-solid-svg-icons";
import {IMile, IMileData} from "../Mileage";
import moment from "moment";
import {IWake} from "../../IWake";

interface IProps {
    mileData: IMileData,
    mileRow: IMile,
    days: string[],
    updateMileRow: ((mileRow: IMile, newID: number) => void),
    deleteMileRow: ((mileRow: IMile) => void),
    focusElementColumn: string | null,
    focusMoveUp: ((fromRowID: number, fromColumnName: string) => void),
    focusMoveDown: ((fromRowID: number, fromColumnName: string) => void),
    clearFocusElement: (() => void)
}

const MileageReadWrite = (props: IProps) => {
    const dispatch = useDispatch();
    const isMounted = useRef(true);
    const saveTimeout = useRef(setTimeout(() => {
    }, 1));
    const focusRef = useRef(null as any | null);
    const {user} = useSelector((state: AppState) => state);
    const [isDirty, setIsDirty] = useState(false);

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    const handleKeyDown = (e: React.KeyboardEvent) => {
        const name = (e.target as any).name;

        switch (e.keyCode) {
            case 40: // down
                props.focusMoveDown(props.mileRow.mileageID, name);
                break;
            case 38: // up
                props.focusMoveUp(props.mileRow.mileageID, name);
                break;
        }
    };

    const handleInputChange = (e: React.ChangeEvent<any>) => {
        const newMileRow = {
            ...props.mileRow,
            [e.target.name]: ElementCustomValue(e)
        } as IMile;

        props.updateMileRow(newMileRow, props.mileRow.mileageID);

        if (!isDirty && !!newMileRow.destination && !!newMileRow.startodometer && !!newMileRow.endodometer) {
            setIsDirty(true);
        }

        if (isDirty || (!!newMileRow.destination && !!newMileRow.startodometer && !!newMileRow.endodometer)) {
            clearTimeout(saveTimeout.current);
            saveTimeout.current = setTimeout(() => {
                saveChanges(newMileRow)
            }, 1500);
        }
    };

    const saveChanges = (mileRow: IMile) => {
        if (mileRow.mileageID === 0) {
            ShowActivityOverlay()(dispatch);
        }
        APIProcess('mileage', 'Save', mileRow)(iWake)
            .then((results) => {
                if (isMounted.current) {
                    if (mileRow.mileageID === 0) {
                        props.updateMileRow(mileRow, parseInt(results.mileageID));
                    }

                    setIsDirty(false);
                }
            })
            .catch(() => {
                if (isMounted.current) {
                    ShowMessageBox('Could not save', 'warning')(dispatch);
                }
            })
            .finally(() => {
                if (mileRow.mileageID === 0) {
                    HideActivityOverlay()(dispatch);
                }
            });
    };

    const deleteRow = () => {
        ShowActivityOverlay()(dispatch);
        APIProcess('mileage', 'Delete', props.mileRow)(iWake)
            .then(() => {
                HideActivityOverlay()(dispatch);
                if (isMounted.current) {
                    props.deleteMileRow(props.mileRow);
                }
            })
            .catch(() => {
                HideActivityOverlay()(dispatch);
                if (isMounted.current) {
                    ShowMessageBox('Could not delete', 'warning')(dispatch);
                }
            });
    };

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        e.target.select();
    };

    const {clearFocusElement} = props;
    useEffect(() => {
        isMounted.current = true;

        if (!!props.focusElementColumn && !!focusRef.current) {
            focusRef.current!.focus();
            clearFocusElement();
        }

        return () => {
            isMounted.current = false;
        }
    }, [props.focusElementColumn, clearFocusElement]);

    const totalMiles = useMemo(() => {
        const miles = CleanNumber(props.mileRow.endodometer) - CleanNumber(props.mileRow.startodometer);

        return miles > 0 ? miles : 0;
    }, [props.mileRow.endodometer, props.mileRow.startodometer]);

    const totalAmount = useMemo(() => {
        const amount = (CleanNumber(props.mileRow.endodometer) - CleanNumber(props.mileRow.startodometer)) * props.mileRow.rate_amount;

        return amount > 0 ? amount : 0;
    }, [props.mileRow.endodometer, props.mileRow.startodometer, props.mileRow.rate_amount]);

    return (
        <tr>
            <td>
                <InputSelect name="date" value={moment(props.mileRow.date).format('Y-MM-DD')} onChange={handleInputChange}>
                    {props.days.map(day =>
                        <option value={day} key={day}>{moment(day).format('ddd, MM/DD/Y')}</option>
                    )}
                </InputSelect>
            </td>
            <td>
                <Input name="destination" value={props.mileRow.destination} onChange={handleInputChange} autoComplete="off" onFocus={handleFocus} onKeyDown={handleKeyDown} innerRef={(ref: any) => {
                    if (!!ref && !!props.focusElementColumn && props.focusElementColumn === ref.name) {
                        focusRef.current = ref
                    }
                }}/>
            </td>
            <td>
                <Input name="businesspurpose" value={props.mileRow.businesspurpose} onChange={handleInputChange} autoComplete="off" onFocus={handleFocus} onKeyDown={handleKeyDown} innerRef={(ref: any) => {
                    if (!!ref && !!props.focusElementColumn && props.focusElementColumn === ref.name) {
                        focusRef.current = ref
                    }
                }}/>
            </td>
            <td className="odometer">
                <InputNumber name={"startodometer"} value={props.mileRow.startodometer} onChange={handleInputChange} integerScale={8} htmlRef={(ref) => {
                    if (!!ref && !!props.focusElementColumn && props.focusElementColumn === ref.name) {
                        focusRef.current = ref
                    }
                }} onKeyDown={handleKeyDown}/>
            </td>
            <td className="odometer">
                <InputNumber name={"endodometer"} value={props.mileRow.endodometer} onChange={handleInputChange} integerScale={8} htmlRef={(ref) => {
                    if (!!ref && !!props.focusElementColumn && props.focusElementColumn === ref.name) {
                        focusRef.current = ref
                    }
                }} onKeyDown={handleKeyDown}/>
            </td>
            <td className="text-right amountTotals">
                <span className="form-control-plaintext">
                    {ToDigitsBlank(totalMiles, 0)}
                </span>
            </td>
            <td className="text-right amountTotals">
                <span className="form-control-plaintext">
                    {ToCurrencyBlank(totalAmount, 2)}
                </span>
            </td>
            <td className="text-center actionBox">
                {isDirty ?
                    <span className="form-control-plaintext">
                        <Spinner size="sm" color="gray"/>
                    </span>
                    :
                    (!!props.mileRow.mileageID) ?
                        <Button type="button" color="link" onClick={deleteRow}>
                            <FontAwesomeIcon icon={faTimesCircle} color="secondary"/>
                        </Button>
                        : null
                }
            </td>
        </tr>
    );
};

export default MileageReadWrite;

import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import {Link, Redirect, useHistory} from "react-router-dom";
import {HasFeature, TTSFeatures} from "../../Data/TTSFeatures";
import {Button, Col, Container, ListGroup, ListGroupItem, Row} from "reactstrap";
import axios, {CancelTokenSource} from "axios";
import {APIProcess, GetPathThrough, MasterDetail, MDMaster, ToDigits, MDDetail, GetPathComponentAfter, MDLink} from '@denjpeters/intelliwakereact';
import MileageRate from "./MileageRate";
import {Imileage_rate} from "../../Data/Tables/mileage_rate";
import moment from "moment";
import {IWake} from "../../IWake";

const MileageRates = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const isMounted = useRef(true);
    const {user} = useSelector((state: AppState) => state);
    const [mileage_rates, setMileage_Rates] = useState(null as Imileage_rate[] | null);

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    const updateMileage_Rate = (updatedMileage_Rate: Imileage_rate) => {
        if (!!mileage_rates) {
            if (!!updatedMileage_Rate.mileage_rateID) {
                if (!window.location.pathname.endsWith('/Mileage_Rate' + updatedMileage_Rate.mileage_rateID)) {
                    history.push(GetPathThrough("/Mileage_Rates") + "/Mileage_Rate/" + updatedMileage_Rate.mileage_rateID);
                }

                setMileage_Rates([
                    ...mileage_rates.filter(mileage_rate => mileage_rate.mileage_rateID !== updatedMileage_Rate.mileage_rateID),
                    updatedMileage_Rate
                ]);
            }
        }
    }

    useEffect(() => {
        let cancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();
        isMounted.current = true;

        APIProcess('mileage_rate', 'GetList', {}, cancelTokenSource)(iWake)
            .then((results) => {
                if (isMounted.current && cancelTokenSource) {
                    for (let mileage_rateItem of results.mileage_rates) {
                        mileage_rateItem.active = Number(mileage_rateItem.active);
                    }
                    setMileage_Rates((results.mileage_rates as Imileage_rate[]).sort(function (a, b) {
                        return (a.effective_date).localeCompare((b.effective_date), undefined, {sensitivity: 'accent'})
                    }));
                }
            })
            .catch(() => {
                if (isMounted.current && cancelTokenSource) {
                    setMileage_Rates([]);
                }
            })
            .finally(() => {
                cancelTokenSource = null;
            });

        return () => {
            isMounted.current = false;
            if (cancelTokenSource) {
                cancelTokenSource.cancel();
                cancelTokenSource = null;
            }
        }
    }, [iWake])

    const mileage_rateList = useMemo(() => (mileage_rates ?? [])
        .sort((a, b) => a.effective_date.localeCompare(b.effective_date, undefined, {sensitivity: 'base'})), [mileage_rates])

    if (!HasFeature(user, TTSFeatures.Feature_TimeSheet_Admin)) {
        return (
            <Redirect to="/TimeEntry"/>
        );
    }

    return (
        <Container fluid className="px-0">
            <MasterDetail breakAt="sm" mdPath="/MileageRates" backText="Mileage Rates">
                <MDMaster width="15em">
                    <Row className="p-2">
                        <Col>

                        </Col>
                        <Col className="text-right">
                            <Button color="secondary" size="sm" tag={Link} to={GetPathThrough("/MileageRates") + "/MileageRate/New"}>Add</Button>
                        </Col>
                    </Row>
                    <ListGroup flush className="fill-height-scroll text-large-md-smaller">
                        {!mileage_rates ?
                            <ListGroupItem className="text-secondary">Loading...</ListGroupItem>
                            : mileage_rateList.length === 0 ?
                                <ListGroupItem>No mileage rates</ListGroupItem>
                                :
                                mileage_rateList.map((mileage_rate) =>
                                    <MDLink tag="li" panel="MileageRate" className="list-group-item list-group-item-action py-1" key={mileage_rate.mileage_rateID} id={mileage_rate.mileage_rateID}>
                                        <span>{moment(mileage_rate.effective_date).format('YYYY-MM-DD')}</span>
                                        <span className="float-right">${ToDigits(mileage_rate.amount, 4)}</span>
                                    </MDLink>
                                )
                        }
                    </ListGroup>
                </MDMaster>
                <MDDetail panel="MileageRate" titleText="MileageRate">
                    <MileageRate mileage_rateID={GetPathComponentAfter('/MileageRate')} updateMileage_Rate={updateMileage_Rate}/>
                </MDDetail>
            </MasterDetail>
        </Container>
    );
};

export default MileageRates;

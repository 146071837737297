/**
 * Automatically generated: 2020-06-15 15:30:50
 * © 2020, Solid Basis Ventures, LLC.
 * DO NOT MODIFY
 *
 * Table Manager for: tbluser_file_type
 */

export interface Itbluser_file_type {
	id: number,
	tbluser_file_type_group_id: number,
	name: string,
	sort_order: number,
	ismultiple: number,
	isrequired: number,
	active: number
}

export const initialtbluser_file_type: Itbluser_file_type = {
	id: 0,
	tbluser_file_type_group_id: 0,
	name: '',
	sort_order: 9999999,
	ismultiple: 0,
	isrequired: 1,
	active: 1
};

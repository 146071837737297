import React, {FormEvent, useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Button, Container, Form, FormFeedback, FormGroup, Input, Label} from "reactstrap";
import {APIProcess, ShowActivityOverlay, ShowMessageBox, HideActivityOverlay, ElementCustomValue} from '@denjpeters/intelliwakereact';
import {useHistory} from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faKey} from "@fortawesome/pro-regular-svg-icons";
import {AppState} from "../../Stores/rootReducer";
import {IWake} from "../../IWake";
import {ASRSetPasswordReset} from "../../Stores/appsessionremembers/actions";

interface IState {
    frompassword: string,
    password1: string,
    password2: string,
    submitAttempted: boolean
}

const initialState: IState = {
    frompassword: "",
    password1: "",
    password2: "",
    submitAttempted: false
};

const ChangePassword = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {user, appSessionRemembersChange} = useSelector((state: AppState) => state);
    const [state, setState] = useState(initialState);

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    const formSubmit = (e: FormEvent) => {
        e.preventDefault();

        if (!!state.password1 && state.password1 === state.password2 && state.password1.length > 6) {
            ShowActivityOverlay()(dispatch);
            APIProcess('User', 'PasswordChange', state)(iWake)
                .then(() => {
                    ASRSetPasswordReset(false)(dispatch);
                    history.push("/TimeEntry");
                    ShowMessageBox('Password changed successfully!')(dispatch);
                })
                .catch(() => {
                })
                .finally(() => {
                    HideActivityOverlay()(dispatch);
                });
        } else {
            setState({
                ...state,
                submitAttempted: true
            });

            ShowMessageBox('Could not process password change', "danger")(dispatch);
        }
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState({
            ...state,
            [e.target.name]: ElementCustomValue(e)
        });
    }

    return (
        <Container>
            <h4 className="py-3 text-center"><FontAwesomeIcon icon={faKey}/> Change Password</h4>
            <Form onSubmitCapture={formSubmit} className="form-sm narrow">
                {!appSessionRemembersChange.requiresPasswordReset ?
                    <FormGroup>
                        <Label>Current Password</Label>
                        <div>
                            <Input type="password" name="frompassword" placeholder="Current" value={state.frompassword} valid={false} invalid={state.submitAttempted && !(state.frompassword.length > 0)} onChange={handleInputChange}/>
                            <FormFeedback valid={!state.submitAttempted && state.frompassword.length > 0}>Please enter
                                your current password</FormFeedback>
                        </div>
                    </FormGroup>
                    :
                    null
                }
                <FormGroup>
                    <Label>New Password</Label>
                    <div>
                        <Input type="password" name="password1" placeholder="New Password" value={state.password1} valid={false} invalid={state.submitAttempted && !(state.password1.length > 6)} onChange={handleInputChange}/>
                        <FormFeedback valid={!state.submitAttempted && state.password1.length > 6}>New password must
                            be at least 6 characters</FormFeedback>
                    </div>
                </FormGroup>
                <FormGroup>
                    <Label>Repeat Password</Label>
                    <div>
                        <Input type="password" name="password2" placeholder="Repeat Password" value={state.password2} valid={false} invalid={state.submitAttempted && state.password2 !== state.password1} onChange={handleInputChange}/>
                        <FormFeedback valid={!state.submitAttempted && state.password2 === state.password1}>Passwords
                            do not match</FormFeedback>
                    </div>
                </FormGroup>
                <FormGroup>
                    <div className="offset">
                        <Button color="primary"><FontAwesomeIcon icon={faKey} fixedWidth/> Change Password</Button>
                    </div>
                </FormGroup>
            </Form>
        </Container>
    );
};

export default ChangePassword;

import React, {FormEvent, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import {APIProcess, ShowActivityOverlay, HideActivityOverlay, CleanNumber, ShowMessageBox, ElementCustomValue, InputSwitch, ActivityOverlayControl} from '@denjpeters/intelliwakereact';
import axios, {CancelTokenSource} from "axios";
import {Button, Col, Form, FormFeedback, FormGroup, Input, Label, Row} from "reactstrap";
import {initialtblactivitycode, Itblactivitycode} from "../../Data/Tables/tblactivitycode";
import BlockNav from "../Generics/BlockNav";
import { useHistory } from 'react-router-dom';
import {IWake} from "../../IWake";

interface IProps {
    activityID?: number | null,
    updateActivityCode?: Function | undefined
}

interface IProjectsUsing {
    projectID: number,
    projectNo: string,
    name: string
}

interface IActivtyCodeStructure {
    activitycode: Itblactivitycode,
    projects: IProjectsUsing[]
}

const initialActivityCodeStructure: IActivtyCodeStructure = {
    activitycode: initialtblactivitycode,
    projects: []
}

const ActivityCode = (props: IProps) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const isMounted = useRef(true);
    const {user, appSessionRemembersChange} = useSelector((state: AppState) => state);
    const [activityCodeStructure, setActivityCodeStructure] = useState(null as IActivtyCodeStructure | null);
    const [submitAttempted, setSubmitAttempted] = useState(false);
    const [blockNavigation, setBlockNavigation] = useState(false);

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    const openProject = (projectID: number) => {
        history.push('/Project/' + projectID);
    }

    const formSubmit = (e: FormEvent) => {
        e.preventDefault();

        if (blockNavigation) {
            setSubmitAttempted(true);

            if (!!activityCodeStructure) {
                if (activityCodeStructure.activitycode.name) {
                    ShowActivityOverlay()(dispatch);
                    APIProcess('activitycode', 'Save', activityCodeStructure.activitycode)(iWake)
                        .then((result) => {
                            if (isMounted.current) {
                                setSubmitAttempted(false);
                                setBlockNavigation(false);
                                const newState = {
                                    ...activityCodeStructure,
                                    activitycode: {
                                        ...activityCodeStructure.activitycode,
                                        activityID: CleanNumber(result.activityID)
                                    }
                                };
                                setActivityCodeStructure(newState);
                                if (props.updateActivityCode instanceof Function) {
                                    props.updateActivityCode(newState.activitycode);
                                }
                                ShowMessageBox('Saved')(dispatch);
                            }
                        })
                        .catch(() => {
                        })
                        .finally(() => {
                            HideActivityOverlay()(dispatch);
                        });
                } else {
                    ShowMessageBox('Name required', "danger")(dispatch);
                }
            }
        }
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target: any = e.target;

        if (!!target && !!activityCodeStructure) {
            setActivityCodeStructure({
                ...activityCodeStructure,
                activitycode: {
                    ...activityCodeStructure.activitycode,
                    [target.name]: ElementCustomValue(e)
                }
            } as IActivtyCodeStructure);

            setBlockNavigation(true);
        }
    }

    useEffect(() => {
        let cancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();
        isMounted.current = true;

        APIProcess('activitycode', 'Get', {activityID: props.activityID})(iWake)
            .then((results) => {
                if (isMounted.current && cancelTokenSource) {
                    // console.log(results);
                    setBlockNavigation(false);
                    setSubmitAttempted(false);
                    if (!!results.activitycode) {
                        setActivityCodeStructure({...initialActivityCodeStructure, ...results});
                    } else {
                        setActivityCodeStructure(initialActivityCodeStructure);
                    }
                }
            })
            .catch(() => {
                if (isMounted.current && cancelTokenSource) {
                    setBlockNavigation(false);
                    setSubmitAttempted(false);
                    setActivityCodeStructure(initialActivityCodeStructure);
                }
            })
            .finally(() => {
                cancelTokenSource = null;
            });

        return () => {
            isMounted.current = false;
            if (cancelTokenSource) {
                cancelTokenSource.cancel();
                cancelTokenSource = null;
            }
        }
    }, [iWake, props.activityID]);

    return (
        <>
            {!!activityCodeStructure ?
                <>
                    <Form onSubmitCapture={formSubmit} onBlur={formSubmit} className="form-sm fill-height-scroll mt-4">
                        <FormGroup>
                            <Label>Activity Code Name</Label>
                            <Col className="medium">
                                <Input type="text" name="name" placeholder="ActivityCode Name" value={activityCodeStructure.activitycode.name ?? ""} valid={false} invalid={submitAttempted && !(activityCodeStructure.activitycode.name.length > 0)} onChange={handleInputChange}/>
                                <FormFeedback valid={!submitAttempted && !!activityCodeStructure.activitycode.name}>Name
                                    required</FormFeedback>
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col className="offset">
                                <InputSwitch label="Active" name="active" onChange={handleInputChange} value={activityCodeStructure.activitycode.active}/>
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col className="offset mt-4">
                                <Row>
                                    <Col>
                                        <InputSwitch label="Is Billable" name="is_billable" onChange={handleInputChange} value={activityCodeStructure.activitycode.is_billable}/>
                                        <InputSwitch label="Contributes to OT" name="is_base_to_ot_pay" onChange={handleInputChange} value={activityCodeStructure.activitycode.is_base_to_ot_pay}/>
                                        <InputSwitch label="Is Overtime" name="is_overtime" onChange={handleInputChange} value={activityCodeStructure.activitycode.is_overtime}/>
                                    </Col>
                                    <Col>
                                        <InputSwitch label="Is Vacation" name="is_vacation" onChange={handleInputChange} value={activityCodeStructure.activitycode.is_vacation}/>
                                        <InputSwitch label="Is Holiday" name="is_holiday" onChange={handleInputChange} value={activityCodeStructure.activitycode.is_holiday}/>
                                        <InputSwitch label="Is Illness" name="is_illness" onChange={handleInputChange} value={activityCodeStructure.activitycode.is_illness}/>
                                    </Col>
                                </Row>
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Label>Active Projects Using</Label>
                            <Col>
                                <div className="form-control-plaintext">
                                    <small>
                                        {activityCodeStructure.projects
                                            .sort((a: IProjectsUsing, b: IProjectsUsing) => {
                                                    switch (appSessionRemembersChange.projectSort) {
                                                        case "Name":
                                                            return a.name.localeCompare(b.name, undefined, {sensitivity: 'base'});
                                                        case "NoAsc":
                                                            return a.projectNo.localeCompare(b.projectNo, undefined, {sensitivity: 'base'});
                                                        default:
                                                            return b.projectNo.localeCompare(a.projectNo, undefined, {sensitivity: 'base'});
                                                    }
                                                }
                                            )
                                            .map(project =>
                                                <Button key={project.projectID} color="link" className="btn-link-inline" onClick={() => openProject(project.projectID)}>{project.projectNo} - {project.name}</Button>
                                            )
                                        }
                                    </small>
                                </div>
                            </Col>
                        </FormGroup>
                    </Form>

                </>
                :
                null
            }
            <ActivityOverlayControl show={!activityCodeStructure}/>
            <BlockNav when={blockNavigation}/>
        </>
    );
};

export default ActivityCode;

import React, {useEffect, useMemo, useState} from 'react';
import {Button} from "reactstrap";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDoubleLeft, faAngleDoubleRight} from "@fortawesome/pro-regular-svg-icons";
import {CookieCreate, ClassNames, InputDate} from "@denjpeters/intelliwakereact";

interface IProps {
    endDate: string,
    periodUpdated: ((date: string) => void)
}

const PayPeriodNav = (props: IProps) => {
    const [dateEdit, setDateEdit] = useState(props.endDate);

    const weeks = 2;

    const setDate = (goForward: boolean) => {
        const endDate = moment(props.endDate).add(goForward ? weeks : (weeks * -1), 'weeks').format('YYYY-MM-DD');
        CookieCreate('selectedEndDate', endDate, 0.1);
        props.periodUpdated(endDate);
    }

    const isCurrentWeek = useMemo(() => moment().diff(moment(props.endDate), 'days') < 0, [props.endDate]);

    const onKeyDown = (e: React.KeyboardEvent) => {
        if (e.keyCode === 13) {
            toDateEdit();
        }
    }

    const toDateEdit = () => {
        const endDate = moment(dateEdit).format('YYYY-MM-DD');
        CookieCreate('selectedEndDate', endDate, 0.1);
        props.periodUpdated(endDate);
    }

    useEffect(() => {
        setDateEdit(moment(props.endDate).endOf('isoWeek').format('YYYY-MM-DD'));
    }, [props.endDate]);

    return (
        <>
            <Button color="link" onClick={() => setDate(false)}>
                <FontAwesomeIcon icon={faAngleDoubleLeft} className="mr-2"/>
                Previous Period
            </Button>
            <Button color="link" className={ClassNames({"d-none": isCurrentWeek})} onClick={() => setDate(true)}>
                Next Period
                <FontAwesomeIcon icon={faAngleDoubleRight} className="ml-2"/>
            </Button>
            <InputDate value={dateEdit} onChange={(e) => setDateEdit(e.target.value)} className="d-inline-block border-0 w-auto" onBlur={toDateEdit} onKeyDown={onKeyDown}/>
        </>
    );
};

export default PayPeriodNav;

import {UserState, UserActionTypes, UPDATE_USER, UPDATE_SESSION_USER} from './types'

export const initialUserState: UserState = {
    loggedIn: false,
    userID: null,
    firstname: "",
    lastname: "",
    email: "",
    caller: 0,
    smsphone: null,
    features: []
};

export function reducerUser(
    state: any = initialUserState,
    action: UserActionTypes
): UserState {
    switch (action.type) {
        case UPDATE_SESSION_USER: {
            return {
                ...state,
                ...action.payload
            };
        }
        case UPDATE_USER: {
            return {
                ...state,
                ...action.payload
            };
        }
        default:
            return state
    }
}

/**
 * Automatically generated: 2020-06-15 15:30:50
 * © 2020, Solid Basis Ventures, LLC.
 * DO NOT MODIFY
 *
 * Table Manager for: tbluser_diary
 */

export interface Itbluser_diary {
	id: number,
	userID: number,
	tbluser_diary_type_id: number | null,
	name: string,
	notes: string | null,
	diary_date: string,
	active: number,
	create_userID: number,
	create_ts: string
}

export const initialtbluser_diary: Itbluser_diary = {
	id: 0,
	userID: 0,
	tbluser_diary_type_id: null,
	name: '',
	notes: '',
	diary_date: '',
	active: 1,
	create_userID: 0,
	create_ts: ''
};

import React from 'react';
import { IPropsSelectDDItem, SelectDD } from '@denjpeters/intelliwakereact';

export interface IProps {
    selectedID: 1 | 0 | -1,
    handleSelectID: (id: 1 | 0 | -1) => void,
    color?: string,
    size?: 'lg' | 'sm' | undefined,
    caret?: boolean,
    className?: string,
    inline?: boolean,
    likeSelect?: boolean
}

export const ActiveDD = (props: IProps) => {
    const items: IPropsSelectDDItem[] = [
        {id: 1, name: "Active"},
        {id: 0, name: "Inactive"},
        {id: -1, name: "All"}
    ];

    return (
        <SelectDD {...props} handleSelectID={(id) => {props.handleSelectID(id as 1 | 0 | -1)}} items={items}/>
    );
};

export default ActiveDD;

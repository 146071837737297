import moment from "moment";

export enum DayOfWeek {
    MON = 'mon',
    TUES = 'tues',
    WED = 'wed',
    THURS = 'thurs',
    FRI = 'fri',
    SAT = 'sat',
    SUN = 'sun'
}

export const DaysOfWeek: string[] = [
    DayOfWeek.MON,
    DayOfWeek.TUES,
    DayOfWeek.WED,
    DayOfWeek.THURS,
    DayOfWeek.FRI,
    DayOfWeek.SAT,
    DayOfWeek.SUN
];

export const DaysOfWeekGT = (searchDate: string, sundayDate: string): string[] => {
    if (!searchDate) return DaysOfWeek;

    const startDate = moment(sundayDate).startOf('isoWeek').format('Y-MM-DD');
    const endDate = moment(sundayDate).endOf('isoWeek').format('Y-MM-DD');

    if (searchDate < startDate) return DaysOfWeek;
    if (searchDate >= endDate) return [];

    const weekDay = moment(searchDate).isoWeekday();

    return DaysOfWeek.filter((day, idx) => idx > weekDay - 1);
};

export const DaysOfWeekLT = (searchDate: string, sundayDate: string): string[] => {
    if (!searchDate) return DaysOfWeek;

    const startDate = moment(sundayDate).startOf('isoWeek').format('Y-MM-DD');
    const endDate = moment(sundayDate).endOf('isoWeek').format('Y-MM-DD');

    if (searchDate > endDate) return DaysOfWeek;
    if (searchDate <= startDate) return [];

    const weekDay = moment(searchDate).isoWeekday();

    return DaysOfWeek.filter((day, idx) => idx < weekDay - 1);
};

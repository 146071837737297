import React, {useEffect, useMemo, useState} from 'react';
import {Itbluser} from "../../Data/Tables/tbluser";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import {Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table} from "reactstrap";
import moment from "moment";
import {APIProcess, ElementCustomValue, ShowActivityOverlay, HideActivityOverlay, ToDigits, InputNumber} from '@denjpeters/intelliwakereact';
import {initialtblaccrual, Itblaccrual} from "../../Data/Tables/tblaccrual";
import {IWake} from "../../IWake";

interface IProps {
    isOpen: boolean,
    doForceRefresh: (() => void),
    closeModal: (() => void),
    tbluser: Itbluser,
    accruals: Itblaccrual[],
    accrualName: 'Vacation' | 'Illness'
}

const UserAccrualsModal = (props: IProps) => {
    const dispatch = useDispatch();
    const user = useSelector((state: AppState) => state.user);

    const [editAccrual, setEditAccrual] = useState(initialtblaccrual as Itblaccrual);

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEditAccrual({
            ...editAccrual,
            [e.target.name]: ElementCustomValue(e)
        } as Itblaccrual);
    }

    const addItem = () => {
        ShowActivityOverlay()(dispatch);
        APIProcess('User', 'AccrualSave', {
            ...editAccrual,
            userID: props.tbluser.userID,
            type: props.accrualName
        })(iWake)
            .then(() => {
                props.doForceRefresh();
                setEditAccrual({
                    ...initialtblaccrual,
                    type: props.accrualName
                });
            })
            .catch(() => {
            })
            .finally(() => {
                HideActivityOverlay()(dispatch);
            });
    }

    const removeItem = (accrualID: number) => {
        ShowActivityOverlay()(dispatch);
        APIProcess('User', 'AccrualDelete', {accrualID: accrualID})(iWake)
            .then(() => {
                props.doForceRefresh();
            })
            .catch(() => {
            })
            .finally(() => {
                HideActivityOverlay()(dispatch);
            });
    }

    useEffect(() => {
        setEditAccrual({
            ...initialtblaccrual,
            type: 'Vacation'
        });
    }, [props.isOpen]);

    return (
        <Modal backdrop keyboard toggle={props.closeModal} isOpen={props.isOpen} size="lg">
            <ModalHeader className="alert-info">
                {props.accrualName} Accruals - {props.tbluser.lastname}, {props.tbluser.firstname}
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        Start date: {props.tbluser.startdate ?? "Not set"}
                    </Col>
                </Row>
                {!!props.tbluser.startdate ?
                    <Row>
                        <Col>
                            Current year of employment: {moment().diff(moment(props.tbluser.startdate), 'years')}
                        </Col>
                    </Row>
                    :
                    null
                }
                <Row className="mt-3">
                    <Col className="pre-scrollable">
                        <Table borderless size="sm" className="table-inputs">
                            <thead>
                            <tr className="table-secondary table-bordered">
                                <th>Start Year</th>
                                <th>Hours to Accrue/Year</th>
                                <th/>
                            </tr>
                            </thead>
                            <tbody>
                            {props.accruals.map(accrual =>
                                <tr key={accrual.accrualID} className="table-bordered">
                                    <td>
                                        <span className="form-control-plaintext text-right">
                                            {ToDigits(accrual.startYear, 0)}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="form-control-plaintext text-right">
                                            {ToDigits(accrual.amount, 2)}
                                        </span>
                                    </td>
                                    <td>
                                        <Button color="link" onClick={() => {
                                            removeItem(accrual.accrualID)
                                        }}>Remove</Button>
                                    </td>
                                </tr>
                            )}
                            <tr className="table-borderless">
                                <td>
                                    <InputNumber name="startYear" value={editAccrual.startYear} onChange={handleInputChange} integerScale={3}/>
                                </td>
                                <td>
                                    <InputNumber name="amount" value={editAccrual.amount} onChange={handleInputChange} integerScale={4} decimalScale={2}/>
                                </td>
                                <td>
                                    <Button color="link" onClick={addItem}>Add New</Button>
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <small>
                            <p>
                                This Employee accrues {props.accrualName} hours beginning with the Start Year, and each anniversary thereafter until another Start Year is defined.
                            </p>
                            <p>
                                If the employee leaves the company, simply put a zero in the next starting year.  So, if they leave in year 5, make year 6 a zero and they will stop accruing.
                            </p>
                        </small>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={props.closeModal}>Close</Button>
            </ModalFooter>
        </Modal>
    );
};

export default UserAccrualsModal;

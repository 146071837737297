import {combineReducers} from "redux";
import {initialSystemState, reducerSystem} from './system/reducers';
import {initialUserState, reducerUser} from "./user/reducers";
import {reducerLocalStore} from "./localstore/reducers";
import {initialAppSessionRemembersState, reducerAppSessionRemembers} from "./appsessionremembers/reducers";
import {initialAppSettings, reducerAppSettings} from "./appsettings/reducers";
import { reducerMenuBack, reducerActivityOverlay, reducerMessageBox, reducerPromptOKCancel } from "@denjpeters/intelliwakereact";

const baseReducer = combineReducers({
    system: reducerSystem,
    user: reducerUser,
    appSettings: reducerAppSettings,
    appSessionRemembersChange: reducerAppSessionRemembers,
    menuBack: reducerMenuBack,
    activityOverlay: reducerActivityOverlay,
    messageBox: reducerMessageBox,
    promptOKCancel: reducerPromptOKCancel,
    localStore: reducerLocalStore
});

const rootReducer = (state: any, action: any) => {
    if (action.type === 'RESET_APP') {
        state = undefined;
    }

    return baseReducer(state, action);
};

// Items that will survive a menu_back refresh
const persistedSessionStates: {[key: string]: any} = {
    'user': initialUserState,
    'system': initialSystemState,
    'appSettings': initialAppSettings,
    'appSessionRemembersChange': initialAppSessionRemembersState
};

export default rootReducer;

export type AppState = ReturnType<typeof rootReducer>;

export const loadState = () => {
    try {
        let results: any = {};

        const serializedSessionState = window.sessionStorage.getItem('state');
        if (serializedSessionState !== null) {
            const objectState: any = JSON.parse(serializedSessionState);
            for (const property of Object.keys(objectState)) {
                if (Object.keys(persistedSessionStates).includes(property)) {
                    results[property] = {...persistedSessionStates[property], ...objectState[property]};
                }
            }
        }

        return results;
    } catch (err) {
        return undefined;
    }
};

export const saveState = (state: any) => {
    try {
        let results: any = {};

        // console.log('Save', state);

        for (const property of Object.keys(state)) {
            if (Object.keys(persistedSessionStates).includes(property)) {
                results[property] = state[property];
            }
        }
        const serializedState = JSON.stringify(results);
        window.sessionStorage.setItem('state', serializedState);
    } catch {
        console.log('Could not save state');
    }
};

import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import {Link, Redirect, useHistory} from "react-router-dom";
import {HasFeature, TTSFeatures} from "../../Data/TTSFeatures";
import {Button, Col, Container, ListGroup, ListGroupItem, Row} from "reactstrap";
import {IUsers} from "../../Data/Views/Users";
import axios, {CancelTokenSource} from "axios";
import {APIProcess, GetPathThrough, MasterDetail, MDMaster, InputSearch, MDDetail, MDLink, GetPathComponentAfter} from '@denjpeters/intelliwakereact';
import ActiveDD from "../Generics/ActiveDD";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter} from "@fortawesome/pro-regular-svg-icons";
import User from "./User";
import {IWake} from "../../IWake";

const Users = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const isMounted = useRef(true);
    const {user} = useSelector((state: AppState) => state);
    const [users, setUsers] = useState(null as IUsers[] | null);
    const [activeView, setActiveView] = useState(1 as 0 | 1 | -1);
    const [search, setSearch] = useState("");

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    const updateUser = (updatedUser: any) => {
        if (!!users) {
            if (updatedUser.userID) {
                if (!window.location.pathname.endsWith('/User/' + updatedUser.userID)) {
                    history.push(GetPathThrough("/Users") + "/User/" + updatedUser.userID);
                }

                const updatedUsers = [...users.filter(user => user.userID !== updatedUser.userID), updatedUser];
                setUsers(updatedUsers);
            }
        }
    };

    useEffect(() => {
        let cancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();
        isMounted.current = true;

        APIProcess('User', 'GetList', {active: activeView}, cancelTokenSource)(iWake)
            .then((results) => {
                if (isMounted.current && cancelTokenSource) {
                    for (let userItem of results.Users) {
                        userItem.active = Number(userItem.active);
                    }
                    setUsers((results.Users as IUsers[]).sort(function (a, b) {
                        return (a.lastname).localeCompare((b.lastname), undefined, {sensitivity: 'accent'})
                    }));
                }
            })
            .catch(() => {
                if (isMounted.current && cancelTokenSource) {
                    setUsers([]);
                }
            })
            .finally(() => {
                cancelTokenSource = null;
            });

        return () => {
            isMounted.current = false;
            if (cancelTokenSource) {
                cancelTokenSource.cancel();
                cancelTokenSource = null;
            }
        }
    }, [iWake, activeView]);

    const searchTerms = useMemo(() => !search ? [] : search
        .split(' ')
        .map(term => term.trim().toLowerCase())
        .filter(term => !!term), [search]);

    const userList = useMemo(() => (users ?? [])
        .filter(userItem => searchTerms.length === 0 || (searchTerms.every(term => (userItem.firstname.toLowerCase().includes(term) || userItem.lastname.toLowerCase().includes(term)))))
        .sort((a, b) => a.lastname.localeCompare(b.lastname, undefined, {sensitivity: 'base'}) ?? a.firstname.localeCompare(b.firstname, undefined, {sensitivity: 'base'})), [searchTerms, users]);

    if (!HasFeature(user, TTSFeatures.Feature_User_Admin)) {
        return (
            <Redirect to="/TimeEntry"/>
        );
    }

    return (
        <Container fluid className="px-0">
            <MasterDetail breakAt="sm" mdPath="/Users" backText="Users" rememberLast>
                <MDMaster width="15em">
                    <Row className="p-2">
                        <Col>
                            <ActiveDD size="sm" handleSelectID={setActiveView} selectedID={activeView} caret/>
                        </Col>
                        <Col className="text-right">
                            <Button color="secondary" size="sm" tag={Link} to={GetPathThrough("/Users") + "/User/New"}>Add</Button>
                        </Col>
                    </Row>
                    <Row className="p-2">
                        <Col>
                            <div className="input-group input-group-filter">
                                <div className="input-group-prepend">
                                    <span className="input-group-text"><FontAwesomeIcon icon={faFilter} fixedWidth/></span>
                                </div>
                                <InputSearch className="noFocusRing" placeholder="Filter" aria-label="Filter" triggerSearchText={setSearch}/>
                            </div>
                        </Col>
                    </Row>
                    <ListGroup flush className="fill-height-scroll text-large-md-smaller">
                        {!users ?
                            <ListGroupItem className="text-secondary">Loading...</ListGroupItem>
                            : userList.length === 0 ?
                                <ListGroupItem>No users</ListGroupItem>
                                :
                                userList.map((user) =>
                                    <MDLink tag="li" panel="User" className={"list-group-item list-group-item-action py-1" + (user.disable === 0 ? "" : " text-gray")} key={user.userID} id={user.userID}>
                                        <span>{user.lastname + ', ' + user.firstname}</span>
                                    </MDLink>
                                )
                        }
                    </ListGroup>
                </MDMaster>
                <MDDetail panel="User" titleText="User">
                    <User userID={GetPathComponentAfter('/User')} updateUser={updateUser}/>
                </MDDetail>
            </MasterDetail>
        </Container>
    );
};

export default Users;

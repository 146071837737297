import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Button, Spinner} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import {DaysOfWeek, DaysOfWeekGT, DaysOfWeekLT} from "../../Data/Enums/DaysOfWeek";
import {ITime, ITimeDataGeneric, ITimeDataUser} from "../../Data/Views/Time";
import {APIProcess, ElementCustomValue, RemoveDupProperties, ShowMessageBox, ShowActivityOverlay, HideActivityOverlay, InputSelect, InputNumber, ToDigitsBlank} from '@denjpeters/intelliwakereact';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimesCircle} from "@fortawesome/pro-solid-svg-icons";
import moment from "moment";
import {IWake} from "../../IWake";

interface IProps {
    timeDataGeneric: ITimeDataGeneric,
    timeDataUser: ITimeDataUser,
    timeRow: ITime,
    updateTimeRow: ((timeRow: any, newID: number) => void),
    deleteTimeRow: ((timeRowID: number) => void),
    focusElementColumn: string | null,
    focusMoveUp: ((fromRowID: number, fromColumnName: string) => void),
    focusMoveDown: ((fromRowID: number, fromColumnName: string) => void),
    clearFocusElement: (() => void)
}

const LineReadWrite = (props: IProps) => {
    const dispatch = useDispatch();
    const isMounted = useRef(true);
    const saveTimeout = useRef(setTimeout(() => {
    }, 1));
    const focusRef = useRef(null as any | null);
    const {user, appSessionRemembersChange} = useSelector((state: AppState) => state);
    const timeRowChanges = useRef({} as { [key: string]: any });
    const [immediateSave, setImmediateSave] = useState(false);
    const [attemptSave, setAttemptSave] = useState(false);
    const [, setForceRefresh] = useState(false);

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    const handleKeyDown = (e: React.KeyboardEvent) => {
        const name = (e.target as any).name;

        switch (e.keyCode) {
            case 40: // down
                props.focusMoveDown(props.timeRow.timeID, name);
                break;
            case 38: // up
                props.focusMoveUp(props.timeRow.timeID, name);
                break;
        }
    };

    const handleInputChange = (e: React.ChangeEvent<any>) => {
        const target = e.target;

        switch (target.name) {
            case 'projectID':
                if (target.value.startsWith("p")) {
                    timeRowChanges.current.projectID = parseInt(target.value.substring(1));
                    if (!!props.timeRow.activityID) {
                        const activityIsNonBillable = !!props.timeDataGeneric.activities.find(activity => props.timeRow.activityID !== null && activity.activityID === props.timeRow.activityID && activity.is_billable === 0);

                        if (activityIsNonBillable) {
                            timeRowChanges.current.activityID = null;
                        }
                    }

                    const availableProjectBudgets = props.timeDataUser.project_budgets.filter(projectBudget => projectBudget.projectID === timeRowChanges.current.projectID && projectBudget.active === 1) ?? [];
                    if (availableProjectBudgets.length > 0) {
                        if (!availableProjectBudgets.find(availableProjectBudget => availableProjectBudget.activityID === props.timeRow.activityID)) {
                            const selectedProjectBudget = availableProjectBudgets.find(() => true);
                            if (!!selectedProjectBudget) {
                                timeRowChanges.current.activityID = selectedProjectBudget.activityID;
                            }
                        }
                    }

                } else if (target.value.startsWith("a")) {
                    timeRowChanges.current.projectID = null;
                    timeRowChanges.current.activityID = parseInt(target.value.substring(1));
                } else {
                    timeRowChanges.current.projectID = null;
                }
                break;
            case 'activityID':
                timeRowChanges.current.activityID = !!target.value ? parseInt(target.value) : null;
                break;
            default:
                timeRowChanges.current[target.name] = ElementCustomValue(e);

                break;
        }

        props.updateTimeRow({
            ...timeRowChanges.current,
            localID: props.timeRow.localID
        }, props.timeRow.timeID);

        setAttemptSave(as => !as);
    };

    useEffect(() => {
        if (Object.keys(timeRowChanges.current).length > 0 && (props.timeRow.activityID > 0 || timeRowChanges.current.activityID > 0)) {
            clearTimeout(saveTimeout.current);
            saveTimeout.current = setTimeout(() => {
                const timeID = props.timeRow.timeID;
                const changePackage = {...timeRowChanges.current, date: props.timeRow.date, userID: props.timeRow.userID, timeID: (timeRowChanges.current.timeID ?? timeID)};

                APIProcess('time', 'Save', changePackage)(iWake)
                    .then((results) => {
                        if (isMounted.current) {
                            timeRowChanges.current = RemoveDupProperties(timeRowChanges.current, changePackage);

                            props.updateTimeRow({
                                ...changePackage,
                                localID: props.timeRow.localID
                            }, parseInt(results.timeID));

                            setForceRefresh(fr => !fr);
                        }
                    })
                    .catch(() => {
                        if (isMounted.current) {
                            ShowMessageBox('Could not save', 'warning')(dispatch);
                        }
                    })
                    .finally(() => {
                        setImmediateSave(false);
                    });
            }, immediateSave ? 1 : 1500);
        }
    }, [attemptSave, immediateSave, props, iWake, dispatch]);

    const deleteRow = () => {
        if (!!props.timeRow.timeID) {
            ShowActivityOverlay()(dispatch);
            APIProcess('time', 'Delete', props.timeRow)(iWake)
                .then(() => {
                    HideActivityOverlay()(dispatch);
                    if (isMounted.current) {
                        props.deleteTimeRow(props.timeRow.timeID);
                    }
                })
                .catch(() => {
                    HideActivityOverlay()(dispatch);
                    if (isMounted.current) {
                        ShowMessageBox('Could not delete', 'warning')(dispatch);
                    }
                });
        }
    };

    const {clearFocusElement} = props;
    useEffect(() => {
        isMounted.current = true;

        if (!!props.focusElementColumn && !!focusRef.current) {
            focusRef.current!.focus();
            clearFocusElement();
        }

        return () => {
            isMounted.current = false;
        }
    }, [props.focusElementColumn, clearFocusElement]);

    const removeDays = (setOfDays: string[], daysToRemove: string[]): string[] => {
        return setOfDays.filter(day => !daysToRemove.includes(day));
    };

    const activityIsNonBillable = useMemo(() =>
        !!props.timeDataGeneric.activities.find(activity => props.timeRow.activityID !== null && activity.activityID === props.timeRow.activityID && activity.is_billable === 0), [props.timeDataGeneric.activities, props.timeRow.activityID]);

    const validProjectActivityIDs: number[] = useMemo(() =>
            props.timeDataUser.project_budgets
                .filter(project_budget => (!!props.timeRow.projectID && (project_budget.projectID === props.timeRow.projectID)))
                .map(project_budget => project_budget.activityID ?? 0)
        , [props.timeDataUser.project_budgets, props.timeRow.projectID]);

    const rowTotal = useMemo(() =>
            DaysOfWeek.map(dayOfWeek => (props.timeRow as any)[dayOfWeek]).reduce((prev, next) => +prev + +next)
        , [props.timeRow]);

    const validDays: string[] = useMemo(() => {
        const endDate = moment(props.timeDataUser.curDate).format('Y-MM-DD');
        let validDaysOfWeek = [...DaysOfWeek];

        if (!!props.timeDataUser.user.termdate) {
            validDaysOfWeek = removeDays(validDaysOfWeek, DaysOfWeekGT(props.timeDataUser.user.termdate, endDate));
        }

        if (!!props.timeDataUser.user.startdate) {
            validDaysOfWeek = removeDays(validDaysOfWeek, DaysOfWeekLT(props.timeDataUser.user.startdate, endDate));
        }

        const project = props.timeDataGeneric.projects.find(project => project.projectID === props.timeRow.projectID);
        if (!!project) {
            if (!!project.end_date) {
                validDaysOfWeek = removeDays(validDaysOfWeek, DaysOfWeekGT(project.end_date, endDate));
            }

            if (!!project.start_date) {
                validDaysOfWeek = removeDays(validDaysOfWeek, DaysOfWeekLT(project.start_date, endDate));
            }

            const activity = props.timeDataGeneric.activities.find(activity => activity.activityID === props.timeRow.activityID);
            if (!!activity) {

                const project_budgets = props.timeDataUser.project_budgets.filter(project_budget => project_budget.projectID === project.projectID && project_budget.activityID === activity.activityID) ?? [];

                for (const project_budget of project_budgets) {
                    if (!!project_budget.end_date) {
                        validDaysOfWeek = removeDays(validDaysOfWeek, DaysOfWeekGT(project_budget.end_date, endDate));
                    }

                    if (!!project_budget.validity_date) {
                        validDaysOfWeek = removeDays(validDaysOfWeek, DaysOfWeekLT(project_budget.validity_date, endDate));
                    }
                }
            }
        }

        return validDaysOfWeek;
    }, [props.timeDataUser.curDate, props.timeDataUser.user, props.timeDataGeneric.projects, props.timeDataGeneric.activities, props.timeRow.activityID, props.timeRow.projectID, props.timeDataUser.project_budgets]);

    // console.log(props.timeRow);

    return (
        <tr>
            <td>
                <InputSelect name="projectID" value={(props.timeRow.projectID !== null) ? ("p" + props.timeRow.projectID.toString()) : ((props.timeRow.activityID !== null) ? "a" + props.timeRow.activityID.toString() : "0")} onChange={handleInputChange}>
                    <option value="0">Select</option>
                    <optgroup label="Assigned Projects"/>
                    {props.timeDataGeneric.projects
                        .filter(project => project.project_assign_count > 0 && (project.projectID === props.timeRow?.projectID || project.disable !== "yes"))
                        .sort((a, b) => {
                                switch (appSessionRemembersChange.projectSort) {
                                    case "Name":
                                        return a.name.localeCompare(b.name, undefined, {sensitivity: 'base'});
                                    case "NoAsc":
                                        return a.projectNo.localeCompare(b.projectNo, undefined, {sensitivity: 'base'});
                                    default:
                                        return b.projectNo.localeCompare(a.projectNo, undefined, {sensitivity: 'base'});
                                }
                            }
                        )
                        .map(project =>
                            <option key={"p" + project.projectID.toString()} value={"p" + project.projectID.toString()}>
                                {project.projectNo + ' - ' + project.name}
                            </option>
                        )}
                    <optgroup label="Unbillable Activities"/>
                    {props.timeDataGeneric.activities
                        .filter(activity => activity.is_billable === 0 && (activity.activityID === props.timeRow?.activityID || activity.active === 1))
                        .sort((a, b) => a.name.localeCompare(b.name, undefined, {sensitivity: 'base'}))
                        .map(activity =>
                            <option key={"a" + activity.activityID.toString()} value={"a" + activity.activityID.toString()}>
                                {activity.name}
                            </option>
                        )}
                    <optgroup label="Other Projects"/>
                    {props.timeDataGeneric.projects
                        .filter(project => project.project_assign_count === 0 && (project.projectID === props.timeRow?.projectID || project.disable !== "yes"))
                        .sort((a, b) => {
                                switch (appSessionRemembersChange.projectSort) {
                                    case "Name":
                                        return a.name.localeCompare(b.name, undefined, {sensitivity: 'base'});
                                    case "NoAsc":
                                        return a.projectNo.localeCompare(b.projectNo, undefined, {sensitivity: 'base'});
                                    default:
                                        return b.projectNo.localeCompare(a.projectNo, undefined, {sensitivity: 'base'});
                                }
                            }
                        )
                        .map(project =>
                            <option key={"p" + project.projectID.toString()} value={"p" + project.projectID.toString()}>
                                {project.projectNo + ' - ' + project.name}
                            </option>
                        )}
                </InputSelect>
            </td>
            <td>
                {activityIsNonBillable ?
                    null
                    :
                    <InputSelect name="activityID" value={props.timeRow.activityID ?? 0} onChange={handleInputChange} isNumeric>
                        <option value={0}>Select</option>
                        <optgroup label="Assigned Activities"/>
                        {props.timeDataGeneric.activities
                            .filter(activity => activity.is_billable === 1 && (activity.activityID === props.timeRow?.activityID || activity.active === 1) && validProjectActivityIDs.includes(activity.activityID))
                            .sort((a, b) => a.name.localeCompare(b.name, undefined, {sensitivity: 'base'}))
                            .map(activity =>
                                <option key={activity.activityID} value={activity.activityID}>
                                    {activity.name}
                                </option>
                            )}
                        <optgroup label="Unassigned Activities"/>
                        {props.timeDataGeneric.activities
                            .filter(activity => activity.is_billable === 1 && (activity.activityID === props.timeRow?.activityID || activity.active === 1) && !validProjectActivityIDs.includes(activity.activityID))
                            .sort((a, b) => a.name.localeCompare(b.name, undefined, {sensitivity: 'base'}))
                            .map(activity =>
                                <option key={activity.activityID} value={activity.activityID}>
                                    {activity.name}
                                </option>
                            )}
                    </InputSelect>
                }
            </td>
            {DaysOfWeek.map(dayOfWeek =>
                <td key={dayOfWeek} className={"timeEntryBox" + (!validDays.includes(dayOfWeek) ? " bg-gray" : "")}>
                    <InputNumber name={dayOfWeek} value={!!(props.timeRow as any)[dayOfWeek] ? (props.timeRow as any)[dayOfWeek] : null} onChange={handleInputChange} htmlRef={(ref) => {
                        if (!!ref && !!props.focusElementColumn && props.focusElementColumn === ref.name) {
                            focusRef.current = ref
                        }
                    }} onKeyDown={handleKeyDown} integerScale={2} decimalScale={2} lowerBound={0} upperBound={24} hideZero/>
                </td>
            )}
            <td className="text-right strong timeTotalBox">
                <span className="form-control-plaintext">
                {ToDigitsBlank(rowTotal)}
                </span>
            </td>
            <td className="text-center actionBox">
                {Object.keys(timeRowChanges.current).length > 0 && (props.timeRow.activityID > 0 || timeRowChanges.current.activityID > 0) ?
                    <span className="form-control-plaintext">
                        <Spinner size="sm" color="gray"/>
                    </span>
                    :
                    (props.timeRow.timeID && rowTotal === 0) ?
                        <Button type="button" color="link" onClick={deleteRow}>
                            <FontAwesomeIcon icon={faTimesCircle} color="secondary"/>
                        </Button>
                        : null
                }
            </td>
        </tr>
    );
};

export default LineReadWrite;

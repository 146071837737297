/**
 * Automatically generated: 2020-06-15 15:30:50
 * © 2020, Solid Basis Ventures, LLC.
 * DO NOT MODIFY
 *
 * Table Manager for: mileage_rate
 */

export interface Imileage_rate {
	mileage_rateID: number,
	effective_date: string,
	amount: number
}

export const initialmileage_rate: Imileage_rate = {
	mileage_rateID: 0,
	effective_date: '',
	amount: 0
};

import React, {FormEvent, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import {APIProcess, ShowActivityOverlay, CleanNumber, ShowMessageBox, HideActivityOverlay, ElementCustomValue, InputSelect, InputSwitch, InputDate, ActivityOverlayControl} from '@denjpeters/intelliwakereact';
import axios, {CancelTokenSource} from "axios";
import {Col, Form, FormFeedback, FormGroup, Input, Label} from "reactstrap";
import {initialtblholiday, Itblholiday} from "../../Data/Tables/tblholiday";
import BlockNav from "../Generics/BlockNav";
import {IWake} from "../../IWake";

interface IProps {
    holidayID?: number | null,
    updateHoliday?: Function | undefined
}

const Holiday = (props: IProps) => {
    const dispatch = useDispatch();
    const isMounted = useRef(true);
    const user = useSelector((state: AppState) => state.user);
    const [holiday, setHoliday] = useState(null as Itblholiday | null);
    const [upcomings, setUpcomings] = useState([] as string[]);
    const [submitAttempted, setSubmitAttempted] = useState(false);
    const [blockNavigation, setBlockNavigation] = useState(false);

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    const formSubmit = (e: FormEvent) => {
        e.preventDefault();

        if (blockNavigation) {
            setSubmitAttempted(true);

            if (!!holiday) {
                if (holiday.name) {
                    ShowActivityOverlay()(dispatch);
                    APIProcess('holiday', 'Save', holiday)(iWake)
                        .then((result) => {
                            if (isMounted.current) {
                                setSubmitAttempted(false);
                                setBlockNavigation(false);
                                const newState = {...holiday, holidayID: CleanNumber(result.holidayID)};
                                setHoliday(newState);
                                if (props.updateHoliday instanceof Function) {
                                    props.updateHoliday(newState);
                                }

                                setUpcomings(result.upcomings ?? []);

                                ShowMessageBox('Saved')(dispatch);
                            }
                        })
                        .catch(() => {
                        })
                        .finally(() => {
                            HideActivityOverlay()(dispatch);
                        });
                } else {
                    ShowMessageBox('Name required', "danger")(dispatch);
                }
            }
        }
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name;
        const value = ElementCustomValue(e);

        setHoliday({
            ...holiday,
            [name]: value
        } as Itblholiday);

        setBlockNavigation(true);
    }

    useEffect(() => {
        let cancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();
        isMounted.current = true;

        APIProcess('holiday', 'Get', {holidayID: props.holidayID})(iWake)
            .then((results) => {
                if (isMounted.current && cancelTokenSource) {
                    // console.log(results);
                    setBlockNavigation(false);
                    setSubmitAttempted(false);
                    setHoliday({...initialtblholiday, ...results.holiday});
                    setUpcomings(results.upcomings ?? []);
                }
            })
            .catch(() => {
                if (isMounted.current && cancelTokenSource) {
                    setBlockNavigation(false);
                    setSubmitAttempted(false);
                    setHoliday(initialtblholiday);
                }
            })
            .finally(() => {
                cancelTokenSource = null;
            });

        return () => {
            isMounted.current = false;
            if (cancelTokenSource) {
                cancelTokenSource.cancel();
                cancelTokenSource = null;
            }
        }
    }, [iWake, props.holidayID]);

    return (
        <>
            {!!holiday ?
                <>
                    <Form onSubmitCapture={formSubmit} onBlur={formSubmit} className="form-sm fill-height-scroll mt-4">
                        <FormGroup>
                            <Label>Holiday Name</Label>
                            <Col className="medium">
                                <Input type="text" name="name" placeholder="Holiday Name" value={holiday.name ?? ""} valid={false} invalid={submitAttempted && !(holiday.name.length > 0)} onChange={handleInputChange}/>
                                <FormFeedback valid={!submitAttempted && holiday.name.length > 0}>Login
                                    name
                                    required</FormFeedback>
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Label>Enabled?</Label>
                            <Col className="short">
                                <InputSelect name="active" value={holiday.active} onChange={handleInputChange} isNumeric>
                                    <option value={1}>Active</option>
                                    <option value={0}>Inactive</option>
                                </InputSelect>
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col className="offset">
                                <InputSwitch name="is_recurring" onChange={handleInputChange} value={holiday.is_recurring} label="Is Recurring?"/>
                            </Col>
                        </FormGroup>
                        {!holiday.is_recurring ?
                            <FormGroup>
                                <Label>SingleDate</Label>
                                <Col className="medium">
                                    <InputDate name="date" value={holiday.date ?? ""} onChange={handleInputChange}/>
                                </Col>
                            </FormGroup>
                            :
                            <>
                                <FormGroup>
                                    <Label>Recurring Description</Label>
                                    <Col>
                                        <Input type="text" name="recurring_rule" placeholder="Recurring Rule" value={holiday.recurring_rule ?? ""} onChange={handleInputChange}/>
                                    </Col>
                                </FormGroup>
                                <FormGroup>
                                    <Col className="offset">
                                        <p className="form-control-plaintext">
                                            Must follow a format similar to one of these: 'Last Thursday of November'
                                            or 'December 25'. Do NOT enter a year.</p>
                                        <p className="form-control-plaintext">
                                            Note: Upcoming Company Holidays will be adjusted following the employee
                                            handbook:
                                            "Holidays that fall on a Saturday will be observed the preceding Friday, and
                                            holidays that fall on a Sunday will be observed the following Monday." </p>
                                    </Col>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Upcoming Company Holidays</Label>
                                    <Col className="form-control-plaintext">
                                        {upcomings.map((upcoming, idx) =>
                                            <React.Fragment key={idx}>
                                                {upcoming}<br/>
                                            </React.Fragment>
                                        )}
                                    </Col>
                                </FormGroup>
                            </>
                        }
                    </Form>

                </>
                :
                null
            }
            <ActivityOverlayControl show={!holiday}/>
            <BlockNav when={blockNavigation}/>
        </>
    );
};

export default Holiday;

import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../Stores/rootReducer";
import {Link, Redirect, useHistory} from "react-router-dom";
import {HasFeature, TTSFeatures} from "../../Data/TTSFeatures";
import {Button, Col, Row, Table} from "reactstrap";
import axios, {CancelTokenSource} from "axios";
import {APIProcess, GetPathThrough, SearchRows, GetPathComponentAfter, MasterDetail, MDMaster, InputSearch, MDLink, MDDetail} from '@denjpeters/intelliwakereact';
import ActiveDD from "../Generics/ActiveDD";
import {IWake} from "../../IWake";
import UserDiary from "./UserDiary";
import {initialtbluser_diary, Itbluser_diary} from "../../Data/Tables/tbluser_diary";
import {Itbluser_diary_file} from "../../Data/Tables/tbluser_diary_file";
import {Itbluser} from "../../Data/Tables/tbluser";
import moment from "moment";

interface IProps {
    tbluser: Itbluser,
    setTbluser: ((tbluser: Itbluser) => void),
    updateUser?: Function | undefined
}

interface IUserDiaryStructure {
    tbluser_diarys: Itbluser_diary[],
    tbluser_diary_files: Itbluser_diary_file[],
    tbluser_diary_types: Itbluser_diary[]
}

const UserDiarys = (props: IProps) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const isMounted = useRef(true);
    const {user} = useSelector((state: AppState) => state);
    const [userDiaryStructure, setUserDiaryStructure] = useState(null as IUserDiaryStructure | null);
    const [activeView, setActiveView] = useState(1 as 0 | 1 | -1);
    const [forceRefresh, setForceRefresh] = useState(false);
    const [search, setSearch] = useState('');

    const iWake = useMemo(() => new IWake(user, dispatch), [user, dispatch]);

    const onDragOver = (e: DragEvent) => {
        e.preventDefault();
    }

    const onDrop = (e: DragEvent) => {
        e.preventDefault();
    }

    useEffect(() => {
        window.addEventListener('dragover', onDragOver);
        window.addEventListener('drop', onDrop);

        return () => {
            window.removeEventListener('dragover', onDragOver);
            window.removeEventListener('drop', onDrop);
        }
    });

    const doForceRefresh = () => {
        setForceRefresh(!forceRefresh);
    }

    const updateDiary = (tbluser_diary: Itbluser_diary) => {
        setUserDiaryStructure(prevState => !prevState ? null : {
            ...prevState,
            tbluser_diarys: [
                ...prevState.tbluser_diarys.filter(diary => diary.id !== tbluser_diary.id),
                tbluser_diary
            ]
        });

        history.replace(GetPathThrough('UserDiary') + "/" + tbluser_diary.id);
    }

    const updateFile = (tbluser_diary_file: Itbluser_diary_file) => {
        setUserDiaryStructure(prevState => !prevState ? null : {
            ...prevState,
            tbluser_diary_files: [
                ...prevState.tbluser_diary_files.filter(diaryFile => diaryFile.id !== tbluser_diary_file.id),
                tbluser_diary_file
            ]
        });
    }

    useEffect(() => {
        let cancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();
        isMounted.current = true;

        APIProcess('UserDiary', 'GetList', {userID: props.tbluser.userID}, cancelTokenSource)(iWake)
            .then((results) => {
                // console.log(results);
                if (isMounted.current && cancelTokenSource) {
                    setUserDiaryStructure(results);
                }
            })
            .catch(() => {
                if (isMounted.current && cancelTokenSource) {
                    setUserDiaryStructure(null);
                }
            })
            .finally(() => {
                cancelTokenSource = null;
            });

        return () => {
            isMounted.current = false;
            if (cancelTokenSource) {
                cancelTokenSource.cancel();
                cancelTokenSource = null;
            }
        }
    }, [iWake, activeView, forceRefresh, props.tbluser.userID]);

    const diaries = useMemo(() =>
            !userDiaryStructure ? [] : SearchRows(userDiaryStructure.tbluser_diarys
                .filter(diary => activeView === -1 || diary.active === activeView)
                .map(diary => {return {...diary, typeName: ((userDiaryStructure.tbluser_diary_types.find(type => type.id === diary.tbluser_diary_type_id) ?? {})['name'] ?? "")}}), search)
                .sort((a, b) => b.diary_date.localeCompare(a.diary_date, undefined, {sensitivity: 'base'}))
        , [userDiaryStructure, activeView, search]);

    const selectedID = parseInt(GetPathComponentAfter('UserDiary') ?? 0);

    const userDiary = useMemo(() =>
        (!userDiaryStructure ? [] : userDiaryStructure.tbluser_diarys ?? []).find(diary => diary.id === selectedID) ?? {
            ...initialtbluser_diary,
            userID: props.tbluser.userID,
            diary_date: moment().format('YYYY-MM-DD'),
            create_userID: user.userID
        } as Itbluser_diary
        , [selectedID, props.tbluser.userID, user, userDiaryStructure])

    if (!HasFeature(user, TTSFeatures.Feature_TimeSheet_Admin)) {
        return (
            <Redirect to="/TimeEntry"/>
        );
    }

    return (
        <MasterDetail breakAt="sm" mdPath="/UserDiarys" backText="Diary Types">
            <MDMaster width="20em">
                <h5 className="text-center">Diaries</h5>
                <Row className="p-2">
                    <Col>
                        <ActiveDD size="sm" handleSelectID={setActiveView} selectedID={activeView} caret/>
                    </Col>
                    <Col className="text-right">
                        <Button color="secondary" size="sm" tag={Link} to={GetPathThrough("/User") + "/" + props.tbluser.userID + "/UserDiarys/UserDiary/New"}>Add</Button>
                    </Col>
                </Row>
                <Row className="p-2 form-condensed">
                    <Col>
                        <InputSearch triggerSearchText={setSearch} placeholder="Search"/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Table size="sm" borderless className="table-scrollable" hover>
                            <tbody>
                            {!userDiaryStructure ?
                                <tr><td className="text-secondary">Loading...</td></tr>
                                :
                                diaries.length === 0 ?
                                    <tr><td className="text-secondary">No Diaries</td></tr>
                                    :
                                    diaries.map((userDiary) =>
                                        <MDLink tag="tr" panel="UserDiary" className={(userDiary.active === 1 ? "" : " text-gray")} key={userDiary.id} id={userDiary.id}>
                                            <td className="td-xl">{moment(userDiary.diary_date).format('ll')}</td>
                                            <td>
                                                {!!userDiary.tbluser_diary_type_id ? <strong>{userDiary.typeName}<br/></strong> : ""}
                                                <small>{userDiary.name}</small>
                                            </td>
                                        </MDLink>
                                    )
                            }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </MDMaster>
            <MDDetail panel="UserDiary" titleText="Diary Type">
                {!!userDiaryStructure && !!userDiary ?
                    <UserDiary tbluser_diary={userDiary} tbluser_diary_types={userDiaryStructure.tbluser_diary_types} tbluser_diary_files={userDiaryStructure.tbluser_diary_files} updateDiary={updateDiary} updateFile={updateFile} forceRefresh={doForceRefresh}/>
                    :
                    null
                }
            </MDDetail>
        </MasterDetail>
    );
};

export default UserDiarys;
